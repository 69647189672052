import React, { useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useGet, usePost } from 'http/useInnovit';
import { useNavigate } from 'react-router';

import {
  EnumAddressType,
  EnumObjectType,
  EnumOrderPostStatus,
  EnumOrderStatus,
  EnumProductVersion,
  IDeliveryAddressViewModel,
  MissingPhoto,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq, rqPost } from 'http/apiRoutes';
import _ from 'lodash';
import {
  IOrderCompanyViewModel,
  IOrderPostViewModel,
  IOrderState,
  IHeaderOrderListItem,
  IOrderContactViewModel,
  IOrderAddViewModel,
  IOrderInitialStateData,
  IOrderPostSimpleViewModel,
} from 'Interfaces/IOrderConfig';
import { DeliveryAddressView } from 'components/ui/DeliveryAddressView';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import { SingleOrderPostProductView } from './OrderPost/SingleOrderPostProductView';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { SingleOrderCompanyView } from 'components/ui/SingleOrderCompanyView';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import dayjs from 'dayjs';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import GenerateGuid from 'functions/GenerateGuid';
import userAtom from 'data/atoms/userAtom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useGetObjectList } from 'functions/useGetObjectList';
import { atom } from 'jotai';
import { useTranslation } from 'react-i18next';

const activeHeaderIndexAtom = atom<any>(0);

const _addressList: IDeliveryAddressViewModel[] = [
  {
    id: '0',
    addressId: null,
    addressType: EnumAddressType.owner,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '1',
    addressId: null,
    addressType: EnumAddressType.company,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '2',
    addressId: null,
    addressType: EnumAddressType.department,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '3',
    addressId: null,
    addressType: EnumAddressType.employee,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '4',
    addressId: null,
    addressType: EnumAddressType.custom,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
];
const GenerateAddressList = (
  owner: any,
  employee: IOrderContactViewModel | null,
  company: IOrderCompanyViewModel | null,
  addressList: IDeliveryAddressViewModel[]
) => {
  let tmp: IDeliveryAddressViewModel[] = _.cloneDeep(_addressList);
  if (owner != null) {
    tmp[0].addressId = owner.deliveryAddress.id;
    tmp[0].street = owner.deliveryAddress.street;
    tmp[0].postalCode = String(owner.deliveryAddress.postalCode);
    tmp[0].area = owner.deliveryAddress.area;
    tmp[0].city = owner.deliveryAddress.city;
    tmp[0].country = owner.deliveryAddress.country;
    tmp[0].postBox = owner.deliveryAddress.postBox
      ? owner.deliveryAddress.postBox
      : '';
    tmp[0].attention = addressList[0].attention;
  }

  if (
    (company?.deliveryAddress != null && addressList[1].addressId === null) ||
    (company?.deliveryAddress != null &&
      addressList[1].addressId === company?.deliveryAddress.id)
  ) {
    tmp[1].addressId = company.deliveryAddress.id;
    tmp[1].street = company.deliveryAddress.street;
    tmp[1].postalCode = String(company.deliveryAddress.postalCode);
    tmp[1].area = company.deliveryAddress.area;
    tmp[1].city = company.deliveryAddress.city;
    tmp[1].country = company.deliveryAddress.country;
    tmp[1].postBox = company.deliveryAddress.postBox
      ? company.deliveryAddress.postBox
      : '';
    tmp[1].attention = addressList[1].attention;
  }

  if (
    (employee?.departmentAddress != null &&
      addressList[2].addressId === null) ||
    (employee?.departmentAddress != null &&
      addressList[2].addressId === employee.departmentAddress.id)
  ) {
    tmp[2].addressId = employee.departmentAddress.id;
    tmp[2].street = employee.departmentAddress.street;
    tmp[2].postalCode = String(employee.departmentAddress.postalCode);
    tmp[2].area = employee.departmentAddress.area;
    tmp[2].city = employee.departmentAddress.city;
    tmp[2].country = employee.departmentAddress.country;
    tmp[2].postBox = employee.departmentAddress.postBox
      ? employee.departmentAddress.postBox
      : '';
    tmp[2].attention = addressList[2].attention;
  }

  if (
    (employee?.homeAddress != null && addressList[3].addressId === null) ||
    (employee?.homeAddress != null &&
      addressList[3].addressId === employee.homeAddress.id)
  ) {
    tmp[3].addressId = employee.homeAddress.id;
    tmp[3].street = employee.homeAddress.street;
    tmp[3].postalCode = String(employee.homeAddress.postalCode);
    tmp[3].area = employee.homeAddress.area;
    tmp[3].city = employee.homeAddress.city;
    tmp[3].country = employee.homeAddress.country;
    tmp[3].postBox = employee.homeAddress.postBox
      ? employee.homeAddress.postBox
      : '';
    tmp[3].attention = addressList[3].attention;
  }

  tmp[4].addressId = addressList[4].id;
  tmp[4].street = addressList[4].street;
  tmp[4].postalCode = addressList[4].postalCode;
  tmp[4].area = addressList[4].area;
  tmp[4].city = addressList[4].city;
  tmp[4].country = addressList[4].country;
  tmp[4].postBox = addressList[4].postBox ? addressList[4].postBox : '';
  tmp[4].attention = addressList[4].attention;

  return tmp;
};
function useConvertToOrder(
  state: IOrderInitialStateData,
  responsibleId: string
) {
  // instead of setting the values to the first vendor in the list, find the first vendor with proper values
  // to avoid empty vendor errors
  const [activeAtomHeaderIndex] = useAtom(activeHeaderIndexAtom);
  console.log(
    'state.orderList[activeAtomHeaderIndex]',
    state.orderList[activeAtomHeaderIndex]
  );

  let _order: IOrderAddViewModel = {
    vendorInvoiceNumber: 'new',
    attention:
      state.orderList[activeAtomHeaderIndex].orderState.deliveryAddress
        .attention || '',
    deliveryArea:
      state.orderList[activeAtomHeaderIndex].orderState.deliveryAddress.area,
    deliveryCity:
      state.orderList[activeAtomHeaderIndex].orderState.deliveryAddress.city,
    deliveryCountry:
      state.orderList[activeAtomHeaderIndex].orderState.deliveryAddress.country,
    deliveryDate: null,
    deliveryPostalCode:
      state.orderList[activeAtomHeaderIndex].orderState.deliveryAddress
        .postalCode,
    deliveryStreet:
      state.orderList[activeAtomHeaderIndex].orderState.deliveryAddress.street,
    deliveryPostBox:
      state.orderList[activeAtomHeaderIndex].orderState.deliveryAddress
        .postBox || '',
    estimatedDeliveryDate: dayjs().add(4, 'day').toDate(),
    orderDate: dayjs().toDate(),
    orderNo: 'new',
    orderPosts: state.orderList[
      activeAtomHeaderIndex
    ].orderState.orderPostsList.map((post): IOrderPostSimpleViewModel => {
      return {
        id: post.id,
        orderNo: 'new',
        status: EnumOrderStatus.Ordered,
        companyId: post.companyId || null, // Fallback til tom streng hvis null
        productId: post.productId || null, // Fallback til tom streng hvis null
        ticketProductId: post.ticketProductId || null, // Fallback til tom streng hvis null
        deliveryDate: post.deliveryDate || new Date(), // Fallback til dagens dato, juster etter behov
        unitPrice: post.unitPrice,
        retailPrice: post.retailPrice,
        quantity: post.quantity,
        deliveredQuantity: post.deliveredQuantity || 0, // Fallback til 0 hvis null
        serialNumber: null, // Tar det første serienummeret, eller fallback til tom streng
        serialNumbers: post.serialNumbers || [], // Fallback til tom array hvis null
        ticketId: post.ticketId || null, // Fallback til tom streng hvis null
        prevOrderPostId: post.prevOrderPostId || null, // Fallback til tom streng hvis null
      };
    }),

    orderRetailValue:
      state.orderList[activeAtomHeaderIndex].orderState.totalOrderCost,
    orderStatus: EnumOrderStatus.Ordered,
    orderUnitCost:
      state.orderList[activeAtomHeaderIndex].orderState.totalOrderCost,
    responsibleId: responsibleId,
    shippingCost:
      state.orderList[activeAtomHeaderIndex].orderState.shippingCost,
    trackingNo: 'new',
    vendorContactId: null,
    vendorId:
      state.orderList[activeAtomHeaderIndex].orderState?.vendor?.id || '',
  };
  return _order;
}

type Action =
  | { type: 'RESET_FORM'; payload: number; activeHeaderIndex: number }
  | {
      type: 'SET_SHIPPING_COST';
      value: number;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_VENDOR';
      payload: IOrderCompanyViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'ADD_RIGHT_SIDE_HEADER_ITEM';
      payload: number;
      activeHeaderIndex: number;
    }
  | {
      type: 'REMOVE_RIGHT_SIDE_HEADER_ITEM';
      payload: number;
      activeHeaderIndex: number;
    }
  | {
      type: 'SWITCH_ORDER_PRODUCT';
      payload: IOrderPostViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_ACTIVE_ORDER_POST_INDEX';
      payload: number;
    }
  | {
      type: 'ADD_ORDER_PRODUCT';
      payload: IOrderPostViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_LEFT_SIDE_ORDER_POST';
      payload: IOrderPostViewModel[];
      activeHeaderIndex: number;
    }
  | {
      type: 'REMOVE_LEFT_SIDE_ORDER_POST';
      payload: IOrderPostViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'UPDATE_ORDER_POST';
      fieldType: 'unitPrice' | 'retailPrice' | 'quantity';
      field: keyof IOrderPostViewModel;
      value: number;
      id: string;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_DELIVERY_ADDRESSES';
      payload: IDeliveryAddressViewModel[];
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_DELIVERY_ADDRESS';
      payload: IDeliveryAddressViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'UPDATE_DELIVERY_ADDRESS';
      payload: IDeliveryAddressViewModel;
      activeHeaderIndex: number;
      addressIndex: number;
    }
  | {
      type: 'SET_NEW_PRODUCTS';
      payload: IBillingProductViewModel[];
    };

const initialStateHeaderOrderList: IHeaderOrderListItem[] = [
  {
    id: 0,
    url: null,
    orderState: {
      vendor: null,
      deliveryAddressList: _addressList,
      orderPostsList: [],
      deliveryAddress: _addressList[0],
      totalOrderCost: 0,
      shippingCost: 0,
    } as IOrderState,
  },
];

const isSaveOrderButtonDisabled = (
  headerOrderListItem: IHeaderOrderListItem
) => {
  let isDisabled = true;
  if (
    !!headerOrderListItem.orderState.vendor?.id &&
    !!headerOrderListItem.orderState.deliveryAddress.street &&
    headerOrderListItem.orderState.orderPostsList.length > 0
  ) {
    isDisabled = false;
  }
  return isDisabled;
};

const initialState: any = {
  orderPostsList: [] as IOrderPostViewModel[], // left side list
  orderList: initialStateHeaderOrderList, // right side object
  products: [] as IBillingProductViewModel[],
  activeOrderPostIndex: null,
};

function stateReducer(state: any, action: Action): any {
  let _orderList = _.cloneDeep(state.orderList);
  let _orderPostsList = _.cloneDeep(state.orderPostsList);

  switch (action.type) {
    case 'SET_SHIPPING_COST':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.shippingCost =
        +action.value;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'RESET_FORM':
      return initialState;
    case 'SET_LEFT_SIDE_ORDER_POST':
      return {
        ...state,
        orderPostsList: action.payload,
      };
    case 'ADD_RIGHT_SIDE_HEADER_ITEM':
      const newItem: IHeaderOrderListItem = {
        id: state.orderList.length, // or some other logic to assign a unique id
        url: MissingPhoto.COMPANY_URL,
        orderState: {
          vendor: null,
          deliveryAddressList: _addressList,
          orderPostsList: [],
          deliveryAddress: _addressList[4],
          totalOrderCost: 0,
          shippingCost: 0,
        } as IOrderState,
      };
      return {
        ...state,
        orderList: [...state.orderList, newItem],
      };
    case 'REMOVE_RIGHT_SIDE_HEADER_ITEM': {
      let _orderList = _.cloneDeep(state.orderList); // Cloning the orderList

      // Check if the orderList has only one item
      if (_orderList.length === 1) {
        // Replace the only item with a new default item
        return {
          ...state,
          orderList: [initialStateHeaderOrderList[0]],
          activeHeaderIndex: 0, // Reset activeHeaderIndex since the list is replaced
        };
      } else {
        // Filter out the item with the given id
        const updatedOrderList = _orderList.filter(
          (item: IHeaderOrderListItem) => item.id !== action.payload
        );

        // Calculate the new activeHeaderIndex
        let newActiveHeaderIndex = state.activeHeaderIndex;
        if (state.activeHeaderIndex >= updatedOrderList.length) {
          newActiveHeaderIndex = updatedOrderList.length - 1; // Adjust index if it's out of bounds
        }

        return {
          ...state,
          orderList: updatedOrderList,
          activeHeaderIndex: newActiveHeaderIndex, // Update the activeHeaderIndex
        };
      }
    }
    case 'SET_ACTIVE_ORDER_POST_INDEX': {
      return { ...state, activeOrderPostIndex: action.payload };
    }
    case 'SWITCH_ORDER_PRODUCT':
      const activeOrderPostsList =
        state.orderList[action.activeHeaderIndex].orderState.orderPostsList;

      const productToBeReplaced =
        activeOrderPostsList[state.activeOrderPostIndex];

      const updatedOrderPosts = activeOrderPostsList.map(
        (orderPost: IOrderPostViewModel, index: number) => {
          // Replace the order post at the active index
          if (index === state.activeOrderPostIndex) {
            return {
              ...action.payload,
              prevOrderPostId: productToBeReplaced.id,
            };
          }
          return orderPost; // Keep all other order posts as they are
        }
      );

      // Now, we update the state with these updated order posts
      return {
        ...state,
        orderList: state.orderList.map(
          (orderListItem: { orderState: any }, index: number) => {
            if (index === action.activeHeaderIndex) {
              return {
                ...orderListItem,
                orderState: {
                  ...orderListItem.orderState,
                  orderPostsList: updatedOrderPosts,
                },
              };
            }
            return orderListItem;
          }
        ),
      };

    case 'ADD_ORDER_PRODUCT': {
      // Check if the specified order exists and has an initialized orderPostList
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }

      if (
        !_orderList[action.activeHeaderIndex] ||
        !_orderList[action.activeHeaderIndex].orderState ||
        !Array.isArray(
          _orderList[action.activeHeaderIndex].orderState.orderPostsList
        )
      ) {
        // If not, initialize orderPostList as an empty array
        if (!_orderList[action.activeHeaderIndex]) {
          _orderList[action.activeHeaderIndex] = {
            orderState: { orderPostsList: [] },
          };
        } else if (!_orderList[action.activeHeaderIndex].orderState) {
          _orderList[action.activeHeaderIndex].orderState = {
            orderPostsList: [],
          };
        } else {
          _orderList[action.activeHeaderIndex].orderState.orderPostsList = [];
        }
      }
      // check if the orderPost already exists in the list
      const existingPostIndex = _orderList[
        action.activeHeaderIndex
      ].orderState.orderPostsList.findIndex(
        (p: IOrderPostViewModel) => p.id === action.payload.id
      );
      // add qunatity to existing post
      if (existingPostIndex !== -1) {
        _orderList[action.activeHeaderIndex].orderState.orderPostsList[
          existingPostIndex
        ].quantity += action.payload.quantity;
      } else {
        // Add the new product in an immutable way
        _orderList[action.activeHeaderIndex].orderState.orderPostsList = [
          ..._orderList[action.activeHeaderIndex].orderState.orderPostsList,
          action.payload,
        ];
      }
      // calculate total cost
      let totalCost = 0;
      _orderList[action.activeHeaderIndex].orderState.orderPostsList.forEach(
        (post: IOrderPostViewModel) => {
          totalCost += post.unitPrice * post.quantity;
        }
      );
      _orderList[action.activeHeaderIndex].orderState.totalOrderCost =
        totalCost;
      return {
        ...state,
        orderList: _orderList,
      };
    }
    case 'REMOVE_LEFT_SIDE_ORDER_POST':
      _orderPostsList = _orderPostsList.filter(
        (orderPost: IOrderPostViewModel) => orderPost.id !== action.payload.id
      );
      return {
        ...state,
        orderPostsList: _orderPostsList,
      };

    case 'UPDATE_ORDER_POST':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      if (!_orderList[action.activeHeaderIndex]?.orderState?.orderPostsList) {
        console.error('Order or orderPostsList not found');
        return state; // Handle this case as per your app's needs.
      }

      if (action.fieldType === 'quantity') {
        // Handle the update or removal based on action.value.
        if (true) {
          // Update the item if action.value is not 0.
          _orderList[action.activeHeaderIndex].orderState.orderPostsList =
            _orderList[action.activeHeaderIndex].orderState.orderPostsList.map(
              (post: IOrderPostViewModel) => {
                if (post.id === action.id) {
                  if (
                    action.value >= 0 &&
                    action.value < post.originalQuantity
                  ) {
                    // Get total quantity for the specific post id
                    let totalQuantity = state.orderList.reduce(
                      (
                        total: number,
                        headerOrderListItem: IHeaderOrderListItem
                      ) => {
                        return (
                          total +
                          headerOrderListItem.orderState.orderPostsList.reduce(
                            (
                              subTotal: number,
                              orderPost: IOrderPostViewModel
                            ) => {
                              // Add to subtotal only if the id matches
                              return orderPost.id === action.id
                                ? subTotal + orderPost.quantity
                                : subTotal;
                            },
                            0
                          )
                        );
                      },
                      0
                    );

                    // Adjust the total quantity by subtracting the current post's quantity
                    totalQuantity -= post.quantity;

                    // Calculate the difference
                    const difference =
                      post.originalQuantity - totalQuantity - action.value;

                    // Check if a post with the same id already exists in _orderPostsList
                    const existingPostIndex = _orderPostsList.findIndex(
                      (p: IOrderPostViewModel) => p.id === post.id
                    );
                    if (existingPostIndex !== -1) {
                      // Update the existing post's quantity, ensuring it doesn't exceed originalQuantity
                      const updatedQuantity =
                        post.originalQuantity - totalQuantity - action.value;
                      _orderPostsList[existingPostIndex] = {
                        ..._orderPostsList[existingPostIndex],
                        quantity: updatedQuantity,
                      };
                    } else {
                      // Create a new post with the difference and add to _orderPostsList
                      if (
                        post.productVersion !== EnumProductVersion.OrderProduct
                      ) {
                        const newPost = { ...post, [action.field]: difference };
                        newPost.unitPrice = post.originalUnitPrice;
                        newPost.retailPrice = post.originalRetailPrice;
                        newPost.originalQuantity = post.originalQuantity;
                        _orderPostsList.push(newPost);
                      }
                    }
                    // Update the current post with action.value
                    return { ...post, [action.field]: action.value };
                  } else if (action.value > post.originalQuantity) {
                    // If action.value exceeds originalQuantity, remove the post from _orderPostsList
                    _orderPostsList = _orderPostsList.filter(
                      (p: IOrderPostViewModel) => p.id !== post.id
                    );

                    // Return the post with updated value
                    return { ...post, [action.field]: action.value };
                  } else {
                    // If action.value does not meet the specific condition, just update the post
                    return { ...post, [action.field]: action.value };
                  }
                } else {
                  return post;
                }
              }
            );
          // Delete item from the list if quantity is 0
          console.log('action.activeHeaderIndex', action.activeHeaderIndex);

          if (action.value === 0) {
            _orderList[action.activeHeaderIndex].orderState.orderPostsList =
              _orderList[
                action.activeHeaderIndex
              ].orderState.orderPostsList.filter(
                (p: IOrderPostViewModel) => p.id !== action.id
              );
          }
        }
        // calculate total cost
        let totalCost = 0;
        _orderList[action.activeHeaderIndex].orderState.orderPostsList.forEach(
          (post: IOrderPostViewModel) => {
            totalCost += post.unitPrice * post.quantity;
          }
        );
        _orderList[action.activeHeaderIndex].orderState.totalOrderCost =
          totalCost;
        return {
          ...state,
          orderList: _orderList,
          orderPostsList: _orderPostsList,
        };
      } else {
        // Update the item if action.value is not 0.
        _orderList[action.activeHeaderIndex].orderState.orderPostsList =
          _orderList[action.activeHeaderIndex].orderState.orderPostsList.map(
            (post: IOrderPostViewModel) => {
              if (post.id === action.id) {
                return { ...post, [action.field]: action.value };
              } else {
                return post;
              }
            }
          );
        // calculate total cost
        let totalCost = 0;
        _orderList[action.activeHeaderIndex].orderState.orderPostsList.forEach(
          (post: IOrderPostViewModel) => {
            totalCost += post.unitPrice * post.quantity;
          }
        );
        _orderList[action.activeHeaderIndex].orderState.totalOrderCost =
          totalCost;
        return {
          ...state,
          orderList: _orderList,
        };
      }
    case 'SET_VENDOR':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      } else {
        console.log(
          'activeHeaderIndex is found at setVendor',
          action.activeHeaderIndex
        );
      }
      _orderList[action.activeHeaderIndex].orderState.vendor = action.payload;
      _orderList[action.activeHeaderIndex].url = action.payload.url;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'SET_DELIVERY_ADDRESSES':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.deliveryAddressList =
        action.payload;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'SET_DELIVERY_ADDRESS':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.deliveryAddress =
        action.payload;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'UPDATE_DELIVERY_ADDRESS':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.deliveryAddressList[
        action.addressIndex
      ] = action.payload;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'SET_NEW_PRODUCTS':
      return {
        ...state,
        products: action.payload,
      };
  }
}

export const OrderPosts = () => {
const { t } = useTranslation();

  // INITIALIZATION
  const { height } = useWindowsDimension();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [activeHeaderIndex, setActiveHeaderIndex] = useState<number>(0);
  const [confirmSave, setConfirmSave] = useState(false);
  const [activeAtomHeaderIndex, setActiveAtomHeaderIndex] = useAtom(
    activeHeaderIndexAtom
  );

  console.log('ORDERPOSTS STATE', state);

  const [owner] = useAtom(ownerAtom);
  const [user] = useAtom(userAtom);
  let _order = _.cloneDeep(state);
  let newOrder = useConvertToOrder(_order, user.id);
  // api
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let companyId = 'new';
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
    setIsObjectList(false);
  }, [objectDrawerList]);

  const validFieldTypes: Array<'unitPrice' | 'retailPrice' | 'quantity'> = [
    'unitPrice',
    'retailPrice',
    'quantity',
  ];
  let navigate = useNavigate();

  // API
  let isEnabled: boolean = true;
  const { data: orderPostData } = useGet(
    rq.orderPosts('0', isEnabled),
    isEnabled
  );
  const postTicket = usePost(rqPost.order('0', isEnabled), isEnabled);
  let orderPostsList = state.orderList[0].orderState.orderPostsList;

  // HANDLES
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleAddItem = () => {
    if (state.orderList.length < 7) {
      dispatch({
        type: 'ADD_RIGHT_SIDE_HEADER_ITEM',
        payload: 0,
        activeHeaderIndex: activeHeaderIndex,
      });
    }
  };
  const handleSelectionChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | null
  ): void => {
    if (newValue !== null) {
      setActiveHeaderIndex(newValue);
      setActiveAtomHeaderIndex(newValue);
    }
  };
  const handleChangeVendor = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Vendor:
        let _vendor = selectedObject as IOrderCompanyViewModel;
        dispatch({
          type: 'SET_VENDOR',
          payload: _vendor,
          activeHeaderIndex: activeHeaderIndex,
        });
        break;
      default:
        break;
    }
  };
  const handleOrderPostAssign = (e: any, obj: IOrderPostViewModel) => {
    e.preventDefault();
    dispatch({
      type: 'ADD_ORDER_PRODUCT',
      payload: obj,
      activeHeaderIndex: activeHeaderIndex,
    });
    dispatch({
      type: 'REMOVE_LEFT_SIDE_ORDER_POST',
      payload: obj,
      activeHeaderIndex: activeHeaderIndex,
    });

    let addresses = GenerateAddressList(
      owner,
      obj.companyContact,
      obj.company,
      state.orderList[activeHeaderIndex].orderState.deliveryAddressList
    );
    dispatch({
      type: 'SET_DELIVERY_ADDRESSES',
      payload: addresses,
      activeHeaderIndex: activeHeaderIndex,
    });
  };
  const handleProductBlur = (
    e: any,
    obj: IOrderPostViewModel,
    name: string,
    value: number
  ) => {
    e.preventDefault();
    if (validFieldTypes.includes(name as any)) {
      dispatch({
        type: 'UPDATE_ORDER_POST',
        fieldType: name as 'unitPrice' | 'retailPrice' | 'quantity',
        field: name as keyof IOrderPostViewModel,
        value: value,
        id: obj.id,
        activeHeaderIndex: activeHeaderIndex,
      });
    } else {
      console.error('Invalid field type');
    }
  };
  const handleChangeDeliveryAddress = (
    deliveryAddress: IDeliveryAddressViewModel
  ) => {
    dispatch({
      type: 'SET_DELIVERY_ADDRESS',
      payload: deliveryAddress,
      activeHeaderIndex: activeHeaderIndex,
    });
    let addressIndex = Number(deliveryAddress.addressType);
    dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS',
      payload: deliveryAddress,
      activeHeaderIndex: activeHeaderIndex,
      addressIndex: addressIndex,
    });
  };

  const handleSwitchProduct = (
    e: any,
    selectedObject: IBillingProductViewModel,
    objectType: EnumObjectType
  ) => {
    let _orderPost: IOrderPostViewModel = {
      id: GenerateGuid(),
      status: EnumOrderPostStatus.InTransit,
      deliveryDate: null,
      companyId: owner.id,
      company: null,
      companyContactId: null,
      companyContact: null,
      ticketId: null,
      ticket: null,
      productId: selectedObject.id,
      product: selectedObject,
      productVersion: EnumProductVersion.OrderProduct, //key information
      ticketProductId: null,
      ticketProduct: {
        id: null,
        companyProduct: null,
        product: selectedObject,
        ticket: null,
      },
      unitPrice: selectedObject.unitPrice,
      retailPrice: selectedObject.retailPrice,
      quantity: 1,
      deliveredQuantity: 0,
      serialNumbers: null,
      isSerialNumber: false,
      originalQuantity: 1,
      originalUnitPrice: selectedObject.unitPrice,
      originalRetailPrice: selectedObject.retailPrice,
      url: selectedObject.url,
      responsibleId: '',
      prevOrderPostId: null,
    };

    dispatch({
      type: 'SWITCH_ORDER_PRODUCT',
      payload: _orderPost,
      activeHeaderIndex: activeHeaderIndex,
    });
  };
  const handleChangeAddProduct = (
    event: any,
    selectedObject: IBillingProductViewModel,
    objectType: EnumObjectType
  ) => {
    event.preventDefault();
    //console.log('handleChangeAddProduct', selectedObject);
    //converting the product to orderPost
    let _orderPost: IOrderPostViewModel = {
      id: GenerateGuid(),
      status: EnumOrderPostStatus.InTransit,
      deliveryDate: null,
      companyId: owner.id,
      company: null,
      companyContactId: null,
      companyContact: null,
      ticketId: null,
      ticket: null,
      productId: selectedObject.id,
      product: selectedObject,
      productVersion: EnumProductVersion.OrderProduct, //key information
      ticketProductId: null,
      ticketProduct: {
        id: null,
        companyProduct: null,
        product: selectedObject,
        ticket: null,
      },
      unitPrice: selectedObject.unitPrice,
      retailPrice: selectedObject.retailPrice,
      quantity: 1,
      deliveredQuantity: 0,
      serialNumbers: null,
      isSerialNumber: false,
      originalQuantity: 1,
      originalUnitPrice: selectedObject.unitPrice,
      originalRetailPrice: selectedObject.retailPrice,
      url: selectedObject.url,
      responsibleId: '',
      prevOrderPostId: null,
    };
    dispatch({
      type: 'ADD_ORDER_PRODUCT',
      payload: _orderPost,
      activeHeaderIndex: activeHeaderIndex,
    });
    let addresses = GenerateAddressList(
      owner,
      null,
      null,
      state.orderList[activeHeaderIndex].orderState.deliveryAddressList
    );
    dispatch({
      type: 'SET_DELIVERY_ADDRESSES',
      payload: addresses,
      activeHeaderIndex: activeHeaderIndex,
    });
  };

  // USE EFFECTS

  useEffect(() => {
    console.log('activeHeaderIndex', activeHeaderIndex);
  }, [activeHeaderIndex]);

  // orders

  useEffect(() => {
    if (orderPostData) {
      const _orderPosts: IOrderPostViewModel[] = orderPostData.orderPosts.map(
        (obj: IOrderPostViewModel) => ({
          id: obj.id,
          status: 0,
          deliveryDate: obj.ticketProduct?.ticket?.deliveryDate || null,
          companyId: obj.company?.id || null,
          company: obj.company,
          companyContactId: obj.companyContact?.id || null,
          companyContact: obj.companyContact,
          ticketId: obj.ticketProduct?.ticket?.id || null,
          ticket: obj.ticketProduct?.ticket,
          productId: obj.ticketProduct?.product?.id || null,
          product: obj.ticketProduct?.product || null,
          productVersion: obj.ticketProduct?.product
            ? 0
            : obj.ticketProduct?.companyProduct
            ? 1
            : null,
          ticketProduct: obj.ticketProduct,
          unitPrice: obj.unitPrice,
          retailPrice: obj.retailPrice,
          quantity: obj.quantity,
          originalQuantity: obj.quantity,
          originalUnitPrice: obj.unitPrice,
          originalRetailPrice: obj.retailPrice,
        })
      );
      dispatch({
        type: 'SET_LEFT_SIDE_ORDER_POST',
        payload: _orderPosts,
        activeHeaderIndex: activeHeaderIndex,
      });
    }
  }, [orderPostData]);
  // vendors

  // ON SUBMIT
  const onSubmit = () => {
    console.log('onSubmit- newOrder', newOrder);

    postTicket.mutate(newOrder, {
      onSuccess: (res: any) => {
        dispatch({
          type: 'REMOVE_RIGHT_SIDE_HEADER_ITEM',
          payload: 0,
          activeHeaderIndex: activeHeaderIndex,
        });
        let addresses = GenerateAddressList(
          owner,
          null,
          null,
          state.orderList[activeHeaderIndex].orderState.deliveryAddressList
        );
        dispatch({
          type: 'SET_DELIVERY_ADDRESSES',
          payload: addresses,
          activeHeaderIndex: activeHeaderIndex,
        });
      },
    });
  };

  const activeOrderPostsList =
    state.orderList[activeHeaderIndex].orderState.orderPostsList;
  const hasZeroUnitPrice = activeOrderPostsList.some(
    (post: { unitPrice: number }) => post.unitPrice === 0
  );

  console.log(
    'state.orderList[activeHeaderIndex].orderState.orderPostsList',
    state.orderList[activeHeaderIndex].orderState.orderPostsList,
    hasZeroUnitPrice
  );
  // console.log('objectListData', objectListData);
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      {/* Left side */}
      <Grid item xs={6} sx={{ pr: 0.5, m: 0 }}>
        <SearchFilter 
          Icon={CreditCardIcon} 
          Header={t("Orders.OrderPosts.unmanagedProducts")} 
        />
        <Grid
          item
          xs={12}
          sx={{
            p: 2,
            m: 0,
            backgroundColor: 'primary.main',
          }}
        >
          <List
            sx={{
              p: 2,
              height: Math.ceil(height - 280),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {!!state.orderPostsList &&
              state.orderPostsList.map((orderPost: IOrderPostViewModel, index: number) => (
                <Grid
                  sx={{
                    backgroundColor: 'transparent',
                    border: 0,
                    boxShadow: 'none',
                  }}
                  key={index}
                  onClick={(e) => {
                    handleOrderPostAssign(e, orderPost);
                  }}
                >
                  <SingleOrderPostProductView
                    orderPost={orderPost}
                    isDisabled={true}
                    handleBlur={null}
                  />
                </Grid>
              ))}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          />
        </Grid>
    </Grid>
  
      {/*   RIGHT SIDE    */}
      <Grid item xs={6} sx={{ pl: 0.5, m: 0 }}>
        {/*   HEADER    */}
        <Paper
          variant='elevation3'
          sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <Grid container display={'flex'} flexDirection={'row'}>
            <Grid item xs={1} sx={{ p: 0, m: 0 }}>
              <CreditCardIcon sx={{ fontSize: 32, m: 1.5 }} />
            </Grid>
            <Grid item xs={2} sx={{ pl: 1, m: 0 }}>
              <Typography
                sx={{
                  m: 2,
                  ml: -1,
                  pt: 0.5,
                  flexGrow: 1,
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                  fontSize: 14,
                  letterSpacing: 2,
                }}
              >
               {t("Orders.OrderPosts.orders")}

              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ p: 0, m: 0, mt: 0.7, pr: 1 }}>
              <ToggleButtonGroup
                sx={{
                  m: 0,
                  p: 0,
                  borderWidth: 0,
                  backgroundColor: 'primary.back',
                }}
                color='primary'
                size='small'
                orientation='horizontal'
                value={activeHeaderIndex}
                aria-label='text alignment'
              >
                {state.orderList.map(
                  (obj: IHeaderOrderListItem, index: number) => {
                    if (!obj || !obj.orderState) {
                      return null;
                    }
                    return (
                      <ToggleButton
                        key={index}
                        sx={{
                          m: 0.3,
                          p: 0,
                          borderRadius: 2,
                          borderColor:
                            index === activeHeaderIndex
                              ? 'primary.text'
                              : 'primary.main',
                        }}
                        value={index}
                        onClick={(e: any) =>
                          handleSelectionChange(e, index as number)
                        }
                        aria-label='left aligned'
                      >
                        <Grid
                          item
                          sx={{
                            m: 0,
                            p: 0,
                            width: 96,
                            borderRadius: 2,
                            height: 48,
                            backgroundColor: obj.url
                              ? 'primary.light'
                              : 'primary.main',
                          }}
                        >
                          <img
                            src={obj.url || MissingPhoto.COMPANY_URL}
                            style={{
                              margin: 0,
                              padding: 0,
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20,
                              borderBottomLeftRadius: 20,
                              borderBottomRightRadius: 20,
                              width: '100%',
                              height: '100%',
                              borderRadius: 10,
                              objectFit: 'scale-down',
                            }}
                            alt={'logo'}
                          />
                        </Grid>
                      </ToggleButton>
                    );
                  }
                )}
                <Button
                  sx={{
                    color: 'primary.text',
                    backgroundColor: 'primary.light',
                    borderRadius: 2,
                    width: 48,
                    height: 54,
                    m: 0,
                    p: 0,
                    // outline: '1px solid red',
                  }}
                  variant='contained'
                  key={99}
                  value='addItem' // The value can be anything that distinguishes this button
                  onClick={handleAddItem}
                  disabled={true}
                >
                  <AddRoundedIcon sx={{ fontSize: 32 }} />
                </Button>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Paper>
        {/*    VENDOR INFO    */}
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            borderRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            // backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
            }}
          >
            <Grid item xs={6} sx={{}}>
              {!!state.orderList[activeHeaderIndex].orderState?.vendor ? (
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: 'primary.back', borderRadius: 2 }}
                >
                  <Stack direction='row'>
                    <Grid item sx={{ flexGrow: 1 }}>
                      <SingleOrderCompanyView
                        company={
                          state.orderList[activeHeaderIndex]?.orderState?.vendor
                        }
                      />
                    </Grid>
                    <Grid item sx={{ p: 1 }}>
                      <ObjectDrawer
                        buttonContext={t("Orders.OrderPosts.vendor")}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.Vendor]}
                        handleChange={handleChangeVendor}
                        buttonHeight={32}
                        buttonWidth={32}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              ) : (
                <ObjectDrawer
                  buttonContext={t("Orders.OrderPosts.vendor")}
                  buttonIcon={null}
                  objectList={!!objectDrawerList ? objectDrawerList : []}
                  objectType={[EnumObjectType.Vendor]}
                  handleChange={handleChangeVendor}
                  buttonHeight={88}
                  buttonWidth={'100%'}
                />
              )}
            </Grid>
            <Grid item xs={2} sx={{}}></Grid>
            <Grid item xs={4} sx={{ m: 0, p: 0, pr: 1 }}>
              <Stack direction={'column'} sx={{}}>
                <Stack direction={'row'} sx={{}}>
                  <Typography
                    sx={{ m: 1, pt: 0.5, fontWeight: 'bold', width: '60%' }}
                  >
                    {t("Orders.OrderPosts.totalOrderCost")}:
                  </Typography>
                  <Typography
                    sx={{
                      m: 1,
                      pt: 0.5,
                      width: '40%',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    {state.orderList[
                      activeHeaderIndex
                    ].orderState.totalOrderCost.toFixed(2)}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    width: '100%',
                    letterSpacing: 1,
                    pl: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                {t("Orders.OrderPosts.shippingCost")}:

                </Typography>
                <TextField
                  sx={{
                    mt: -0.5,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    '& .MuiInputAdornment-root': {
                      '& p': {
                        fontSize: '14px',
                        textAlign: 'left',
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontSize: 14,
                      paddingRight: 0,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <p>.00</p>
                      </InputAdornment>
                    ),
                  }}
                  value={state.orderList[
                    activeHeaderIndex
                  ].orderState.shippingCost.toFixed()}
                  onFocus={handleFocus}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_SHIPPING_COST',
                      value: +e.target.value,
                      activeHeaderIndex: activeHeaderIndex,
                    })
                  }
                  /* onBlur={(e: any) => {
                    !!handleBlur &&
                      handleBlur(e, state, 'unitPrice', +e.target.value);
                  }}
                  disabled={isDisabled} */
                />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <SearchFilter
          Icon={ContactMailRoundedIcon}
          Header={t("Orders.OrderPosts.productToBeOrdered")}
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
          gfx={
            <ObjectDrawer
              buttonContext={t("Orders.OrderPosts.addProduct")}
              buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
              objectList={!!objectDrawerList ? objectDrawerList : []}
              objectType={[EnumObjectType.Product]}
              handleChange={handleChangeAddProduct}
              buttonHeight={30}
              buttonWidth={30}
            />
          }
        />
        {/*   ORDER POSTS    */}
        <Grid
          item
          xs={12}
          sx={{
            p: 2,
            m: 0,
            backgroundColor: 'primary.main',
          }}
        >
          <List
            sx={{
              p: 2,
              height: Math.ceil(height - 686),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {!!state.orderList[activeHeaderIndex].orderState.orderPostsList &&
              state.orderList[activeHeaderIndex].orderState.orderPostsList?.map(
                (orderPost: IOrderPostViewModel, index: number) => (
                  <Grid
                    sx={{
                      backgroundColor: 'transparent',
                      border: 0,
                      boxShadow: 'none',
                    }}
                    key={index}
                  >
                    <Grid
                      item
                      sx={{
                        position: 'absolute',
                        right: 20,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        zIndex: 999,
                      }}
                      onClick={() => {
                        dispatch({
                          type: 'SET_ACTIVE_ORDER_POST_INDEX',
                          payload: index,
                        });
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={t("Orders.OrderPosts.selectIssueType")}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[
                          EnumObjectType.Product,
                          EnumObjectType.StorageProduct,
                          EnumObjectType.unassignedCompanyProduct,
                          EnumObjectType.Package,
                        ]}
                        handleChange={handleSwitchProduct}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          backgroundColor: 'primary.light',
                          display: 'flex', // <-- Set this to flex
                          justifyContent: 'center', // <-- This centers children horizontally
                          alignItems: 'center', // <-- This centers children vertically
                          borderRadius: 2,
                          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                          '&:hover': {
                            filter: 'brightness(120%)',
                          },
                        }}
                      >
                        <IconButton
                          sx={{
                            p: 0,
                            m: 0,
                            width: 18,
                            height: 18,
                            //color: 'secondary.light',
                            /*  '&:hover': {
                color: 'secondary.light'
              } */
                          }}
                          onClick={() => {
                            dispatch({
                              type: 'UPDATE_ORDER_POST',
                              fieldType: 'quantity',
                              field: 'quantity',
                              value: 0,
                              id: orderPost.id,
                              activeHeaderIndex: activeHeaderIndex,
                            });
                          }}
                        >
                          <DeleteForeverIcon
                            sx={{ fontSize: 32, color: 'warning.light' }}
                          />
                        </IconButton>
                      </Box>
                    </Grid>

                    <SingleOrderPostProductView
                      orderPost={orderPost}
                      isDisabled={false}
                      handleBlur={handleProductBlur}
                    />
                  </Grid>
                )
              )}
          </List>
        </Grid>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,

            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid item xs={12} sx={{ p: 0, m: 0, mb: 1 }}>
            <DeliveryAddressView
              addressList={
                state.orderList[activeHeaderIndex].orderState
                  .deliveryAddressList || []
              }
              handleChange={handleChangeDeliveryAddress}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              p: 0,
              m: 0,
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              variant='contained'
              //type='submit'
              onClick={
                hasZeroUnitPrice ? () => setConfirmSave(!confirmSave) : onSubmit
              }
              disabled={isSaveOrderButtonDisabled(
                state.orderList[activeHeaderIndex]
              )}
            >
              {t("Orders.OrderPosts.saveOrder")}

            </Button>
            {confirmSave && hasZeroUnitPrice ? (
              <>
                <Button
                  variant='contained'
                  sx={{ mr: 2 }}
                  color='secondary'
                  onClick={onSubmit}
                >
                  {t("Orders.OrderPosts.confirmSave")}
                </Button>
                <Typography sx={{ color: 'secondary', mr: 2, mt: 1 }}>
                {t("Orders.OrderPosts.saveOrderNote")}

                </Typography>
              </>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

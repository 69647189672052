import React, { useEffect, useState } from 'react';
import { Grid, List, Paper, Typography } from '@mui/material';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SearchFilter } from 'components/ui/SearchFilter';
import SingleAgreementView from 'components/ui/SingleAgreementView';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';

import GetColorByName from 'functions/GetColorByName';
import { NoEncryption } from '@mui/icons-material';
import { t } from 'i18next';

let _ = require('lodash');

export const Agreements = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowsDimension();
  const { companyId } = useParams();

  // API
  let isEnabled = companyId !== 'new' ? true : false;
  const { data: agreementData } = useGet(
    rq.agreement(companyId || '', isEnabled),
    isEnabled
  );

  // States
  const [selectedPeriod, setSelectedPeriod] = useState<string>('Year'); // Default selected period
  const [agreements, setAgreements] = useState<any[]>([]);
  const [relevantBindingPeriods, setRelevantBindingPeriods] = useState<
    { period: string; monthlyCost: number }[]
  >([]);
  // Filter relevant binding periods with monthly cost
  useEffect(() => {
    if (agreementData && Array.isArray(agreementData.periods)) {
      const bindingPeriodsWithTotalCost = agreementData.periods
        .filter((period: any) => period.products.length > 0)
        .map((period: any) => ({
          period: period.name,
          monthlyCost: period.totalPrice, // Use totalPrice as monthlyCost
        }));
      // Use type assertion to explicitly define the type
      setRelevantBindingPeriods(
        bindingPeriodsWithTotalCost as {
          period: string;
          monthlyCost: number;
        }[]
      );
      // Calculate total cost
    }
  }, [agreementData]);

  // Handle binding period selection
  const handleBindingPeriodSelect = (period: string) => {
    setSelectedPeriod(period);
  };

  //console.log(agreementData);

  // Display a loading message or placeholder while data is being fetched
  if (!agreementData) {
    return <div>Loading...</div>;
  }

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: 0.5, m: 0 }}>
        <SearchFilter
          Icon={PeopleAltTwoToneIcon}
          Header='Agreements'
          gfx={
            <Typography
              sx={{ pr: 4, color: 'secondary.text' }}
            >{`Total`}</Typography>
          }
        />
        {/* Display relevant binding periods on the left */}
        <List
          sx={{
            height: Math.ceil(height - 244),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {relevantBindingPeriods.map((periodWithCost, index) => (
            <Paper
              variant='elevation3'
              sx={{
                mb: 1,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderLeft: 8,
                borderRight: 3,
                borderRadius: 3,
                borderColor: GetColorByName(`${periodWithCost?.period}`),
                height: '100%',
                '&:hover': {
                  filter: 'brightness(125%)', // Define the hover color here
                },
                maxHeight: 80,
                flexDirection: 'row',
                paddingLeft: 3,
                paddingRight: 3,
              }}
              key={index}
              onClick={() => {
                handleBindingPeriodSelect(periodWithCost.period);
              }}
            >
              {/* Display the relevant binding period category name */}
              <Typography variant='subtitle1'>
                {periodWithCost.period}
              </Typography>
              {/* Display the monthly cost on the right */}
              {periodWithCost.monthlyCost !== undefined && (
                <Typography
                  variant='body1'
                  sx={{ marginLeft: 'auto', color: 'secondary.text' }}
                >{`${periodWithCost.monthlyCost.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}</Typography>
              )}
            </Paper>
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          {/* Display totalPriceThisMonth in the header */}
        </Paper>
      </Grid>
      {/* RIGHT  SIDE */}
      <Grid item xs={6} sx={{ m: 0, p: 0, pl: 0.5 }}>
        <SearchFilter
          Icon={ImportantDevicesRoundedIcon}
          Header={t('Products.ProductList.products')}
          // filterValue={filterType}
          // handleFilterChange={handleFilterChange}
          // handleSearch={handleSearch}
          // handleNew={handleNew}
          // selectList={productTypes}
          hasSelectAll={true}
        />
        <Paper
          variant='elevation3'
          sx={{
            // p: 2,
            height: Math.ceil(height - 180),
          }}
        >
          <List
            sx={{
              height: Math.ceil(height - 245),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.dark',
              overflow: 'auto',
            }}
          >
            {/* Render agreements for the selected binding period */}
            {agreementData.periods
              .filter((period: any) => period.name === selectedPeriod)
              .map((filteredPeriod: any) =>
                filteredPeriod.products.map((agreement: any, index: number) => (
                  <Paper variant='elevation3' key={index}>
                    <Grid item key={index} xs={12} sx={{ pr: 0, m: 0 }}>
                      <SingleAgreementView key={index} agreement={agreement} />
                    </Grid>
                  </Paper>
                ))
              )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const getSecondsDifference = (fromDate, toDate) => {
  let diffMilliseconds = toDate - fromDate;

  // Calculate the number of hours and minutes
  let diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
  let diffMinutes = Math.floor(
    (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  // Combine the hours and minutes into a HH:mm format string
  let diffTime = diffHours * 60 + diffMinutes;
  return diffTime;
};

import React from 'react';
import { Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';

var _ = require('lodash');

export const FormDeduction = (props:any) => {
  const { control, name, label, errors, inputFormat } = props;
  const errorMessage = _.get(errors, name);
  return (
    <>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DesktopDatePicker
            //  label={label}
            inputFormat={inputFormat}
            mask=''
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                sx={{ m: 0, p: 0 }}
                {...params}
                label={label}
                error={!!error}
                helperText={errorMessage?.message}
              />
            )}
          />
        )}
      />
    </LocalizationProvider> */}
    </>
  );
};

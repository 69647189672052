import { createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CustomNavigationClient } from './utils/NavigationClient';
import PageLayout from './pages/PageLayout';
import { SnackbarProvider } from 'notistack';
import Button from '@mui/material/Button';
import { DevTools } from 'jotai-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  },
});

//console.log('environment', process.env.NODE_ENV);

const App = ({ pca }) => {
  // msal
  const history = useNavigate();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  // add action to all snackbars
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  //console.log('app')
  return (
    <>
      <DevTools />
      <MsalProvider instance={pca}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            preventDuplicate
            autoHideDuration={3000}
            maxSnack={2}
            ref={notistackRef}
            action={(key) => (
              <Button onClick={onClickDismiss(key)}>Dismiss</Button>
            )}
          >
            <PageLayout />
          </SnackbarProvider>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
      </MsalProvider>
    </>
  );
};
export default App;

import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import dayjs, { Dayjs } from 'dayjs';
import { ITicketPostBillingViewModel } from 'Interfaces/ITicketConfig';

type Props = {
  rates: IRateViewModel[];
  startTime: Dayjs | string | Date | null;
  endTime: Dayjs | string | Date | null;
  startDeduction: Dayjs | string | Date | null;
  endDeduction: Dayjs | string | Date | null;
  isInHouse: boolean;
  workRole: IWorkRoleViewModel;
};

export default function CalculateRateValue({
  rates,
  startTime,
  endTime,
  startDeduction,
  endDeduction,
  isInHouse,
  workRole,
}: Props): ITicketPostBillingViewModel {
  const hourlyRate = isInHouse
    ? workRole.inHouseHourlyRate
    : workRole.onSiteHourlyRate;
  const hourlyCost = isInHouse
    ? workRole.inHouseHourlyRateCost
    : workRole.onSiteHourlyRateCost;

  // Convert startTime and endTime to Dayjs objects
  let start = dayjs(startTime);
  let end = dayjs(endTime);

  let startDeduct = dayjs(startDeduction);
  let endDeduct = dayjs(endDeduction);

  const billing: ITicketPostBillingViewModel = {
    id: '', // This might need to be set somewhere else
    ticketPostId: '', // This might need to be set somewhere else
    isBillable: true, // This might need to be set somewhere else
    nightHours: 0,
    nightCost: 0,
    nightBillableValue: 0,
    workHours: 0,
    workCost: 0,
    workBillableValue: 0,
    midDayHours: 0,
    midDayCost: 0,
    midDayBillableValue: 0,
    eveningHours: 0,
    eveningCost: 0,
    eveningBillableValue: 0,
    notBillableHours: 0,
    notBillableCost: 0,
    notBillableBillingValue: 0,
  };

  rates.forEach((currentRate, index) => {
    let overlapStart = start.isAfter(dayjs().hour(currentRate.startHour))
      ? start
      : dayjs().hour(currentRate.startHour);
    let overlapEnd = end.isBefore(dayjs().hour(currentRate.endHour))
      ? end
      : dayjs().hour(currentRate.endHour);

    if (overlapStart.isBefore(overlapEnd)) {
      // Checks if there's an overlap
      let minutes = overlapEnd.diff(overlapStart, 'minute');
      let hours = minutes / 60;

      let adjustedRate =
        hourlyRate + (hourlyRate * currentRate.percentageIncrease) / 100;
      let value = hours * adjustedRate;

      let adjustedCost =
        hourlyCost + (hourlyCost * currentRate.percentageIncrease) / 100;
      let cost = hours * adjustedCost;
      // console.log('currentRate.percentageIncrease', currentRate.percentageIncrease, hourlyCost, adjustedCost);
      switch (index) {
        case 0:
          billing.nightHours = hours;
          billing.nightCost = cost;
          billing.nightBillableValue = value;
          break;
        case 1:
          billing.workHours = hours;
          billing.workCost = cost;
          billing.workBillableValue = value;
          break;
        case 2:
          billing.midDayHours = hours;
          billing.midDayCost = cost;
          billing.midDayBillableValue = value;
          break;
        case 3:
          billing.eveningHours = hours;
          billing.eveningCost = cost;
          billing.eveningBillableValue = value;
          break;
      }
    }
  });

  rates.forEach((currentRate, index) => {
    let overlapStart = startDeduct.isAfter(dayjs().hour(currentRate.startHour))
      ? startDeduct
      : dayjs().hour(currentRate.startHour);
    let overlapEnd = endDeduct.isBefore(dayjs().hour(currentRate.endHour))
      ? endDeduct
      : dayjs().hour(currentRate.endHour);

    if (overlapStart.isBefore(overlapEnd)) {
      // Checks if there's an overlap
      let minutes = overlapEnd.diff(overlapStart, 'minute');
      let hours = minutes / 60;

      let adjustedRate =
        hourlyRate + (hourlyRate * currentRate.percentageIncrease) / 100;
      let value = hours * adjustedRate;

      let adjustedCost =
        hourlyCost + (hourlyCost * currentRate.percentageIncrease) / 100;
      let cost = hours * adjustedCost;
      //console.log('currentRate.percentageIncrease', currentRate.percentageIncrease, hourlyCost, adjustedCost);
      switch (index) {
        case 0:
          billing.nightHours -= hours;
          billing.nightCost -= cost;
          billing.nightBillableValue -= value;
          break;
        case 1:
          billing.workHours -= hours;
          billing.workCost -= cost;
          billing.workBillableValue -= value;
          break;
        case 2:
          billing.midDayHours -= hours;
          billing.midDayCost -= cost;
          billing.midDayBillableValue -= value;
          break;
        case 3:
          billing.eveningHours -= hours;
          billing.eveningCost -= cost;
          billing.eveningBillableValue -= value;
          break;
      }
    }
  });

  return billing;
}

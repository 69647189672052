import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/material';

import AddRoundedIcon from '@mui/icons-material/AddRounded';

export const SubHeading = (props: any) => {
  const { icon: IH, title, handleClick } = props;

  return (
    <Paper
      variant='elevation3'
      sx={{
        mb: 2,
        p: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box sx={{ pt: 1 }}>
          <IH sx={{ fontSize: 40 }} />
        </Box>
        <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
          <Typography variant='subheader'>{title}</Typography>
        </Box>
        <Box sx={{}}>
          <Box>
            {!!handleClick && (
              <Button
                component='a'
                sx={{ mt: 0.5, mx: 'auto' }}
                type='add'
                variant='contained'
                onClick={(e) => {
                  handleClick(e);
                }}
              >
                <AddRoundedIcon sx={{ fontSize: 32 }} />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

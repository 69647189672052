import React from 'react';
import { Box, Paper, Stack, Typography, Badge, Grid } from '@mui/material';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import GetChartColor from 'functions/GetChartColor';
type Props = {
  value: number;
  oldValue: number;
  colorType: number;
};

const CompareData = ({ value, oldValue, colorType }:Props) => {
  if (value > oldValue) {
    return (
      <Badge
        sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <Typography
            sx={{
              fontSize: 14,
              backgroundColor: 'transparent',
              color: `${GetChartColor(colorType)}.text`,
            }}
          >
            {((value - oldValue) / (value / 100)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '%'}
          </Typography>
        }
      >
        <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
          <TrendingUpRoundedIcon
            sx={{
              mt: 2.5,
              fontSize: 40,
              color: `${GetChartColor(colorType)}.text`,
            }}
          />
        </Box>
      </Badge>
    );
  } else if (value < oldValue) {
    return (
      <Badge
        sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <Typography
            sx={{
              fontSize: 14,
              backgroundColor: 'transparent',
              color: `${GetChartColor(colorType)}.text`,
            }}
          >
            {((value - oldValue) / (value / 100)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '%'}
          </Typography>
        }
      >
        <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
          <TrendingDownRoundedIcon
            sx={{
              mt: 2,
              fontSize: 40,
              color: `${GetChartColor(colorType)}.text`,
            }}
          />
        </Box>
      </Badge>
    );
  } else {
    return (
      <Badge
        sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <Typography
            sx={{
              fontSize: 14,
              backgroundColor: 'transparent',
              color: `${GetChartColor(colorType)}.text`,
            }}
          >
            {((value - oldValue) / (value / 100)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '%'}
          </Typography>
        }
      >
        <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
          <TrendingFlatRoundedIcon
            sx={{
              mt: 2,
              fontSize: 40,
              color: `${GetChartColor(colorType)}.text`,
            }}
          />
        </Box>
      </Badge>
    );
  }
};

type ChartDataViewProps = {
  subHeader: string;
  value: number;
  oldValue: number;
  colorType: number;
  trend: boolean;
};
export const ChartDataView = ({
  subHeader,
  value,
  oldValue,
  colorType,
  trend,
}: ChartDataViewProps) => {
  //console.log(colorType);
  return (
    <Paper
      variant='elevation3'
      sx={{
        backgroundColor: 'primary.back',
        ml: 1,
        mr: 1,
        p: 0,
        mt: 1,
        borderRadius: 3,
        borderLeft: 8,
        borderColor: `${GetChartColor(colorType)}.light`,
      }}
    >
      <Stack>
        <Typography
          sx={{
            display: 'flex',
            mt: 1,
            mx: 'auto,',
            fontSize: 18,
            letterSpacing: 1,
            alignSelf: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {subHeader}
        </Typography>
        <Stack sx={{ width: '100%' }} direction={'row'}>
          <Grid flexGrow={1} sx={{ display: 'flex' }}>
            <Typography
              sx={{
                pl: 1,
                mb: 1,
                mx: 'auto',
                alignSelf: 'flex-center',
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'bold',
                letterSpacing: 4,
                fontSize: 40,
              }}
            >
              {value}
            </Typography>
          </Grid>
          {!!trend && (
            <Grid item sx={{ mr: 2 }}>
              <CompareData
                value={value}
                oldValue={oldValue}
                colorType={colorType}
              />
            </Grid>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

interface StarRatingProps {
  rating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  const filledStars = Math.floor(rating);
  const halfStar = rating - filledStars >= 0.5;

  const starElements: JSX.Element[] = [];

  for (let i = 0; i < filledStars; i++) {
    starElements.push(<StarIcon key={i} sx={{color:'secondary.light'}}/>);
  }

  if (halfStar) {
    starElements.push(<StarIcon key="half" />);
  }

  const remainingStars = 5 - starElements.length;

  for (let i = 0; i < remainingStars; i++) {
    starElements.push(<StarBorderIcon key={i + filledStars} />);
  }

  return <div>{starElements}</div>;
};

export default StarRating;

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Typography, Paper, Stack, IconButton } from '@mui/material';
import GetPriorityColor from 'functions/GetPriorityColor';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';

type Props = {
  company: ICompanyViewModel | null;
};

export const CompanyInfoView = ({ company }: Props) => {
  let navigate = useNavigate();

  return (
    <Stack
      direction={'row'}
      sx={{ m: 0, p: 1, backgroundColor: 'primary.main' }}
    >
      <Grid item sx={{ width: 132, height: 64, display: 'flex' }}>
        {company?.url != null && (
          <img
            src={company?.url || MissingPhoto.COMPANY_URL}
            style={{
              paddingRight: 2,
              width: '100%',
              height: '100%',
              objectFit: 'scale-down',
            }}
            alt={'logo'}
          />
        )}
      </Grid>

      <Grid container flexGrow={1} direction={'column'} sx={{}}>
        <Grid item sx={{}}>
          <Stack direction={'row'} flexGrow={1}>
            <Grid flexGrow={1} sx={{}}>
              <Typography
                sx={{
                  ml: 1,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                {company?.name}
              </Typography>
            </Grid>
            <Typography
              sx={{
                ml: 1,
                mt: 0.3,
                pr: 1,
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {company?.organizationNumber != null
                ? `( ${company?.organizationNumber} )`
                : ''}
            </Typography>
          </Stack>
        </Grid>

        <Grid container flexGrow={1} sx={{ mt: -0.5 }}>
          <Grid
            item
            xs={8}
            sx={{
              pl: 1,
            }}
          >
            <Typography
              sx={{
                m: 0,
                pr: 0.3,
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {company?.email}
            </Typography>
            <Typography
              sx={{
                mb: 1,
                pr: 0.3,
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {company?.webPage}
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            justifyContent='flex-end'
            alignItems='flex-end'
            sx={{
              mb: 1,
              pr: 0.5,
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                m: 0,
                pr: 0.3,
                color: 'secondary.text',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {company?.phone}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { itsConfig } from 'http/authConfig';
import { AppRoutes } from 'routes';
import App from './App';

import './index.css';
import { LinearProgress } from '@mui/material';
import './languages/i18n';
// MSAL setup
export const msalInstance = new PublicClientApplication(itsConfig);
// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    console.log('login event.eventType: ', event.eventType);
    console.log('login account: ', account);
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    console.log('Login success: ', event);
  }
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    console.log('Acquire token failure: ', event);
  }
  if (event.eventType === EventType.SSO_SILENT_FAILURE) {
    console.log('SSO silent failure: ', event);
  }
  if (event.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE) {
    console.log('Acquire token by code failure ', event);
  }
  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log('Login failure ', event);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <App pca={msalInstance} /> */}
      <Routes>
        <Route path='/blank.html' />
      </Routes>
      <Suspense fallback={<LinearProgress />}>
        <App pca={msalInstance} />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

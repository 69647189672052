import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid, List, ListItem, Paper } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import { SearchFilter } from 'components/ui/SearchFilter';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { SingleDepartmentView } from 'components/ui/SingleDepartmentView';
import _ from 'lodash';
import { getFilteredList } from 'functions/misc';
import { useGetObjectList } from 'functions/useGetObjectList';
import { EnumObjectType, objectArray } from 'Interfaces/IGlobalTypeConfig';
import { IResourcesViewModel } from 'Interfaces/IResourcesConfig';
import { useTranslation } from 'react-i18next';

export const Resources = () => {
  const { t } = useTranslation();

  let navigate = useNavigate();
  const { companyId } = useParams();
  const { height } = useWindowsDimension();

  // states
  const [resources, setResources] = useState<IResourcesViewModel[]>(
    null as unknown as IResourcesViewModel[]
  );

  // api
  let isEnabled = companyId !== undefined && companyId !== 'new' ? true : false;
  const { data: resourceData } = useGet(
    rq.resources(companyId || 'new', isEnabled),
    isEnabled
  );

  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  // states
  const handleNew = () => {
    navigate(`/companies/${companyId}/resources/new`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResources(
      getFilteredList(
        objectDrawerList[Number(EnumObjectType.Resource)].objectList,
        e.target.value
      )
    );
  };
  // USE EFFECTS
  useEffect(() => {
    console.log('resourceData', resourceData);
    let tmp = _.cloneDeep(resourceData?.resources);
    setResources(tmp);
  }, [resourceData]);
  useEffect(() => {
    setResources(objectDrawerList[Number(EnumObjectType.Resource)].objectList);
    setIsObjectList(false);
  }, [objectDrawerList]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: 0.5, mr: 0 }}>
        <Paper
          variant='elevation3'
          sx={{
            p: 0,
            height: Math.ceil(height - 128),
          }}
        >
          <SearchFilter
            Icon={InventoryIcon}
            Header={t('resources.listHeader')}
            handleSearch={handleSearch}
            handleNew={handleNew}
          />
          <List
            sx={{
              height: Math.ceil(height - 248),
              ml: 1,
              mr: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              overflow: 'auto',
            }}
          >
            {resources && resources.length > 0 ? (
              resources.map((resource: IResourcesViewModel, index) => (
                <ListItem
                  key={index}
                  sx={{ pl: 1, pr: 1 }}
                  onClick={() => {
                    navigate(
                      '/companies/' + companyId + '/resources/' + resource.id
                    );
                  }}
                >
                  <SingleDepartmentView
                    key={index}
                    index={index}
                    department={resource}
                  />
                </ListItem>
              ))
            ) : (
              <p>{t('list.empty')}</p>
            )}
          </List>
        </Paper>
      </Grid>
      {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ m: 0, pl: 0.5 }}>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: Math.ceil(height - 128),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};

import { Box } from '@mui/material';
import { TextField, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { fileURLToPath } from 'url';

export function escapeRegExp(value: any) {
  if (typeof value !== 'undefined') {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  return '';
}

export const QuickSearchToolbar = (props: any) => {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant='standard'
        value={props.value}
        onChange={props.onChange}
        placeholder='Search…'
        InputProps={{
          startAdornment: <SearchIcon fontSize='small' />,
          endAdornment: (
            <IconButton
              title='Clear'
              aria-label='Clear'
              size='small'
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize='small' />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
};

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* @list an array of objects */
export const getName = (id: string | number, list: any) => {
  for (let i = 0; i < list.length; i++) {
    if (id === list[i].id) {
      return list[i].name;
    }
  }
};

/* image handling */
export const convert2base64 = (file: any) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    //setImage usestate
  };
  return reader.readAsDataURL(file);
};

/* open google map */
export const openInNewTab = (e: any, url: string) => {
  e.preventDefault();
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

/* get filter list */
export const getFilteredList = (list: any, filterValue: any) => {
  let filterRegex = new RegExp(escapeRegExp(filterValue), 'i');

  const isMatch = (value: any): boolean => {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'object') {
      return searchInObject(value);
    }
    return filterRegex.test(value.toString());
  };

  const searchInObject = (obj: any): boolean => {
    return Object.values(obj).some((value) => {
      if (Array.isArray(value)) {
        return value.some(isMatch);
      }
      return isMatch(value);
    });
  };

  return list.filter((row: any) => searchInObject(row));
};

export const getFilteredListWithMultiple = (
  list: any[],
  searchValue: string,
  filterValue: string = ''
) => {
  let searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

  const isMatch = (obj: any): boolean => {
    // Check if the productType matches the filterValue, if provided
    const filterMatch =
      !filterValue || (obj.productType && obj.productType.id === filterValue);

    // If there's no filter match, no need to check further
    if (!filterMatch) return false;

    // If searchValue is empty, filterValue match is enough
    if (!searchValue) return true;

    // Check if any of the object values match the search regex
    // We assume here you're searching in other properties too, adjust as needed
    const searchMatch = Object.values(obj).some((value) => {
      if (typeof value === 'object' && value !== null) {
        // Recursively search in nested objects
        return isMatch(value);
      } else {
        return typeof value === 'string' || typeof value === 'number'
          ? searchRegex.test(value.toString())
          : false;
      }
    });

    return searchMatch;
  };

  return list.filter(isMatch);
};

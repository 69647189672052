import {
  Box,
  Button,
  Grid,
  List,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  ITicketPostViewModel,
  ITicketViewModel,
} from 'Interfaces/ITicketConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePost } from 'http/useInnovit';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SingleMessage } from 'components/ui/tickets/SingleMessage';
import { FormBox } from 'components/form';
import TicketContext from './TicketContext';

export const SelectedTicketMerge = () => {
  const { height } = useWindowsDimension();
  const { ticketId, selectedTicketId } = useParams();
  const navigate = useNavigate();
  const ticketData = useContext(TicketContext); // Accessing the context
  console.log('ticketId', selectedTicketId);
  const [ticketPosts, setTicketPosts] = useState<ITicketPostViewModel[]>([]);
  const [mergedTicketPosts, setMergedTicketPosts] = useState<
    ITicketPostViewModel[]
  >([]);

  const [initialTicket, setInitialTicket] = useState<ITicketViewModel | null>(
    null
  );
  const [ticketToMerge, setTicketToMerge] = useState<ITicketViewModel | null>(
    null
  );

  const { data: mergeTicketData } = useGet(
    rq.ticket(selectedTicketId!, true, '0'),
    true
  );

  const { data: initialTicketPostData } = useGet(rq.ticketPosts(ticketId!));
  const { data: mergeTicketPostsData } = useGet(
    rq.ticketPosts(selectedTicketId!)
  );
  const { mutate: postTicketMerge } = usePost(
    rqPost.ticketMerge(ticketId!, selectedTicketId!)
  );

  const handleMergeSuccess = (response: any) => {
    console.log('Merge successful:', response);
    navigate(`/tickets/${response.id}`);
  };

  const handleMergeClick = () => {
    postTicketMerge(null, {
      onSuccess: (res: any) => handleMergeSuccess(res),
    });
  };

  useEffect(() => {
    if (mergeTicketPostsData) {
      setTicketPosts(mergeTicketPostsData.ticketPosts);
      //console.log('TICKET POSTS DATA', ticketPosts);
    }
    if (initialTicketPostData && ticketPosts) {
      console.log('initialTicketPostData', initialTicketPostData);
      const mergedPostsArray =
        initialTicketPostData.ticketPosts.concat(ticketPosts);

      setMergedTicketPosts(mergedPostsArray);
      //console.log('TICKET POSTS DATA', ticketPosts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeTicketPostsData, initialTicketPostData, ticketPosts]);

  useEffect(() => {
    if (ticketData) {
      setInitialTicket(ticketData);
      console.log('ticketData---: ', ticketData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData]);

  useEffect(() => {
    if (mergeTicketData) {
      setTicketToMerge(mergeTicketData);
      console.log('ticketData TO MERGE---: ', mergeTicketData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeTicketData]);

  return (
    <>
      <List
        sx={{
          pl: 1,
          pr: 1,
          height: Math.ceil(height / 3),
          backgroundColor: 'primary.back',
          overflow: 'auto',
        }}
      >
        {!!ticketPosts &&
          ticketPosts?.map(
            (ticketPost: ITicketPostViewModel, index: number) => (
              <Grid
                sx={{
                  backgroundColor: 'transparent',
                  border: 0,
                  boxShadow: 'none',
                }}
                key={index}
                // onClick={() => {
                //   handleAssignPost(ticketPost);
                // }}
              >
                <SingleMessage ticketPost={ticketPost} />
              </Grid>
            )
          )}
      </List>
      {/* ASSIGN TECHNICIAN HEADER */}
      <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1, mt: 1 }}>
        <FormBox
          // Icon={PersonSearchRoundedIcon}
          title='Merge Result: '
        />
      </Grid>
      <Typography sx={{ pl: 2 }}>
        Ticket no. {initialTicket?.ticketNo} will be merged into{' '}
        {ticketToMerge && ticketToMerge.ticketNo}
      </Typography>
      <List
        sx={{
          pl: 1,
          pr: 1,
          height: Math.ceil(height / 3 + 39),
          backgroundColor: 'primary.back',
          overflow: 'auto',
        }}
      >
        {!!mergedTicketPosts &&
          mergedTicketPosts?.map(
            (ticketPost: ITicketPostViewModel, index: number) => (
              <Grid
                sx={{
                  backgroundColor: 'transparent',
                  border: 0,
                  boxShadow: 'none',
                }}
                key={index}
                // onClick={() => {
                //   handleAssignPost(ticketPost);
                // }}
              >
                <SingleMessage ticketPost={ticketPost} />
              </Grid>
            )
          )}
      </List>

      <Paper
        variant='elevation3'
        sx={{
          p: 2,
          height: 64,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Stack direction={'column'}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <Button
              sx={{ width: '25%' }}
              variant='contained'
              onClick={handleMergeClick}
            >
              Merge
            </Button>
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

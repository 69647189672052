import * as React from 'react';

import { useEffect, useState } from 'react';
//@ts-ignore
import GetAvatarInitials from 'functions/GetAvatarInitials';
import {
  Grid,
  Avatar,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
//@ts-ignore
import GetDirectionColor from 'functions/GetDirectionColor';
import { ITicketPostViewModel } from 'Interfaces/ITicketConfig';
import {
  EnumTicketStatus,
  MissingPhoto,
  enumTicketStatusToStringMapping,
} from 'Interfaces/IGlobalTypeConfig';
import dayjs from 'dayjs';
const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};

type props = {
  ticketPost: ITicketPostViewModel;
};

export const SingleMessage = ({ ticketPost }: props) => {
  // console.log('SingleMessage', ticketPost);
  const direction =
    ticketPost.direction === 1 || ticketPost.direction === 4
      ? 'row'
      : 'row-reverse';
  const dateTime = dayjs(ticketPost.startTime).format('DD.MM.YYYY HH:mm');
  console.log('ticketPost.author', ticketPost.author);
  return (
    <Grid container display={'flex'} flexDirection={direction}>
      <Grid item xs={10.5} sx={{}}>
        <ListItem
          alignItems='flex-start'
          sx={{
            mb: 1,
            borderRadius: 3,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            borderLeft:8,
            borderRight:2,
            borderColor:ticketPost.internal === false
            ? GetDirectionColor(ticketPost.direction) 
            : GetDirectionColor(6),
            pb: 1,
            ':hover': {
              cursor: 'pointer',
              filter: 'brightness(120%)',
              transition: 'all 0.2s ease-in-out',
            },
          }}
        >
          <ListItemText
            primary={
              <Grid
                container
                sx={{
                  pl: 1,
                  pr: 1,
                  borderRadius: 2,
                  width: '100%',                
                }}
              >
                <Typography
                  sx={{ display: 'inline', fontSize:12, letterSpacing:2,fontWeight: 'normal',}}
                  component='span'
                  variant='body2'
                  color='text.secondary'
                >                  
                  {dateTime}
                </Typography>

                <Typography sx={{ flexGrow: 1, alignContent:'center', textAlign:'center',fontSize:14, }}
                  color='text.secondary'
                  >
                  {ticketPost.internal ? 'INTERNAL ' : ''}
                  {ticketPost.direction > 3 ? 'MERGED' : ''}
                </Typography>
                <Typography
                  sx={{ display: 'inline', fontSize:14,  }}
                  component='span'
                  variant='body1'
                  color='text.secondary'
                >
                  {
                    enumTicketStatusToStringMapping[
                      ticketPost.status as EnumTicketStatus
                    ]
                  }
                </Typography>
                {ticketPost.overTime && (
                  <IconButton aria-label='overtime' size='small' sx={{mt:-.3}}>
                    <MoreTimeRoundedIcon sx={{ fontSize: 14, }} />
                  </IconButton>
                )}
                {ticketPost.billable && (
                  <IconButton aria-label='billable' size='small' sx={{mt:-.3}}>
                    <AttachMoneyRoundedIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                )}
                {ticketPost?.attachments &&
                  ticketPost.attachments.length > 0 && (
                    <IconButton aria-label='attachment' size='small' sx={{mt:-.3}}>
                      <AttachFileRoundedIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  )}
              </Grid>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{
                    mr: 1,
                    display: 'inline',
                    fontWeight: 'bold',
                    fontSize: 16,
                    letterSpacing: 1,
                  }}
                  component='span'
                  variant='body1'
                  color='text.secondary'
                >
                  {ticketPost.author?.name ? ticketPost.author.name : ''}
                </Typography>
                <Typography                
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  <pre
                    style={{
                      display: 'block',
                      maxWidth: '100%',
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap',
                      margin: 0,
                      fontSize: 16,
                      letterSpacing: 0.5
                    }}
                  >
                    {ticketPost.message}
                  </pre>
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </Grid>
      <Grid
        item
        xs={1.5}
        sx={{
          pb: 1,
          width: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
          <img
          src={ticketPost.author?.url || MissingPhoto.IMAGE_URL}
          style={{
            borderRadius: '15%',
            width: 64,
            height: 64,
            objectFit: 'cover',
          }}
          alt={'logo'}
        />
      </Grid>
    </Grid>
  );
};

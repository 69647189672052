import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';

type Props = {
  workRole: IWorkRoleViewModel;
  
};

export const SingleWorkRoleView = ({ workRole }: Props) => {
 
  return (
    <Grid
      container
      direction={'row'}
      sx={{
        mb: 1,
        p: 1,
        borderLeft: 8,
        borderRadius: 2,
        backgroundColor: 'primary.main',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
        borderColor: GetColorByName(`${workRole.id}`),
        height: 52,
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            m: 0,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            letterSpacing: 2,
            fontSize: 14,
          }}
        >
          {workRole.title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
      <Typography
            sx={{
              m: 0,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 10,
              color: 'primary.text',
             
            }}
          >
            {workRole.id.toString()}
          </Typography>

      </Grid>
      <Grid item xs={4}>
      <Stack direction={'row-reverse'} spacing={0} sx={{textAlign: 'right',}}>
       
       <Typography
         sx={{
           m: 0,
           textAlign: 'right',
           overflow: 'hidden',
           textOverflow: 'ellipsis',
           whiteSpace: 'nowrap',
           fontWeight: 'bold',
           letterSpacing: 1,
           fontSize: 14,
           color: 'secondary.text',
           width: 75,
         }}
       >
         {workRole.inHouseHourlyRate.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
       </Typography>

       <Typography
         sx={{
           m: 0,
           textAlign: 'right',
           overflow: 'hidden',
           textOverflow: 'ellipsis',
           whiteSpace: 'nowrap',
           fontWeight: 'normal',
           letterSpacing: 1,             
           fontSize: 12,
         }}
       >
         In House 
       </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={4}>
        <Stack direction={'row-reverse'} spacing={0} sx={{textAlign: 'right',}}>
       
          <Typography
            sx={{
              m: 0,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 14,
              color: 'secondary.text',
              width: 75,
            }}
          >
            {workRole.onSiteHourlyRate.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>

          <Typography
            sx={{
              m: 0,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              letterSpacing: 1,             
              fontSize: 12,
            }}
          >
            On-Site 
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

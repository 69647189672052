import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import useWindowsDimension from 'functions/useWindowsDimension';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SearchFilter } from 'components/ui/SearchFilter';
import { Button, List, TextField } from '@mui/material';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import {
  IPackageViewModel,
  IProductPackageProductViewModel,
} from 'Interfaces/IPackageConfig';
import { SinglePackageView } from 'components/ui/SinglePackageView';
import { PackageForm } from 'pages/Companies/PackageForm';
import { Label } from 'recharts';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';

export const DefaultSettings = () => {
  const { height } = useWindowsDimension();
  const [owner, setOwner] = useAtom(ownerAtom);
  const [TicketNO, setTicketNO] = useState('12345');
  const [OrderNO, setOrderNO] = useState('12345');
  const [InvoiceNO, setInvoiceNO] = useState('12345');
  const [defaultPackageName, setdefaultPackageName] =
    useState('Default package');
  const [defaultDepartmentName, setdefaultDepartmentName] =
    useState('Default department');

  let packageID = '601f90d5-6ab0-4fbc-8d0c-c1cc00000001';
  const [defaultPackage, setDefaultPackage] = useState<IPackageViewModel>(
    {} as IPackageViewModel
  );
  // api

  const { data: packageData } = useGet(
    rq.package(owner.id, packageID, true),
    true
  );

  useEffect(() => {
    if (packageData) {
      setDefaultPackage(packageData as IPackageViewModel);
    }
  }, [packageData]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          p: 0,
          m: 0,
          pr: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter Header='Default values setup' />
        <List
          sx={{
            height: Math.ceil(height - 148),
            backgroundColor: 'primary.back',
            p: 1,
            overflow: 'auto',
          }}
        >
          <Grid
            sx={{
              p: 0,
              m: 0,
              pr: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid
              sx={{
                p: 0,
                m: 1,
                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                sx={{ width: '65%', m: 1 }}
                label='TicketNO'
                value={TicketNO}
              ></TextField>
              <Button variant='contained' sx={{ m: 1 }}>
                Set
              </Button>
              <Button variant='contained' sx={{ m: 1 }} color='secondary'>
                Confirm
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                sx={{ width: '65%', m: 1 }}
                label='OrderNO'
                value={OrderNO}
              ></TextField>
              <Button variant='contained' sx={{ m: 1 }}>
                Set
              </Button>
              <Button variant='contained' sx={{ m: 1 }} color='secondary'>
                Confirm
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                sx={{ width: '65%', m: 1 }}
                label='InvoiceNO'
                value={InvoiceNO}
              ></TextField>
              <Button variant='contained' sx={{ m: 1 }}>
                Set
              </Button>
              <Button variant='contained' sx={{ m: 1 }} color='secondary'>
                Confirm
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                sx={{ width: '65%', m: 1 }}
                label='Default package name'
                value={defaultPackageName}
              ></TextField>
              <Button variant='contained' sx={{ m: 1 }}>
                Set
              </Button>
              <Button variant='contained' sx={{ m: 1 }} color='secondary'>
                Confirm
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                sx={{ width: '65%', m: 1 }}
                label='Default department name'
                value={defaultDepartmentName}
              ></TextField>
              <Button variant='contained' sx={{ m: 1 }}>
                Set
              </Button>
              <Button variant='contained' sx={{ m: 1 }} color='secondary'>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </List>
        {/*  footer */}
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          p: 0,
          m: 0,
          pr: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter Header='Edit default package' />
        <Paper>
          <PackageForm packageData={defaultPackage} isDefaultPackage={true} />
        </Paper>
      </Grid>
    </Grid>
  );
};

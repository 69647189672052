export function formatPhoneNumber(input: string): string {
    //console.log('input', input);
  
    // Remove the leading country code (+47 or 47)
    const countryCode = input.startsWith('+') ? input.slice(0, 3) : input.slice(0, 2);
    const numericInput = input.slice(3); // Remove the country code from the input
  
    
    // Remove any non-numeric characters from the numeric part
    const numericPart = numericInput.replace(/\D/g, '');
    //console.log('numericInput', numericPart);
  
    // Format the numeric part as needed (e.g., xxx xx xxx)
    let formattedPhoneNumber = '';
  
    if (numericPart.length <= 3) {
      formattedPhoneNumber = numericPart;
    } else if (numericPart.length <= 6) {
      formattedPhoneNumber = `${numericPart.slice(0, 3)} ${numericPart.slice(3)}`;
    } else if (numericPart.length <= 9) {
      formattedPhoneNumber = `${numericPart.slice(0, 3)} ${numericPart.slice(3, 5)} ${numericPart.slice(5)}`;
    } else if (numericPart.length <= 11) {
      formattedPhoneNumber = `${numericPart.slice(0, 3)} ${numericPart.slice(3, 5)} ${numericPart.slice(5, 8)} ${numericPart.slice(8)}`;
    }
  
    return `${countryCode} ${formattedPhoneNumber}`;
  }
  
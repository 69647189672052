import {  
  Button,
  Grid,
  IconButton,
  List,
  Paper,
  
} from '@mui/material';
// @ts-ignore
import { EmployeeDrawer } from 'pages/Companies/EmployeeDrawer';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { memo, useEffect, useReducer, useState } from 'react';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { usePut } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import ownerAtom from 'data/atoms/ownerAtom';
import { IUserViewModel } from 'data/atoms/atomConfig';
import { SearchFilter } from '../SearchFilter';
import { SingleEmployeeView } from '../SingleEmployeeView';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useTranslation } from 'react-i18next';

const reducer = (state:any, action:any) => {
  switch (action.type) {
    case 'ADD_RESPONSIBLE':
      // console.log ('ADD_RESPONSIBLE',action.technician)
      return {
        ...state,
        responsibleFor: [...state.responsibleFor, action.responsibleFor],
      };

    case 'REMOVE_RESPONSIBLE':
      // console.log (action.technician)
      return {
        ...state,
        responsibleFor: state.responsibleFor.filter(
          (responsibleFor:any) =>
            responsibleFor.technician.id !== action.responsibleFor.technician.id
        ),
      };
    default:
      return null;
  }
};

const initialState:any = {
  responsibleFor: [],
};

export const ResponsibleList = () => {
  // TRANSLATIONS
  const { t } = useTranslation();
  // INITIALIZATIONS 
  const [user, setUser] = useAtom(userAtom)
  const [owner, setOwner] = useAtom(ownerAtom)
  const { height } = useWindowsDimension();
  // STATE
  const [state, actions] = useReducer(reducer, initialState);

  let isEnable = user?.id && owner?.id ? true : false;
  let _ownerId = owner?.id ? owner.id : '';
  let _userId = user?.id ? user.id : '';
  const putUser = usePut(rqPost.employee(_ownerId, _userId, isEnable), isEnable);


  // HANDLERS
  //console.log("state",state);
  const handleRemoveResponsibleSubmit = (e:any, technician:IUserViewModel) => {
    e.preventDefault();

    let responsibleFor = {
      employeeId: user?.id,
      technicianId: technician.id,
      technician: technician,
    };
    actions({ type: 'REMOVE_RESPONSIBLE', responsibleFor });
    //actions({ type: 'REMOVE_RESPONSIBLE_ID', responsibleFor });
  };

  const handleResponsibleSubmit = (e:any, technician:IUserViewModel) => {
    e.preventDefault();

    let responsibleFor = {
      employeeId: user?.id,
      technicianId: technician.id,
      technician: technician,
    };
    actions({ type: 'ADD_RESPONSIBLE', responsibleFor });
  };

  const handleUpdate = () => {
    if (!user) return;
    user.responsibleFor = state.responsibleFor;
    putUser.mutate(user);
    setUser(user);
  };

  // MODULES

  return (
    <Grid container direction={'row'}>
    <Grid item xs={12}>
      <Grid sx={{}}>
      
          <SearchFilter
            Icon={PersonOutlineRoundedIcon}
            Header={t('myPage.workRole')}
            gfx={
              owner.id && (
                <EmployeeDrawer
                  buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                  companyId={owner.id}
                  handleEmployeeSubmit={handleResponsibleSubmit}
                />
              )
            }
          />
       
        <List
          sx={{
            height: Math.ceil(height - 834),
            overflow: 'auto',
            backgroundColor: 'primary.back',
            p: 2,
          }}
        >
          {state?.responsibleFor.length > 0 &&
            state.responsibleFor?.map((responsible:any, index:number) => (
              <SingleEmployeeView
                key={index}
                employee={responsible.technician}
                action={PersonRemoveRoundedIcon}
                gfx={
                  <IconButton
                    sx={{ fontSize: 26, p: 0, m: 0 }}
                    onClick={(e) =>
                      handleRemoveResponsibleSubmit(e, responsible.technician)
                    }
                  >
                    <DeleteForeverRoundedIcon />
                  </IconButton>
                }
              />
            ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid container direction={'row'}>
            <Grid flexGrow={1}></Grid>
            <Button
              size='small'
              variant='contained'
              onClick={() => handleUpdate()}
            >
              {t('button.update')}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </Grid>
  );
};

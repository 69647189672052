import React, { useEffect } from 'react';
import { Paper, Stack, Typography, Grid } from '@mui/material';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import GetChartColor from 'functions/GetChartColor';
import { IExtendedData } from 'pages/Finance/IFinanceConfig';

type Props = {
  header: string;
  oldValue: number;
  value: number;
  extendedData?: IExtendedData | null;
};

export const ChartSingleView = ({
  header,
  oldValue,
  value,
  extendedData,
}: Props) => {
  const [showExtended, setShowExtended] = React.useState(false);

  useEffect(() => {
    if (extendedData !== null) {
      setShowExtended(true);
    }
  }, [extendedData]);

  let colorType = 1;
  if (value > oldValue) {
    colorType = 0;
  } else if (value < oldValue) {
    colorType = 2;
  }

  return (
    <Grid item xs={3} sx={{ m:0,p:0,}}>
      <Paper
        variant='elevation3'
        sx={{
          mr:2,
          mp:2, 
          height: 350,        
          borderRadius: 3,
          borderLeft: 8,
          borderColor: `${GetChartColor(colorType)}.text`,
        }}
      >
        <Grid container sx={{ height: '45%', p: 0, }}>
          <Grid item xs={12}>
            {/* Header */}
            <Typography
              sx={{
                pl:1,              
                fontSize: 20,
                letterSpacing: 2,
                alignSelf: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
              }}
            >
              {header}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {/* value */}
            <Stack direction='column' spacing={0} sx={{ pl: 1, pt: 1 }}>
              <Typography
                sx={{
                  width: '100%',
                  fontWeight: 'normal',
                  letterSpacing: 2,
                  fontSize: 20,
                  color: 'primary.text',
                  textAlign: 'left', // Align text to the left
                }}
              >
                {oldValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>

              <Typography
                sx={{
                  mt: 2,
                  width: '100%',
                  fontWeight: 'normal',
                  letterSpacing: 0,
                  fontSize: 30,
                  color: 'white',
                  textAlign: 'left', // Align text to the left
                }}
              >
                {value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            {/* graph */}
            <Stack direction='column' spacing={0} sx={{ pl: 1, pt: 1 }}>
              <Typography
                sx={{
                  fontSize: 20,
                  backgroundColor: 'transparent',
                  color: `${GetChartColor(colorType)}.text`,
                  textAlign: 'center',
                }}
              >
                {oldValue === 0
                  ? `${value.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}%`
                  : (((value - oldValue) / oldValue) * 100).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }
                    ) + '%'}
              </Typography>
              {colorType === 0 && (
                <TrendingUpRoundedIcon
                  sx={{
                    mt: -2,
                    fontSize: 70,
                    color: `${GetChartColor(colorType)}.text`,
                  }}
                />
              )}
              {colorType === 2 && (
                <TrendingDownRoundedIcon
                  sx={{
                    mt: -2,
                    fontSize: 70,
                    color: `${GetChartColor(colorType)}.text`,
                  }}
                />
              )}
              {colorType === 1 && (
                <TrendingFlatRoundedIcon
                  sx={{
                    mt: -2,
                    fontSize: 70,
                    color: `${GetChartColor(colorType)}.text`,
                  }}
                />
              )}
            </Stack>
          </Grid>
        </Grid>

        {showExtended && (
          <Grid container sx={{ height: '45%', p:1, }}>
            <Grid item xs={12}>
              {/* Header */}
              <Typography
                sx={{
                  pl: 1,
                  fontSize: 18,
                  letterSpacing: 1,
                  alignSelf: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'capitalize',
                  whiteSpace: 'nowrap',
                }}
              >
                {extendedData?.header}
              </Typography>
            </Grid>
            {extendedData?.lines.map((line, index) => (
              <Grid container key={index}>
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      pl: 1,
                      fontWeight: 'normal',
                      letterSpacing: 2,
                      fontSize: 16,
                      color: 'primary.text',
                      textAlign: 'left', // Align text to the left
                    }}
                  >
                    {line.name}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ justifyContent: 'flex-end' }}>
                  <Typography
                    sx={{
                      fontWeight: 'normal',
                      letterSpacing: 0,
                      fontSize: 16,
                      color: 'white',
                      textAlign: 'right', // Align text to the left
                    }}
                  >
                    {line.value.toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack
                    direction='row'
                    spacing={0}
                    sx={{ pl: 1, pr: 1, justifyContent: 'flex-end' }}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        backgroundColor: 'transparent',
                        color: `${GetChartColor(
                          line.value > line.oldValue
                            ? 0
                            : line.value < line.oldValue
                            ? 2
                            : 1
                        )}.text`,
                        textAlign: 'center',
                      }}
                    >
                      {line.oldValue === 0
                        ? `${line.value.toFixed(0)}%`
                        : (
                            ((line.value - line.oldValue) / line.oldValue) *
                            100
                          ).toFixed(0) + '%'}
                    </Typography>
                    {line.oldValue === 0 && line.value > 0 && (
                      <TrendingUpRoundedIcon
                        sx={{
                          mt: -0.5,
                          fontSize: 24,
                          color: `${GetChartColor(0)}.text`,
                        }}
                      />
                    )}

                    {line.value === 0 && line.oldValue > 0 && (
                      <TrendingDownRoundedIcon
                        sx={{
                          mt: -0.5,
                          fontSize: 24,
                          color: `${GetChartColor(2)}.text`,
                        }}
                      />
                    )}

                    {line.oldValue === 0 && line.value === 0 && (
                      <TrendingFlatRoundedIcon
                        sx={{
                          mt: -0.5,
                          fontSize: 24,
                          color: `${GetChartColor(1)}.text`,
                        }}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

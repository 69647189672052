import Grid from '@mui/material/Grid';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SearchFilter } from 'components/ui/SearchFilter';
import {
  Button,
  List,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePost} from 'http/useInnovit';
import React, { useEffect, useReducer, useState } from 'react';
import SingleBillingPostView from 'components/ui/SingleBillingPostView';
import {
  IBillingDetailViewModel,
  IBillingPostViewModel,
  IInvoicePostViewModel,
  IInvoiceViewModel,
} from '../../Interfaces/BillingConfig';
import { CompanyInfoView } from 'components/ui/CompanyInfoView';
import { SingleBillingDetailView } from 'components/ui/SingleBillingDetailView';
import { PDFFile } from 'components/ui/Billing/PDFFile';
import {pdf} from '@react-pdf/renderer';

import dayjs from 'dayjs';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import { useTranslation } from 'react-i18next';
let _ = require('lodash');

// STYLE
const sxHeader = {
  pl: 1,
  pt: 0,
  width: '50&',
  letterSpacing: 2,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 16,
  m: 0,
};
const sxSubHeader = {
  pl: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontSize: 14,
  color: 'primary.text',
};
const sxSubValue = {
  pl: 1,
  pr: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 16,
  color: 'secondary.text',
};

const billingPostReducer = (state: any, action: any) => {
  switch (action.type.toUpperCase()) {
    case 'SELECT_BILLING_POST':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_BILLING_POST':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const initialState: IBillingPostViewModel = {
  id: null,
  BillingNo: 'No2023-00001',
  agreementInvoiceValue: 0,
  agreementCost: 0,
  billingViewModels: [],
  company: {} as ICompanyViewModel,
  cost: 0,
  investmentInvoiceValue: 0,
  investmentCost: 0,
  isBilled: false,
  otherInvoiceValue: 0,
  otherCost: 0,
  supportCost: 0,
  supportInvoiceValue: 0,
  invoiceNo: null,
};

const ConvertBillingViewModel = (
  billingPostViewModel: IBillingPostViewModel
) => {
  const _invoiceViewModel = {} as IInvoiceViewModel;

  _invoiceViewModel.invoicePosts = [] as IInvoicePostViewModel[];
  _invoiceViewModel.supportCost = billingPostViewModel.supportCost;
  _invoiceViewModel.supportInvoiceValue =
    billingPostViewModel.supportInvoiceValue;
  _invoiceViewModel.agreementCost = billingPostViewModel.agreementCost;
  _invoiceViewModel.agreementInvoiceValue =
    billingPostViewModel.agreementInvoiceValue;
  _invoiceViewModel.investmentCost = billingPostViewModel.investmentCost;
  _invoiceViewModel.investmentInvoiceValue =
    billingPostViewModel.investmentInvoiceValue;
  _invoiceViewModel.otherCost = billingPostViewModel.otherCost;
  _invoiceViewModel.otherInvoiceValue = billingPostViewModel.otherInvoiceValue;

  _invoiceViewModel.companyId = billingPostViewModel.company.id
    ? billingPostViewModel.company.id
    : '';
  _invoiceViewModel.isPayed = false;
  _invoiceViewModel.InvoiceNo = 'new';


  billingPostViewModel.billingViewModels.forEach((item) => {
    let _invoicePost: IInvoicePostViewModel = {} as IInvoicePostViewModel;


    _invoicePost.InvoiceNo = 'new';
    _invoicePost.agreementProductId = item.agreementProduct?.id || null;
    _invoicePost.companyId = billingPostViewModel.company.id;
    _invoicePost.companyProductId = item.companyProduct?.id || null;
    _invoicePost.ticketPostId = item.ticketPost?.id || null;

    // need to fine quantity, retailPrice and unitPrice from eighter agreementProduct or companyProduct depending on which one is not null
    _invoicePost.quantity =
      item.agreementProduct?.quantity || item.companyProduct?.quantity || 0;
    _invoicePost.retailPrice =
      item.agreementProduct?.retailPrice ||
      item.companyProduct?.retailPrice ||
      0;
    _invoicePost.unitPrice =
      item.agreementProduct?.unitPrice || item.companyProduct?.unitPrice || 0;

    _invoiceViewModel.invoicePosts.push(_invoicePost);
  });
  return _invoiceViewModel;
};

export const BillingView = () => {
  const { t } = useTranslation();
  // INITIALIZATION
  const { height } = useWindowsDimension();

  // States
  const [state, dispatch] = useReducer(billingPostReducer, initialState);
  const [billingPosts, setBillingPosts] = useState<IBillingPostViewModel[]>([]);
  const [billingPostDetails, setBillingPostDetails] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  // API
  const isEnabled = true;
  const { data: billingPostData } = useGet(rq.billing(isEnabled), isEnabled);
  const postInvoiceData = usePost(rqPost.Invoice(isEnabled), isEnabled);

  let otherInvoiceValue = 0;
  // USE EFFECTS
  useEffect(() => {
    if (billingPostData) {
      setBillingPosts(billingPostData.billingPost);
    }
  }, [billingPostData]);

  useEffect(() => {
    let filteredBillingDetails;

    if (selectedFilters.length === 0) {
      // If no filters are selected, use the whole list
      filteredBillingDetails = state.billingViewModels;
    } else {
      // this filters and returns a new array based on the filter value we send in
      filteredBillingDetails = state.billingViewModels.filter(
        (billingViewModel: IBillingDetailViewModel) => {
          return selectedFilters.some((filter) => {
            switch (filter) {
              case 'agreementProduct':
                return (
                  billingViewModel.agreementProduct !== null &&
                  billingViewModel.agreementProduct !== undefined
                );
              case 'investment':
                return (
                  billingViewModel.investment !== null &&
                  billingViewModel.investment !== 0
                );
              case 'support':
                return billingViewModel.support !== null;
              case 'other':
                return (
                  billingViewModel.other !== null &&
                  billingViewModel.other !== 0
                );
              default:
                return false;
            }
          });
        }
      );
    }

    setBillingPostDetails(filteredBillingDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.billingViewModels, selectedFilters]);

  useEffect(() => {
    setIsDisabled(billingPostDetails.length === 0);
  }, [billingPostDetails]);

  useEffect(() => {
    if (billingPosts && billingPosts.length > 0) {
      billingPosts.sort((a: any, b: any) => {
        if (!a.agreementProduct || !b.agreementProduct) return 0;

        const dateA = a.agreementProduct?.lastBilled
          ? dayjs(a.agreementProduct?.lastBilled).toDate()
          : dayjs().toDate();
        const dateB = b.agreementProduct?.lastBilled
          ? dayjs(b.agreementProduct?.lastBilled).toDate()
          : dayjs().toDate();

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;

        // If dates are equal, compare product names
        if (a.agreementProduct.product.name < b.agreementProduct.product.name)
          return -1;
        if (a.agreementProduct.product.name > b.agreementProduct.product.name)
          return 1;

        // If product names are equal, compare by agreementProduct (assuming it's a string or number)
        if (a.agreementProduct < b.agreementProduct) return -1;
        if (a.agreementProduct > b.agreementProduct) return 1;

        return 0;
      });
    }
  }, [billingPosts]);

  // HANDLES
  const handleFilterChange = (event: any, newFilters: any) => {
    setSelectedFilters(newFilters);
  };

  const handlePostInvoice = async (e: any) => {
    e.preventDefault();

    const _billingViewPostModel = { ...state };
    // replace the state billingPostDetails with the filtered ones
    _billingViewPostModel.billingViewModels = billingPostDetails;

    if (
      String(_billingViewPostModel.id) ===
        '00000000-0000-0000-0000-000000000000' ||
      _billingViewPostModel.id === null ||
      _billingViewPostModel.id === undefined
    ) {
      const invoice = ConvertBillingViewModel(_billingViewPostModel);
      invoice.companyId = state.company.id;
      setIsDisabled(true);

      postInvoiceData.mutate(invoice, {
        onSuccess: async (res: any) => {
          try {
            // Mark this callback as async

            // Generate the PDF Blob
            let tmp = { ...state, billingViewModels: billingPostDetails };
            tmp.invoiceNo = res.invoiceNo;
            const blob = await pdf(<PDFFile data={tmp} />).toBlob();

            // Create a link and trigger the download
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${tmp.invoiceNo} invoice.pdf`; // or any desired file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            dispatch({
              type: 'RESET_BILLING_POST',
              payload: initialState,
            });
          } catch (error) {
            console.error('Error in onSuccess:', error);
            // Handle the error appropriately
          }
        },
      });
    } else {
      /* putBillingData.mutate(_invoice, {
        onSuccess: () => {
        },
      }); */
    }
  };

  // JSX
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      {/* Left side */}
      <Grid
        item
        xs={6}
        sx={{
          pr: .5,
          m: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter Icon={RequestQuoteRoundedIcon} Header={t('Billing.billingView.unbilledPost')} />
        <List
          sx={{
            height: Math.ceil(height - 250),
            backgroundColor: 'primary.back',
          }}
        >
          {billingPosts.length > 0 ? (
            billingPosts.map((billingPost, index) => (
              <Grid
                sx={{ m: 1 }}
                key={index}
                onClick={() => {
                  dispatch({
                    type: 'SELECT_BILLING_POST',
                    payload: billingPost,
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                <SingleBillingPostView billingPost={billingPost} />
              </Grid>
            ))
          ) : (
            t('Billing.billingView.noDataAvailableMessage')
          )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'primary.main',
          }}
        >
          {/*   <PDFViewer style={{ width: '100%', height: '100%' }}>
             <PDFFile data={state} />
          </PDFViewer> */}
        </Paper>
      </Grid>

      {/* Right side */}

      <Grid
        item
        xs={6}
        sx={{
          p: 0,
          m: 0,
          pl: .5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter
          Icon={RequestQuoteRoundedIcon}
          Header={t("Billing.billingView.selectedBillingPost")}
        />
        <CompanyInfoView company={state.company ? state.company : null} />
        <List
          sx={{
            height: Math.ceil(height - 500),
            backgroundColor: 'primary.back',
            p: 1,
            overflow: 'auto',
          }}
        >
          {state.billingViewModels?.length > 0 ? (
            state.billingViewModels
              .filter((billingDetail: any) => {
                if (selectedFilters.length === 0) return true;
                return selectedFilters.some((filter) => {
                  if (filter === 'agreementProduct') {
                    return billingDetail.agreementProduct !== null;
                  } else if (filter === 'investment') {
                    return (
                      billingDetail.investment !== null &&
                      billingDetail.investment !== 0
                    );
                  } else if (filter === 'support') {
                    return (
                      billingDetail.support !== null &&
                      billingDetail.support.cost !== 0
                    );
                  } else if (filter === 'other') {
                    return (
                      billingDetail.other !== null && billingDetail.other !== 0
                    );
                  }
                  return false;
                });
              })
              .map((billingDetail: IBillingDetailViewModel, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    dispatch({
                      type: 'SELECT_BILLING_POST',
                      payload: billingDetail,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <SingleBillingDetailView billingDetail={billingDetail} />
                </div>
              ))
          ) : (
             t('Billing.billingView.noDataAvailableMessage')
          )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
           
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: 156,
            }}
          >
            <Grid
              container
              display={'flex'}
              flexDirection='row'
              sx={{ m: 0, pl: 1 }}
            >
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>{t('Billing.billingView.agreementSubHeader')}</Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {state.agreementInvoiceValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>{t('Billing.billingView.supportSubHeader')}</Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {state.supportInvoiceValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>{t('Billing.billingView.investmentSubHeader')}</Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {state.investmentInvoiceValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>{t('Billing.billingView.otherSubHeader')}</Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {state.otherInvoiceValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>Total</Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {otherInvoiceValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
         
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between', height: 48, backgroundColor: 'primary.back'}}
          >
            {/*   {isInvoiceOk && (
              <PDFDownloadLink
                document={<PDFFile data={state} />}
                ref={downloadLinkRef}
                style={{ display: 'none' }}
              >
                Download Invoice
              </PDFDownloadLink>
            )} */}
            <ToggleButtonGroup
            sx={{height: 48}}
              value={selectedFilters}
              onChange={handleFilterChange}
              aria-label='billing filters'
            >
              <ToggleButton
                value='agreementProduct'
                aria-label='agreementProduct'
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light', // Apply the background color when this button is selected
                    color: 'primary.dark',
                  },
                }}
              >
              {t("Billing.billingView.agreementBottomHeader")}
              </ToggleButton>
              <ToggleButton
                value='investment'
                aria-label='investment'
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light', // Apply the background color when this button is selected
                    color: 'primary.dark',
                  },
                }}
              >
              {t("Billing.billingView.investmentSubHeader")}
              </ToggleButton>
              <ToggleButton
                value='support'
                aria-label='support'
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light', // Apply the background color when this button is selected
                    color: 'primary.dark',
                  },
                }}
              >
                {t("Billing.billingView.supportSubHeader")}
              </ToggleButton>
              <ToggleButton
                value='other'
                aria-label='other'
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light', // Apply the background color when this button is selected
                    color: 'primary.dark',
                  },
                }}
              >
                {t("Billing.billingView.otherSubHeader")}
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
            sx={{  }}
              size='small'
              variant='contained'
              disabled={isDisabled}
              onClick={(e) => handlePostInvoice(e)}
            >
            {t("Billing.billingView.createInvoiceBttn")}

            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

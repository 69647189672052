import React from 'react';
import { Box, Paper, Stack, Typography, Badge, Grid } from '@mui/material';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import GetChartColor from 'functions/GetChartColor';
import { formatNumber } from 'functions/formatNumber';
import GetSingleMultiBarColor from 'functions/GetSingleMultibarColor';
type Props = {
  totalValue: number;
  lastPeriodValue: number;
};

export const CompareData = ({ totalValue, lastPeriodValue }: Props) => {
  let colorType = `success.text`;
  if (totalValue > lastPeriodValue) {
    colorType = `success.text`;
    return (
      <Badge
        sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <Typography
            sx={{
              fontSize: 14,
              backgroundColor: 'transparent',
              color: colorType,
            }}
          >
            {(
              (totalValue - lastPeriodValue) /
              (totalValue / 100)
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '%'}
          </Typography>
        }
      >
        <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
          <TrendingUpRoundedIcon
            sx={{
              mt: 2.5,
              fontSize: 40,
              color: colorType,
            }}
          />
        </Box>
      </Badge>
    );
  } else if (totalValue < lastPeriodValue) {
    colorType = `warning.text`;
    return (
      <Badge
        sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <Typography
            sx={{
              fontSize: 14,
              backgroundColor: 'transparent',
              color: colorType,
            }}
          >
            {formatNumber(
              (totalValue - lastPeriodValue) / (totalValue / 100),
              2
            ) + '%'}
          </Typography>
        }
      >
        <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
          <TrendingDownRoundedIcon
            sx={{
              mt: 2,
              fontSize: 40,
              color: colorType,
            }}
          />
        </Box>
      </Badge>
    );
  } else {
    colorType = `secondary.text`;
    return (
      <Badge
        sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <Typography
            sx={{
              fontSize: 14,
              backgroundColor: 'transparent',
              color: colorType,
            }}
          >
            {(
              (totalValue - lastPeriodValue) /
              (totalValue / 100)
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '%'}
          </Typography>
        }
      >
        <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
          <TrendingFlatRoundedIcon
            sx={{
              mt: 2,
              fontSize: 40,
              color: colorType,
            }}
          />
        </Box>
      </Badge>
    );
  }
};

type ChartDataViewProps = {
  index: number;
  subHeader: string;
  totalValue: number;
  lastPeriodValue: number;
};
export const SingleChartValue = ({
  index,
  subHeader,
  totalValue,
  lastPeriodValue,
}: ChartDataViewProps) => {
  //console.log(colorType);
  return (
    <Paper
      variant='elevation3'
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        width: '100%',
        ml: 1,
        mr: 1,
        mb: 0.7,
        borderRadius: 3,
        borderLeft: 8,
        borderColor: GetSingleMultiBarColor(index),
        position: 'relative',
      }}
    >
      <Typography
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          textAlign: 'center',
          fontSize: 12,
          letterSpacing: 1,
          textTransform: 'upperCase',
          alignSelf: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {subHeader}
      </Typography>

      <Typography
        sx={{
          position: 'absolute',
          left: 7,
          bottom: 23,
          width: '60%',
          alignSelf: 'flex-center',
          textAlign: 'left',
          textTransform: 'capitalize',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 'normal',
          letterSpacing: 1,
          fontSize: 12,
        }}
      >
        {formatNumber(lastPeriodValue, 1)}
      </Typography>

      <Typography
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '60%',
          alignSelf: 'flex-center',
          textAlign: 'center',
          textTransform: 'capitalize',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 'bold',
          letterSpacing: 1,
          fontSize: 16,
        }}
      >
        {formatNumber(totalValue, 1)}
      </Typography>

      <Grid
        container
        sx={{
          position: 'absolute',
          right: 10,
          bottom: -10,
          transform: 'scale(0.8)',
          justifyContent: 'right',
          transformOrigin: 'right bottom', // Juster etter behov
        }}
      >
        <CompareData
          totalValue={totalValue}
          lastPeriodValue={lastPeriodValue}
        />
      </Grid>
    </Paper>
  );
};

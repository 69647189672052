import { useEffect, useReducer } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import GetAvatarInitials from "functions/GetAvatarInitials";
import { SingleTicketHistory } from "components/ui/SingleTicketHistory";
import { emptyCompany, newProduct } from "data/templates";
import { dummyEmployee_1, dummyEmployee_2 } from "data/variables";

import useWindowsDimension from "functions/useWindowsDimension";
import { FormSelect } from "components/form";

import {
  Avatar,
  Button,
  ButtonGroup,
  Grid,
  List,
  Box,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { SubHeading } from "components/ui";
import { SaleCompany } from "./SaleCompany";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import { ProductCard } from "components/ui/ProductCard";
import { PersonList } from "components/ui/PersonList";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PersonRemoveRoundedIcon from "@mui/icons-material/PersonRemoveRounded";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";
import DevicesOtherRoundedIcon from "@mui/icons-material/DevicesOtherRounded";
import GetPriorityColor from "functions/GetPriorityColor";
import SignalWifiStatusbarConnectedNoInternet4RoundedIcon from "@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Rounded";

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + "/img/",
};

const Issues = (props) => {
  const { step1, step2, step3, comment, product } = props;

  //console.log(product);
  return (
    <Grid container direction={"row"} sx={{ flexGrow: 1, m: 0, p: 1 }}>
      {/*COMMUNICATION */}

      <Stack direction={"row"} sx={{ pl: 1, pr: 1 }}>
        <Grid item sx={{ m: 0, pr: 1 }}>
          {!!product[0].url ? (
            <Avatar
              variant="rounded"
              alt={product[0].name || ""}
              src={variables.PHOTO_URL + product[0].url + ".png"}
              sx={{ p: 0, width: 72, height: 72, borderRadius: 3 }}
            />
          ) : (
            <Avatar
              {...GetAvatarInitials(
                product[0].name || "empty sender",
                72,
                72,
                3
              )}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            {step1}
          </Typography>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            {step2}
          </Typography>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            {step3}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "wrap",
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            {comment}
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
};

const Header = (props) => {
  const { icon: IH, action: AH, headerText: HT } = props;
  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <IH sx={{ fontSize: 40 }} />
      </Box>
      <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
        <Typography noWrap variant="subHeader">
          {HT} {props.children}
        </Typography>
      </Box>
      <Box sx={{}}>{AH && <AH sx={{ fontSize: 40 }} />}</Box>
    </Box>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_BOARD":
      return {
        ...state,
        board: action.board,
      };
    case "CHANGE_PRIORITY":
     // console.log("CHANGE_PRIORITY", state, action);
      return {
        ...state,
        priority: action.priority,
      };
    case "CHANGE_TECH":
      return {
        ...state,
        techs: action.technicians,
      };
    case "CHANGE_EMPLOYEE":
      return {
        ...state,
        employees: action.employees,
      };
    default:
      return null;
  }
};

export const SaleDetails = () => {
  const { width, height } = useWindowsDimension();
  const { ticketId } = useParams();
  let ticket = {};
  // console.log("TicketDetails -", ticket);

  // form
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setFocus,
    formState: { isDirty },
  } = useForm({
    mode: "onSubmit",
    resolver: undefined,
    // defaultValues: product,
  });

  // states
  const [state, actions] = useReducer(reducer, ticket);

  /* FUNCTIONS */

  // handles

  const handleBoard = () => {
  //  console.log("handleBoard");
    handleSubmit(onSubmit);
  };

  // form onsubmit
  const onSubmit = (data, e) => {
    //e.preventDefault();
   // console.log(data);
  };
  //console.log("ticket priority", ticket.priority);

  /* useEffect(() => {
    console.log("useeffect ticket state priority", ticket.priority, state.priority);

  }, [state.priority]); */

  return (
    <div>
      <Grid sx={{ ml: 2 }}>
        <SubHeading icon={SupportAgentRoundedIcon} title="Ticket Details" />
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Grid sx={{ ml: 2 }}>
            <SaleCompany company={emptyCompany} />
          </Grid>
          <Paper
            variant="elevation3"
            sx={{
              ml: 2,
              mb: 2,
              backgroundColor: "primary.main",
            }}
          >
            <Header icon={ReportProblemRoundedIcon} headerText="Issue" />
            <Typography
              sx={{
                m: 0,
                ml: 2,
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                letterSpacing: 2,
                fontSize: 14,
                color: "secondary.text",
              }}
            >
              #{ticket.id}
            </Typography>
            <Issues
              product={ticket.product}
              step1={ticket.step1}
              step2={ticket.step2}
              step3={ticket.step3}
              comment={ticket.comment}
            />
          </Paper>

          <form onSubmit={handleSubmit(onSubmit)} name="priorityForm">
            <Paper
              variant="elevation3"
              sx={{
                ml: 2,
                mb: 2,
                p: 1,
                backgroundColor: "primary.main",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack display={"flex"} flexGrow={1} direction={"row"}>
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Header
                      icon={DevicesOtherRoundedIcon}
                      headerText="priority"
                    />
                  </Box>
                </Stack>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  size="small"
                >
                  <Button
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === "LOW"
                          ? GetPriorityColor(state.priority)
                          : "primary.main",
                    }}
                    onClick={() => {
                      actions({ type: "CHANGE_PRIORITY", priority: "LOW" });
                    }}
                  >
                    Low
                  </Button>
                  <Button
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === "NORMAL"
                          ? GetPriorityColor(state.priority)
                          : "primary.main",
                    }}
                    onClick={() => {
                      actions({
                        type: "CHANGE_PRIORITY",
                        priority: "NORMAL",
                      });
                    }}
                  >
                    Normal
                  </Button>
                  <Button
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === "HIGH"
                          ? GetPriorityColor(state.priority)
                          : "primary.main",
                    }}
                    onClick={() => {
                      actions({ type: "CHANGE_PRIORITY", priority: "HIGH" });
                    }}
                  >
                    High
                  </Button>
                </ButtonGroup>
              </Grid>
            </Paper>
          </form>
          <form onSubmit={handleSubmit(onSubmit)} name="boardForm">
            <Paper
              variant="elevation3"
              sx={{
                ml: 2,
                mb: 2,
                p: 1,
                backgroundColor: "primary.main",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <Header
                    icon={DevicesOtherRoundedIcon}
                    headerText="board"
                  ></Header>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <FormSelect
                    headerText={"Select Board"}
                    control={control}
                    label="Board"
                    list={[
                      { id: 0, name: "Support" },
                      { id: 1, name: "Sale" },
                      { id: 2, name: "Project" },
                    ]}
                    handleChange={handleSubmit(onSubmit)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ ml: 2, mb: 2 }}>
            <Paper variant="elevation3" sx={{ height: 275 }}>
              <Header
                icon={PersonOutlineRoundedIcon}
                action={PersonAddAltRoundedIcon}
                headerText="contacts"
              />
              <List
                sx={{
                  pl: 2,
                  pr: 2,
                  height: 200,
                  overflow: "auto",
                }}
              >
                {ticket.contacts.map((contact) => (
                  <PersonList
                    key={contact.id}
                    person={contact}
                    action={PersonRemoveRoundedIcon}
                  />
                ))}
              </List>
            </Paper>
          </Grid>

          <Grid sx={{ ml: 2, mb: 2 }}>
            <Paper variant="elevation3" sx={{ height: 150 }}>
              <Header
                icon={PersonOutlineRoundedIcon}
                action={PersonAddAltRoundedIcon}
                headerText="owner"
              />
              <List
                sx={{
                  pl: 2,
                  pr: 2,
                  height: 250,
                  overflow: "auto",
                }}
              >
                <PersonList
                  key={ticket.owner.id}
                  person={ticket.owner}
                  action={PersonRemoveRoundedIcon}
                />
              </List>
            </Paper>
          </Grid>

          <Grid sx={{ ml: 2 }}>
            <Paper
              variant="elevation3"
              sx={{ height: Math.ceil(height - 580) }}
            >
              <Header
                icon={EngineeringIcon}
                action={PersonAddAltRoundedIcon}
                headerText="technicians"
              />
              <List
                sx={{
                  pl: 2,
                  pr: 2,
                  height: Math.ceil(height - 662),
                  overflow: "auto",
                }}
              >
                {ticket.technicians.map((contact) => (
                  <PersonList
                    key={contact.id}
                    person={contact}
                    action={PersonRemoveRoundedIcon}
                  />
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

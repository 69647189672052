import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { HeaderButtonInterface } from 'Interfaces/IGlobalTypeConfig';
import { useTranslation } from 'react-i18next';

export const OrderHeaderButtonDetails = (
  
  linkId1: string | null,
  linkId2: string | null
  
): HeaderButtonInterface[] => [
  
  {
    name: "Orders.HeaderButtons.unmanaged",
    component: { Link },
    to: `/orders`,
    icon: DashboardIcon,
  },
  {
    name: "Orders.HeaderButtons.orderReceived",
    component: { Link },
    to: `/orders/orderReceived`,
    icon: LocalShippingIcon,
  },
  {
    name: "Orders.HeaderButtons.history",
    component: { Link },
    to: `/orders/orderHistory`,
    icon: HistoryToggleOffIcon,
  },
  {
    name: "Orders.HeaderButtons.finance",
    component: { Link },
    to: `/orders/finance`,
    icon: AutoGraphIcon,
  },
];
import { Badge, Box, Typography } from "@mui/material";
import { formatNumber } from "functions/formatNumber";

import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';

type Props = {
    subHeader: string;
    totalValue: number;
    lastPeriodValue: number;
  };

export const HeaderValue = ({subHeader, totalValue, lastPeriodValue }: Props) => {
    let colorType = `success.text`;
    if (totalValue > lastPeriodValue) {
      colorType = `success.text`;
      return (
        <Badge
          sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={
            <Typography
              sx={{
                fontSize: 14,
                backgroundColor: 'transparent',
                color: colorType,
              }}
            >
              {(
                (totalValue - lastPeriodValue) /
                (totalValue / 100)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + '%'}
            </Typography>
          }
        >
          <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
            <TrendingUpRoundedIcon
              sx={{
                mt: 2.5,
                fontSize: 40,
                color: colorType,
              }}
            />
          </Box>
        </Badge>
      );
    } else if (totalValue < lastPeriodValue) {
      colorType = `warning.text`;
      return (
        <Badge
          sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={
            <Typography
              sx={{
                fontSize: 14,
                backgroundColor: 'transparent',
                color: colorType,
              }}
            >
              {formatNumber(
                (totalValue - lastPeriodValue) / (totalValue / 100),
                2
              ) + '%'}
            </Typography>
          }
        >
          <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
            <TrendingDownRoundedIcon
              sx={{
                mt: 2,
                fontSize: 40,
                color: colorType,
              }}
            />
          </Box>
        </Badge>
      );
    } else {
      colorType = `secondary.text`;
      return (
        <Badge
          sx={{ mr: 2.5, mt: 1.5, backgroundColor: 'transparent' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={
            <Typography
              sx={{
                fontSize: 14,
                backgroundColor: 'transparent',
                color: colorType,
              }}
            >
              {(
                (totalValue - lastPeriodValue) /
                (totalValue / 100)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + '%'}
            </Typography>
          }
        >
          <Box flexGrow={1} sx={{ mr: -2.5, mt: -1.5 }}>
            <TrendingFlatRoundedIcon
              sx={{
                mt: 2,
                fontSize: 40,
                color: colorType,
              }}
            />
          </Box>
        </Badge>
      );
    }
  };
import GetAvatarInitials from "functions/GetAvatarInitials";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + "/img/",
};

export const SaleCompany = (props) => {
  const { company } = props;
  return (
    <>
      <Paper
        variant="elevation3"
        sx={{
          m: 0,
          p: 0,
          mb: 2,
        }}
      >
        <ListItem>
          <ListItemAvatar
          sx={{backgroundColor:'primary.text',p:1,borderRadius:2,}}
          >
            {company.logo ? (
              <Avatar
                variant="rounded"
                alt={company.name}
                src={variables.PHOTO_URL + company.logo + ".png"}
                sx={{ width: 110, height: 55 }}
              />
            ) : (
              <Avatar {...GetAvatarInitials(company?.name, 110, 55, 2)} />
            )}
          </ListItemAvatar>
          <ListItemText
            sx={{ ml: 2 }}
            primary={
              <Typography variant="subHeader">{company?.name}</Typography>
            }
            secondary={company?.email}
          />
        </ListItem>

        
      </Paper>
    </>
  );
};

import SupportAgentRounded from '@mui/icons-material/SupportAgentRounded';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import { Grid, List, Paper } from '@mui/material';
import { ICompanyTicketViewModel } from 'Interfaces/ICompaniesConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import { SingleTicketView } from 'components/ui/tickets/SingleTicketView';
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import TicketContext from './TicketContext';
import { ITicketViewModel } from 'Interfaces/ITicketConfig';

export const TicketMerge = (props: any) => {
  const { height } = useWindowsDimension();
  let navigate = useNavigate();
  const { ticketId } = useParams() as any;
  // console.log('ticketId', ticketId);

  const [companyId, setCompanyId] = useState('');
  const [mergeableTickets, setMergeableTickets] = useState([]);

  /*  
  const { data: ticketsData } = useGet(
    rq.CompanyTickets(companyId, isEnabled),
    isEnabled
  );
 */
  let isEnabled = ticketId ? true : false;
  let isTicketsEnabled = companyId ? true : false;
  const { data: ticketData } = useGet(
    rq.ticket(ticketId, true, '0'),
    isEnabled // Only fetch if ticketId is present
  );

  const {
    data: mergeableTicketsData,
    isError,
    error,
  } = useGet({
    url: `/Ticket/Mergable/${companyId}/${ticketId}`,
    keys: ['mergeable tickets', companyId],
    cacheQuery: { keys: ['mergeable tickets'], id: companyId },
    enabled: !!companyId && isEnabled,
  });

  const handleClick = (selectedTicketId: any) => {
    console.log('clicked', selectedTicketId);
    navigate(`/tickets/${ticketId}/merge/${selectedTicketId}`);
  };

  useEffect(() => {
    if (ticketData) {
      console.log('ticket data', ticketData);
      setCompanyId(ticketData.company.id);
    }
  }, [ticketData]);

  useEffect(() => {
    if (mergeableTicketsData) {
      const filteredTickets = mergeableTicketsData.tickets.filter(
        (ticket: ITicketViewModel) => ticketId !== ticket.id
      );
      setMergeableTickets(filteredTickets);
    }
  }, [mergeableTicketsData, ticketId]);

  useEffect(() => {
    console.log('companyId', companyId, !!companyId);
  }, [companyId]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: 0.5, m: 0 }}>
        <SearchFilter
          Icon={SupportAgentRounded}
          Header='Choose ticket to merge...'
          // filterValue={filterType}
          // handleFilterChange={handleFilter}
          // handleSearch={handleSearch}
          // handleNew={handleNew}
          // selectList={departments}
          hasSelectAll={true}
        />

        <List
          sx={{
            height: Math.ceil(height - 244),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {mergeableTicketsData &&
            mergeableTicketsData?.tickets.map(
              (ticket: ICompanyTicketViewModel, index: number) => (
                <SingleTicketView
                  // key={ticketIndex}
                  // index={id}
                  ticket={ticket}
                  onClick={() => {
                    handleClick(ticket.id);
                  }}
                  // setIsDragDisabled={setIsDisabled}
                  //setCompany={setCompany}
                />
              )
            )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          PAGINATION
        </Paper>
      </Grid>
      {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ m: 0, pl: 0.5 }}>
        <Paper
          variant='elevation3'
          sx={{
            height: Math.ceil(height - 128),
          }}
        >
          <Grid item xs={12} sx={{ pr: 0.5, m: 0 }}>
            <SearchFilter
              Icon={TextsmsRoundedIcon}
              Header='Selected Ticket Posts To Merge'
              // filterValue={filterType}
              // handleFilterChange={handleFilterChange}
              // handleSearch={handleSearch}
              // selectList={ticketStatus}
            />
            <TicketContext.Provider value={ticketData}>
              <Outlet />
            </TicketContext.Provider>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

import { createTheme } from '@mui/material';
import {
  blue,
  red,
  amber,
  orange,
  cyan,
  purple,
  blueGrey,
  teal,
  yellow,
  grey,
  green,
} from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    header?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    header?: React.CSSProperties;
  }
}

const primaryColor_Main = blueGrey[700];
const primaryColor_Light = blueGrey[500];
const primaryColor_Lighter = blueGrey[300];
const primaryColor_Dark = blueGrey[900];
const primaryColor_Back = blueGrey[800];
const primaryColor_Text = blueGrey[100];

const secondaryColor_Main = orange[700];
const secondaryColor_Light = orange[500];
const secondaryColor_Lighter = orange[300];
const secondaryColor_Dark = orange[900];
const secondaryColor_Back = orange[800];
const secondaryColor_Text = orange[100];

const thirdColor_Main = purple[700];
const thirdColor_Light = purple[500];
const thirdColor_Lighter = purple[300];
const thirdColor_Dark = purple[900];
const thirdColor_Back = purple[800];
const thirdColor_Text = purple[100];

const errorColor_Main = amber[700];
const errorColor_Light = amber[500];
const errorColor_Lighter = amber[300];
const errorColor_Dark = amber[900];
const errorColor_Back = amber[800];
const errorColor_Text = amber[100];

const none_Dark = grey[600];
const none_Main = grey[800];
const none_Light = grey[400];

const low_Dark = blue[800];
const low_Main = blue[600];
const low_Light = blue[400];

const normal_Dark = teal[800];
const normal_Main = teal[600];
const normal_Light = teal[400];

const high_Dark = red[800];
const high_Main = red[600];
const high_Light = red[400];

const critical_Dark = purple[800];
const critical_Main = purple[600];
const critical_Light = purple[400];

const warningColor_Main = red[700];
const warningColor_Light = red[500];
const warningColor_Lighter = red[300];
const warningColor_Dark = red[900];
const warningColor_Back = red[800];
const warningColor_Text = red[100];

const infoColor_Main = cyan[700];
const infoColor_Light = cyan[500];
const infoColor_Lighter = cyan[300];
const infoColor_Dark = cyan[900];
const infoColor_Back = cyan[800];
const infoColor_Text = cyan[100];

const successColor_Main = teal[700];
const successColor_Light = teal[500];
const successColor_Lighter = teal[300];
const successColor_Dark = teal[900];
const successColor_Back = teal[800];
const successColor_Text = teal[100];

const paperDefaultColor_Main = blueGrey[700];

const divider_Menu = blueGrey[300];

const avatar_background_light = blueGrey[50];
const avatar_background_main = blueGrey[100];
const avatar_background_dark = blueGrey[200];

// type declerations for custom palette options
declare module '@mui/material/styles' {
  interface PaletteOptions {
    third: PaletteOptions['primary'];
    priorityNone: PaletteOptions['primary'];
    priorityLow: PaletteOptions['primary'];
    priorityNormal: PaletteOptions['primary'];
    priorityHigh: PaletteOptions['primary'];
    priorityCritical: PaletteOptions['primary'];
    avatar: PaletteOptions['primary'];
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    back?: string;
    text?: string;
    error?: string;
  }

  interface TypographyVariants {
    subheader: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subheader?: React.CSSProperties;
  }
}

// type declerations for custom component variants
declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    elevation3: true;
    accordion: true;
  }
}

declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    dividerForm: true;
    dividerMenu: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subheader: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    barColor?: {
      investment: { Equal: string, Up: string, Down: string, normal: string };
      support: { Equal: string, Up: string, Down: string, normal: string };
      agreement: { Equal: string, Up: string, Down: string, normal: string };
      other: { Equal: string, Up: string, Down: string, normal: string };
    };
  }
  interface PaletteOptions {
    barColor?: {
      investment: { Equal: string, Up: string, Down: string, normal: string };
      support: { Equal: string, Up: string, Down: string, normal: string };
      agreement: { Equal: string, Up: string, Down: string, normal: string };
      other: { Equal: string, Up: string, Down: string, normal: string };
    };
  }
}

const BluuTheme = createTheme({
  palette: {
    mode: 'dark',
    barColor: {
      investment: { Equal: blue[700], Up: blue[700], Down: blue[700], normal: blue[700] },
      support: { Equal: teal[700], Up: teal[700], Down: teal[700], normal: teal[700] },
      agreement: { Equal: red[700], Up:red[700], Down: red[700], normal: red[700] },
      other: { Equal: orange[700], Up: orange[700], Down:orange[700], normal: orange[700] },
    },
    primary: {
      main: primaryColor_Main,
      light: primaryColor_Light,
      lighter: primaryColor_Lighter,
      dark: primaryColor_Dark,
      back: primaryColor_Back,
      text: primaryColor_Text,
    },
    secondary: {
      main: secondaryColor_Main,
      light: secondaryColor_Light,
      lighter: secondaryColor_Lighter,
      dark: secondaryColor_Dark,
      back: secondaryColor_Back,
      text: secondaryColor_Text,
    },
    third: {
      main: thirdColor_Main,
      light: thirdColor_Light,
      lighter: thirdColor_Lighter,
      dark: thirdColor_Dark,
      back: thirdColor_Back,
      text: thirdColor_Text,
    },
    error: {
      main: errorColor_Main,
      light: errorColor_Light,
      lighter: errorColor_Lighter,
      dark: errorColor_Dark,
      back: errorColor_Back,
      text: errorColor_Text,
    },
    warning: {
      main: warningColor_Main,
      light: warningColor_Light,
      lighter: warningColor_Lighter,
      dark: warningColor_Dark,
      back: warningColor_Back,
      text: warningColor_Text,
    },
    info: {
      main: infoColor_Main,
      light: infoColor_Light,
      lighter: infoColor_Lighter,
      dark: infoColor_Dark,
      back: infoColor_Back,
      text: infoColor_Text,
    },
    success: {
      main: successColor_Main,
      light: successColor_Light,
      lighter: successColor_Lighter,
      dark: successColor_Dark,
      back: successColor_Back,
      text: successColor_Text,
    },
    background: {
      paper: '#37474f',
      default: '#263238',
    },
    priorityNone: {
      dark: none_Dark,
      main: none_Main,
      light: none_Light,
    },
    priorityLow: {
      dark: low_Dark,
      main: low_Main,
      light: low_Light,
    },
    priorityNormal: {
      dark: normal_Dark,
      main: normal_Main,
      light: normal_Light,
    },
    priorityHigh: {
      dark: high_Dark,
      main: high_Main,
      light: high_Light,
    },
    priorityCritical: {
      dark: critical_Dark,
      main: critical_Main,
      light: critical_Light,
    },
    avatar: {
      main: avatar_background_main,
      light: avatar_background_light,
      dark: avatar_background_dark,
    },
  },

  typography: {},

  components: {
    MuiPaper: {
      variants: [
        {
          props: { variant: 'elevation3' },
          style: {
            borderRadius: 10,
            elevation: 1,
            backgroundColor: paperDefaultColor_Main,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          },
        },
        {
          props: { variant: 'accordion' },
          style: {
            borderRadius: 10,
            backgroundColor: paperDefaultColor_Main,
            elevation: 3,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          },
        },
      ],
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'dividerForm' },
          style: {
            background: divider_Menu,
          },
        },
        {
          props: { variant: 'dividerMenu' },
          style: {
            background: divider_Menu,
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.95rem',
          color: primaryColor_Text,
        },
        shrink: {
          '&.Mui-focused': {
            transform: 'translate(14px, -8px) scale(1) !important',
            color: successColor_Text,
          },
          transform: 'translate(14px, -8px) scale(1) !important',
          color: primaryColor_Text,
        },
        outlined: {
          transform: 'translate(14px, 16px) scale(1)',
          color: primaryColor_Text,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true,
        sx: { mb: 2 },
      },
      styleOverrides: {
        root: {
          '& label': {
            // set some styles for the label if need it
          },
          '& legend': {
            // set some styles for the legend if need it
          },

          // this is styles for the new variants
          '&.subvariant-hovered': {
            '& fieldset': {
              border: 'none',
            },
            '& .MuiInputBase-input:hover + fieldset': {
              border: `2px solid blue`,
            },
            '& .MuiInputBase-input:focus + fieldset': {
              border: `2px solid blue`,
            },
          },
        },
      },
    },
  },
});

export default BluuTheme;

import { useEffect, useReducer, useState } from 'react';
import { useGet, usePost } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import GetPriorityColor from 'functions/GetPriorityColor';
import {
  ButtonGroup,
  Grid,
  List,
  Paper,
  Stack,
  Typography,
  Select,
  Button,
  TextField,
  MenuItem,
  ListItem,
} from '@mui/material';
import { ticketType } from 'data/enum';
import EditIcon from '@mui/icons-material/Edit';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import {
  IEmployeeTicketViewModel,
  IEmployeeViewModel,
  IEmployeeWithCompanyViewModel,
  INewTicketAddWithProductInitialState,
  ITicketAddWithProductViewModel,
  ITicketCompanyViewModel,
} from '../../Interfaces/ITicketConfig';
import {
  EnumBillingType,
  EnumBoard,
  EnumJobType,
  EnumObjectType,
  EnumPlacement,
  EnumPriority,
  EnumScheduleType,
  EnumTicketStatus,
  IDeliveryAddressViewModel,
  IMobileAnswersViewModel,
  IScheduleViewModel,
  addressListData,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { ScheduleView } from 'components/ui/ScheduleView';
import { DeliveryAddressView } from 'components/ui/DeliveryAddressView';
import { IAutoAnswersViewModel } from 'pages/AutoAnswersConfig';
import { SingleAutoAnswerView } from 'components/ui/SingleAutoAnswerView';
import { SingleTicketContact } from 'components/ui/SingleTicketContact';
import { SingleTicketContactProduct } from 'components/ui/SingleTicketContactProduct';
import { ProductCardEmployeeView } from 'components/ui/ProductCardEmployeeView';
import { FormBox } from 'components/form';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  ConvertProductToCompanyProduct,
  ConvertStorageProductToCompanyProduct,
} from 'functions/Convert';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import userAtom from 'data/atoms/userAtom';
import { useGetObjectList } from 'functions/useGetObjectList';
import { GenerateAddressList } from 'functions/Converters';

let _ = require('lodash');

function sleep(ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const ConvertStateToTicketAddWithProductViewModel = (
  state: INewTicketAddWithProductInitialState,
  user: any
) => {
  let obj: ITicketAddWithProductViewModel = {
    board: state.board,
    priority: state.priority,
    status: state.status,
    ticketNo: state.ticketNo,
    deliveryDate: state.scheduleTime.deliveryDate,
    scheduled: state.scheduleTime.startDate,
    ETCHours: state.scheduleTime.estimatedTime.hours,
    ETCMinutes: state.scheduleTime.estimatedTime.minutes,
    jobType: state.jobType,
    supportTypeId: state.supportType.id,
    errorDescriptionId: state.errorDescription.id,
    companyId: state.companyId,
    contactId: state.contactId,
    creatorId: user.id,
    responsibleId: state.responsibleId || null,

    shippingCost: state.shippingCost,

    deliveryStreet: state.deliveryAddress?.street ?? '',
    deliveryPostalCode: state.deliveryAddress.postalCode || '',
    deliveryArea: state.deliveryAddress.area || '',
    deliveryCity: state.deliveryAddress.city || '',
    deliveryCountry: state.deliveryAddress.country || '',
    deliveryPostbox: state.deliveryAddress.postBox || '',

    attention: state.deliveryAddress.attention || '',
    comment: state.comment,

    fixedHourlyRate: 0,
    fixedTime: 0,
    fixedPrice: 0,
    maxTime: 0,
    maxPrice: 0,

    productBilling: state.productBilling,
    billing: state.billing,
    title: state.title,
    companyProductId: state.companyProduct.id,

    companyProducts: state.companyProducts,
    productCatalog: state.productCatalog,
    storageProducts: state.storageProducts,
    unassignedCompanyProducts: state.unassignedCompanyProducts,
    techniciansId: state.techniciansId,
  };

  return obj;
};

type Action =
  | { type: 'SET_AUTO_ANSWERS'; payload: any }
  | { type: 'SET_AUTO_ANSWER'; payload: any }
  | { type: 'SET_BOARD'; payload: any }
  | { type: 'EDIT_COMMENT'; payload: any }
  | { type: 'SET_EMPLOYEES'; payload: IEmployeeViewModel[] }
  | {
      type: 'SET_COMPANY_PRODUCTS';
      payload: ICompanyProductViewModel[];
    }
  | {
      type: 'SET_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'SET_COMPANY'; payload: any }
  | { type: 'SET_CONTACT'; payload: IEmployeeWithCompanyViewModel }
  | { type: 'SET_RESPONSIBLE'; payload: IEmployeeViewModel }
  | { type: 'SET_TECHNICIANS'; payload: IEmployeeViewModel[] }
  | { type: 'SET_PRIORITY'; payload: string | number }
  | { type: 'SET_COMMENT'; payload: string }
  | { type: 'SET_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'SET_DELIVERY_ADDRESS'; payload: { id: string } }
  | { type: 'UPDATE_ADDRESS_LIST'; payload: IDeliveryAddressViewModel[] }
  | { type: 'REMOVE_RESPONSIBLE'; payload: IEmployeeViewModel }
  | { type: 'REMOVE_CONTACT'; payload: IEmployeeViewModel }
  | { type: 'REMOVE_TECHNICIAN'; payload: IEmployeeViewModel }
  | { type: 'RESET_FORM' }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'CLEAR_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'EDIT_TITLE'; payload: string }
  | {
      type: 'ADD_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'REMOVE_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'SET_DIRTY';
      payload: boolean;
    }
  | {
      type: 'UPDATE_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    };

const initialState: INewTicketAddWithProductInitialState = {
  ticketNo: '',
  board: EnumBoard.Support,
  priority: EnumPriority.NORMAL,
  status: EnumTicketStatus.New,
  company: {} as ITicketCompanyViewModel,
  contact: {} as IEmployeeViewModel,
  responsible: {} as IEmployeeViewModel,
  shippingCost: 0,
  deliveryAddress: {
    id: '',
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  } as IDeliveryAddressViewModel,
  scheduleTime: {
    id: '0',
    scheduleType: EnumScheduleType.None,
    deliveryDate: new Date(),
    startDate: new Date(),
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  } as IScheduleViewModel,
  jobType: EnumJobType.Support,
  supportType: {} as IMobileAnswersViewModel,
  errorDescription: {} as IMobileAnswersViewModel,
  companyProduct: {} as ICompanyProductViewModel,
  technicians: [] as IEmployeeTicketViewModel[],
  comment: '',
  fixedHourlyRate: 0,
  fixedTime: 0,
  fixedPrice: 0,
  maxTime: 0,
  maxPrice: 0,
  productBilling: true,
  updated: new Date(),
  billing: EnumBillingType.SystemPeriod,
  title: '',
  companyProducts: [] as ICompanyProductViewModel[],
  companyId: '',
  contactId: '',
  responsibleId: '',
  techniciansId: [],
  addressList: addressListData,
  productCatalog: [] as ICompanyProductViewModel[],
  storageProducts: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isDirty: false,
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      //console.log('ADD_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: [...state.productCatalog, action.payload],
      };
    case 'REMOVE_PRODUCT':
      // console.log('REMOVE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_PRODUCT':
      // console.log('UPDATE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.map((product: any) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_STORAGE_PRODUCT':
      // console.log('ADD_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: [...state.storageProducts, action.payload],
      };
    case 'REMOVE_STORAGE_PRODUCT':
      // console.log('REMOVE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_STORAGE_PRODUCT':
      // console.log('UPDATE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.map((product: any) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: [...state.companyProducts, action.payload],
      };
    case 'REMOVE_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.map((product: any) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_UNASSIGNED_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action.payload,
        ],
      };
    case 'REMOVE_UNASSIGNED_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_UNASSIGNED_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.map(
          (product: any) =>
            product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'SET_AUTO_ANSWERS': {
      return {
        ...state,
        mobileAnswers: action.payload,
      };
    }
    case 'SET_AUTO_ANSWER': {
      return {
        ...state,
        autoAnswerSelection: action.payload,
      };
    }
    case 'SET_BOARD': {
      return {
        ...state,
        board: action.payload,
      };
    }
    case 'EDIT_COMMENT': {
      return {
        ...state,
        comment: action.payload,
      };
    }
    case 'SET_EMPLOYEES':
      return { ...state, employees: action.payload };
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.payload,
        companyId: action.payload.id,
      };
    case 'SET_COMPANY_PRODUCTS': {
      return {
        ...state,
        companyProducts: action.payload,
      };
    }
    case 'SET_COMPANY_PRODUCT': {
      return {
        ...state,
        companyProduct: action.payload,
      };
    }
    case 'SET_CONTACT': {
      return {
        ...state,
        contact: action.payload,
        contactId: action.payload.id,
      };
    }
    case 'SET_RESPONSIBLE': {
      return {
        ...state,
        responsible: action.payload,
        responsibleId: action.payload.id,
      };
    }
    case 'SET_TECHNICIANS': {
      return {
        ...state,
        technicians: [...state.technicians, ...action.payload],
      };
    }
    case 'SET_PRIORITY': {
      return {
        ...state,
        priority: action.payload,
      };
    }
    case 'SET_COMMENT': {
      return {
        ...state,
        comment: action.payload,
      };
    }

    case 'SET_SCHEDULE_TIME':
      return {
        ...state,
        scheduleTime: {
          ...action.payload,
        },
      };
    case 'UPDATE_ADDRESS_LIST':
      return {
        ...state,
        addressList: {
          ...action.payload,
        },
      };
    case 'SET_DELIVERY_ADDRESS':
      return {
        ...state,
        deliveryAddress: {
          ...action.payload,
        },
      };
    case 'REMOVE_RESPONSIBLE': {
      return {
        ...state,
        responsible: {} as IEmployeeViewModel,
        responsibleId: null,
      };
    }
    case 'REMOVE_CONTACT': {
      // console.log('remove contact', action.payload);
      return {
        ...state,
        contactId: null,
        contact: null,
      };
    }
    case 'REMOVE_TECHNICIAN': {
      //  console.log('REMOVE_TECHNICIAN', action.payload, state.technicians);
      const updatedTechnicians = state.technicians.filter(
        (technician: any) => technician.id !== action.payload.id
      );

      // Return the new state with the updated technicians array
      return { ...state, technicians: updatedTechnicians };
    }
    case 'SET_DIRTY': {
      return { ...state, isDirty: action.payload };
    }
    case 'EDIT_TITLE':
      return {
        ...state,
        title: action.payload,
      };
    case 'CLEAR_SCHEDULE_TIME': {
      return {
        ...state,
        scheduleTime: action.payload,
      };
    }
    case 'RESET_FORM': {
      return { ...initialState };
    }
    default:
      return initialState;
  }
};

export const NewTicket = () => {
  /* INITIALIZE */
  // let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const [owner, setOwner] = useAtom(ownerAtom);
  const [user, setUser] = useAtom(userAtom);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  // states
  const [options, setOptions] = useState<any>([]);
  const loading = options.length === 0;

  // api
  let isEnabled = state.contact?.id ? true : false;

  // api
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let companyId = state?.company?.id ? state.company.id : 'new';
  let employeeId = state?.contact?.id ? state.contact.id : 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
    // setResource(objectDrawerList[Number(EnumObjectType.Resource)].objectList);
    setIsObjectList(false);
  }, [objectDrawerList]);

  const { data: mobileAutoAnswersData } = useGet(
    rq.mobileAutoAnswers('BBB00BBB-6C0C-4C0C-8C0C-BBB001BBB001', true),
    true
  );
  const { data: employeeData } = useGet(rq.allEmployees(true), true);
  const { data: employeeWithProductsData } = useGet(
    rq.employeeWithProducts(
      state.state?.company?.id,
      state.contact?.id,
      isEnabled
    ),
    isEnabled
  );

  const postTicket = usePost(
    rqPost.ticket(state.companyId || '', 1, true),
    true
  );

  /* FUNCTIONS */
  const autoAnswerIsObject = (object: any) => {
    return typeof object === 'object';
  };
  useEffect(() => {
    console.log('FORM STATE', state);
  }, [state.ticket, state]);

  // HANDLES
  const handleChange = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Product:
        let product = ConvertProductToCompanyProduct(
          selectedObject as IBillingProductViewModel
        );
        dispatch({
          type: 'ADD_PRODUCT',
          payload: product as ICompanyProductViewModel,
        });

        break;
      case EnumObjectType.CompanyProduct:
        dispatch({
          type: 'ADD_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });

        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'ADD_UNASSIGNED_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });

        break;
      case EnumObjectType.StorageProduct:
        // convert to company product
        let storageProduct = ConvertStorageProductToCompanyProduct(
          selectedObject as IStorageProductViewModel
        );
        dispatch({
          type: 'ADD_STORAGE_PRODUCT',
          payload: storageProduct as ICompanyProductViewModel,
        });

        break;
    }
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
    let isStorageProductEmpty =
      state.storageProducts.length === 0 && state.productCatalog.length === 0
        ? 0
        : 1;
    dispatch({
      type: 'SET_BOARD',
      payload: isStorageProductEmpty,
    });
  };
  const handleRemoveProduct = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'REMOVE_PRODUCT', payload: companyProduct });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'REMOVE_UNASSIGNED_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.CompanyProduct:
        if (companyProduct.resourceId) {
          // Utfører første dispatch-kall
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              resourceId: null,
              resource: null,
              placement: EnumPlacement.NONE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: true,
          });
        } else {
          // Utfører dispatch-kall hvis betingelsen ikke er oppfylt
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              resourceId: state.id,
              placement: EnumPlacement.RESOURCE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: false,
          });
        }
        break;

      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'REMOVE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
    }

    let isStorageProductEmpty =
      state.storageProducts.length === 0 && state.productCatalog.length === 0
        ? 0
        : 1;
    dispatch({
      type: 'SET_BOARD',
      payload: isStorageProductEmpty,
    });
  };
  const handleChangeCompanyProduct = (
    event: any,
    companyProduct: ICompanyProductViewModel
  ) => {
    dispatch({ type: 'SET_COMPANY_PRODUCT', payload: companyProduct });
  };
  const handleResponsibleChange = (
    event: any,
    responsible: IEmployeeViewModel
  ) => {
    dispatch({ type: 'SET_RESPONSIBLE', payload: responsible });
  };
  const handlePriorityChange = (e: any, priority: EnumPriority) => {
    e.preventDefault();
    dispatch({ type: 'SET_PRIORITY', payload: priority });
  };

  const handleChangeEmployee = (
    e: any,
    employee: IEmployeeWithCompanyViewModel
  ) => {
    e.preventDefault();
    let tmp: IDeliveryAddressViewModel[] = GenerateAddressList(
      state.addressList,
      owner,
      employee
    );
    dispatch({
      type: 'UPDATE_ADDRESS_LIST',
      payload: tmp,
    });

    dispatch({
      type: 'SET_CONTACT',
      payload: employee,
    });
    dispatch({
      type: 'SET_COMPANY',
      payload: employee.company,
    });

    dispatch({
      type: 'SET_PRIORITY',
      payload: employee.company.priority,
    });

    setIsObjectList(true);
  };
  const handleChangeAutoAnswer = (
    event: any,
    autoAnswer: IAutoAnswersViewModel
  ) => {
    console.log('handleChangeAutoAnswer', autoAnswer);
    // console.log('handleChangeAutoAnswer', autoAnswer);
    dispatch({
      type: 'SET_AUTO_ANSWER',
      payload: autoAnswer,
    });
  };
  const handleChangeSchedule = (changeType: string, e: any) => {
    let scheduleTime = { ...state.scheduleTime };
    switch (changeType.toUpperCase()) {
      case 'HOURS':
        scheduleTime.estimatedTime.hours = e;
        break;
      case 'MINUTES':
        scheduleTime.estimatedTime.minutes = e;
        break;
    }
    dispatch({
      type: 'SET_SCHEDULE_TIME',
      payload: scheduleTime,
    });
  };
  const handleChangeDeliveryAddress = (
    deliveryAddress: IDeliveryAddressViewModel
  ) => {
    // console.log('handleChangeDeliveryAddress', deliveryAddress);
    // console.log('handleChangeDeliveryAddress', deliveryAddress);
    if (deliveryAddress && deliveryAddress.id) {
      dispatch({
        type: 'SET_DELIVERY_ADDRESS',
        payload: { id: deliveryAddress.id },
      });
    }
  };
  const handleTechniciansChange = (
    event: any,
    technician: IEmployeeViewModel
  ) => {
    // console.log('handleTechniciansChange', technician);
    // console.log('handleTechniciansChange', technician);
    dispatch({
      type: 'SET_TECHNICIANS',
      payload: [technician],
    });
  };

  const handleRemoveTechnician = (
    event: React.ChangeEvent<HTMLInputElement>,
    technician: IEmployeeViewModel
  ) => {
    // console.log('handleRemoveTechnician', technician);
    // console.log('handleRemoveTechnician', technician);
    dispatch({ type: 'REMOVE_TECHNICIAN', payload: technician });
  };

  const handleBlur = (e: any) => {
    e.preventDefault();
    // console.log('blur', e.target.value);
    // console.log('blur', e.target.value);
    let comment = e.target.value;
    dispatch({ type: 'EDIT_COMMENT', payload: comment });
  };

  const handleChangeTitle = (e: any) => {
    dispatch({ type: 'EDIT_TITLE', payload: e.target.value });
    dispatch({ type: 'SET_DIRTY', payload: true });
  };

  const toggleDisabled = () => {
    setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(3e3); // For demo purposes.

      if (active) {
        setOptions([...ticketType]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!employeeData) return;
    let _allEmployees: IEmployeeViewModel[] = _.cloneDeep(
      employeeData?.allEmployees
    );
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: _allEmployees,
    });
  }, [employeeData]);

  useEffect(() => {
    // console.log('STATE TICKET: ', state);
  }, [state]);

  useEffect(() => {
    if (mobileAutoAnswersData) {
      // console.log('mobileAutoAnswersData', mobileAutoAnswersData);
      let autoAnswers = mobileAutoAnswersData.mobileAutoAnswers;
      dispatch({ type: 'SET_AUTO_ANSWERS', payload: autoAnswers });
      dispatch({ type: 'SET_AUTO_ANSWER', payload: autoAnswers[5] });
    }
  }, [mobileAutoAnswersData]);

  useEffect(() => {
    if (employeeWithProductsData) {
      dispatch({
        type: 'SET_COMPANY_PRODUCTS',
        payload: employeeWithProductsData?.companyProducts,
      });
    }
  }, [employeeWithProductsData]);

  const onSubmit = () => {
    let newTicket = ConvertStateToTicketAddWithProductViewModel(state, user);
    console.log('NEWTICKET =>', newTicket);
    postTicket.mutate(newTicket, {
      onSuccess: (state: any) => {
        dispatch({ type: 'RESET_FORM' });
        dispatch({
          type: 'CLEAR_SCHEDULE_TIME',
          payload: {
            id: '0', // or null, if that's more appropriate for a cleared state
            scheduleType: EnumScheduleType.None,
            deliveryDate: new Date(), // or null/undefined, based on what you consider "cleared"
            startDate: new Date(), // or null/undefined
            estimatedTime: {
              hours: 0,
              minutes: 0,
            },
          },
        });
        let autoAnswers = mobileAutoAnswersData.mobileAutoAnswers;
        dispatch({ type: 'SET_AUTO_ANSWERS', payload: autoAnswers });
        dispatch({ type: 'SET_AUTO_ANSWER', payload: autoAnswers[5] });
      },
    });
  };
  // RENDER
  return (
   
      <Grid
        container
        sx={{ m: 0,  display: 'flex',
        flexDirection: 'row', }}
      >
        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
          }}
        >
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              borderRadius: 2,
              backgroundColor: 'primary.main',
            }}
          >
            {/* HEADER  */}
            <Grid container display={'flex'} direction='row' flexGrow={1}>
              <Grid item xs={4} display={'flex'} flexDirection={'row'}>
                <PriorityHighRoundedIcon sx={{ fontSize: 32, m: 0 }} />
                <Typography
                  sx={{
                    m: 0,
                    ml: 0.5,
                    pt: 1,
                    flexGrow: 1,
                    textTransform: 'uppercase',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    fontSize: 14,
                    letterSpacing: 2,
                  }}
                >
                  New ticket
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  flexGrow: 1,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Grid item xs={3} sx={{ pr: 2 }}>
                  <Select
                    sx={{ width: '100%', p: 0, mt: -0.5 }}
                    labelId='Board'
                    id='Board'
                    variant='standard'
                    value={state.board}
                    label='Board'
                    onChange={(e) => {
                      dispatch({ type: 'SET_BOARD', payload: e.target.value });
                    }}
                  >
                    <MenuItem value={0}>Sale</MenuItem>
                    <MenuItem value={1}>Support</MenuItem>
                    <MenuItem value={2}>Project</MenuItem>
                    <MenuItem value={3}>UnKnown</MenuItem>
                    <MenuItem value={4}>NoReplay</MenuItem>
                    <MenuItem value={5}>Spam</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* LEFT SIDE  */}
        <Grid
          item
          xs={6}
          sx={{
            m: 0,
            pr: .5,
          }}
        >
          <Paper
            variant='elevation3'
            sx={{
              height: Math.ceil(height - 180),
              display: 'flex',
              flexDirection: 'column',
              p: 1,
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 0,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/* ASSIGN CUSTOMER EMPLOYEE HEADER */}
              <Grid item xs={12} sx={{ p: 0, mb: 1 }}>
                <FormBox
                  Icon={PersonSearchRoundedIcon}
                  title='Assign Customer Employee'
                />
              </Grid>
              {/* ASSIGN CUSTOMER EMPLOYEE */}
              <Grid item xs={12} sx={{ p: 0, mb: 1 }}>
                {state.contactId !== null ? (
                  <Stack direction='column'>
                    <SingleTicketContact
                      employee={state.contact}
                      company={state.company}
                      priority={state.priority}
                    />
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                        bottom: 78,
                        right: 8,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={'Select Employee'}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.allEmployees]}
                        handleChange={handleChangeEmployee}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </Stack>
                ) : (
                  <ObjectDrawer
                    buttonContext={'Select Employee'}
                    buttonIcon={null}
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[EnumObjectType.allEmployees]}
                    handleChange={handleChangeEmployee}
                    buttonHeight={82}
                    buttonWidth={'100%'}
                  />
                )}
              </Grid>
              {/* PRIORITY HEADER */}
              <Grid item xs={12} sx={{ p: 0, mb: 1 }}>
                <FormBox Icon={PriorityHighRoundedIcon} title='Priority' />
              </Grid>
              {/* PRIORITY BUTTONS  */}
              <Grid
                item
                xs={12}
                sx={{
                  p: 0,
                  mb: 2,
                  flexGrow: 1,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonGroup
                  variant='contained'
                  aria-label='outlined primary button group'
                  size='small'
                  sx={{ width: '100%', backgroundColor: 'red' }}
                >
                  <Button
                    key='NONE'
                    sx={{
                      width: '20%',
                      backgroundColor:
                        state.priority === EnumPriority.NONE
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.NONE),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 0);
                    }}
                  >
                    None
                  </Button>
                  <Button
                    key='LOW'
                    sx={{
                      width: '20%',
                      backgroundColor:
                        state.priority === EnumPriority.LOW
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.LOW),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 1);
                    }}
                  >
                    Low
                  </Button>
                  <Button
                    key='NORMAL'
                    sx={{
                      width: '20%',
                      backgroundColor:
                        state.priority === EnumPriority.NORMAL
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.NORMAL),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 2);
                    }}
                  >
                    Normal
                  </Button>
                  <Button
                    key='HIGH'
                    sx={{
                      width: '20%',
                      backgroundColor:
                        state.priority === EnumPriority.HIGH
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.HIGH),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 3);
                    }}
                  >
                    High
                  </Button>

                  <Button
                    key='CRITICAL'
                    sx={{
                      width: '20%',
                      backgroundColor:
                        state.priority === EnumPriority.CRITICAL
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(
                          EnumPriority.CRITICAL
                        ),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 4);
                    }}
                  >
                    Critical
                  </Button>
                </ButtonGroup>
              </Grid>
              {/* ISSUE HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
                <FormBox
                  Icon={DevicesOtherRoundedIcon}
                  title='Ticket Description'
                />
              </Grid>
              {/* ISSUE TYPE */}
              <Grid item xs={5} sx={{ m: 0, pr: 1, mb: -1 }}>
                {!autoAnswerIsObject(state?.autoAnswerSelection) ? (
                  <>
                    <ObjectDrawer
                      buttonContext={'Select Issue Type'}
                      buttonIcon={null}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.IssueType]}
                      handleChange={handleChangeAutoAnswer}
                      buttonHeight={80}
                      buttonWidth={'100%'}
                    />
                  </>
                ) : (
                  <>
                    <SingleAutoAnswerView
                      autoAnswer={state?.autoAnswerSelection}
                    />
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                        bottom: 78,
                        right: 4,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={'Select Issue Type'}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.IssueType]}
                        handleChange={handleChangeAutoAnswer}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {/* ISSUE PRODUCT */}
              <Grid item xs={7} sx={{ m: 0, pl: 1, mb: -1 }}>
                {true ? (
                  <>
                    <SingleTicketContactProduct
                      product={state.companyProduct}
                    />
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                        bottom: 78,
                        right: 8,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={'Select Company Product'}
                        buttonIcon={
                          <ChangeCircleIcon sx={{ fontSize: 26, m: 1.5 }} />
                        }
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.EmployeeProducts]}
                        handleChange={handleChangeCompanyProduct}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </>
                ) : (
                  state.companyProducts &&
                  state.companyProducts.length > 0 && (
                    <ObjectDrawer
                      buttonContext={'Select Company Product'}
                      buttonIcon={null}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.EmployeeProducts]}
                      handleChange={handleChangeCompanyProduct}
                      buttonHeight={80}
                      buttonWidth={'100%'}
                    />
                  )
                )}
              </Grid>
              {/* TICKET TITLE */}
              <Grid
                item
                xs={12}
                sx={{ mb: 1 }}
                display={'flex'}
                flexDirection={'row'}
              >
                <TextField
                  autoFocus
                  id='name'
                  label='Title'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={isDisabled}
                  value={state.title}
                  onChange={(e) => handleChangeTitle(e)}
                  sx={{ mr: 2 }}
                />
                <Button
                  variant='contained'
                  sx={{
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    padding: 0,
                    backgroundColor: 'primary.light',
                    borderRadius: 2,
                  }}
                  onClick={toggleDisabled}
                >
                  <EditIcon sx={{ height: 20, width: 20 }} />
                </Button>
              </Grid>
              {/* ISSUE HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
                <FormBox
                  Icon={DevicesOtherRoundedIcon}
                  title='EDIT ISSUE DESCRIPTION'
                />
              </Grid>
              {/* ISSUE DESCRIPTION */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <TextField
                  sx={{ m: 0, p: 0, backgroundColor: 'primary.main' }}
                  //value={state.comment}
                  value={state.comment}
                  label={'issue description'}
                  variant='filled'
                  multiline={true}
                  minRows={8}
                  onChange={(e) => {
                    dispatch({
                      type: 'EDIT_COMMENT',
                      payload: e.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              {/* PRODUCT HEADER */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <FormBox
                  Icon={DevicesOtherRoundedIcon}
                  title='Sales products'
                  FormBTN={
                    <ObjectDrawer
                      buttonContext={'Add Product'}
                      buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[
                        EnumObjectType.Product,
                        EnumObjectType.StorageProduct,
                        EnumObjectType.unassignedCompanyProduct,
                        // EnumObjectType.Package,
                      ]}
                      handleChange={handleChange}
                      buttonHeight={26}
                      buttonWidth={26}
                    />
                  }
                />
              </Grid>
              {/* PRODUCTS */}
              <Grid item xs={12} sx={{ m: 0, p: 0,  }}>
                <List
                  sx={{
                    width: '100%',
                    height: Math.ceil(height - 946
                    ),
                    //p: 2,
                    overflow: 'auto',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {state?.SalesProducts?.map(
                    (
                      companyProduct: ICompanyProductViewModel,
                      index: number
                    ) => {
                      return !!companyProduct ? (
                        <ListItem sx={{ mb: 1, pl: 1, pr: 1 }} key={index * 10}>
                          <ProductCardEmployeeView
                            companyProduct={companyProduct}
                            location={EnumObjectType.Resource}
                            objectType={EnumObjectType.CompanyProduct}
                            handleRemoveProduct={handleRemoveProduct}
                            height={80}
                          />
                        </ListItem>
                      ) : null;
                    }
                  )}
                  {state?.unassignedCompanyProducts?.map(
                    (
                      companyProduct: ICompanyProductViewModel,
                      index: number
                    ) => {
                      return !!companyProduct ? (
                        <ListItem sx={{ mb: 1, p: 0 }} key={index * 100}>
                          <ProductCardEmployeeView
                            companyProduct={companyProduct}
                            location={EnumObjectType.Resource}
                            objectType={EnumObjectType.unassignedCompanyProduct}
                            handleRemoveProduct={handleRemoveProduct}
                          />
                        </ListItem>
                      ) : null;
                    }
                  )}
                  {state?.storageProducts?.map(
                    (
                      companyProduct: ICompanyProductViewModel,
                      index: number
                    ) => {
                      return !!companyProduct ? (
                        <ListItem key={index * 1000} sx={{ mb: 1, p: 0 }}>
                          <ProductCardEmployeeView
                            companyProduct={companyProduct}
                            location={EnumObjectType.Resource}
                            objectType={EnumObjectType.StorageProduct}
                            handleRemoveProduct={handleRemoveProduct}
                          />
                        </ListItem>
                      ) : null;
                    }
                  )}
                  {state?.productCatalog?.map(
                    (
                      companyProduct: ICompanyProductViewModel,
                      index: number
                    ) => {
                      return !!companyProduct ? (
                        <ListItem sx={{ mb: 1, p: 0 }} key={index * 10000}>
                          <ProductCardEmployeeView
                            companyProduct={companyProduct}
                            location={EnumObjectType.Resource}
                            objectType={EnumObjectType.Product}
                            handleRemoveProduct={handleRemoveProduct}
                          />
                        </ListItem>
                      ) : null;
                    }
                  )}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* RIGHT SIDE  */}
        <Grid
          item
          xs={6}
          sx={{
            m: 0,
            pl: .5,
          }}
        >
          <Paper
            variant='elevation3'
            sx={{
              height: Math.ceil(height - 186),
              display: 'flex',
              flexDirection: 'column',
              p: 1,
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 0,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/* ASSIGN RESPONSIBLE HEADER */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <FormBox
                  Icon={PersonSearchRoundedIcon}
                  title='Assign Responsible'
                  FormBTN={
                    <ObjectDrawer
                      buttonContext={'Assign Responsible'}
                      buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.AppOwnerEmployees]}
                      handleChange={handleResponsibleChange}
                      buttonHeight={26}
                      buttonWidth={26}
                    />
                  }
                />
              </Grid>
              {/* ASSIGN RESPONSIBLE */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                {!!state.responsible ? (
                  <Stack direction='column'>
                    <SingleEmployeeView employee={state.responsible} />
                  </Stack>
                ) : (
                  <ObjectDrawer
                    buttonContext={'Select Responsible'}
                    buttonIcon={null}
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[EnumObjectType.AppOwnerEmployees]}
                    handleChange={handleResponsibleChange}
                    buttonHeight={80}
                    buttonWidth={'100%'}
                  />
                )}
              </Grid>
              {/* ASSIGN TECHNICIAN HEADER */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <FormBox
                  Icon={PersonSearchRoundedIcon}
                  title='Assign Technicians'
                  /*  FormBTN={
              <ObjectDrawer
                buttonContext={'Assign Responsible'}
                buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                objectList={!!objectDrawerList ? objectDrawerList : []}
                objectType={[
                  EnumObjectType.AppOwnerEmployees,
                ]}
                handleChange={handleChange}
                buttonHeight={26}
                buttonWidth={26}
              />
            } */
                />
              </Grid>
              {/* TECHNICIANS */}
              <Grid item xs={12} sx={{ p: 0, m: 0, mb: 2 }}>
                <Button
                  variant='contained'
                  sx={{
                    //border: 1,
                    borderRadius: 2,
                    //borderColor: 'primary.dark',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    width: '100%',
                    height: 250,
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 14,
                      letterSpacing: 2,
                    }}
                  >
                    UNDER CONSTRUCTION
                  </Typography>
                </Button>
                {/*    {state?.technicians.length > 0 ? (
            <List
              sx={{
                height: Math.ceil(height - 850),
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 2,
              }}
            >
              <Box sx={{ mb: 1 }}>
                <ObjectDrawer
                  buttonContext={'Add Technician'}
                  buttonIcon={null}
                  objectList={!!ccs ? ccs.allEmployees : []}
                  objectType={[EnumObjectType.CompanyEmployee]}
                  handleChange={handleTechniciansChange}
                  buttonHeight={80}
                  buttonWidth={'100%'}
                />
              </Box>
              {state?.technicians.length > 0 &&
                state.technicians?.map(
                  (technician: any, index: number) => (
                    <>
                      <SingleEmployeeView
                        key={index}
                        employee={technician}
                        action={PersonRemoveRoundedIcon}
                        gfx={
                          <TechnicianGfx
                            handleRemoveTechnician={
                              handleRemoveTechnician
                            }
                            technician={technician}
                          />
                        }
                      />
                    </>
                  )
                )}
            </List>
          ) : (
            <ObjectDrawer
              buttonContext={'Select Technicians'}
              buttonIcon={null}
              objectList={!!ccs ? ccs.allEmployees : []}
              objectType={[EnumObjectType.CompanyEmployee]}
              handleChange={handleTechniciansChange}
              buttonHeight={182}
              buttonWidth={'100%'}
            />
          )} */}
              </Grid>
              {/* SCHEDULE */}
              <Grid item xs={12} sx={{ p: 0, m: 0, mb: 2 }}>
                {state.scheduleTime && (
                  <ScheduleView
                    scheduleTime={state.scheduleTime}
                    handleChange={handleChangeSchedule}
                  />
                )}
              </Grid>
              {/* DELIVERY ADDRESS */}
              <Grid item xs={12} sx={{ p: 0, m: 0, mb: 2 }}>
                <DeliveryAddressView
                  addressList={state.addressList || []}
                  handleChange={handleChangeDeliveryAddress}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            p: 2,
            m: 0,
            borderRadius: 2,
            backgroundColor: 'primary.main',
            display: 'flex',
            flexDirection: 'flex-end',
          }}
        >
          <Button
            sx={{ width: 150, marginLeft: 'auto' }}
            variant='contained'
            //type='submit'
            onClick={onSubmit}
            disabled={
              postTicket.isLoading ||
              !state.contactId ||
              !state.companyId ||
              !autoAnswerIsObject(state.autoAnswerSelection) ||
              !state.comment
            }
          >
            Save Ticket
          </Button>
        </Grid>
      </Grid>
   
  );
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid} from '@mui/material';
import { ProductForm } from './ProductForm';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import {
  EnumObjectType,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { useGetObjectList } from 'functions/useGetObjectList';
import { ProductList } from './ProductList';
import { PageHeader } from 'components/ui/Global/PageHeader';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import { useTranslation } from 'react-i18next';

let _ = require('lodash');

export const Products = () => {
const { t } = useTranslation();

  /* INITIALIZATION */
  let navigate = useNavigate();
  const { width, height } = useWindowsDimension();
  // states
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  const [product, setProduct] = useState<IProductViewModel>(
    {} as unknown as IProductViewModel
  );
  const [filterType, setFilterType] = useState([
    { id: 1, name: 'All' },
    { id: 2, name: 'Active' },
  ]);
  const [isProducts, setIsProducts] = useState(true);
  // api
  // api
  let departmentId = 'new';
  let resourceId = 'new';
  let employeeId = 'new';
  let companyId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );

  // handles
  const handleClick = (event: any, selectedObject: IProductViewModel) => {
    // e.preventDefault();
    setProduct(selectedObject);
    navigate(`/products/${selectedObject.id}`);
  };

  const handleNew = (e: any) => {
    // e.preventDefault();
    setProduct({} as unknown as IProductViewModel);
    navigate('/products/new');
  };

  const handleFilterChange = (e: any) => {
    // setFilterType(e.target.value);
    // ref.current = e.target.value;
  };
  /* USE EFFECT */

  return (
    <Grid container rowSpacing={0}>
      {/* HEADER */}
      <Grid item xs={12} sx={{ p: 0, m: 0 }}>
        <PageHeader
          Icon={DevicesOtherRoundedIcon}
          title={t("Products.ProductsTsx.productCatalog")}
          FormJSX={null}
          HeaderButtons={[]}
        />
      </Grid>
        {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: .5, m: 0 }}>
        <ProductList
          categories={
            objectDrawerList[Number(EnumObjectType.ProductCategories)]
              .objectList
          }
          products={objectDrawerList[Number(EnumObjectType.Product)].objectList}
          handleClick={handleClick}
          handleNew={handleNew}
        />
      </Grid>
  {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ pl:.5, m: 0 }}>
        {product != null &&
          !!objectDrawerList[Number(EnumObjectType.ProductCategories)]
            .objectList && (
            <ProductForm
              categories={
                objectDrawerList[Number(EnumObjectType.ProductCategories)]
                  .objectList
              }
              product={product}
              brands={
                objectDrawerList[Number(EnumObjectType.Brands)].objectList
              }
              setIsProducts={setIsProducts}
            />
          )}
      </Grid>
    </Grid>
  );
};

import * as React from 'react';
import Typography from '@mui/material/Typography';
import GetAvatarInitials from 'functions/GetAvatarInitials';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import {
  Grid,
  Paper,
  Avatar,
  Stack,
  AvatarGroup,
  Box,
  IconButton,
} from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import GetPriorityColor from 'functions/GetPriorityColor';
import { Navigate, useNavigate } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};

export const SingleTicketHistory = (props) => {
  let navigate = useNavigate();
  const id = `${props.ticket.id}`;
  const index = props.index;
  const ticket = props.ticket;

  return (
    <Paper
      variant='elevation3'
      sx={{
        p: 1,
        mb: 1,
        borderLeft: 8,
        borderColor: GetPriorityColor(ticket.priority),
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Stack direction={'row'}>
        <Grid container direction={'row'}>
          <Grid item sx={{ mr: 1, width: 82 }}>
            <Stack direction={'column'}>
              <Typography
                sx={{
                  m: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.id}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.timeStamp}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                Age {ticket.hoursTotal}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ mr: 1, width: 100 }}>
            <Paper
              sx={{
                mx: 'auto',
                display: 'flex',
                alignSelf: 'flex-center',
                backgroundColor: 'white',
                mt: 0.2,
                p: 1,
              }}
            >
              {!!ticket.company.logo ? (
                <Avatar
                  variant='rounded'
                  alt={ticket.company.name}
                  src={variables.PHOTO_URL + ticket.company.logo + '.png'}
                  sx={{
                    mx: 'auto',
                    borderRadius: 2,
                    width: 88,
                    height: 44,
                  }}
                />
              ) : (
                <Avatar
                  {...GetAvatarInitials(ticket.company.name, 88, 44, 3)}
                />
              )}
            </Paper>
          </Grid>

          <Grid item sx={{ mr: 1, width: 150 }}>
            <Stack direction={'column'}>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.step1}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.step2}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.step3}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs zeroMinWidth sx={{ mr: 1, flexGrow: 1 }}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                textAlign: 'left',
                whiteSpace: 'wrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {ticket.comment} sd 7 gh 47 t y jth dfgh dfgh retyh tyjyru krfyh
              mjfjmrytuk8sadf sdfg sdfg sdfg sdfg grt rtherth erge tyr
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{}}>
            <AvatarGroup max={2}>
              {ticket.contacts.map((employee) =>
                !!employee.logo ? (
                  <Avatar
                    key={employee.id}
                    alt={employee.name}
                    src={variables.PHOTO_URL + employee.logo + '.png'}
                    sx={{ borderRadius: 10, width: 48, height: 48 }}
                  />
                ) : (
                  <Avatar
                    key={employee.id}
                    {...GetAvatarInitials(employee.name, 48, 48, 10)}
                  />
                )
              )}
            </AvatarGroup>
          </Grid>
        </Grid>
        <Grid sx={{}}>
          <IconButton
            sx={{ mr: -1, mt: -1 }}
            onClick={() => {
              navigate(`/tickets/${ticket.id}`);
            }}
          >
            <LaunchRoundedIcon sx={{ fontSize: 26 }} />
          </IconButton>
        </Grid>
      </Stack>
    </Paper>
  );
};

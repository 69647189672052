import { Grid, Paper, Typography } from '@mui/material';
import {
  IItemGroup,
  IMultiChartBar,
  ISingleChartBar,
} from 'Interfaces/IChartConfig';
import React from 'react';
import { SingleMultiBar } from './Components/SingleMultiBar';
import useWindowsDimension from 'functions/useWindowsDimension';
import { ChartHeader } from './Components/ChartHeader';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { CompareData, SingleChartValue } from './Components/SingleChartValue';
import GetSingleMultiBarColor from 'functions/GetSingleMultibarColor';
import { HeaderValue } from './Components/HeaderValue';
import { formatNumber } from 'functions/formatNumber';
interface MultiBarProps {
  data: IMultiChartBar;
  width: number;
  height: number;
}

export const MultiBarView: React.FC<MultiBarProps> = ({
  data,
  width,
  height,
}) => {
  // Først, flate ut alle itemGroups i en enkelt array
  const flattenedItemGroups: IItemGroup[] = data.itemGroups.reduce(
    (acc: IItemGroup[], chartBar: ISingleChartBar) => [
      ...acc,
      ...chartBar.itemGroups,
    ],
    []
  );

  // Definere en type for aggregerte summer
  interface AggregatedSummaries {
    [key: string]: { totalCurrentValue: number; totalLastPeriodValue: number };
  }

  // Aggregere summer basert på navn
  const aggregatedSummaries: AggregatedSummaries = flattenedItemGroups.reduce(
    (acc: AggregatedSummaries, itemGroup: IItemGroup) => {
      const { name, currentValue, lastPeriodValue } = itemGroup;
      if (!acc[name]) {
        acc[name] = { totalCurrentValue: 0, totalLastPeriodValue: 0 };
      }
      acc[name].totalCurrentValue += currentValue;
      acc[name].totalLastPeriodValue += lastPeriodValue;
      return acc;
    },
    {}
  );
  console.log('aggregatedSummaries', aggregatedSummaries);

  // Konvertere til en array av sammendrag
  const summaries = Object.entries(aggregatedSummaries).map(
    ([name, { totalCurrentValue, totalLastPeriodValue }]) => ({
      name,
      totalCurrentValue,
      totalLastPeriodValue,
    })
  );

  const totalCurrentValue = summaries.reduce(
    (acc: number, item: any) => acc + item.totalCurrentValue,
    0
  );
  const totalLastPeriodValue = summaries.reduce(
    (acc: number, item: any) => acc + item.totalLastPeriodValue,
    0
  );

  let totalDiff = totalCurrentValue - totalLastPeriodValue;
  return (
    <Grid
      xs={12}
      sx={{
        m: 0,
        p: 0.2,
        backgroundColor:
          totalDiff === 0
            ? 'error.lighter'
            : totalDiff > 0
            ? 'success.lighter'
            : 'warning.lighter',
        borderRadius: 2,
      }}
    >
      <Paper
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: 2,
        }}
      >
        <Grid container direction={'row'} alignItems={'center'} sx={{}}>
          <Grid item xs={12} sx={{}}>
            <ChartHeader
              Icon={LeaderboardIcon}
              title={data.name}
              GraphJSX={
              <Grid container direction={'row'} sx={{width: 150,height:52,}}>
             <Grid item xs={8} sx={{ hight: 38 }}>
             <Typography
                sx={{
                  pt:.5,
                  width: '100%',
                  fontSize: 14,
                  backgroundColor: 'transparent',
                }}
              >
               {formatNumber(totalLastPeriodValue,2)}
              </Typography>
              <Typography
                sx={{
                  width: '100%',
                  fontSize: 18,
                  fontWeight: 'bold',
                  backgroundColor: 'transparent',
                }}
              >
               {formatNumber(totalCurrentValue,2)}
              </Typography>
              
              </Grid>
              <Grid item xs={4} sx={{ hight: 38 }}>
             < HeaderValue
                  subHeader={'Total'}
                  totalValue={totalCurrentValue}
                  lastPeriodValue={totalLastPeriodValue}
                />
              </Grid>
            </Grid>               
              }
              FormJSX={null}
              HeaderButtons={[]}
            />
          </Grid>
          <Grid
            item
            xs={10}
            display={'flex'}
            direction={'row'}
            sx={{ height: height - 128, mt: -0.5 }}
          >
            {!!data.itemGroups &&
              data.itemGroups.map((item: ISingleChartBar, index: number) => {
                return (
                  <SingleMultiBar
                    key={index}
                    data={item}
                    width={width / data.itemGroups.length}
                    height={height - 128}
                  />
                );
              })}
          </Grid>
          <Grid item xs={2} sx={{ mt: 0.5 }}>
            {summaries.map((item: any, index: number) => {
              return (
                <Grid
                  container
                  display={'flex'}
                  sx={{ flexGrow: 1, height: 68, pr: 1, pb: 0.5 }}
                >
                  <SingleChartValue
                    key={index}
                    index={index}
                    subHeader={item.name}
                    totalValue={item.totalCurrentValue}
                    lastPeriodValue={item.totalLastPeriodValue}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  text: string;
  from: number;
  to: number;
  isFading: boolean;
};

export const FadingTypography: React.FC<Props> = ({
  text,
  from,
  to,
  isFading,
}) => {
  const [opacity, setOpacity] = useState(from);

  useEffect(() => {
    if (isFading) {
      const fadeIn = () => {
        setOpacity((prevOpacity) => {
          if (prevOpacity < to) {
            return prevOpacity + 0.03; // Adjust this value to control the speed of the fade
          }
          return prevOpacity;
        });
      };

      const intervalId = setInterval(fadeIn, 25); // Adjust this value to control the speed of the fade

      return () => clearInterval(intervalId); // Cleanup
    }
  }, [isFading, to]);

  return (
    <Typography
      sx={{
        m: 1.5,
        ml: -0.5,
        pt: 0.2,
        pr: 2,
        textTransform: 'uppercase',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: 2,
        color: isFading ? 'info.lighter' : 'primary.main',
        opacity: opacity, // Apply the opacity here
        transition: 'opacity 0.5s ease-in-out', // Optional: Smooth transition for opacity change
      }}
    >
      {text}
    </Typography>
  );
};

import { memo, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { usePost, usePut } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';
import { openInNewTab } from 'functions/misc';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import * as yup from 'yup';
import {
  FormAccordionSummary,
  FormBox,
  FormBoxElevated,
} from 'components/form';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';

import {
  Button,
  Accordion,
  AccordionDetails,
  Grid,
  List,
  IconButton,
  Paper,
  TextField,
  Switch,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  EnumObjectType,
  IAddressViewModel,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import {
  ICompanyViewModel,
  IDepartmentAddViewModel,
  IDepartmentInitialState,
  IDepartmentUpdateViewModel,
  IDepartmentViewModel,
} from 'Interfaces/ICompaniesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { ObjectDrawer } from './ObjectDrawer';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { formatPhoneNumber } from 'functions/FormatPhoneNumber';
import { useTranslation } from 'react-i18next';

const convertToAddDepartment = (
  department: IDepartmentInitialState,
  companyId: string
) => {
  if (!companyId) {
    console.error('companyId is required');
  }
  let address = null;
  if (
    department.mainAddress.id !== 'new' &&
    department.mainAddress.street.length >= 2 &&
    department.mainAddress.area.length >= 2
  ) {
    address = {
      street: department.mainAddress.street,
      postalCode: department.mainAddress.postalCode,
      city: department.mainAddress.city,
      area: department.mainAddress.area || '',
      country: department.mainAddress.country || '',
      postBox: department.mainAddress.postBox || '',
    };
  }
  let addDepartment: IDepartmentAddViewModel = {
    companyId: companyId,
    name: department.name,
    phone: department.phone || '',
    email: department.email || '+47',
    mainAddress: address,
    contactId: department.contact?.id || '',
    isActive: department.isActive,
    isDefault: department.isDefault,
  };
  return addDepartment;
};

const convertToUpdateDepartment = (
  department: IDepartmentInitialState,
  companyId: string | null
) => {
  if (!companyId) {
    console.error('companyId is required');
  }
  let updateDepartment: IDepartmentUpdateViewModel = {
    id: department.id,
    companyId: companyId || '',
    name: department.name,
    phone: department.phone || '',
    email: department.email || '',
    mainAddress: department.mainAddress,
    contactId: department.contact?.id || '',
    isActive: department.isActive,
    isDefault: department.isDefault,
  };
  return updateDepartment;
};
let _ = require('lodash');

const schema = yup
  .object({
    companyId: yup.string().required(),
    name: yup
      .string()
      .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Name is required'),
    email: yup.string().email(),
    mainAddress: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('Street is required'),
      postalCode: yup
        .string()
        .matches(/^(|[0-9]{4,4})$/, '4 numbers required')
        .required('Postal code is required'),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('City is required'),
    }),
    /* deliveryAddress: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, "Between 2-50 characters")
        .required("Street is required"),
      postalCode: yup
        .string()
        .matches(/^(|[0-9]{4,4})$/, "4 numbers required")
        .required("Postal code is required"),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, "Between 2-50 characters")
        .required("City is required"),
    }), */
    active: yup.boolean(),
  })
  .required();

const initialState: IDepartmentInitialState = {
  id: 'new',
  name: '',
  companyId: 'new',
  email: '',
  phone: '',
  contact: {} as unknown as IEmployeeViewModel,
  contactId: 'new',
  mainAddress: {} as IAddressViewModel,
  deliveryAddress: {} as IAddressViewModel,
  canDelete: false,
  isDirty: false,
  isActive: true,
  isDefault: false,
};

type Action =
  | { type: 'UPDATE_FIELD'; field: keyof IDepartmentInitialState; value: any }
  | {
      type: 'UPDATE_MAIN_ADDRESS_FIELD';
      field: keyof IAddressViewModel;
      value: any;
    }
  | {
      type: 'UPDATE_DELIVERY_ADDRESS_FIELD';
      field: keyof IAddressViewModel;
      value: any;
    }
  | { type: 'SET_CONTACT'; payload: any }
  | { type: 'RESET' }
  | { type: 'INITIALIZE'; payload: any }
  | { type: 'SET_DIRTY'; payload: any }
  | { type: 'SET_DEPARTMENT'; payload: IDepartmentViewModel }
  | { type: 'SET_DEFAULT'; payload: boolean };
const reducer = (
  state: IDepartmentInitialState,
  action: Action
): IDepartmentInitialState => {
  switch (action.type) {
    case 'SET_DEFAULT':
      return {
        ...state,
        isDefault: action.payload,
        isDirty: true,
      };
    case 'UPDATE_FIELD':
      if (action.field === 'phone') {
        return {
          ...state,
          [action.field]: formatPhoneNumber(action.value),
          isDirty: true,
        };
      }
      return {
        ...state,
        [action.field]: action.value,
        isDirty: true,
      };
    case 'UPDATE_MAIN_ADDRESS_FIELD':
      if (state.mainAddress === null) {
        // If address is null, create a new address object and update the field
        return {
          ...state,
          mainAddress: {
            ...({} as IAddressViewModel),
            [action.field]: action.value,
          },
          isDirty: true,
        };
      } else {
        // If address exists, update the field within the existing address object
        return {
          ...state,
          mainAddress: {
            ...state.mainAddress,
            [action.field]: action.value,
          },
          isDirty: true,
        };
      }
    case 'UPDATE_DELIVERY_ADDRESS_FIELD':
      if (state.deliveryAddress === null) {
        // If address is null, create a new address object and update the field
        return {
          ...state,
          deliveryAddress: {
            ...({} as IAddressViewModel),
            [action.field]: action.value,
          },
          isDirty: true,
        };
      } else {
        // If address exists, update the field within the existing address object
        return {
          ...state,
          deliveryAddress: {
            ...state.deliveryAddress,
            [action.field]: action.value,
          },
        };
      }
    case 'SET_CONTACT':
      return {
        ...state,
        contact: action.payload,
        contactId: action.payload.id,
        isDirty: true,
      };
    case 'RESET':
      return initialState;
    case 'INITIALIZE':
      return action.payload;

    case 'SET_DIRTY':
      return {
        ...state,
        isDirty: action.payload,
      };
    case 'SET_DEPARTMENT':
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        companyId: action.payload.companyId,
        email: action.payload.email,
        phone: action.payload.phone,
        contact: action.payload.contact || null,
        contactId: action.payload.contact?.id || 'new',
        mainAddress: action.payload.mainAddress,
        deliveryAddress: action.payload.deliveryAddress,
        canDelete: action.payload.canDelete,
        isDirty: false,
        isActive: true,
      };
    default:
      return state;
  }
};

type Props = {
  department: IDepartmentViewModel;
  company: ICompanyViewModel;
  objectDrawerList: objectArray[];
  setIsObjectList: (value: boolean) => void;
};
interface OHjsxProps {
  isDefault: boolean;
  dispatch: React.Dispatch<Action>;
}
export const DepartmentForm = ({
  department,
  company,
  objectDrawerList,
  setIsObjectList,
}: Props) => {
  const { t } = useTranslation();
  /* INITIALIZATIONS */
  const { height } = useWindowsDimension();
  const { companyId, departmentId } = useParams();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  // states

  const [state, dispatch] = useReducer(reducer, { ...initialState });

  let isEnabled = departmentId === 'new';

  // api
  const postDepartment = usePost(
    rqPost.department(companyId || '', isEnabled, departmentId || '')
  );
  const putDepartment = usePut(
    rqPost.department(companyId || '', isEnabled, departmentId || '')
  );
  const departmentDeactivate = usePost(
    rqPost.departmentDeactivate(companyId || '', departmentId || '', isEnabled)
  );

  /* FUNCTIONS */

  // handles

  const handleConfirm = (event: any) => {
    //console.log("delete");
    departmentDeactivate.mutate(
      { id: departmentId },
      { onSuccess: navigate(`/companies/${companyId}/departments`) }
    );
  };

  const handleChangeContact = (e: any, contact: IEmployeeViewModel) => {
    e.preventDefault();
    dispatch({
      type: 'SET_CONTACT',
      payload: contact,
    });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });

    // setIsObjectList(true);
  };

  // form
  const onSubmit = (event: any) => {
    event.preventDefault();
    //console.log("onSubmit", data);
    let addDepartment = _.cloneDeep(state);

    if (state.isDirty) {
      if (departmentId === 'new' && companyId) {
        let departmentToAdd = convertToAddDepartment(addDepartment, companyId);
        console.log('departmentToAdd', departmentToAdd);
        postDepartment.mutate(departmentToAdd, {
          onSuccess: (state: any) => {
            queryClient.invalidateQueries('departments');
            navigate(`/companies/${companyId}/departments/${state.id}`);
            setIsObjectList(true);
          },
        });
      } else {
        if (companyId && departmentId !== 'new') {
          let departmentToUpdate = convertToUpdateDepartment(
            addDepartment,
            companyId
          );
          console.log('departmentToUpdate', departmentToUpdate);
          putDepartment.mutate(departmentToUpdate, {
            onSuccess: (data: IDepartmentViewModel) => {
              //console.log("onSuccess", data);
              setIsObjectList(true);
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (department) {
      dispatch({
        type: 'SET_DEPARTMENT',
        payload: department,
      });
    }
  }, [department]);

  const OHjsx: React.FC<OHjsxProps> = ({ isDefault, dispatch }) => {
    const { t } = useTranslation();
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'SET_DEFAULT', payload: e.target.checked });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {isDefault
              ? t('switch.yes').toUpperCase()
              : t('switch.no').toUpperCase()}
          </Typography>

          <Switch
            color={isDefault ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: isDefault ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
              },
              '& .MuiSwitch-track': {
                backgroundColor: isDefault
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)', // This gives a lighter red shade for the track. Adjust as needed.
              },
            }}
            checked={isDefault}
            onChange={handleToggle}
            name='onHold'
          />
        </Stack>
      </Grid>
    );
  };

  const GoogleJSX = memo(() => {
    return (
      <IconButton
        onClick={(e) =>
          openInNewTab(
            e,
            'https://www.google.com/maps/place/' +
              department.mainAddress?.street +
              ',' +
              department.mainAddress?.postalCode +
              department.mainAddress?.city +
              '/'
          )
        }
      >
        <TravelExploreRoundedIcon sx={{ mt: -1, fontSize: 32 }} />
      </IconButton>
    );
  });

  return (
    <Grid
      container
      sx={{
        height: Math.ceil(height - 154),
      }}
    >
      <Grid item xs={12}>
        <FormBox
          Icon={FeedRoundedIcon}
          title={t('department.detailsHeader')}
          FormJSX={GoogleJSX}
        />
      </Grid>
      <List
        sx={{
          width: '100%',
          height: Math.ceil(height - 330),
          p: 2,
        }}
      >
        <Grid
          container
          sx={{
            pt: 2,
          }}
        >
          <Grid item xs={5} sx={{ pr: 2, mb: 2 }}>
            <TextField
              name='name'
              label={t('department.name')}
              sx={{
                '& .Mui-focused': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
              }}
              value={state.name}
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_FIELD',
                  field: 'name',
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              name='email'
              label={t('department.email')}
              sx={{
                '& .Mui-focused': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
              }}
              value={state.email}
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_FIELD',
                  field: 'email',
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={3} sx={{}}>
            <MuiTelInput
              sx={{
                '& .Mui-focused': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
              }}
              continents={['EU']}
              preferredCountries={['NO', 'SE', 'DK', 'DE', 'GB']}
              defaultCountry='NO'
              forceCallingCode={true}
              label={t('department.phone')}
              variant='standard'
              value={state.phone}
              onChange={(value: string, info: MuiTelInputInfo) => {
                dispatch({
                  type: 'UPDATE_FIELD',
                  field: 'phone',
                  value: value,
                });
              }}
            />
          </Grid>
        </Grid>
        {/* Department Address */}
        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
          }}
        >
          <Paper variant='elevation3' sx={{}}>
            <Accordion
              disableGutters
              sx={{
                boxShadow: 0,
                backgroundColor: 'transparent',
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.mainAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.mainAddress?.street || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_MAIN_ADDRESS_FIELD',
                      field: 'street',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='postalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label={t('address.zip')}
                  value={state.mainAddress?.postalCode || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_MAIN_ADDRESS_FIELD',
                      field: 'postalCode',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.mainAddress?.area || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_MAIN_ADDRESS_FIELD',
                      field: 'area',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='city'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.mainAddress?.city || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_MAIN_ADDRESS_FIELD',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                />

                <Select
                  name='country'
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  variant='standard'
                  value={state.mainAddress?.country || 0}
                  label={t('address.country')}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_MAIN_ADDRESS_FIELD',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.mainAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_MAIN_ADDRESS_FIELD',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
          <FormBox
            Icon={PersonSearchRoundedIcon}
            title={t('department.contactPerson')}
            FormBTN={
              <ObjectDrawer
                buttonContext={t('department.addContactPerson')}
                buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26, m: 0 }} />}
                objectList={!!objectDrawerList ? objectDrawerList : []}
                objectType={[EnumObjectType.CompanyEmployees]}
                handleChange={handleChangeContact}
                buttonHeight={30}
                buttonWidth={30}
              />
            }
          />
        </Grid>
        {/* CONTACT PERSON */}
        <Grid item xs={12} sx={{ p: 0, mb: 2 }}>
          {!!state.contact ? (
            <Stack direction='column'>
              <SingleEmployeeView employee={state.contact} height={80} />
            </Stack>
          ) : (
            <ObjectDrawer
              buttonContext={t('department.addContactPerson')}
              buttonIcon={null}
              objectList={!!objectDrawerList ? objectDrawerList : []}
              objectType={[EnumObjectType.CompanyEmployees]}
              handleChange={handleChangeContact}
              buttonHeight={82}
              buttonWidth={'100%'}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
          }}
        >
          <Paper variant='elevation3' sx={{}}>
            <Accordion
              disableGutters
              sx={{
                boxShadow: 0,
                backgroundColor: 'transparent',
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.deliveryAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.deliveryAddress?.street || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DELIVERY_ADDRESS_FIELD',
                      field: 'street',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='postalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label={t('address.zip')}
                  value={state.deliveryAddress?.postalCode || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DELIVERY_ADDRESS_FIELD',
                      field: 'postalCode',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.deliveryAddress?.area || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DELIVERY_ADDRESS_FIELD',
                      field: 'area',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='city'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.deliveryAddress?.city || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DELIVERY_ADDRESS_FIELD',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                />

                <Select
                  name='country'
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  variant='standard'
                  value={state.deliveryAddress?.country || 0}
                  label={t('address.country')}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DELIVERY_ADDRESS_FIELD',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.mainAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DELIVERY_ADDRESS_FIELD',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        {/*     Active/Inactive */}
        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
          }}
        >
          <FormBoxElevated
            Icon={StarRoundedIcon}
            title={t('department.default')}
            FormJSX={() => (
              <OHjsx isDefault={state.isDefault} dispatch={dispatch} />
            )}
          />
        </Grid>
        {/*     Active/Inactive */}
        {/* <Grid
              item
              xs={12}
              sx={{
                mt: 2,
              }}
            >
              <FormBoxElevated
                Icon={DomainDisabledRoundedIcon}
                title="Active"
                FormJSX={ActiveJSX}
              />
            </Grid> */}
      </List>
      <Grid item xs={12} sx={{ m: 2, display: 'flex', flexDirection: 'row' }}>
        <Button
          sx={{ m: 2 }}
          variant='contained'
          disabled={departmentId === 'new'}
          onClick={() => setDeleteConfirm(!deleteConfirm ? true : false)}
        >
          {/*  {postEmployeeDeactivate.isLoading
                ? "Deleting Company..."
                : "Delete Company"} */}
          {t('button.delete')}
        </Button>
        {!!deleteConfirm === true && (
          <Button
            sx={{ m: 2 }}
            variant='contained'
            color='secondary'
            onClick={handleConfirm}
          >
            {t('button.confirmDelete')}
          </Button>
        )}
        <Grid sx={{ flexGrow: 1 }}></Grid>{' '}
        <Button
          sx={{ m: 2 }}
          type='submit'
          variant='contained'
          disabled={
            !state.isDirty || state.contactId === 'new' || state.name.length < 2
          }
          onClick={(e) => onSubmit(e)}
        >
          {departmentId === 'new' ? t('button.create') : t('button.update')}
        </Button>
      </Grid>
    </Grid>
  );
};

import { memo, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
// @ts-ignore
import { useDel, usePost, usePut } from 'http/useInnovit';
import { rqDel, rqPost } from 'http/apiRoutes';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
// @ts-ignore
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import * as yup from 'yup';
import {
  FormAccordionSummary,
  FormBox,
  FormBoxElevated,
} from 'components/form';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import ArtTrackRoundedIcon from '@mui/icons-material/ArtTrackRounded';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import ImageUploader from 'components/ui/ImageUploader';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import GetPriorityColor from 'functions/GetPriorityColor';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import {
  EnumObjectType,
  EnumPlacement,
  IAddressViewModel,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import { formatPhoneNumber } from 'functions/FormatPhoneNumber';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import {
  ICompanyAddViewModel,
  ICompanyInterface,
  ICompanyUpdateViewModel,
  ICompanyViewModel,
  IFileModel,
} from 'Interfaces/ICompaniesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import {
  ConvertProductToCompanyProduct,
  ConvertStorageProductToCompanyProduct,
} from 'functions/Convert';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import { ProductCardEmployeeView } from 'components/ui/ProductCardEmployeeView';
import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import { useTranslation } from 'react-i18next';

const phoneRegex = /^\+[0-9]{2}(?=(?:\s*\d){8})([\d\s]*)$/;
const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
    .required('Name is required'),
  organizationNumber: yup
    .string()
    .required('Organization number is required')
    .min(9, 'Organization number must be at least 9 characters')
    .max(9, 'Organization number must be at most 9 characters')
    .matches(/^[0-9]{9}$/, '9 numbers required'),
  email: yup.string().email().required('Email is required'),
  // phone: yup.string().matches(/^(|[0-9]{8,16})$/, "Between 8-16 characters"),
  //webPage: yup.string().notRequired(),
  // .test(
  //   'test-webPage', // Name of the test
  //   'Enter a valid webPage URL starting with www.', // Error message
  //   (value) => !value || /^www\.[\w-]+\.[a-zA-Z]{2,}$/.test(value) // Test function
  // ),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegex, 'Enter a valid phone number'),
  mainAddress: yup.object().shape({
    street: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Street is required'),
    area: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Area is required'),
    postalCode: yup
      .string()
      .matches(/^(|[0-9]{4,4})$/, '4 numbers required')
      .required('Postal code is required'),
    city: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('City is required'),
    country: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Country is required'),
  }),
  deliveryAddress: yup
    .object()
    .notRequired()
    .shape({
      street: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
      area: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
      postalCode: yup.string().matches(/^(|[0-9]{4,4})$/, '4 numbers required'),
      city: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
      country: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
    }),
  invoiceAddress: yup
    .object()
    .notRequired()
    .shape({
      street: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),

      area: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),

      postalCode: yup.string().matches(/^(|[0-9]{4,4})$/, '4 numbers required'),

      city: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),

      country: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
    }),
});

const ConvertToUpdateCompany = (
  state: ICompanyInterface,
  fileModel: IFileModel | null
) => {
  let companyToUpdate: ICompanyUpdateViewModel = {
    id: state.id ?? 'new',
    organizationNumber: state.organizationNumber,
    name: state.name,
    mainAddress: state.mainAddress,
    deliveryAddress: state.deliveryAddress ?? null,
    invoiceAddress: state.invoiceAddress ?? null,
    phone: state.phone,
    email: state.email,
    supportEmail: state.supportEmail,
    companyType: state.companyType,
    webPage: state.webPage,
    fileModel: fileModel,
    productBilling: state.productBilling,
    onHold: state.onHold,
    isSupport: state.isSupport,
    onHoldNote: state.onHoldNote,
    priority: state.priority,
    fixedHourlyRate: state.fixedHourlyRate,
    workRoleId: state.workRoleId,
    contactId: state.contactId,
    companyProducts: state.companyProducts,
    productCatalog: state.productCatalog,
    storageProducts: state.storageProducts,
    unassignedCompanyProducts: state.unassignedCompanyProducts,
  };
  return companyToUpdate;
};
const initialState: ICompanyInterface = {
  id: 'new',
  organizationNumber: '',
  name: '',
  mainAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: '',
  } as IAddressViewModel,
  deliveryAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: '',
  } as IAddressViewModel,
  invoiceAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: '',
  } as IAddressViewModel,
  phone: '+47',
  email: '',
  supportEmail: '',
  companyType: 0,
  webPage: '',
  url: '',
  fileModel: null,
  productBilling: true,
  onHold: false,
  isSupport: false,
  onHoldNote: '',
  priority: 2,
  fixedHourlyRate: 0,
  workRole: null,
  workRoleId: null,
  contactId: null,
  contact: null,
  companyProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[],
  storageProducts: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isValid: false,
  isDirty: false,
  isOnboardingEnabled: false,
  isProductChanged: false,
};
type Action =
  | { type: 'SET_DIRTY'; payload: boolean }
  | { type: 'SET_IS_PRODUCT_CHANGED'; payload: boolean }
  | { type: 'VALIDATE' }
  | { type: 'UPDATE_FIELD'; field: keyof ICompanyInterface; value: any }
  | {
      type: 'UPDATE_ADDRESS_FIELD';
      addressType: 'mainAddress' | 'deliveryAddress' | 'invoiceAddress';
      field: keyof IAddressViewModel;
      value: any;
    }
  | { type: 'UPDATE_COMPANY_TYPE'; value: number }
  | { type: 'CHANGE_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'CHANGE_PRIORITY'; payload: number }
  | { type: 'TOGGLE_ON_HOLD'; payload: boolean }
  | { type: 'SET_PRODUCT_CATALOG'; payload: ICompanyProductViewModel[] }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_UNASSIGN_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'SET_CONTACT'; payload: IEmployeeViewModel }
  | { type: 'SET_URL'; payload: string }
  | { type: 'SET_SHARED_PRODUCTS'; payload: ICompanyProductViewModel[] }
  | {
      type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'TOGGLE_ONBOARDING_ENABLED'; payload: boolean }
  | { type: 'SET_COMPANY'; payload: ICompanyViewModel };
const reducer = (
  state: ICompanyInterface,
  action: Action
): ICompanyInterface => {
  switch (action.type) {
    case 'SET_DIRTY':
      return {
        ...state,
        isDirty: action.payload,
      };
    case 'SET_IS_PRODUCT_CHANGED':
      return {
        ...state,
        isProductChanged: action.payload,
      };
    case 'VALIDATE':
      try {
        schema.validateSync(state, { abortEarly: false });
        return { ...state, isValid: true };
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          // Log the entire error object
          console.log('Validation Error: ', error);

          // Log specific details
          console.log('Errors: ', error.errors); // Array of error messages
          console.log('Inner Errors: ', error.inner); // Detailed error objects for each field

          // If you want to log more specific details for each field
          error.inner.forEach((err) => {
            console.log(err.path, err.message);
          });
        } else {
          // If the error is not a yup.ValidationError, log it
          console.log('Unexpected Error: ', error);
        }

        return { ...state, isValid: false };
      }
    case 'UPDATE_FIELD':
      if (action.field === 'phone') {
        return {
          ...state,
          [action.field]: formatPhoneNumber(action.value),
        };
      }
      return { ...state, [action.field]: action.value };

    case 'UPDATE_ADDRESS_FIELD':
      console.log('update_address field', action);
      return {
        ...state,
        [action.addressType]: {
          ...state[action.addressType],
          [action.field]: action.value.toString(),
        },
      };
    case 'UPDATE_COMPANY_TYPE':
      return {
        ...state,
        companyType: action.value,
      };
    case 'CHANGE_WORK_ROLE':
      //console.log('change_work_role', action);
      return {
        ...state,
        workRole: action.payload,
        workRoleId: action.payload.id,
      };
    case 'CHANGE_PRIORITY':
      return {
        ...state,
        priority: action.payload,
      };
    case 'TOGGLE_ON_HOLD':
      //  console.log('TOGGLE_ON_HOLD', action.payload);
      return {
        ...state,
        onHold: action.payload,
      };
    case 'SET_PRODUCT_CATALOG':
      return {
        ...state,
        productCatalog: action.payload,
      };
    case 'ADD_PRODUCT':
      // console.log('ADD_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: [...state.productCatalog, action.payload],
      };
    case 'REMOVE_PRODUCT':
      // console.log('REMOVE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_PRODUCT':
      //console.log('UPDATE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_STORAGE_PRODUCT':
      //console.log('ADD_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: [...state.storageProducts, action.payload],
      };
    case 'REMOVE_STORAGE_PRODUCT':
      //console.log('REMOVE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_STORAGE_PRODUCT':
      //console.log('UPDATE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_COMPANY_PRODUCT':
      //console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: [...state.companyProducts, action.payload],
      };
    case 'REMOVE_COMPANY_PRODUCT':
      //console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_COMPANY_PRODUCT':
      //console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_UNASSIGN_COMPANY_PRODUCT':
      //console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action.payload,
        ],
      };
    case 'REMOVE_UNASSIGN_COMPANY_PRODUCT':
      //console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_UNASSIGN_COMPANY_PRODUCT':
      //console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.map(
          (product) =>
            product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'SET_URL':
      return {
        ...state,
        url: action.payload,
      };
    case 'SET_CONTACT':
      return {
        ...state,
        contact: action.payload,
        contactId: action.payload.id,
        isDirty: true,
      };
    case 'TOGGLE_ONBOARDING_ENABLED':
      return {
        ...state,
        isOnboardingEnabled: action.payload,
      };
    case 'SET_SHARED_PRODUCTS':
      return {
        ...state,
        companyProducts: action.payload as ICompanyProductViewModel[],
      };
    case 'SET_COMPANY':
      return {
        ...state,
        id: action.payload.id,
        organizationNumber: action.payload.organizationNumber,
        name: action.payload.name,
        mainAddress: action.payload.mainAddress,
        deliveryAddress: action.payload.deliveryAddress,
        invoiceAddress: action.payload.invoiceAddress,
        phone: action.payload.phone,
        email: action.payload.email,
        companyType: action.payload.companyType,
        webPage: action.payload.webPage,
        url: action.payload.url,
        productBilling: action.payload.productBilling,
        isSupport: action.payload.isSupport,
        onHold: action.payload.onHold,
        onHoldNote: action.payload.onHoldNote,
        workRole: action.payload.workRole || null,
        workRoleId: action.payload.workRole?.id || null,
        fixedHourlyRate: action.payload.fixedHourlyRate,
        priority: action.payload.priority,
      };
    default:
      return state;
  }
};
interface OHjsxProps {
  onHold: boolean;
  dispatch: React.Dispatch<Action>;
}
interface FormErrors {
  name?: string;
  organizationNumber?: number;
  email?: string;
  webPage?: string;
  fixedHourlyRate?: number;
}

type Props = {
  company: ICompanyViewModel;
  employees: IEmployeeViewModel[];
  objectDrawerList: objectArray[];
  setIsObjectList: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CompanyForm = ({
  company,
  employees,
  objectDrawerList,
  setIsObjectList,
}: Props) => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const { companyId } = useParams<string>();

  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  // STATES
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...company,
  });
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [base64String, setBase64String] = useState('') as any;
  const [image, setImage] = useState(null) as any;
  const [user] = useAtom(userAtom);
  // test const [isEnabled, setIsEnabled] = useState<boolean>(false)

  // API CALLS
  let isEnabled = companyId !== 'new' ? true : false;
  const postCompany = usePost(
    rqPost.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );
  const putCompany = usePut(
    rqPost.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );
  const delCompany = useDel(
    rqDel.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );

  console.log('state.url', state.url, typeof state.url);

  const [errors, setErrors] = useState<FormErrors>({});

  const validateField = async (
    field: keyof ICompanyViewModel,
    value: string
  ) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors((prev) => ({ ...prev, [field]: '' }));
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prev) => ({ ...prev, [field]: error.message }));
      }
    }
  };

  const priorityJSX = memo(() => {
    return (
      <>
        <ButtonGroup
          variant='contained'
          aria-label='outlined primary button group'
          size='small'
          sx={{ m: 2 }}
        >
          <Button
            key='LOW'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 1
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 1);
            }}
          >
            {t('priority.low')}
          </Button>
          <Button
            key='NORMAL'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 2
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 2);
            }}
          >
            {t('priority.normal')}
          </Button>
          <Button
            key='HIGH'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 3
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 3);
            }}
          >
            {t('priority.high')}
          </Button>
        </ButtonGroup>
      </>
    );
  });
  const OHjsx: React.FC<OHjsxProps> = ({ onHold, dispatch }) => {
    const { t } = useTranslation();
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'TOGGLE_ON_HOLD', payload: e.target.checked });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onHold
              ? t('switch.yes').toUpperCase()
              : t('switch.no').toUpperCase()}
          </Typography>

          <Switch
            color={onHold ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onHold ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
              },
              '& .MuiSwitch-track': {
                backgroundColor: onHold
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)', // This gives a lighter red shade for the track. Adjust as needed.
              },
            }}
            checked={onHold}
            onChange={handleToggle}
            name='onHold'
          />
        </Stack>
      </Grid>
    );
  };

  // HANDLERS
  const handleChangeContact = (e: any, contact: IEmployeeViewModel) => {
    e.preventDefault();
    dispatch({
      type: 'SET_CONTACT',
      payload: contact,
    });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
    dispatch({
      type: 'VALIDATE',
    });
    // setIsObjectList(true);
  };
  const handleChange = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    event.preventDefault();
    // console.log('handleChange', objectType);
    switch (objectType) {
      case EnumObjectType.Product:
        let product = ConvertProductToCompanyProduct(
          selectedObject as IBillingProductViewModel
        );
        if (
          state.isOnboardingEnabled &&
          product.product &&
          product.product.paymentCycle === 0
        ) {
          product.retailPrice = 0; // Set the price to 0 only if onboarding is enabled
        }
        //product.id = 'new' + product.id;
        dispatch({
          type: 'ADD_PRODUCT',
          payload: product as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.CompanyProduct:
        //console.log('handleChange', selectedObject);
        dispatch({
          type: 'ADD_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        //console.log('handleChange', selectedObject);
        dispatch({
          type: 'ADD_UNASSIGN_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.StorageProduct:
        // convert to company product
        let storageProduct = ConvertStorageProductToCompanyProduct(
          selectedObject as IStorageProductViewModel
        );
        dispatch({
          type: 'ADD_STORAGE_PRODUCT',
          payload: storageProduct as ICompanyProductViewModel,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: true,
        });
        break;
      case EnumObjectType.Package:
        // console.log('package selected in object drawer', selectedObject);
        let packageProducts = selectedObject.productPackageProducts.map(
          (packageProduct: ICompanyProductViewModel) => {
            let newProduct = { ...packageProduct };

            // Adding the 'retailPrice' from the product to our new object
            newProduct.retailPrice = packageProduct.product.retailPrice;
            newProduct.serviceTag = '';
            return newProduct;
          }
        );

        packageProducts.forEach((product: ICompanyProductViewModel) => {
          dispatch({
            type: 'ADD_PRODUCT',
            payload: product as ICompanyProductViewModel,
          });
        });
        //console.log('packageProducts: ', packageProducts);

        break;
    }
    dispatch({
      type: 'SET_IS_PRODUCT_CHANGED',
      payload: true,
    });
  };
  const handleRemoveProduct = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'REMOVE_PRODUCT', payload: companyProduct });
        dispatch({
          type: 'SET_IS_PRODUCT_CHANGED',
          payload: false,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_IS_PRODUCT_CHANGED',
          payload: false,
        });
        break;
      case EnumObjectType.CompanyProduct:
        if (companyProduct.placement === EnumPlacement.COMPANY) {
          // Utfører første dispatch-kall
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              placement: EnumPlacement.NONE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_IS_PRODUCT_CHANGED',
            payload: true,
          });
        } else {
          // Utfører dispatch-kall hvis betingelsen ikke er oppfylt
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              placement: EnumPlacement.COMPANY,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_IS_PRODUCT_CHANGED',
            payload: false,
          });
        }
        break;

      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'REMOVE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
    }
  };
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: 'UPDATE_FIELD',
      field: name as keyof ICompanyViewModel,
      value: value,
    });
    dispatch({ type: 'VALIDATE' });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validateField(name as keyof ICompanyViewModel, value);
  };
  const handlePrioritySubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    priority: number
  ) => {
    dispatch({ type: 'CHANGE_PRIORITY', payload: priority });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
  };
  const handleConfirm = async (e: any) => {
    e.preventDefault();
    setDeleteConfirm(false);
  };
  const handleChangeWorkRole = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.WorkRole:
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: object });
        dispatch({ type: 'VALIDATE' });
        dispatch({
          type: 'SET_DIRTY',
          payload: true,
        });
        break;
      default:
        break;
    }
  };
  const setFormDirty = () => {
    dispatch({ type: 'VALIDATE' });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
  };

  // FUNCTIONS

  // USSEFFECTS
  useEffect(() => {
    if (company) {
      dispatch({ type: 'SET_COMPANY', payload: company });
      dispatch({ type: 'VALIDATE' });
    }
  }, [company]);

  useEffect(() => {
    /*    console.log(
      'sharedProducts',
      objectDrawerList[EnumObjectType.sharedProducts].objectList
    ); */
    if (objectDrawerList[EnumObjectType.sharedProducts].objectList.length > 0) {
      let prod = objectDrawerList[EnumObjectType.sharedProducts].objectList;
      dispatch({ type: 'SET_SHARED_PRODUCTS', payload: prod });
    }

    if (
      objectDrawerList[EnumObjectType.SelectedCompany].objectList.length > 0
    ) {
      let workRole =
        objectDrawerList[EnumObjectType.SelectedCompany].objectList[0].workRole;

      if (workRole != null) {
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: workRole });
      }
    }
    if (
      objectDrawerList[EnumObjectType.SelectedCompany].objectList.length > 0
    ) {
      let url =
        objectDrawerList[EnumObjectType.SelectedCompany].objectList[0].url;

      if (url != null) {
        dispatch({ type: 'SET_URL', payload: url });
      }
    }
    // Set WorkRole
  }, [objectDrawerList]);

  // ONSUBMIT
  const onSubmit = (e: any) => {
    e.preventDefault();
    let fileModel = null;
    // converting image to fileModel
    if (image) {
      fileModel = ConvertBase64StringToFileModel(base64String, image.name);
    }
    // check if it is a new company
    if (state?.id !== 'new' && state?.id !== null) {
      let updateCompany: ICompanyUpdateViewModel = ConvertToUpdateCompany(
        state,
        fileModel
      );
      console.log('ONSUBMIT: updateCompany => ', updateCompany);

      putCompany.mutate(updateCompany, {
        onSuccess: (res: any) => {
          navigate(`/companies/${res.id}`);
          dispatch({
            type: 'SET_PRODUCT_CATALOG',
            payload: [] as ICompanyProductViewModel[],
          });
          setIsObjectList(true);
        },
      });
    } else if (state?.id === 'new') {
      let newCompany: ICompanyAddViewModel = {
        organizationNumber: state.organizationNumber,
        name: state.name,
        mainAddress: state.mainAddress,
        deliveryAddress: state?.deliveryAddress ? state.deliveryAddress : null,
        invoiceAddress: state?.invoiceAddress ? state.invoiceAddress : null,
        phone: state.phone,
        email: state.email,
        supportEmail: state.supportEmail,
        companyType: state.companyType,
        webPage: state.webPage,
        fileModel: fileModel ? fileModel : null,
        productBilling: state.productBilling,
        onHold: state.onHold,
        isSupport: state.isSupport,
        onHoldNote: state.onHoldNote,
        priority: state.priority,
        fixedHourlyRate: state.fixedHourlyRate,
        workRoleId: state.workRoleId,
        contactId: null,
        companyProducts: state.companyProducts,
        productCatalog: state.productCatalog,
        storageProducts: state.storageProducts,
        unassignedCompanyProducts: state.unassignedCompanyProducts,
      };
      postCompany.mutate(newCompany, {
        onSuccess: (res: ICompanyViewModel) => {
          navigate(`/companies/${res.id}`);
          window.location.reload();
        },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
      }}
    >
      {/*  LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: 0.5 }}>
        <Grid item xs={12}>
          <SearchFilter
            Icon={FeedRoundedIcon}
            Header={t('company.details.header')}
            filterValue={null}
            handleFilterChange={null}
            handleSearch={null}
            handleNew={null}
            selectList={null}
          />
        </Grid>

        <List
          sx={{
            width: '100%',
            height: Math.ceil(height - 244),
            p: 2,
            overflow: 'auto',
            backgroundColor: 'primary.main',
          }}
        >
          <Stack direction='row'>
            <Grid item sx={{ width: 384, mb: 2 }}>
              <Stack direction='column'>
                <Typography
                  sx={{
                    m: 0,
                    p: 2,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal ',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  {t('company.details.logo')}
                </Typography>

                <ImageUploader
                  width={256}
                  height={128}
                  setImage={setImage}
                  previewImage={state?.url ? state.url : null}
                  setBase64String={(str) => {
                    if (typeof str === 'string' || str === null) {
                      setBase64String(str);
                    }
                  }}
                  setFormDirty={setFormDirty}
                  base64String={base64String}
                />

                <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                  <Typography
                    sx={{
                      m: 0,
                      p: 2,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      fontSize: 14,
                      color: 'primary.text',
                    }}
                  >
                    {t('company.details.workRole')}
                  </Typography>

                  {state.workRole && state.workRole.id ? (
                    <Grid
                      container
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        width: '100%',
                        height: 60,
                        borderRadius: 2,
                        backgroundColor: 'primary.main',
                      }}
                    >
                      <Grid item sx={{ m: 0, p: 0, flexGrow: 1 }}>
                        <Typography>{state.workRole.title}</Typography>
                        <Typography>
                          {state.workRole.onSiteHourlyRate}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ m: 0, p: 0 }}>
                        <Stack direction='row'>
                          <Box
                            sx={{
                              mr: 1,
                              width: 30,
                              height: 30,
                              backgroundColor: 'primary.light',
                              display: 'flex', // <-- Set this to flex
                              justifyContent: 'center', // <-- This centers children horizontally
                              alignItems: 'center', // <-- This centers children vertically
                              borderRadius: 2,
                              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                              '&:hover': {
                                filter: 'brightness(120%)',
                              },
                            }}
                          >
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'CHANGE_WORK_ROLE',
                                  payload: {} as IWorkRoleViewModel,
                                });
                              }}
                            >
                              <DeleteForeverRoundedIcon />
                            </IconButton>
                          </Box>
                          <ObjectDrawer
                            buttonContext={t('company.details.workRole')}
                            buttonIcon={<ChangeCircleIcon />}
                            objectList={
                              !!objectDrawerList ? objectDrawerList : []
                            }
                            objectType={[EnumObjectType.WorkRole]}
                            handleChange={handleChangeWorkRole}
                            buttonHeight={30}
                            buttonWidth={30}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                        <Divider sx={{ backgroundColor: 'primary.text' }} />
                      </Grid>
                    </Grid>
                  ) : (
                    objectDrawerList[Number(EnumObjectType.WorkRole)] && (
                      <ObjectDrawer
                        buttonContext={t('company.details.workRole')}
                        buttonIcon={null}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.WorkRole]}
                        handleChange={handleChangeWorkRole}
                        buttonHeight={60}
                        buttonWidth={'100%'}
                      />
                    )
                  )}
                </Grid>
              </Stack>
            </Grid>
            <Grid
              container
              sx={{
                p: 3,
              }}
              rowSpacing={1}
              columnSpacing={{ xs: 2 }}
            >
              <Grid item xs={8}>
                <TextField
                  name='name'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.name')}
                  value={state.name}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  name='organizationNumber'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.orgNumber')}
                  value={state.organizationNumber}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.organizationNumber}
                  helperText={errors.organizationNumber}
                />
              </Grid>

              <Grid item xs={7}>
                <TextField
                  name='email'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.email')}
                  value={state.email}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              <Grid item xs={5}>
                <MuiTelInput
                  name='phone'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  continents={['EU']}
                  preferredCountries={['NO', 'SE', 'DK', 'DE', 'GB']}
                  defaultCountry='NO'
                  forceCallingCode={true}
                  label={t('company.details.phone')}
                  variant='standard'
                  value={state.phone}
                  onChange={(value: string, info: MuiTelInputInfo) => {
                    validateField('phone', value);
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'phone',
                      value: value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  name='webPage'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.website')}
                  value={state?.webPage}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.webPage}
                  helperText={errors.webPage}
                />
              </Grid>
              {company?.companyType !== 5 && (
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      m: 0,
                      p: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      fontSize: 14,
                      color: 'primary.text',
                    }}
                  >
                    {t('company.details.type')}
                  </Typography>
                  <Select
                    sx={{ width: '100%', p: 0, mt: -0.5 }}
                    labelId='companyType'
                    id='companyType'
                    variant='standard'
                    value={state.companyType}
                    label={t('company.details.type')}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_COMPANY_TYPE',
                        value: e.target.value as number,
                      })
                    }
                  >
                    <MenuItem value={0}>Prospect</MenuItem>
                    <MenuItem value={1}>FormerClient</MenuItem>
                    <MenuItem value={2}>Client</MenuItem>
                    <MenuItem value={3}>Lost</MenuItem>
                    <MenuItem value={4}>Vendor</MenuItem>
                  </Select>
                </Grid>
              )}
              <Grid item xs={6}></Grid>
            </Grid>
          </Stack>
          {/*     Priority   */}

          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormBoxElevated
              Icon={ArtTrackRoundedIcon}
              title={t('priority.header')}
              FormJSX={priorityJSX}
            />
          </Grid>

          {/*     Main Address */}

          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              border: 0,
              borderRadius: 2,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Accordion
              disableGutters
              expanded
              sx={{
                backgroundColor: 'transparent',
                border: 0,
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.mainAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.mainAddress?.street || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'street',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
                <TextField
                  name='postalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label={t('address.zip')}
                  value={state.mainAddress?.postalCode || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'postalCode',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
                <TextField
                  name='area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.mainAddress?.area || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'area',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
                <TextField
                  name='city'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.mainAddress?.city || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'city',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />

                <Select
                  name='country'
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  label={t('address.country')}
                  variant='standard'
                  value={state.mainAddress?.country || 0}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'country',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.mainAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/*     Delivery Address */}

          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              border: 0,
              borderRadius: 2,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Accordion
              disableGutters
              sx={{
                backgroundColor: 'transparent',
                border: 0,
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.deliveryAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.deliveryAddress?.street || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'street',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='PostalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label={t('address.zip')}
                  value={state.deliveryAddress?.postalCode || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'postalCode',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='Area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.deliveryAddress?.area || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'area',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='City'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.deliveryAddress?.city || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                />

                <Select
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  variant='standard'
                  value={state.deliveryAddress?.country || 'Norway'}
                  label={t('address.country')}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.deliveryAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/*     Invoice Address */}

          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              border: 0,
              borderRadius: 2,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Accordion
              disableGutters
              sx={{
                backgroundColor: 'transparent',
                border: 0,
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.billingAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.invoiceAddress?.street || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'street',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='postalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label='Postal Code'
                  value={state.invoiceAddress?.postalCode || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'postalCode',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.invoiceAddress?.area || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'area',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='city'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.invoiceAddress?.city || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                />

                <Select
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  variant='standard'
                  value={state.invoiceAddress?.country || 0}
                  label={t('address.country')}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.invoiceAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormBoxElevated
              Icon={ReportProblemRoundedIcon}
              title={t('company.details.onHold')}
              FormJSX={() => (
                <OHjsx onHold={state.onHold} dispatch={dispatch} />
              )} // Pass it as a component
            />
          </Grid>
          {state.onHold && (
            <TextField
              name='onHoldeNote'
              className='subvariant-hovered'
              label={t('company.details.onHoldNote')}
              variant='filled'
              multiline={true}
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_FIELD',
                  field: 'onHoldNote',
                  value: e.target.value,
                })
              }
            />
          )}
        </List>

        <Grid item xs={12}>
          <Paper
            variant='elevation3'
            sx={{
              width: '100%',
              p: 0,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: 'primary.main',
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <Grid item>
                <Button
                  variant='contained'
                  disabled={true}
                  onClick={() => setDeleteConfirm(!deleteConfirm)}
                >
                  {delCompany.isLoading
                    ? t('button.delete')
                    : t('button.delete')}
                </Button>
                {deleteConfirm && (
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleConfirm}
                  >
                    {t('button.confirmDelete')}
                  </Button>
                )}
              </Grid>

              <Grid item>
                {companyId !== 'new' && (
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={state.isDirty === false}
                    onClick={onSubmit}
                  >
                    {putCompany.isLoading
                      ? t('button.update')
                      : t('button.update')}
                  </Button>
                )}
                {companyId === 'new' && (
                  <Button
                    type='submit'
                    variant='contained'
                    onClick={onSubmit}
                    disabled={state.isValid === false}
                  >
                    {postCompany.isLoading
                      ? t('button.create')
                      : t('button.create')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/*  RIGHT SIDE */}
      <Grid item xs={6} sx={{ pl: 0.5 }}>
        <Paper
          variant='elevation3'
          sx={{
            width: '100%',
            borderRadius: 2,
            backgroundColor: 'primary.main',
            p: 1,
          }}
        >
          {/* PRIMARY CONTACTS */}
          {!!objectDrawerList[EnumObjectType.SelectedCompany].objectList[0] && (
            <Grid item xs={12}>
              <SearchFilter
                Icon={ContactMailRoundedIcon}
                Header={t('company.details.primaryContacts')}
                filterValue={null}
                handleFilterChange={null}
                handleSearch={null}
                handleNew={null}
                selectList={null}
              />

              <List
                sx={{
                  height: 200,
                  width: '100%',
                  p: 1,
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  overflow: 'auto',
                  mb: 2,
                }}
              >
                {}
                {objectDrawerList[EnumObjectType.primaryContacts].objectList
                  .length <= 0 ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      letterSpacing: 3,
                      fontSize: 18,
                      textTransform: 'capitalize',
                      fontWeight: 'bold',
                      alignSelf: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {t('list.empty')}
                  </Typography>
                ) : (
                  objectDrawerList[
                    EnumObjectType.primaryContacts
                  ].objectList.map(
                    (_employee: IEmployeeViewModel, index: number) => (
                      <SingleEmployeeView key={index} employee={_employee} />
                    )
                  )
                )}
              </List>
            </Grid>
          )}

          {/* CONTACT PERSON */}
          <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
            <FormBox
              Icon={PersonSearchRoundedIcon}
              title={t('company.details.contactPersonForProducts')}
              FormBTN={
                <ObjectDrawer
                  buttonContext={'Select Employee'}
                  buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26, m: 0 }} />}
                  objectList={!!objectDrawerList ? objectDrawerList : []}
                  objectType={[EnumObjectType.CompanyEmployees]}
                  handleChange={handleChangeContact}
                  buttonHeight={30}
                  buttonWidth={30}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ p: 0, mb: 2 }}>
            {!!state.contact ? (
              <Stack direction='column'>
                <SingleEmployeeView employee={state.contact} height={80} />
              </Stack>
            ) : (
              <ObjectDrawer
                buttonContext={t('company.details.addContactPersonForProducts')}
                buttonIcon={null}
                objectList={!!objectDrawerList ? objectDrawerList : []}
                objectType={[EnumObjectType.CompanyEmployees]}
                handleChange={handleChangeContact}
                buttonHeight={82}
                buttonWidth={'100%'}
              />
            )}
          </Grid>
          {/* SHARED PRODUCTS */}
          <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
            <FormBox
              Icon={ContactMailRoundedIcon}
              title={t('company.details.sharedProducts')}
              FormBTN={
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid display={'flex'} sx={{ mr: 2 }}>
                    <Switch
                      checked={state.isOnboardingEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: 'TOGGLE_ONBOARDING_ENABLED',
                          payload: e.target.checked,
                        })
                      }
                      name='enableOnboarding'
                      color='warning'
                      sx={{}}
                    />
                    <Typography
                      sx={{
                        m: 0,
                        pt: 1,
                        // outline: '1px solid red',
                        pr: 0,
                        textAlign: 'left',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: 'warning.text',
                      }}
                    >
                      {t('company.details.enableAddFirstTimeProduct')}
                    </Typography>
                  </Grid>
                  {companyId !== 'new' && state.isOnboardingEnabled ? (
                    <ObjectDrawer
                      buttonContext= {t('company.details.addFirstTimeProduct')}
                      buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[
                        EnumObjectType.Product,
                        /*  EnumObjectType.StorageProduct,
                      EnumObjectType.unassignedCompanyProduct,
                      EnumObjectType.Package, */
                      ]}
                      handleChange={handleChange}
                      buttonHeight={26}
                      buttonWidth={26}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 26,
                        height: 26,
                        backgroundColor: 'primary.light',
                        display: 'flex', // <-- Set this to flex
                        justifyContent: 'center', // <-- This centers children horizontally
                        alignItems: 'center', // <-- This centers children vertically
                        borderRadius: 2,
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                      }}
                    >
                      <IconButton
                        disabled={true}
                        sx={{
                          p: 0,
                          m: 0,
                          width: 26,
                          height: 26,
                          //color: 'secondary.light',
                          /*  '&:hover': {
        color: 'secondary.light'
      } */
                        }}
                        // onClick={() => setOpen(!open)}
                      >
                        <AddRoundedIcon sx={{ fontSize: 26 }} />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{
                height: Math.ceil(height - 672),
                width: '100%',
                mb: 0,
                p: 2,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                overflow: 'auto',
              }}
            >
              {state.companyProducts.length > 0 &&
                state.companyProducts?.map(
                  (companyProduct: ICompanyProductViewModel, index: number) => {
                    return !!companyProduct ? (
                      <ListItem sx={{ mb: 1, p: 0 }} key={index * 10}>
                        <ProductCardEmployeeView
                          companyProduct={companyProduct}
                          location={EnumObjectType.Company}
                          objectType={EnumObjectType.CompanyProduct}
                          handleRemoveProduct={handleRemoveProduct}
                        />
                      </ListItem>
                    ) : null;
                  }
                )}
              {state?.unassignedCompanyProducts?.map(
                (companyProduct: ICompanyProductViewModel, index: number) => {
                  return !!companyProduct ? (
                    <ListItem sx={{ mb: 1, p: 0 }} key={index * 100}>
                      <ProductCardEmployeeView
                        companyProduct={companyProduct}
                        location={EnumObjectType.Company}
                        objectType={EnumObjectType.unassignedCompanyProduct}
                        handleRemoveProduct={handleRemoveProduct}
                      />
                    </ListItem>
                  ) : null;
                }
              )}
              {state?.storageProducts?.map(
                (companyProduct: ICompanyProductViewModel, index: number) => {
                  return !!companyProduct ? (
                    <ListItem key={index * 1000} sx={{ mb: 1, p: 0 }}>
                      <ProductCardEmployeeView
                        companyProduct={companyProduct}
                        location={EnumObjectType.Company}
                        objectType={EnumObjectType.StorageProduct}
                        handleRemoveProduct={handleRemoveProduct}
                      />
                    </ListItem>
                  ) : null;
                }
              )}
              {state?.productCatalog?.map(
                (companyProduct: ICompanyProductViewModel, index: number) => {
                  return !!companyProduct ? (
                    <ListItem sx={{ mb: 1, p: 0 }} key={index * 10000}>
                      <ProductCardEmployeeView
                        companyProduct={companyProduct}
                        location={EnumObjectType.Company}
                        objectType={EnumObjectType.Product}
                        handleRemoveProduct={handleRemoveProduct}
                      />
                    </ListItem>
                  ) : null;
                }
              )}
            </List>

            <Grid
              container
              sx={{
                m: 0,
                p: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <Grid item></Grid>

              <Grid item>
                <Button
                  onClick={onSubmit}
                  disabled={!state.isProductChanged}
                  variant='contained'
                >
                  {t('button.updateSharedProducts')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

import { IDeliveryAddressViewModel } from 'Interfaces/IGlobalTypeConfig';
import _ from 'lodash';

export const GenerateAddressList = (
  addressList: IDeliveryAddressViewModel[],
  owner: any,
  employee: any
) => {
  let tmp: IDeliveryAddressViewModel[] = _.cloneDeep(addressList);
  if (owner != null) {
    tmp[0].addressId = owner.deliveryAddress.id;
    tmp[0].street = owner.deliveryAddress.street;
    tmp[0].postalCode = String(owner.deliveryAddress.postalCode);
    tmp[0].area = owner.deliveryAddress.area;
    tmp[0].city = owner.deliveryAddress.city;
    tmp[0].country = owner.deliveryAddress.country;
    tmp[0].postBox = owner.deliveryAddress.postBox
      ? owner.deliveryAddress.postBox
      : '';
    tmp[0].attention = '';
  }
  if (employee.companyAddress != null) {
    tmp[1].addressId = employee.companyAddress.id;
    tmp[1].street = employee.companyAddress.street;
    tmp[1].postalCode = String(employee.companyAddress.postalCode);
    tmp[1].area = employee.companyAddress.area;
    tmp[1].city = employee.companyAddress.city;
    tmp[1].country = employee.companyAddress.country;
    tmp[1].postBox = employee.companyAddress.postBox
      ? employee.companyAddress.postBox
      : '';
    tmp[1].attention = '';
  }
  if (employee.departmentAddress != null) {
    tmp[2].addressId = employee.departmentAddress.id;
    tmp[2].street = employee.departmentAddress.street;
    tmp[2].postalCode = String(employee.departmentAddress.postalCode);
    tmp[2].area = employee.departmentAddress.area;
    tmp[2].city = employee.departmentAddress.city;
    tmp[2].country = employee.departmentAddress.country;
    tmp[2].postBox = employee.departmentAddress.postBox
      ? employee.departmentAddress.postBox
      : '';
    tmp[2].attention = '';
  }
  if (employee.homeOffice != null) {
    tmp[3].addressId = employee.homeOffice.id;
    tmp[3].street = employee.homeOffice.street;
    tmp[3].postalCode = String(employee.homeOffice.postalCode);
    tmp[3].area = employee.homeOffice.area;
    tmp[3].city = employee.homeOffice.city;
    tmp[3].country = employee.homeOffice.country;
    tmp[3].postBox = employee.homeOffice.postBox
      ? employee.homeOffice.postBox
      : '';
    tmp[3].attention = '';
  }
  return tmp;
};

import { Grid, List, Paper, Typography } from "@mui/material";
import { SubHeading } from "components/ui";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import GetAvatarInitials from "functions/GetAvatarInitials";
import useWindowsDimension from "functions/useWindowsDimension";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import { SingleSaleView } from "components/ui/SingleSaleView";
const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + "/img/",
};

export const SalesTable = () => {
  const [ticketData, updateTicketData] = useState({});
  const { width, height } = useWindowsDimension();

  const orginalTicketLists = [
    {
      id: 0,
      heading: "Unassigned",
      tickets: [
       
      ],
    },
    {
      id: 1,
      heading: "Assigned",
      tickets: [],
    },
  ];

  const [ticketLists, updateTicketList] = useState(orginalTicketLists);

  const ListView = (props) => {
    const heading = props.heading;
    const tickets = props.tickets;

    //console.log("ListView", tickets);

    return (
      <Grid item xs={6} sx={{}}>
        <Paper
          variant="elevation3"
          sx={{
            ml: 2,
            p: 2,
            height: Math.ceil(height - 124),
          }}
        >
          {/* HEADER */}

          <Grid
            sx={{
              height: 45,
            }}
          >
            <Typography variant="subHeader" noWrap sx={{}}>
              {heading}
            </Typography>
          </Grid>
          {/* LIST */}

          <Droppable droppableId={heading}>
            {(provided, snapshot) => (
              <List
                sx={{
                  height: Math.ceil(height - 264),
                  p: 2,
                  backgroundColor: `${
                    snapshot.isDraggingOver ? "primary.dark" : "primary.back"
                  }`,
                  overflow: "auto",

                  borderRight: 1,
                  borderRightColor: "primary.text",
                  borderBottom: 1,
                  borderBottomColor: "primary.text",
                  borderRadius: 3,
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tickets?.map((_ticket, index) => (
                  <SingleSaleView key={index} index={index} ticket={_ticket} />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </Paper>
      </Grid>
    );
  };

  //console.log("Tickets", ticketList);
  // handles
  const handleClick = (e) => {
    e.preventDefault();
  };

  const handleOnDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    /* Check if drop outside of droppable zone */
    if (!destination) {
      return;
    }

    /* Check if drop is the same place as it was */
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    /* find list where you picket up the ticket */
    const start = ticketLists.find((obj) => {
      return obj.heading === source.droppableId;
    });

    /* find list where you dropped the ticket */
    const finish = ticketLists.find((obj) => {
      return obj.heading === destination.droppableId;
    });

    /* check if you dropped it in the same list you picked it */
    if (start.id === finish.id) {
      const newTickets = Array.from(start.tickets);

      /* reorganize the ticket list */
      const ticketToMove = start.tickets[source.index];
      newTickets.splice(source.index, 1);
      newTickets.splice(destination.index, 0, ticketToMove);
      const newList = {
        ...start,
        tickets: newTickets,
      };

      /* get the orginal list and change the tickets */
      const newTicketList = Array.from(ticketLists);
      newTicketList.map((list) => {
        if (source.droppableId === list.heading) {
          list.tickets = newList.tickets;
        }
      });

      /* update the lists */
      updateTicketList(newTicketList);

      /* update the lists */

      return;
    }

    /* get all tickets where you picked up and where you dropped it  */
    const newStartTickets = Array.from(start.tickets);
    const newDroppedTickets = Array.from(finish.tickets);

    /* reorganize the ticket list */
    const ticketToMove = start.tickets[source.index];
    newStartTickets.splice(source.index, 1);
    newDroppedTickets.splice(destination.index, 0, ticketToMove);

    const newStartList = {
      ...start,
      tickets: newStartTickets,
    };

    const newDroppedList = {
      ...finish,
      tickets: newDroppedTickets,
    };

    /* reorganize the ticket list */
    const newTicketList = Array.from(ticketLists);
    newTicketList.map((list) => {
      if (source.droppableId === list.heading) {
        list.tickets = newStartList.tickets;
      }
      if (destination.droppableId === list.heading) {
        list.tickets = newDroppedList.tickets;
      }
    });
    updateTicketList(newTicketList);
    /* XXX Update ticket with technician */
  };

  return (
    <Grid container rowSpacing={0} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
      <Grid item xs={12}>
        <SubHeading
          icon={SupportAgentRoundedIcon}
          title="Tickets"
          handleClick={handleClick}
        />
      </Grid>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Grid
          container
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          {ticketLists.map((_list) => {
            // console.log("_list", _list);

            return !!_list && <ListView key={_list.id} {..._list} />;
          })}
        </Grid>
      </DragDropContext>
    </Grid>
  );
};

import { Paper, List, ListItem, } from '@mui/material';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

export const TicketAttachments = () => {
  const { ticketId } = useParams();
  const { height } = useWindowsDimension();

  const [ticketPosts, setTicketPosts] = useState([]);
  const [attachments, setAttachments] = useState([]);

  let isEnabled = ticketId !== 'new';
  const { data } = useGet(rq.ticketPosts(ticketId, isEnabled), isEnabled);

  useEffect(() => {
    if (data) {
      setTicketPosts(data.ticketPosts);

      let att = [];
      data.ticketPosts.forEach((post) => {
        att.push(...post.attachments);
      });
      setAttachments(att);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Paper
        variant='elevation3'
        sx={{
          pl: 2,
          pt: 2,
          pr: 2,
          height: Math.ceil(height - 128),
        }}
      >
        <List>
          {attachments.length > 0 ? (
            attachments.map((attachment, index) => {
              // console.log('attachment', attachment.fileName);
              return (
                <ListItem key={index}>
                  <a
                    href={attachment.url}
                    target='_blank'
                    style={{ color: 'white' }}
                    rel='noreferrer'
                  >
                    {attachment.fileName}
                  </a>
                </ListItem>
              );
            })
          ) : (
            <></>
          )}
        </List>
      </Paper>
    </>
  );
};

import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  FormText,
} from "components/form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import FeedRoundedIcon from "@mui/icons-material/FeedRounded";

let _ = require("lodash");

const schema = yup
  .object()
  .shape({
    organizationNumber: yup.string().min(9).max(9).required(),
    name: yup.string().min(3).max(50).required(),
    mainAddress: yup.object().shape({
      street: yup.string().required(),
      postalCode: yup.string().min(4).max(4).required(),
      city: yup.string().min(2).max(50).required(),
    }),
    phone: yup.string().min(8).max(16).required(),
    email: yup.string().email().required(),
  })
  .required();

let teller = -1;
const values = [
  {
    id: teller++,
    seniorCost: 1290,
    juniorCost: 995,
  },
];
export const AppValues = () => {
  /* INITIALIZATIONS */
  const {
    handleSubmit,
    control,
    getValues,
    register,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: values,
    //resolver: yupResolver(schema),
  });

  /* FUNCTIONS */

  return (
    <>
      <Grid item xs={12}>
        <form name="appValuesForm">
          <Paper
            sx={{
              mb: 2,
              borderRadius: 3,
              backgroundColor: "primary.main",
              color: "primary.text",
            }}
          >
            <Accordion
              expanded
              disableGutters
              elevation={0}
              sx={{
                m: 0,
                backgroundColor: "transparent",
                boxShadow: 0,
                border: 0,
              }}
            >
              <AccordionSummary
                aria-controls="panel0a-content"
                id="panel0a-header"
              >
                <Box sx={{ pt: 1 }}>
                  <FeedRoundedIcon sx={{ fontSize: 40 }} />{" "}
                </Box>
                <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
                  <Typography
                    noWrap
                    variant= "subHeader"
                  >
                    App Values
                  </Typography>
                </Box>
                {/* <Box sx={{ pt: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        onChange={handleSubmit(onSubmit)}
                      />
                    }
                    {...register("active")}
                    label="Active"
                    labelPlacement="top"
                  />
                </Box> */}
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  sx={{
                    p: 3,
                  }}
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={8}>
                    <FormText
                      name="SeniorCost"
                      control={control}
                      label="Senior Hour Price"
                    />
                  </Grid>
                </Grid>

                <Box
                  sx={{ m: 2, display: "flex", flexDirection: "row-reverse" }}
                >
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </form>
      </Grid>
    </>
  );
};

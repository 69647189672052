import {
  Box,
  Divider,
  List,
  Paper,
  Typography,
} from "@mui/material";
import { ChartDataList } from "../ui";

function getTotal(currentData) {
  let _value = 0;
  currentData.map((_cd) => (_value += Number(_cd.value)));
  return _value;
}

export const ChartTypeCard = (props) => {
  const { version, header, currentData } = props;

  const chartSelected = [
    <List
      sx={{
        m: 0.5,
        p: 0.5,
        pr: 2,
        pb: 2,
      }}
    >
      {currentData.map((_cd) =>
        _cd.id > -1 ? <ChartDataList key={`_cd${_cd.id}`} {..._cd} /> : <Divider />
      )}
    </List>,
  ];

  return (
    <Box
      sx={{
        height: 350,
        m: 0,
      }}
    >
      <Paper
        elevation={3}
        sx={{  
          pl:2,
          pr:2,       
          borderRadius: 3,
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 18,
            letterSpacing: 3,
            mb: 0,
            pt: 1.5,
          }}
        >
          {header}
        </Typography>

        {chartSelected[version]}
      </Paper>
    </Box>
  );
}

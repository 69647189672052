import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
// @ts-ignore
import { getFilteredList } from 'functions/misc';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { SearchFilter } from 'components/ui/SearchFilter';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { List } from '@mui/material';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { useTranslation } from 'react-i18next';
let _ = require('lodash');

export const Employees = () => {
  const { t } = useTranslation();
  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { companyId } = useParams();
  const { height } = useWindowsDimension();
  const [allEmployees, setAllEmployees] = useState([]);
  const [employees, setEmployees] = useState<IEmployeeViewModel[]>([]);
  const [departments, setDepartments] = useState([]);
  // api
  let isEnabled = companyId !== undefined && companyId !== 'new' ? true : false;
  const { data: employeeData } = useGet(
    rq.employees(companyId || '', isEnabled),
    isEnabled
  );
  const { data: departmentData } = useGet(
    rq.departments(companyId || '', isEnabled),
    isEnabled
  );

  // states
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState('all');

  /* FUNCTIONS */
  // handles
  const handleNew = (e: any) => {
    e.preventDefault();
    navigate(`/companies/${companyId}/employees/new`);
  };

  const handleFilter = (e: any) => {
    setFilterType(e);
    let filterValue = e.toLowerCase() === 'all' ? '' : e;
    let filteredRows = getFilteredList(employees, filterValue);
    let searchRows = getFilteredList(filteredRows, search);

    setEmployees(searchRows);
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    let filterValue = filterType === 'all' ? '' : filterType;
    let filteredRows = getFilteredList(allEmployees, filterValue);
    let searchRows = getFilteredList(filteredRows, e.target.value);

    setEmployees(searchRows);
  };

  useEffect(() => {
    let tmp = _.cloneDeep(departmentData?.departments);
    setDepartments(tmp);
  }, [departmentData]);

  useEffect(() => {
    let tmp = _.cloneDeep(employeeData?.allEmployees || []);
    setAllEmployees(tmp);
    setEmployees(tmp);
  }, [employeeData]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: .5, m: 0 }}>
        <SearchFilter
          Icon={PeopleAltTwoToneIcon}
          Header={t('company.employees.listHeader')}
          filterValue={filterType}
          handleFilterChange={handleFilter}
          handleSearch={handleSearch}
          handleNew={handleNew}
          selectList={departments}
          hasSelectAll={true}
        />

        <List
          sx={{
            height: Math.ceil(height - 244),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {employees && employees.length > 0 ? (
            employees?.map((employee: IEmployeeViewModel, index: number) => (
              <Paper
                variant='elevation3'
                sx={{ mb: 1 }}
                key={index}
                onClick={() => {
                  navigate(
                    '/companies/' + companyId + '/employees/' + employee.id
                  );
                }}
              >
                <SingleEmployeeView
                  key={index}
                  index={index}
                  employee={employee}
                />
              </Paper>
            ))
          ) : (
            <p>No employees found</p>
          )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
     
        </Paper>
      </Grid>
      {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ m: 0, pl: .5 }}>
        <Paper
          variant='elevation3'
          sx={{
            height: Math.ceil(height - 128),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};

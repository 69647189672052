import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { PageHeader } from 'components/ui/Global/PageHeader';
import { HeaderButtonDetails } from './Component/StorageStyleConfig';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import { useTranslation } from 'react-i18next';

export const Storage = () => {
const { t } = useTranslation();

  /* INITIALIZATIONS */
  const headerButtons = HeaderButtonDetails(null, null);

  return (
    <Grid container direction='row' spacing={0}>
      {/* HEADER */}
      <Grid item xs={12} sx={{ p: 0, m: 0 }}>
        <PageHeader
          Icon={DevicesOtherRoundedIcon}
          title={t("Storage.Storage.headerTitle")}
          FormJSX={null}
          HeaderButtons={headerButtons}
        />
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

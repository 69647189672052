import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore|
import { getFilteredList } from 'functions/misc';
import useWindowsDimension from 'functions/useWindowsDimension';
import _ from 'lodash';

import { Grid, List, Paper, Typography } from '@mui/material';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import { SearchFilter } from 'components/ui/SearchFilter';
import { ICategoriesViewModel } from 'Interfaces/IGlobalTypeConfig';
import { SingleStorageProductView } from 'components/ui/SingleStorageProductView';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import { ListStyle,FooterStyle } from 'data/FontStyle';
import { useTranslation } from 'react-i18next';

type Props = {
  categories: ICategoriesViewModel[];
  storageProducts: IStorageProductViewModel[];
  cost: number;
  value: number;
  handleClick: any;
};

const formatCost = (cost: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'decimal',
  })
    .format(cost)
    .replace(/,/g, ' ');
};

export const StorageList = ({
  categories,
  storageProducts,
  cost,
  value,
  handleClick,
}: Props) => {
const { t } = useTranslation();

  /* INITIALIZATION */
  const storageProductList: IStorageProductViewModel[] =
    _.cloneDeep(storageProducts);

  /*  list?.map((storageProduct:any) => {
    storageProduct.typeId = storageProduct.productType.id;
    return <></>;
  }); */

  const { height } = useWindowsDimension();
  let navigate = useNavigate();

  // states
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState('All');
  const [filteredProducts, setFilteredProducts] =
    useState<IStorageProductViewModel[]>(storageProducts);
  const [productTypes, setProductTypes] = useState([]);

  /* FUNCTIONS */

  // handles
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    let filterValue = filterType === 'All' ? '' : filterType;
    let filteredRows: IStorageProductViewModel = getFilteredList(
      storageProductList,
      filterValue
    );
    let searchRows = getFilteredList(filteredRows, e.target.value);

    setFilteredProducts(searchRows);
  };

  const handleFilterChange = (e: any) => {
    setFilterType(e);
    let filterValue = e === 'All' ? 'All' : e;
    let filteredRows: IStorageProductViewModel[] = [];

    if (filterValue === 'All') {
      filteredRows = storageProducts;
    } else {
      filteredRows = storageProducts.filter(
        (item: IStorageProductViewModel) =>
          item.product.productType.id === filterValue
      );
    }

    setFilteredProducts(filteredRows);
  };

  useEffect(() => {
    setFilteredProducts(storageProducts);
  }, [storageProducts]);

  useEffect(() => {
    let tmp: any = [{ name: 'All', id: 'All' }]; // Add the "All" option
    categories.forEach((category: any) => {
      category.productTypes.forEach((type: any) => {
        tmp.push(type);
      });
    });
    setProductTypes(tmp.sort((a: any, b: any) => a.name.localeCompare(b.name)));
  }, [categories]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        backgroundColor: 'primary.main',
        borderRadius: 3,
      }}
    >
      <Grid item xs={12} sx={{ p: 0, m: 0 }}>
        <SearchFilter
          Icon={WarehouseRoundedIcon}
          Header={t("Storage.StorageList.storageProducts")}
          filterValue={filterType}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          selectList={productTypes}
        />
        <List
          sx={{
            ...ListStyle.list,
            height: Math.ceil(height - 238),
          }}
        >
          {filteredProducts?.length === 0 && <div>{t("Storage.StorageList.noProductsFound")}</div>}
          {filteredProducts?.length > 0 &&
            filteredProducts?.map(
              (product: IStorageProductViewModel, index: any) => (
                <Paper
                  variant='elevation3'
                  key={index}
                  onClick={(e: any) => {
                    if (!product.ticket) {
                      handleClick(e, product);
                    }
                  }}
                >
                  <SingleStorageProductView storageProduct={product} />
                </Paper>
              )
            )}
        </List>
        {/* RIGHT SIDE */}
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 52,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item xs={3} sx={{}}>
              <Typography sx={{ ...FooterStyle.text }}>
                {t("Storage.StorageList.estimatedStorageCost")}
              </Typography>
            </Grid>
  
            <Grid item xs={3}>
              <Typography sx={{ ...FooterStyle.value }}>
                {cost ? formatCost(cost) : t("Storage.StorageList.zeroCost")}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography sx={{ ...FooterStyle.text }}>
                {t("Storage.StorageList.estimatedStorageValue")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ ...FooterStyle.value }}>
                {value ? formatCost(value) : t("Storage.StorageList.zeroValue")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
  
};

import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { HeaderButtonInterface } from 'Interfaces/IGlobalTypeConfig';



export const HeaderButtonDetails = (
  linkId1: string | null,
  linkId2: string | null, 
): HeaderButtonInterface[] => [
  {
    name: 'Details',
    component: { Link },
    to: `/storage/`,
    icon: DashboardIcon,
  },
 
];

import { Grid, Paper, Stack, Typography } from '@mui/material';
// @ts-ignore
import { ChartDataView } from './ChartDataView';

export const ChartTriple = (props: any) => {
  const { header, icon: IH, tripleData } = props;
  // CHART TRIPLE DATA

  return (
    <Paper
      variant='elevation3'
      sx={{
        p: 1,
        borderRadius: 3,
        backgroundColor: 'primary.main',
      }}
    >
      <Grid container direction={'row'}>
        <Grid item xs={12} sx={{}}>
          <Stack direction={'row'}>
            <IH sx={{ fontSize: 32 }} />
            <Typography
              sx={{
                m: 1,
                fontSize: 18,
                letterSpacing: 2,
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {header}
            </Typography>
          </Stack>
        </Grid>

        {tripleData?.map((obj: any, index: number) => {
          if (obj.value > obj.oldValue) {
            obj.colorType = 0;
          } else if (obj.value < obj.oldValue) {
            obj.colorType = 2;
          } else {
            obj.colorType = 1;
          }
          return (
            <Grid key={index} item xs={3} sx={{}}>
              <ChartDataView
                subHeader={obj.subHeader}
                value={obj.value}
                oldValue={obj.oldValue}
                colorType={obj.colorType}
                trend={obj.trend}
              />
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

import { Grid, Typography } from '@mui/material';
import { workRoles } from 'data/enum';
import { useState } from 'react';
import { getTimeDifference } from './GetTimeDifference';
import { getSecondsDifference } from './GetSecondsDifference';

export const BillableTime = (props) => {
  const { startTime, startDeduction, endDeduction, endTime, color, size } =
    props;

  let secondsToDeduct = getSecondsDifference(
    new Date(startDeduction),
    new Date(endDeduction)
  );
  let totalTime = getSecondsDifference(new Date(startTime), new Date(endTime));
  let timeToShow = totalTime - secondsToDeduct;

  let diffHours = Math.floor(timeToShow / 60);
  let diffMinutes = Math.floor(timeToShow % 60);

  let diffHoursStr = diffHours.toString().padStart(2, '0');
  let diffMinutesStr = diffMinutes.toString().padStart(2, '0');

  // Combine the hours and minutes into a HH:mm format string
  let diffTimeStr = `${diffHoursStr}:${diffMinutesStr}`;

  return (
    <Typography
      sx={{
        pl: 1,
        pr: 1,
        width: 75,
        fontSize: size,
        letterSpacing: 3,
        textAlign: 'right',
        color: color,
        fontWeight: 'bold',
      }}
    >
      {diffTimeStr}
    </Typography>
  );
};

import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { HeaderButtonInterface } from 'Interfaces/IGlobalTypeConfig';

export const BillingHeaderButtonDetails = (
  linkId1: string | null,
  linkId2: string | null
): HeaderButtonInterface[] => [
  {
    name: "Billing.billingView.unbilledPost",
    component: { Link },
    to: `/BillingView`,
    icon: DashboardIcon,
  },
  {
    name: "Billing.billingView.billed",
    component: { Link },
    to: `/billing/ordersList`,
    icon: CreditScoreIcon,
  },
  {
    name: "Billing.billingView.history",
    component: { Link },
    to: `/billing/ordersList`,
    icon: HistoryToggleOffIcon,
  },
];

import { memo } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import useWindowsDimension from "functions/useWindowsDimension";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const Sale = () => {
  const { ticketId } = useParams();
  const { height, width } = useWindowsDimension();
  const drawerWidth = 250;
  let teller = -1;

  const TicketLinks = memo(() => {
    return (
      <List sx={{ color: "primary.text" }}>
        <ListItem
          button
          key={teller++}
          component={Link}
          to={`/tickets/${ticketId}`}
        >
          <ListItemText primary="Details" />
        </ListItem>
        <ListItem
          button
          key={teller++}
          component={Link}
          to={`/tickets/${ticketId}/communications`}
        >
          <ListItemText primary="Communications" />
        </ListItem>
        <ListItem
          button
          key={teller++}
          component={Link}
          to={`/tickets/${ticketId}/economy`}
        >
          <ListItemText primary="Economy" />
        </ListItem>
        <ListItem
          button
          key={teller++}
          component={Link}
          to={`/tickets/${ticketId}/products`}
        >
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem
          button
          key={teller++}
          component={Link}
          to={`/tickets/${ticketId}/productshistory`}
        >
          <ListItemText primary="Products history" />
        </ListItem>
        <ListItem
          button
          key={teller++}
          component={Link}
          to={`/tickets/${ticketId}/resolution`}
        >
          <ListItemText primary="Resolution" />
        </ListItem>
        <ListItem
          button
          key={teller++}
          component={Link}
          to={`/tickets/${ticketId}/attachments`}
        >
          <ListItemText primary="Attachments" />
        </ListItem>
      </List>
    );
  });
  
  return (
    <>
      <Grid container direction="row" spacing={0}>
        <Grid item width={drawerWidth} sx={{}}>
          <Paper variant="elevation3" sx={{}}>
            <TicketLinks />
          </Paper>
        </Grid>

        <Grid item width={width - drawerWidth * 2 - 76} sx={{}}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

import { Grid } from '@mui/material';
import { Dashboard as DashboardIcon } from '@mui/icons-material';
import useWindowsDimension from 'functions/useWindowsDimension';
import { PageHeader } from 'components/ui/Global/PageHeader';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();
  const { height } = useWindowsDimension();

  return (
    <Grid container display={'flex'} direction={'row'}>
      <Grid item xs={12} sx={{}}>
      <PageHeader
          Icon={DashboardIcon}
          title={t('dashboard.header')}
          FormJSX={null}
          HeaderButtons={[]}
        />

      </Grid>
    <Grid item xs={12} sx={{height:height-134, borderRadius:2, backgroundColor:'primary.main',}}>
      </Grid>
  
    </Grid>
  );
};

export default Dashboard;

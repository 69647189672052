import { Grid, List, Paper } from '@mui/material';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import DevicesIcon from '@mui/icons-material/Devices';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
// const useWindowsDimension = require('functions/useWindowsDimension.js');
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq } from 'http/apiRoutes';
const { SearchFilter } = require('../../components/ui/SearchFilter');
const { useGet } = require('http/useInnovit');
const { SingleProductView } = require('components/ui/SingleProductView');

type TicketProductParams = {
  ticketId: string;
};

export const TicketProducts = () => {
  let { ticketId } = useParams<TicketProductParams>();
  if (!ticketId) {
    ticketId = '0';
  }

 // console.log('ticket ID: ' + ticketId);
  const { height } = useWindowsDimension();
  const [products, setProducts] = useState<any[]>([]);
  const [companyProducts, setCompanyProducts] = useState<any[]>([]);

  let isEnabled = ticketId === '0' ? false : true;
  const { data: ticketProductsData } = useGet(
    rq.ticketProducts(ticketId, isEnabled),
    isEnabled
  );

  useEffect(() => {
    if (ticketProductsData) {
      setProducts(ticketProductsData.products);
      setCompanyProducts(ticketProductsData.companyProducts);
    }
  }, [ticketProductsData]);

 // console.log('ticketProducts', ticketProductsData);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
     
          <SearchFilter
            Icon={DevicesIcon}
            Header='Ticket Products'
            // filterValue={filterType}
            //handleFilterChange={handleFilterChange}
            //handleSearch={handleSearch}
            // handleNew={handleNew}
            //selectList={companyTypes}
          />       

        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {!products && <div>No data</div>}
          {products &&
            products.map((product: object, index: number) => {
              return (
                <SingleProductView
                  key={index}
                  product={product}
                ></SingleProductView>
              );
            })}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            height: 64,
            // borderRadius: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderRadius: 10,
            elevation: 3,
            // backgroundColor: paperDefaultColor_Main,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        ></Paper>
      </Grid>
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
    
          <SearchFilter
            Icon={DevicesOtherIcon}
            Header='Ticket Company Products'
            // filterValue={filterType}
            //handleFilterChange={handleFilterChange}
            //handleSearch={handleSearch}
            // handleNew={handleNew}
            //selectList={companyTypes}
          />

        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {!companyProducts && <div>No data</div>}
          {companyProducts &&
            companyProducts?.map(
              (product: { product: object }, index: number) => {
                const companyProduct = product.product;
                return (
                  <SingleProductView
                    key={index}
                    product={companyProduct}
                  ></SingleProductView>
                );
              }
            )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            height: 64,
            // borderRadius: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderRadius: 10,
            elevation: 3,
            // backgroundColor: paperDefaultColor_Main,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
};

const ConvertBase64StringToFileModel = (base64String:any, fileName:string) => {
  // console.log("base64String", base64String);
  const encodedBase64String = base64String.split(',')[1];
  // console.log("encodedBase64String", encodedBase64String);

  // decode base64String to blob
  // const byteArray = atob(encodedBase64String)
  //   .split("")
  //   .map((char) => char.charCodeAt(0));


  return { content: encodedBase64String, fileName: fileName};
  // const blob = new Blob([byteArray], {
  //   type: `image/${base64String.split("/")[1].split(";")[0]}`,
  // });
};

export default ConvertBase64StringToFileModel;

import { Divider, Grid, List, Typography } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { ChartDataColor } from '../../data/variables';
import { ChartDataList } from './ChartDataList';

function getTotal(currentData: any) {
  let _value = 0;
  currentData && currentData.map((_cd: any) => (_value += Number(_cd.value)));
  return _value;
}

export const ChartTypePie = (props: any) => {
  const { header, currentData } = props;
  //console.log('ChartTypePie', props);
  const _total = getTotal(currentData) || 0;

  return (
    <ResponsiveContainer>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            height: 64,
          }}
        >
          <Typography
            sx={{
              p: 2,
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 18,
              letterSpacing: 3,
            }}
          >
            {header}
          </Typography>
        </Grid>
        <Grid
          item
          xs={10}
          sx={{
            display: 'flex',
            width: '100%',
            height: 'calc(100% - 64)',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <ResponsiveContainer width='100%'>
            <PieChart key={'P1'}>
              <Pie
                key={'P11'}
                dataKey={currentData.value}
                data={currentData}
                cx='50%'
                cy='50%'
                innerRadius={65}
                outerRadius={90}
                label
              >
                {currentData.map((entry: any, id: string) => (
                  <Cell
                    fontSize={18}
                    key={`cell-${id}`}
                    fill={ChartDataColor[id]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            pr: 2,
          }}
        >
          <List>
            {currentData.map((_cd: any, index: number) =>
              _cd.id > -1 ? (
                <ChartDataList key={`_cd${_cd.id}`} {..._cd} />
              ) : (
                <Divider />
              )
            )}
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            height: 64,
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 22,
              letterSpacing: 3,
              mx: 'auto',
            }}
          >
            {'Total : ' + _total}
          </Typography>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};

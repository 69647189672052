import { AccordionSummary, Typography, Box, Paper } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export const FormAccordionSummary = (props:any) => {
  const { Icon, FormJSX, title, FromSelect } = props;
  return (
    <Paper elevation={1}
    sx={{ m:0,p:0, pr:1, backgroundColor: 'primary.main', borderRadius: 2,}}
    >
      <AccordionSummary
        sx={{ m:0,p:0, pr:1, backgroundColor: 'transparent', borderRadius: 2, minHeight: 48, '&.Mui-expanded': { minHeight: 48 }}}
        expandIcon={<ExpandMoreRoundedIcon sx={{ fontSize: 32, }} />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
        <Box sx={{ ml: 1 }}>
          <Icon sx={{ fontSize: 32, pt:.5,}}/>
        </Box>
        <Box sx={{ pl: 1, pt: 1, flexGrow: 1 }}>
          <Typography
           sx={{
            textTransform: 'uppercase',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: 2,
          }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ }}>
        {!!FormJSX && FormJSX}
        </Box>
      </AccordionSummary>
      </Paper>
  );
};

import { DepartmentForm } from 'pages/Companies/DepartmentForm';
import { useParams } from 'react-router';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import {
  EnumObjectType,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { IDepartmentViewModel } from 'Interfaces/ICompaniesConfig';
import { useGetObjectList } from 'functions/useGetObjectList';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';

const initialState: IDepartmentViewModel = {
  id: 'new',
  name: '',
  companyId: 'new',
  contactId: 'new',
  contact: {} as unknown as IEmployeeViewModel,
  phone: '',
  email: '',
  mainAddress: {
    id: 'new',
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
  },
  deliveryAddress: {
    id: 'new',
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
  },
  canDelete: false,
  isDirty: false,
  isActive: true,
  isDefault: false,
};

export const Department = () => {
  const { companyId, departmentId } = useParams();
  // STATES
  const [department, setDepartment] = useState<IDepartmentViewModel>(
    null as unknown as IDepartmentViewModel
  );
  const [employees, setEmployees] = useState<IEmployeeViewModel[]>(
   null as unknown as IEmployeeViewModel[]
 );
 const [departments, setDepartments] = useState<IDepartmentViewModel[]>(
  null as unknown as IDepartmentViewModel[]);

  // api

  let isEnabled = departmentId !== undefined && departmentId !== 'new' ? true : false;
  const { data:departmentsData } = useGet(rq.departments(companyId || '',isEnabled), isEnabled);
  const { data: departmentData } = useGet(
  rq.department(companyId || '',departmentId || '', isEnabled),
  isEnabled
);
const { data: employeeData } = useGet(
  rq.employees(companyId || '', isEnabled),
  isEnabled
);
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let employeeId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
     // update objectList
     if (employeeData !== null) {
      let _companyEmployees = _.cloneDeep(employeeData?.allEmployees || []);
      setEmployees(_companyEmployees);
      objectDrawerList[EnumObjectType.CompanyEmployees].objectList = _companyEmployees;
    }

      // set selected Resource  
      if (departmentId === 'new') {
        
        setDepartment(initialState);

      } 
      else {
      let tmp = _.cloneDeep(departmentData);  
      setDepartment(tmp);
    }

      // set departmens
    if (departmentsData !== null) {
      let tmp = _.cloneDeep(departmentsData?.departments);
      setDepartments(tmp);
      objectDrawerList[EnumObjectType.Department].objectList = tmp;
    }

    if (departmentsData !== null && employeeData !== null && departmentsData !== null) {
      setIsObjectList(true);
    } else {
      setIsObjectList(false);
    }
  }, [objectDrawerList, employeeData, departmentsData, departmentData]);

  //console.log("Department", departmentId, companyId);
  
  const { data: company } = useGet(rq.company(companyId, true), true);

  if (department === null || department === undefined) {
    return <div>loading...</div>;
  }
    return (
      <DepartmentForm
        department={department}
        objectDrawerList={objectDrawerList}
        company={company}
        setIsObjectList={setIsObjectList}
      />
    );
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, Avatar, Stack, Typography } from '@mui/material';
// @ts-ignore
import GetAvatarInitials from 'functions/GetAvatarInitials';
// @ts-ignore
import GetColorByName from 'functions/GetColorByName';
import { IPackageViewModel } from '../../Interfaces/IPackageConfig';
import { MissingPhoto } from '../../Interfaces/IGlobalTypeConfig';

type Props = {
  packageData: IPackageViewModel;
};
export const SinglePackageView = (packageData: Props) => {
  return (
    <Grid
      container
      direction={'row'}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(
          `${packageData.packageData?.name}` || 'default'
        ),
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid item sx={{ ml: -0.1, width: 80, height: 80 }}>
        <img
          src={MissingPhoto.PRODUCT_URL}
          style={{
            width: '100%',
            height: '100%',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            objectFit: 'scale-down',
          }}
          alt={'logo'}
        />
      </Grid>

      <Grid
        item
        flexGrow={1}
        sx={{
          pl: 1,
        }}
      >
        <Stack direction={'column'}>
          <Typography
            sx={{
              letterSpacing: 2,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {packageData.packageData?.name}
          </Typography>
          <Typography
            sx={{
              height: 24,
              letterSpacing: 3,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 16,
            }}
          >
            {/*   +47 {_package?.phone} */}
          </Typography>
          <Typography
            sx={{
              height: 24,
              letterSpacing: 3,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 16,
            }}
          >
            {/* {_package?.email} */}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export const FormTextMultiline = (props: any) => {
  return (
    <Controller
      {...props}
      render={({
        field: { onChange, ref, value, onBlur },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          fullWidth
          className='subvariant-hovered'
          sx={{ m: 0, p: 0, width: '100%' }}
          inputRef={ref}
          helperText={error ? error.message : null}
          error={!!error}
          onChange={(e) => {
            if (props.onChange) props.onChange(e);
            onChange(e);
          }}
          onBlur={(e) => {
            if (props.onBlur) props.onBlur(e);
            onBlur();
          }}
          value={props.value ? props.value : value}
          label={props.label}
          variant='filled'
          multiline={true}
          minRows={props.minRows ? props.minRows : 3}
        />
      )}
    />
  );
};

import React, { useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import { SubHeading } from 'components/ui/SubHeading';
import useWindowsDimension from 'functions/useWindowsDimension';
import { ChartTypeBar } from 'components/ui/ChartTypeBar';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { useNavigate, useParams } from 'react-router';
import { ChartSingleView } from 'components/ui/ChartSingleView';
import { ISingleData } from 'pages/Finance/IFinanceConfig';

export const CompanyFinance = () => {
  // INITIALIZATION
  const { height } = useWindowsDimension();
  let navigate = useNavigate();
  const { companyId } = useParams();

  // CONST
  const [turnoverExtended, setTurnoverExtended] = React.useState(
    {} as ISingleData
  );
  let isEnabled = companyId !== 'new' ? true : false;
  let thisYear = new Date().getFullYear();
  const { data: economyTurnoverData } = useGet(
    rq.economyPeriod(companyId || '', thisYear - 1, thisYear + 1, isEnabled),
    isEnabled
  );

  //console.log('economyTurnoverData', economyTurnoverData);
  useEffect(() => {
    if (economyTurnoverData) {
      let tempData: ISingleData = {
        header: 'Turnover This Year',
        oldValue:
          economyTurnoverData[0].period.agreements +
          economyTurnoverData[0].period.investment +
          economyTurnoverData[0].period.other +
          economyTurnoverData[0].period.support,
        value:
          economyTurnoverData[1].period.agreements +
          economyTurnoverData[1].period.investment +
          economyTurnoverData[1].period.other +
          economyTurnoverData[1].period.support,
        extendedData: {
          header: 'Turnover',
          lines: [
            {
              name: 'Agreements',
              oldValue: economyTurnoverData[0].period.agreements,
              value: economyTurnoverData[1].period.agreements,
            },
            {
              name: 'Investment',
              oldValue: economyTurnoverData[0].period.agreements,
              value: economyTurnoverData[1].period.investment,
            },
            {
              name: 'Other',
              oldValue: economyTurnoverData[0].period.agreements,
              value: economyTurnoverData[1].period.other,
            },
            {
              name: 'Support',
              oldValue: economyTurnoverData[0].period.agreements,
              value: economyTurnoverData[1].period.support,
            },
          ],
        },
      };
      setTurnoverExtended(tempData);
      //console.log('tempData', tempData);
    }
  }, [economyTurnoverData]);

  let teller = 2003;
  const chartAnnualData = [
    {
      id: teller++,
      name: teller++,
      support: 2400,
      agreements: 3200,
      sale: 2400,
      other: 2380,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 3000,
      agreements: 3300,
      sale: 1398,
      other: 2310,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 0,
      agreements: 3490,
      sale: 2900,
      other: 2290,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 2780,
      agreements: 3400,
      sale: 3908,
      other: 2000,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 1890,
      agreements: 3450,
      sale: 2480,
      other: 2381,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 2390,
      agreements: 3550,
      sale: 3800,
      other: 0,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 3490,
      agreements: 3640,
      sale: 0,
      other: 2310,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 2780,
      agreements: 3700,
      sale: 3908,
      other: 2000,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 1890,
      agreements: 3688,
      sale: 2480,
      other: 2381,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 0,
      agreements: 3750,
      sale: 2900,
      other: 2290,
      rent: 1200,
    },
  ];
  teller = 0;
  const currentAnnualData = [
    {
      id: teller++,
      name: 'agreements',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.agreements),
        0
      ),
    },
    {
      id: teller++,
      name: 'support',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.support),
        0
      ),
    },

    {
      id: teller++,
      name: 'sale',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.sale),
        0
      ),
    },
    {
      id: teller++,
      name: 'rent',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.rent),
        0
      ),
    },
    {
      id: teller++,
      name: 'other',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.other),
        0
      ),
    },
  ];
  teller = -1;

  //console.log("Finance", ticketList);
  // handles
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        m: 0,
        height: Math.ceil(height - 124),
      }}
    >
      {turnoverExtended && turnoverExtended.header && (
        <ChartSingleView
          header={turnoverExtended.header}
          oldValue={turnoverExtended.oldValue}
          value={turnoverExtended.value}
          extendedData={turnoverExtended.extendedData}
        />
      )}

      <Grid
        item
        xs={6}
        sx={{
          width: '100%',
          height: height / 2 - 126,
          p: 0,
        }}
      >
        <Paper variant='elevation3' sx={{ height: 410 }}>
          <ChartTypeBar
            key='0'
            version={0}
            header={'Revenue This Year'}
            chartData={chartAnnualData}
            currentData={currentAnnualData}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

import * as React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { IMobileAnswersViewModel } from 'Interfaces/IGlobalTypeConfig';
import { useTranslation } from 'react-i18next';

type Props = {
  autoAnswer: IMobileAnswersViewModel;
};

export const SingleAutoAnswerView = ({ autoAnswer }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction={'row'}
      sx={{       
        p: 1,
        mb:1,
        borderLeft: 8,
        borderRadius: 2,
        backgroundColor: 'rgba(0,0,0,0.2)',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
        borderColor: GetColorByName(`${autoAnswer.id}`),
        height: 80,
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            m: 0,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            letterSpacing: 2,
            fontSize: 14,
          }}
        >
          {autoAnswer.english}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Stack
          direction={'row-reverse'}
          spacing={0}
          sx={{ textAlign: 'right' }}
        >
          {/*  <Typography
         sx={{
           m: 0,
           textAlign: 'right',
           overflow: 'hidden',
           textOverflow: 'ellipsis',
           whiteSpace: 'nowrap',
           fontWeight: 'bold',
           letterSpacing: 1,
           fontSize: 14,
           color: 'secondary.text',
           width: 75,
         }}
       >
         {workRole.inHouseHourlyRate.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
       </Typography>
 */}
          <Typography
            sx={{
              m: 0,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              letterSpacing: 1,
              fontSize: 12,
            }}
          >
           {t("SingleAutoAnswer.inHouse")}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={4}>
        <Stack
          direction={'row-reverse'}
          spacing={0}
          sx={{ textAlign: 'right' }}
        >
          {/* <Typography
            sx={{
              m: 0,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 14,
              color: 'secondary.text',
              width: 75,
            }}
          >
            {workRole.onSiteHourlyRate.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography> */}

          <Typography
            sx={{
              m: 0,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              letterSpacing: 1,
              fontSize: 12,
            }}
          >
            {t("SingleAutoAnswer.onSite")}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import moment from 'moment-timezone';
var _ = require('lodash');

export const FormTime = (props) => {
  const { control, name, label, errors, inputFormat, onTimeChange, timezone } =
    props;
  const errorMessage = _.get(errors, name);
  const selectedTimezone = moment.tz.guess();
  const displayTimezone = timezone || selectedTimezone;
  //console.log('controller', control);

  const handleTimeChange = (time) => {
    if (time) {
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      const formattedTime = moment
        .tz({ hours, minutes, seconds }, displayTimezone)
        .format();
      onTimeChange(formattedTime);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value = null },
            fieldState: { error },
          }) => (
            <DesktopTimePicker
              //  label={label}
              inputFormat={inputFormat}
              mask=''
              ampm={false}
              value={props.value ? props.value : value}
              onChange={(e) => {
                onChange(e);
                handleTimeChange(e);
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 0, p: 0 }}
                  {...params}
                  label={label}
                  error={!!error}
                  helperText={errorMessage?.message}
                />
              )}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

import { Divider, Grid, List, Typography } from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ChartDataColor } from 'data/variables';

import { useRef } from 'react';
import { ChartDataList } from './ChartDataList';

function getTotal(currentData: any) {
  let _value = 0;
  currentData.map((_cd: any) => (_value += Number(_cd.value)));
  return _value;
}

export const ChartTypeBar = (props: any) => {
  /*  console.log("ChartDataList", props); */
  const { header, chartData, currentData } = props;

  const _total = getTotal(currentData);
  return (
    <ResponsiveContainer>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            height: 64,
          }}
        >
          <Typography
            sx={{
              p: 2,
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 18,
              letterSpacing: 3,
            }}
          >
            {header}
          </Typography>
        </Grid>
        <Grid
          item
          xs={10}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <ResponsiveContainer width='100%' height='94%'>
            <BarChart
              key={'B2'}
              className='BarChart'
              margin={{
                top: 10,
                right: 10,
                left: 10,
                bottom: 10,
              }}
              data={chartData}
            >
              <CartesianGrid stroke='#343a40' strokeDasharray='5 5' />
              <XAxis dataKey='name' stroke='#f8f9fa' />
              <YAxis stroke='#f8f9fa' />
              <Tooltip />
              <Legend />
              {currentData.map(
                (pd: any, index: number) =>
                  pd.value > 0 && (
                    <Bar
                      key={`bar-${pd.id}`}
                      id={pd.id}
                      dataKey={pd.name}
                      stackId='a'
                      fill={ChartDataColor[index]}
                      //label
                    />
                  )
              )}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            pr: 2,
          }}
        >
          <List>
            {currentData.map((_cd: any, index: number) =>
              _cd.id > -1 ? (
                <ChartDataList key={`_cd${_cd.id}`} {..._cd} />
              ) : (
                <Divider />
              )
            )}
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            height: 64,
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 22,
              letterSpacing: 3,
              mt: 0,
            }}
          >
            {'Total : ' + _total}
          </Typography>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};

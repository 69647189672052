import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
var _ = require("lodash");
// https://blog.logrocket.com/using-material-ui-with-react-hook-form/
// https://codesandbox.io/s/react-hook-form-controller-601-j2df5?file=/src/MuiAutoComplete.js

export const FormAutocompleteFeature = (props:any) => {
  const { control, name, label, list, errors } = props;
  const errorMessage = _.get(errors, name);
  //console.log("value", list);

  let nullValue = "";
  let value1;
  return (
    <>
      <Controller
        render={({
          field: { onChange, ref, value },
          fieldState: { error },
          //formState: { errors },
          //...props
        }) => (
          <Autocomplete
            /* {...field} */
            {...props}
            options={list}
            getOptionLabel={(option:any) => option.description || ""}
            isOptionEqualToValue={(option, value:any) =>
              option.description === value.description
            }
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.description}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{ m: 0, p: 0 }}
                {...params}
                inputRef={ref}
                variant="standard"
                error={!!error}
                //helperText={error ? error.message : console.log("errors", props)}
                helperText={errorMessage?.message}
                label={label}
              />
            )}
            onChange={(_, data) =>
              data ? (onChange(data), (value1 = data)) : onChange(nullValue)
            }
            //value={value}
            //defaultValue={props.showValue}
            defaultValue={{ id: { name }, description: "" }}
            //defaultValue={value ? value : nullValue}
          />
        )}
        
        
         name={name}
        control={control}
      />
    </>
  );
};

import {
  Paper,
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Header } from 'data/FontStyle';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HeaderButtonInterface } from 'Interfaces/IGlobalTypeConfig';

type Props = {
  Icon: any;
  title: string;
  FormJSX: any;
  HeaderButtons: HeaderButtonInterface[];
};

export const PageHeader = ({ Icon, title, FormJSX, HeaderButtons }: Props) => {
  /* INITIALIZATIONS */
  const { width } = useWindowsDimension();
  const navigate = useNavigate();
  /* FUNCTIONS */
  let isHelp = false;
  return (
    <Grid item xs={12}>
    <Paper
      elevation={0}
      sx={{
        height: 96,
        backgroundColor: 'primary.main',
        borderRadius: 2,
        mb: 1,
      }}
    >
      <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
        <Grid item sx={{}}>
          {!!Icon && <Icon sx={Header.icon} />}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={Header.text}>{title}</Typography>
        </Grid>
        <Grid item sx={{ mr: 1 }}>
          {isHelp ? (
            <HelpIcon sx={{ fontSize: 38, m: 1, color: 'secondary.text' }} />
          ) : (
            <HelpOutlineIcon
              sx={{ fontSize: 38, m: 1, color: 'secondary.text' }}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ p: 0.5 }}>
          <ToggleButtonGroup
            sx={{
              width: '100%',
              ml: 0.5,
              mt: -0.3,
              p: 0,
            }}
            color='secondary'
            size='small'
            orientation='horizontal'
            // value={currentView}
            exclusive
            aria-label='text alignment'
          >
            {HeaderButtons.map((obj, index) => {
              //console.log('headerlink object', obj);
              return (
                <ToggleButton
                  key={index}
                  name={obj.name}
                  onClick={() => navigate(obj.to)}
                  // Link={obj.to}
                  sx={{
                    m: 0,
                    p: 0,
                    width: 225,
                    borderRadius: 2,
                    borderLeft: 2,
                    borderRight: 2,
                    borderLeftColor: 'primary.light',
                    borderRightColor: 'primary.light',
                  }}
                  value={index}
                  aria-label='left aligned'
                >
                  {obj.icon && <obj.icon sx={{ mr: 1 }} />}
                  {obj.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Paper>
    </Grid>
  );
};

import { ProductCard } from "components/ui/ProductCard";
import useWindowsDimension from "functions/useWindowsDimension";
import { newCompany, newProduct } from "data/templates";

import { Box, Grid, Paper, Typography } from "@mui/material";
import DevicesOtherRoundedIcon from "@mui/icons-material/DevicesOtherRounded";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";

const Header = (props) => {
  const { icon: IH, action: AH, headerText: HT } = props;
  return (
    <Box
      sx={{
        display: "flex",
        pb:2,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <IH sx={{ fontSize: 40 }} />
      </Box>
      <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
        <Typography noWrap variant="subHeader">
          {HT}
        </Typography>
      </Box>
      <Box sx={{}}>{AH && <AH sx={{ fontSize: 40 }} />}</Box>
    </Box>
  );
};

export const SaleProducts = () => {
  const { width, height } = useWindowsDimension();

  return (
    <>
      <Paper
        variant="elevation3"
        sx={{
          height: Math.ceil(height - 226),
          ml: 2,
          p:2,
          backgroundColor: "primary.main",
        }}
      >
        <Header icon={DevicesOtherRoundedIcon} headerText="product" />
        
        <ProductCard product={newProduct} />
        
      </Paper>
    </>
  );
};

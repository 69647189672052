import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
var _ = require("lodash");
// https://blog.logrocket.com/using-material-ui-with-react-hook-form/
// https://codesandbox.io/s/react-hook-form-controller-601-j2df5?file=/src/MuiAutoComplete.js

export const FormAutocompleteCountry = (props:any) => {
  const { control, name, label, list, errors } = props;
  const errorMessage = _.get(errors, name);
  //console.log('es', errorMessage)
  let nullValue = {
    code: "",
    label: "",
    phone: "",
  };
  return (
    <Controller
      render={({
        field: { onChange, ref, value },
        fieldState: { error },
        //formState: { errors },
        //...props
      }) => (
        <Autocomplete
          /* {...field} */
          {...props}
          options={list}
          //getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option:any, value:any) => option.label === value}
          renderOption={(props, option:any) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {/* <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              /> */}
              {option.label} ({option.code}) +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              sx={{ m: 0, p: 0 }}
              {...params}
              inputRef={ref}
              variant="standard"
              error={!!error}
              //helperText={error ? error.message : console.log("errors", props)}
              helperText={errorMessage?.message}
              label={label}
            />
          )}
          onChange={(_, data) => (data ? onChange(data.label) : onChange(""))}
          value={value ? value : nullValue}
        />
      )}
      name={name}
      control={control}
    />
  );
};

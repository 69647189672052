import {
  Box,
  Divider,
  List,
  Paper,
  Typography,
} from "@mui/material";
import { ChartDataList } from "./ChartDataList";

function getTotal(currentData) {
  let _value = 0;
  currentData.map((_cd) => (_value += Number(_cd.value)));
  return _value;
}

export const ChartTypeSingle = (props) => {
  const { version, header, currentData } = props;
  const _total = getTotal(currentData);

  const chartSelected = [
    <List
      sx={{
        m: 0.5,
        p: 0.5,
        pr: 2,
        pb: 2,
      }}
    >
      {currentData.map((_cd) =>
        _cd.id > -1 ? (
          <ChartDataList key={`_cd${_cd.id}`} {..._cd} />
        ) : (
          <Divider />
        )
      )}
    </List>,
  ];

  return (
   
      <Paper
        elevation={3}
        sx={{
          mr: 2,
          mb: 2,
          pl: 2,
          pr: 2,
          borderRadius: 3,
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Box component="span" 
          sx={{ 
            display: "block",
            overflow: "hidden",
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            }}>
          <Typography
            sx={{
              textAlign: "center",
              textTransform: "uppercase",   
              fontWeight: "bold",
              fontSize: 18,
              letterSpacing: 3,
              mb: 0,
              pt: 1.5,
            }}
          >
            {header}
          </Typography>
        </Box>

        {chartSelected[version]}
      </Paper>
   
  );
};

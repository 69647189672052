import { Link, List, ListItem, ListItemText } from "@mui/material";

export const SaleAttachments = () => {
    return (
      <>
      <List>
        <ListItem button key={0} component={Link} to={`/sales/1/attachments`}>
            <ListItemText primary="Attachments 1" />
        </ListItem>
        <ListItem button key={1} component={Link} to={`/sales/1/attachments`}>
            <ListItemText primary="Attachments 2" />
        </ListItem>
        <ListItem button key={2} component={Link} to={`/sales/1/attachments`}>
            <ListItemText primary="Attachments 3" />
        </ListItem>
      </List>
      </>
    );
  };
  
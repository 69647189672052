import { msalInstance } from 'index';
import { itsRequest } from 'http/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

// ms graph user.read delegated permission, admin consent required: no

// fetchet nye tokens når accesstoken expiry løp ut

// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206

//I suspect this is happening when the access token has a longer validity than the id token

//Uses account from msalInstance.getActiveAccount() to get a new token silently,
//but should use certificate secret instead? see postman

export default async function getBearer() {
  const account = msalInstance.getActiveAccount();
  const expiry = account.idTokenClaims.exp;
  const refresh = new Date(expiry * 1000) < new Date();
  // console.log("getBearer refresh exp now", refresh, new Date(expiry * 1000), new Date());
  let bearer = null;

  /*  if (!account) {
    console.log("No account found, calling login");
    await msalInstance.loginRedirect(itsRequest);
  } */

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...itsRequest,
      account: account,
      forceRefresh: refresh,
    });
    bearer = `Bearer ${response.idToken}`;
    // bearer = `Bearer ${response.accessToken}`;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance
        .loginRedirect(itsRequest)
        .catch((err) => {
          console.log('Failed to request new login:', err);
        });
      if (response) {
        bearer = `Bearer ${response.idToken}`;
      }
    } else {
      console.log('Not InteractionRequiredAuthError', error);
    }
  }

  //console.log("bearer", bearer);
  return bearer;
}

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled, alpha } from '@mui/material/styles';
import {  Typography, Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { FormSelect } from 'components/form';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const StatusFilter = (props:any) => {
  // export const SearchFilter = (props) => {
  const {
    Icon,
    Header,
    name,
    control,
    list,
    statusValue,
    handleChangeStatus,
    handleNew,
    selectList,
    gfx,
  } = props;

  return (
    <>
      <AppBar
        position='static'
        sx={{
          m: 0,
          p: 0,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          borderRadius: 2,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            }}
        >
          {!!Icon && <Icon sx={{ fontSize: 40, mr: 2 }} />}

          <Typography
            sx={{
              textTransform: 'capitalize',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
              fontSize: 18,
              letterSpacing: 4,
              flexGrow: 1,
            }}
          >
            {Header}
          </Typography>
          <Box sx={{ width: 250 }}>
            {!!list && (
              <FormSelect
                name='status'
                control={control}
                label='Status'
                list={list}
                statusValue={statusValue}
                handleFilterChange={handleChangeStatus}
              />
            )}
          </Box>
          <Box>
            {!!gfx && gfx}
            {!!handleNew && (
              <Button
                sx={{ mt: 0.5, mx: 'auto' }}
                variant='contained'
                onClick={(e) => {
                  handleNew(e);
                }}
              >
                <AddRoundedIcon sx={{ fontSize: 32 }} />
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

import {
  Paper,
  Grid,
  IconButton,
  Box,
  Typography,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  EnumObjectType,
  EnumPlacement,
  MissingPhoto,
} from 'Interfaces/IGlobalTypeConfig';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetColorByName from 'functions/GetColorByName';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import { G } from '@react-pdf/renderer';
type ProductCardEmployeeViewProps = {
  companyProduct: ICompanyProductViewModel;
  objectType: EnumObjectType;
  location: EnumObjectType;
  handleRemoveProduct: (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => void; // Adjust the argument type as needed
  height?: number; // Adjust the argument type as needed
};

export const ProductCardEmployeeView: React.FC<
  ProductCardEmployeeViewProps
> = ({ companyProduct, handleRemoveProduct, objectType, location, height }) => {
  const dynamicFontSize = height ? `${height / 5}px` : '16px'; // Example calculation
  const dynamicImageSize = height ? `${height}px` : '80px';
  return (
    <Paper
      variant='elevation3'
      sx={{
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(
          `${companyProduct?.product?.productType?.name || 'no name detected'}`
        ),
        backgroundColor: 'primary.back',
        width: '100%',
        height: '100%',
        maxHeight: !height ? 80 : height,
      }}
    >
      <Grid
        container
        display={'flex'}
        sx={{ flexDirection: 'row', width: '100%', m: 0, p: 0 }}
      >
        {/*  IMAGE  */}
        <Grid
          item
          sx={{
            mx: 'auto',
            width: dynamicImageSize,
            height: dynamicImageSize,
            backgroundColor: 'primary.light',
          }}
        >
          <img
            src={companyProduct.product?.url || MissingPhoto.PRODUCT_URL}
            alt='previewUrl'
            style={{
              width: dynamicImageSize,
              height: dynamicImageSize,
              borderRadius: 3,
              objectFit: 'contain',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
          />
        </Grid>
        {/*  NAME  */}
        <Grid
          item
          sx={{
            flexGrow: 1,
            pl: 1,
          }}
        >
          <Stack direction={'column'}>
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.product?.name}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.product?.productType?.name}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.serviceTag || 'no service tag detected'}
            </Typography>
          </Stack>
        </Grid>
        {/*  PRICE  */}
        <Grid
          item
          xs={5}
          sx={{
            pl: 1,
            pr: 1,
          }}
        >
          <Stack direction={'column'}>
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.product?.brand?.brandName || 'No Brand'}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.status}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: dynamicFontSize,
                color: 'secondary.text',
              }}
            >
              {companyProduct?.retailPrice?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
        </Grid>

        <Box sx={{}}>
          <Grid
            container
            spacing={0}
            sx={{
              pr: 1,
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              alignItems: 'flex-center',
              alignContent: 'flex-center',
              justifyContent: 'flex-center',
            }}
          >
            <Tooltip
              title={
                companyProduct.id?.startsWith('new') ||
                objectType === EnumObjectType.Product ||
                objectType === EnumObjectType.StorageProduct ||
                objectType === EnumObjectType.unassignedCompanyProduct
                  ? 'Delete Forever'
                  : location === EnumObjectType.Company &&
                    companyProduct.placement === (3 as EnumPlacement)
                  ? 'Disable Domain'
                  : location === EnumObjectType.Company &&
                    companyProduct.placement !== (3 as EnumPlacement)
                  ? 'Add Domain'
                  : location === EnumObjectType.Resource &&
                    companyProduct.placement === (4 as EnumPlacement)
                  ? 'Turn Off Location'
                  : location === EnumObjectType.Resource &&
                    companyProduct.placement !== (4 as EnumPlacement)
                  ? 'Add Location'
                  : location === EnumObjectType.CompanyEmployees &&
                    companyProduct.ownerId
                  ? 'Remove Person'
                  : 'Add Person'
              }
              arrow
            >
              <IconButton
                aria-label='button-action'
                sx={{
                  mt: 3,
                  elevation: 2.8,
                  width: 40,
                  height: 40,
                  mx: 'auto',
                  backgroundColor: 'primary.back',
                  ':hover': {
                    cursor: 'pointer',
                    filter: 'brightness(120%)',
                    color: 'white',
                    backgroundColor: 'primary.light',
                    transition: 'all 0.2s ease-in-out',
                  },
                  boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
                }}
                onClick={() => handleRemoveProduct(companyProduct, objectType)}
              >
                {companyProduct.id?.startsWith('new') ||
                objectType === EnumObjectType.Product ||
                objectType === EnumObjectType.StorageProduct ||
                objectType === EnumObjectType.unassignedCompanyProduct ? (
                  <DeleteForeverIcon
                    sx={{ fontSize: 32, color: 'warning.light' }}
                  />
                ) : location === EnumObjectType.Company &&
                  companyProduct.placement === (3 as EnumPlacement) ? (
                  <DomainDisabledIcon
                    sx={{ fontSize: 32, color: 'secondary.light' }}
                  />
                ) : location === EnumObjectType.Company &&
                  companyProduct.placement !== (3 as EnumPlacement) ? (
                  <DomainAddIcon sx={{ fontSize: 32, color: 'info.light' }} />
                ) : location === EnumObjectType.Resource &&
                  companyProduct.placement === (4 as EnumPlacement) ? (
                  <LocationOffRoundedIcon
                    sx={{ fontSize: 32, color: 'secondary.light' }}
                  />
                ) : location === EnumObjectType.Resource &&
                  companyProduct.placement !== (4 as EnumPlacement) ? (
                  <AddLocationAltRoundedIcon
                    sx={{ fontSize: 32, color: 'info.light' }}
                  />
                ) : location === EnumObjectType.CompanyEmployees &&
                  companyProduct.ownerId ? (
                  <PersonOffRoundedIcon
                    sx={{ fontSize: 32, color: 'secondary.light' }}
                  />
                ) : (
                  <PersonAddRoundedIcon
                    sx={{ fontSize: 32, color: 'info.light' }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
};

import { Button, Grid, List, Paper, Stack, Typography } from "@mui/material";
import { SearchFilter } from "components/ui/SearchFilter";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import useWindowsDimension from "functions/useWindowsDimension";
import { useEffect, useState } from "react";
import { useGet } from "http/useInnovit";
import { rq } from "http/apiRoutes";
import {IInvoiceViewModel } from "Interfaces/BillingConfig";
import { useTranslation } from 'react-i18next';

export const Billed = () => {
const { t } = useTranslation();

//Initalization

const { height } = useWindowsDimension();
const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
const [billedPosts, setBilledPosts] = useState<IInvoiceViewModel[]>(null as unknown as IInvoiceViewModel[]);

//API

const {data: billedData} = useGet(rq.getPayedBillingPosts(true),true);


useEffect(() => {
    if (billedData ) {
        setBilledPosts(billedData.invoicePost)
    }
  }, [billedData]);




//Useffects

//Handlers
    const onSubmit = () => {

      };
return (
    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row' }}>
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pl: 1, m: 0 }}>
        <SearchFilter Icon={CreditCardIcon} Header={t("Billing.Billed.billedHeader")} />
        <List
          sx={{
            p: 2,
            height: Math.ceil(height - 262),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
              <Grid
                sx={{
                  backgroundColor: 'transparent',
                  border: 0,
                  boxShadow: 'none',
                }}
              >
              
              </Grid>
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>

      {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ pl: 1, m: 0 }}>
        <SearchFilter Icon={CreditCardIcon} Header={t("Billing.Billed.selectedOrder")} />
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            p: 2,
            backgroundColor: 'primary.main',
          }}
        >
          <Grid item xs={6} sx={{}}>
          
          </Grid>
          <Grid item xs={2} sx={{}}></Grid>
          <Grid item xs={4} sx={{ m: 0, p: 0, pr: 1 }}>
            <Stack direction={'column'} sx={{}}>
              <Stack direction={'row'} sx={{}}>
                <Typography
                  sx={{ m: 1, pt: 0.5, fontWeight: 'bold', width: '60%' }}
                >
                {t("Billing.Billed.totalCost")}:
                </Typography>
                <Typography
                  sx={{
                    m: 1,
                    pt: 0.5,
                    width: '40%',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                </Typography>
              </Stack>
              <Stack direction={'row'} sx={{}}>
                <Typography
                  sx={{ m: 1, pt: 0.5, fontWeight: 'bold', width: '60%' }}
                >
                {t("Billing.Billed.shippingCost")}:
                </Typography>
                <Typography
                  sx={{
                    m: 1,
                    pt: 0.5,
                    width: '40%',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <List
          sx={{
            p: 2,
            height: Math.ceil(height - 382),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
                <Grid
                  sx={{
                    backgroundColor: 'transparent',
                    border: 0,
                    boxShadow: 'none',
                  }}
                >
                
                </Grid>
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              p: 0,
              m: 0,
              mb: 2,
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              variant='contained'
              //type='submit'
              onClick={onSubmit}
              disabled={isButtonDisabled}
            >
             {t("Billing.Billed.updateOrder")}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
import dayjs, { Dayjs } from 'dayjs';


export default function CalculateTotalTime(
    startTime: dayjs.Dayjs | string | Date | null,
    endTime: dayjs.Dayjs | string | Date | null,
    startDeduction: dayjs.Dayjs | string | Date | null,
    endDeduction: dayjs.Dayjs | string | Date | null
  ): import('react').ReactNode {
    //console.log('CalculateTotalTime',endTime);
    const convertToDayjs = (
      input: dayjs.Dayjs | string | Date | null
    ): dayjs.Dayjs | null => {
      if (dayjs.isDayjs(input)) return input;
      if (typeof input === 'string' || input instanceof Date)
        return dayjs(input);
      return null;
    };

    startTime = convertToDayjs(startTime);
    endTime = convertToDayjs(endTime);
    startDeduction = convertToDayjs(startDeduction);
    endDeduction = convertToDayjs(endDeduction);

    if (!startTime || !endTime || startTime.isAfter(endTime)) {
      if (
        startDeduction &&
        endDeduction &&
        !startDeduction.isAfter(endDeduction)
      ) {
        const deductionDuration = endDeduction.diff(startDeduction, 'minute');

        const hours = Math.floor(deductionDuration / 60);
        const minutes = deductionDuration % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0'
        )}`;
      } else return '00:00';
    }

    let totalDuration = endTime.diff(startTime, 'minute');

    if (
      startDeduction &&
      endDeduction &&
      !startDeduction.isAfter(endDeduction)
    ) {
      const overlapStart = startDeduction.isAfter(startTime)
        ? startDeduction
        : startTime;
      const overlapEnd = endDeduction.isBefore(endTime)
        ? endDeduction
        : endTime;

      if (!overlapStart.isAfter(overlapEnd)) {
        const deductionDuration = overlapEnd.diff(overlapStart, 'minute');
        totalDuration -= deductionDuration;
      }
    }

    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}`;
  }
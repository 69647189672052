import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { variables } from "data/variables";
import GetAvatarInitials from "functions/GetAvatarInitials";

export const PersonList = (props) => {
  const {person, action: AH} = props;
  return (
    <Paper 
      variant="elevation3"
      sx={{ ml: 1, mr: 1, mb: 1, }}
      >
    <ListItem
      sx={{
        m: 0,
        p: 0,
        borderColor: "primary.dark",
        borderRadius: 2,
      }}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        {person?.logo ? (
          <Avatar
            sx={{
              m: 0,
              p: 0,
              border: 1,
              borderColor: "primary.main",
              backgroundColor: "primary.light",
              borderRadius: 2,
              alignItems: "center",
              width: 48,
              height: 48,
            }}
            variant="rounded"
            src={variables.PHOTO_URL + person.logo + ".png"}
            alt={person.name}
          />
        ) : (
          <Avatar {...GetAvatarInitials(person.name, 48, 48, 2)} />
        )}
      </ListItemAvatar>
      <ListItemText
        sx={{
          ml: 2,
        }}
        primary={
          <Typography noWrap sx={{ width: 180, fontWeight: "bold" }}>
            {person.name}
          </Typography>
        }
        secondary={<Typography sx={{}}>{person.email}</Typography>}
      />

      <ListItemText
        sx={{
          ml: 2,
        }}
        primary={
          <Typography noWrap sx={{ fontWeight: "bold" }}>
            {person.phone}
          </Typography>
        }
        secondary={
          <Typography sx={{}}>{`budget : ${person.budget}`}</Typography>
        }
      />
      {AH && <AH sx={{ fontSize: 26 }} />}      
    </ListItem>
    </Paper>
  );
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { IBrandViewModel } from 'pages/Setup/setupConfig';

type Props = {
  brand: IBrandViewModel;
  
};

export const SingleBrandView = ({ brand }: Props) => {
 
  return (
    <Grid
      container
      direction={'row'}
      sx={{
        mb: 1,
        p: 1,
        borderLeft: 8,
        borderRadius: 2,
        backgroundColor: 'primary.main',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
        borderColor: GetColorByName(`${brand.brandName}`),
        height: 36,
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            m: 0,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            letterSpacing: 2,
            fontSize: 14,
          }}
        >
          {brand.brandName}
        </Typography>
      </Grid>
    </Grid>
  );
};

import { Grid, Paper } from '@mui/material';
import { ChartTypeBar, ChartTypePie, SubHeading } from 'components/ui';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';

import useWindowsDimension from 'functions/useWindowsDimension';
import { ChartTriple } from 'components/ui/ChartTriple';

const Finance = () => {
  const { height } = useWindowsDimension();
  let SolvedTicketData = true;

  const tripleData = [
    {
      subHeader: 'Unassigned',
      value: 150,
      oldValue: 100,
      colorType: 0,
      trend: true,
    },
    {
      subHeader: 'In Progress',
      value: 80,
      oldValue: 100,
      colorType: 0,
      trend: true,
    },
    {
      subHeader: 'Solved',
      value: 80,
      oldValue: 80,
      colorType: 0,
      trend: true,
    },
    {
      subHeader: 'Total',
      value: 310,
      oldValue: 300,
      colorType: 0,
      trend: true,
    },
  ];

  let teller = 2003;
  const chartAnnualData = [
    {
      id: teller++,
      name: teller++,
      support: 2400,
      agreements: 3200,
      sale: 2400,
      other: 2380,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 3000,
      agreements: 3300,
      sale: 1398,
      other: 2310,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 0,
      agreements: 3490,
      sale: 2900,
      other: 2290,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 2780,
      agreements: 3400,
      sale: 3908,
      other: 2000,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 1890,
      agreements: 3450,
      sale: 2480,
      other: 2381,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 2390,
      agreements: 3550,
      sale: 3800,
      other: 0,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 3490,
      agreements: 3640,
      sale: 0,
      other: 2310,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 2780,
      agreements: 3700,
      sale: 3908,
      other: 2000,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 1890,
      agreements: 3688,
      sale: 2480,
      other: 2381,
      rent: 1200,
    },
    {
      id: teller++,
      name: teller++,
      support: 0,
      agreements: 3750,
      sale: 2900,
      other: 2290,
      rent: 1200,
    },
  ];
  teller = 0;
  const currentAnnualData = [
    {
      id: teller++,
      name: 'agreements',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.agreements),
        0
      ),
    },
    {
      id: teller++,
      name: 'support',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.support),
        0
      ),
    },

    {
      id: teller++,
      name: 'sale',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.sale),
        0
      ),
    },
    {
      id: teller++,
      name: 'rent',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.rent),
        0
      ),
    },
    {
      id: teller++,
      name: 'other',
      value: chartAnnualData.reduce(
        (total, currentValue) => (total = total + currentValue.other),
        0
      ),
    },
  ];
  teller = -1;

  //console.log("Finance", ticketList);
  // handles
  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={12}>
        <SubHeading
          icon={QueryStatsRoundedIcon}
          title='Finance'
          handleClick={handleClick}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          width: '100%',
          mb: 2,
          pr: 1,
        }}
      >
        {SolvedTicketData && (
          <ChartTriple
            header={'Tickets'}
            icon={FactCheckRoundedIcon}
            tripleData={tripleData}
          />
        )}
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          width: '100%',
          mb: 2,
          pl: 1,
        }}
      >
        {SolvedTicketData && (
          <ChartTriple
            header={'Tickets'}
            icon={FactCheckRoundedIcon}
            tripleData={tripleData}
          />
        )}
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          width: '100%',
          height: height / 2 - 126,
          mb: 2,
          pr: 1,
        }}
      >
        <Paper variant='elevation3' sx={{ height: 410 }}>
          <ChartTypeBar
            key='0'
            version={0}
            header={'Revenue This Year'}
            chartData={chartAnnualData}
            currentData={currentAnnualData}
          />
        </Paper>
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          width: '100%',
          height: height / 2 - 126,
          mb: 2,
          pl: 1,
        }}
      >
        <Paper variant='elevation3' sx={{ height: 410 }}>
          <ChartTypePie
            version={0}
            header={'version 0'}
            currentData={currentAnnualData}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Finance;

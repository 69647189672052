import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
import Grid from '@mui/material/Grid';
import { PageHeader } from 'components/ui/Global/PageHeader';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { CompanyHeaderButtonDetails } from './Components/CompanyStyleConfig';
import { useTranslation } from 'react-i18next';

export const Company = () => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const { width } = useWindowsDimension();
  let navigate = useNavigate();
  const { companyId } = useParams(); // check if companyId is valid?

  // api
  let isEnabled = false;
  const { data: company } = useGet(rq.company(companyId, isEnabled), isEnabled);

  const missingPhoto = {
    PHOTO_URL: process.env.PUBLIC_URL + '/img/noCompany.png',
  };

  if (companyId === undefined) {
    return null;
  }

  const CompanyHeaderLinks = CompanyHeaderButtonDetails(companyId, null);

  /* FUNCTIONS */

  return (
    <Grid container direction='row' spacing={0}>
      <Grid item xs={12} sx={{}}>
        <PageHeader
          Icon={BusinessRoundedIcon}
          title={t('company.header')}
          FormJSX={null}
          HeaderButtons={CompanyHeaderLinks}
        />
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

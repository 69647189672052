import { useState, useReducer, useEffect } from 'react';
import useWindowsDimension from 'functions/useWindowsDimension';
import 'moment-timezone';
import {
  FormTextMultiline,
  FormSwitch,
  FormAccordionSummary,
} from 'components/form';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  AccordionDetails,
  Accordion,
  Switch,
} from '@mui/material';

import { StatusFilter } from 'components/ui/StatusFilter';
import { useParams } from 'react-router';
import { SearchFilter } from 'components/ui/SearchFilter';
import { useRef } from 'react';
import { ticketStatus } from 'data/enum';
import { ticketCommunicationStatus } from 'data/enum';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
//@ts-ignore
import DocumentUploader from 'components/ui/DocumentUploader';
//@ts-ignore
import ConvertToAttachment from 'functions/ConvertToAttachment';
import _, { set } from 'lodash';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import userAtom from 'data/atoms/userAtom';
import { SingleMessage } from 'components/ui/tickets/SingleMessage';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import {
  ITicketCommunicationData,
  ITicketPostAddViewModel,
  ITicketPostUpdateViewModel,
  ITicketPostViewModel,
} from '../../Interfaces/ITicketConfig';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalculateTotalTime from 'functions/CalculateTotalTime';
import CalculateRateValue from 'functions/CalculateRateValue';
import {
  EnumScheduleType,
  IEmailModel,
  EnumObjectType,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { ScheduleView } from 'components/ui/ScheduleView';
import { getFilteredList } from 'functions/misc';
import ratesAtom from 'data/atoms/ratesAtom';
import { useGetObjectList } from 'functions/useGetObjectList';
import { useTranslation } from 'react-i18next';

const ConvertToTicketPost = (
  ticketCommunication: ITicketCommunicationData,
  selectedDocuments: any,
  user: any,
  ticketId: string,
  workRole: any
) => {
  let _ticketPost: ITicketPostAddViewModel = {
    ticketId: ticketId,
    status: ticketCommunication.status,
    startTime: ticketCommunication.startTime,
    endTime: ticketCommunication.endTime,
    startDeduction: ticketCommunication.startDeduction,
    endDeduction: ticketCommunication.endDeduction,
    billable:
      ticketCommunication.internal === true
        ? false
        : ticketCommunication.billable,
    overTime:
      ticketCommunication.internal === true
        ? false
        : ticketCommunication.overTime,
    isBilled: ticketCommunication.isBilled,
    internal: ticketCommunication.internal,
    isInHouse: ticketCommunication.isInHouse,
    direction: ticketCommunication.direction,
    authorId: user.id,
    receiverId: ticketCommunication.receiverId
      ? ticketCommunication.receiverId
      : '',
    message: ticketCommunication.message,
    attachments: ConvertToAttachment(selectedDocuments),
    workRoleId: workRole?.id,
    ticketPostBilling: ticketCommunication.ticketPostBilling,
  };

  return _ticketPost;
};

const ConvertToTicketPut = (
  ticketCommunication: ITicketCommunicationData,
  selectedDocuments: any,
  user: any,
  ticketId: string,
  workRole: any
) => {
  let _ticketPost: ITicketPostUpdateViewModel = {
    id: ticketCommunication.id || '',
    ticketId: ticketId,
    status: ticketCommunication.status,
    startTime: ticketCommunication.startTime,
    endTime: ticketCommunication.endTime,
    startDeduction: ticketCommunication.startDeduction,
    endDeduction: ticketCommunication.endDeduction,
    billable:
      ticketCommunication.internal === true
        ? false
        : ticketCommunication.billable,
    overTime:
      ticketCommunication.internal === true
        ? false
        : ticketCommunication.overTime,
    isBilled: ticketCommunication.isBilled,
    internal: ticketCommunication.internal,
    isInHouse: ticketCommunication.isInHouse,
    direction: ticketCommunication.direction,
    authorId: user.id,
    receiverId: ticketCommunication.receiverId
      ? ticketCommunication.receiverId
      : '',
    message: ticketCommunication.message,
    attachments: ConvertToAttachment(selectedDocuments),
    workRoleId: workRole?.id,
    ticketPostBilling: ticketCommunication.ticketPostBilling,
  };

  return _ticketPost;
};

const initialState: ITicketCommunicationData = {
  id: null,
  ticketId: '',
  status: 0,
  startTime: new Date(),
  endTime: new Date(new Date().getTime() + 15 * 60000),
  startDeduction: new Date(),
  endDeduction: new Date(),
  scheduleTime: {
    id: '0',
    scheduleType: EnumScheduleType.None,
    deliveryDate: new Date(),
    startDate: new Date(),
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  },
  billable: true,
  overTime: false,
  internal: false,
  isInHouse: true,
  direction: 1,
  authorId: '',
  author: null,
  receiverId: null,
  receiver: null,
  message: ' ',
  isBilled: false,
  workRoleId: null,
  workRole: null,
  ticketPostBillingId: null,
  ticketPostBilling: null,
  attachments: [],
};

const setWorkRole = (
  ticketWorkRole: IWorkRoleViewModel | null,
  companyWorkRole: IWorkRoleViewModel | null,
  userWorkRole: IWorkRoleViewModel | null,
  stateWorkRole: IWorkRoleViewModel | null
) => {
  if (stateWorkRole) {
    return stateWorkRole;
  }
  if (ticketWorkRole) {
    return ticketWorkRole;
  }
  if (companyWorkRole) {
    return companyWorkRole;
  }
  return userWorkRole;
};
interface OHjsxProps {
  onHold: boolean;
  setSendEmail: any;
}
interface IResetEndTimeObject {
  isEndTimeChanged: boolean;
  showResetMessage: boolean;
}

export const TicketCommunications = () => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const [owner] = useAtom(ownerAtom);
  const [rates] = useAtom(ratesAtom);
  const [user] = useAtom(userAtom);
  const { ticketId } = useParams<string>();
  const ref: any = useRef(0);
  const { height } = useWindowsDimension();
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [resetEndTimeObject, setResetEndTimeObject] =
    useState<IResetEndTimeObject>({
      isEndTimeChanged: false,
      showResetMessage: false,
    });

  useEffect(() => {
    // console.log('resetEndTimeObject', resetEndTimeObject);
  }, [resetEndTimeObject]);

  const formReducer = (state: any, action: any) => {
    switch (action.type.toUpperCase()) {
      case 'SET_RATES':
        return { ...state, rates: action.payload };
      case 'SET_TICKET_POST_BILLING':
        return { ...state, ticketPostBilling: action.payload };
      case 'CHANGE_WORK_ROLE':
        return { ...state, workRole: action.payload };
      case 'CHANGE_WORK_ROLE_ID':
        return { ...state, workRoleId: action.payload };
      case 'ASSIGN_POST':
        // console.log('assign post', action.payload);
        return {
          ...state,
          ...action.payload,
        };
      case 'ASSIGN_RECEIVER':
        return {
          ...state,
          receiver: action.payload,
          receiverId: action.payload.id,
        };
      case 'CHANGE_STATUS':
        //console.log('action', action.payload);
        if (
          action.payload === 4 ||
          action.payload === 5 ||
          action.payload === 6
        ) {
          setSendEmail(true);
        } else {
          setSendEmail(false);
        }
        return { ...state, status: action.payload };
      case 'CHANGE_START_TIME':
        return { ...state, startTime: dayjs(action.payload) };
      case 'CHANGE_START_DEDUCTION':
        return { ...state, startDeduction: dayjs(action.payload) };
      case 'CHANGE_END_DEDUCTION':
        return { ...state, endDeduction: dayjs(action.payload) };
      case 'CHANGE_END_TIME':
        return { ...state, endTime: dayjs(action.payload) };
      case 'CHANGE_BILLABLE':
        return { ...state, billable: action.payload };
      case 'CHANGE_IN_HOUSE':
        return { ...state, isInHouse: action.payload };
      case 'CHANGE_INTERNAL':
        return { ...state, internal: action.payload };
      case 'CHANGE_MESSAGE':
        return { ...state, message: action.payload };
      case 'CHANGE_OVERTIME':
        return { ...state, overtime: action.payload };
      case 'CHANGE_AUTO_ANSWER':
        return { ...state, message: action.payload };
      case 'CHANGE_ATTACHMENTS':
        return {
          ...state,
          attachments: [
            ...(state.ticketPost?.attachments || []),
            ...action.payload,
          ],
        };
      case 'SET_SCHEDULE_TIME':
        return {
          ...state,
          scheduleTime: {
            ...state.scheduleTime,
            ...action.payload,
          },
        };
      case 'RESET_FORM':
        return initialState;
      default:
        return state;
    }
  };
  // STATES
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);

  const [totalTime, setTotalTime] = useState<any>('00:00');
  const [totalDeductionTime, setTotalDeductionTime] = useState<any>('00:00');
  const [ticketPosts, setTicketPosts] = useState<ITicketPostViewModel[]>([]);
  const [ticket, setTicket] = useState<any>({});
  const [filterType, setFilterType] = useState<any>('all');
  const [workRoles, setWorkRoles] = useState<IWorkRoleViewModel[]>([]);

  // api
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let companyId = 'new';
  let employeeId = state?.receiver?.id ? state.receiver.id : 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
    setIsObjectList(false);
  }, [objectDrawerList]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let message = e.target.value;
    dispatch({
      type: 'CHANGE_MESSAGE',
      payload: message,
    });
  };
  const checkForOvertime = (hours: any, minutes: any) => {
    if (
      hours < rates[1].startHour ||
      (hours === rates[1].startHour && minutes < 0)
    ) {
      dispatch({ type: 'CHANGE_OVERTIME', payload: true });
    } else if (
      hours > rates[1].endHour ||
      (hours === rates[1].endHour && minutes > 14)
    ) {
      dispatch({ type: 'CHANGE_OVERTIME', payload: true });
    } else {
      dispatch({ type: 'CHANGE_OVERTIME', payload: false });
    }
  };
  let economy = false;
  // API
  let _ticketId: string = 'new';
  if (ticketId !== null || ticketId !== undefined) {
    _ticketId = ticketId as string;
  }
  let isEnabled = _ticketId !== 'new';
  const { data: ticketData } = useGet(
    rq.ticket(_ticketId, isEnabled, '0'),
    isEnabled
  );
  const postTicketPost = usePost(rqPost.ticketPost(_ticketId, '0'));
  const putTicketPost = usePut(rq.ticketPost(_ticketId, state?.id));
  const postEmail = usePost(rqPost.sendEmail(true));
  const putTicket = usePut(
    rqPost.ticketResponsible(_ticketId, isEnabled),
    isEnabled
  );
  const { data: ticketPostData } = useGet(rq.ticketPosts(_ticketId));
  isEnabled = owner.id ? true : false;
  const { data: workRoleData } = useGet(
    rq.workRoles(owner.id, isEnabled),
    isEnabled
  );
  const { data: ratesData } = useGet(rq.rates(isEnabled));

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {} as ITicketPostViewModel,
    // resolver: yupResolver(schema),
  });

  // HANDLES
  const handleAssignPost = (ticketPost: ITicketPostViewModel) => {
    dispatch({
      type: 'ASSIGN_POST',
      payload: ticketPost,
    });
    // console.log('ticketPost', ticketPost);
    if (ticketPost.workRole) {
      let _workRole = setWorkRole(
        ticket.workRole || null,
        ticketData.workRole || null,
        user.workRole || null,
        ticketPost.workRole
      );
      dispatch({
        type: 'CHANGE_WORK_ROLE',
        payload: _workRole,
      });
    }
  };
  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_STATUS',
      payload: e,
    });
  };
  const handleChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_START_TIME',
      payload: e,
    });
  };
  const handleChangeStartDeduction = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: 'CHANGE_START_DEDUCTION',
      payload: e,
    });
  };
  const handleChangeEndDeduction = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_END_DEDUCTION',
      payload: e,
    });
  };
  const handleChangeEndTime = (e: any) => {
    dispatch({
      type: 'CHANGE_END_TIME',
      payload: e,
    });
    // console.log('changeEndTime: ', e);
    let _totalTime = CalculateTotalTime(
      state.startTime,
      state.endTime,
      state.startDeduction,
      state.endDeduction
    );
    setTotalTime(_totalTime);
    let startMinutes = (e as any).$m;
    let startHours = (e as any).$H;
    checkForOvertime(startHours, startMinutes);
  };
  const handleChangeBillable = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_BILLABLE',
      payload: e,
    });
  };
  const handleChangeOvertime = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_OVERTIME',
      payload: e,
    });
  };
  const handleChangeInHouse = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_IN_HOUSE',
      payload: e,
    });
  };
  const handleChangeInternal = (e: any) => {
    dispatch({
      type: 'CHANGE_INTERNAL',
      payload: e,
    });
  };
  const handleChangeAutoAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_AUTO_ANSWER',
      payload: e,
    });
  };
  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('message', e);
    /*   dispatch({
      type: 'CHANGE_MESSAGE',
      payload: inputValue,
    }); */
  };
  const handleSearch = (e: any) => {
    setTicketPosts(
      getFilteredList(ticketPostData?.ticketPosts, e.target.value)
    );
    e.preventDefault();
  };
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('filter changed', e);
    ref.current = e;
  };
  const handleChangeAttachments = (attachments: any) => {
    dispatch({
      type: 'CHANGE_ATTACHMENTS',
      payload: attachments,
    });
  };
  const handleAssigneMe = () => {
    let ticketResponsible = {
      ticketId: state.ticketId,
      responsibleId: user.id,
    };
    putTicket.mutate(ticketResponsible);
    ticket.responsible = user;
  };
  const handleChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.WorkRole:
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: object });
        dispatch({ type: 'CHANGE_WORK_ROLE_ID', payload: object.id });

        break;
      default:
        break;
    }
  };
  const handleChangeSchedule = (changeType: string, e: any) => {
    /*   dispatch({
      type: 'SET_SCHEDULE_TIME',
      payload: scheduleTime,
    }); */
  };

  const addTimeToEndTime = (minutes: number) => {
    let newEndTime = dayjs(state.endTime).add(minutes, 'minute');
    // Create an object similar to what handleChangeEndTime expects

    handleChangeEndTime(newEndTime);
    setResetEndTimeObject((prevState: IResetEndTimeObject) => ({
      ...prevState,
      isEndTimeChanged: true,
      showResetMessage: false,
    }));
  };

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    // console.log('e', e.target);
    /*  if (user.id !== state.authorId && state.id !== null) {
      return;
    } */
    if (sendEmail) {
      let emailModel: IEmailModel = {
        ticketNr: ticket.ticketNo,
        status: 'Updated',
        startTime: state.startTime,
        endTime: state.endTime,
        contactName: state.receiver?.name || ' receiver name',
        contactEmail: state.receiver?.email || ' receiver email',
        authorName: user?.name || ' name',
        authorEmail: user?.email || ' email',
        authorPhone: user?.phone || ' phone',
        authorTittle: user?.title || ' titel',
        companyName: owner?.name || ' eier',
        comment: state?.message || ' ikke beskjed',
        productName: ticket?.product?.name || ' ingen product',
        serviceTag: ticket?.product?.serviceTag || ' ingen tag',

        //attachments: ConvertToAttachment(selectedDocuments),
      };

      console.log('send email', emailModel);
      postEmail.mutate(emailModel);
    }
    const _ticketCommunication = { ...state };

    if (state?.id === null) {
      //console.log('POST TICKET POST', _ticketPost);
      const _ticketPost = ConvertToTicketPost(
        _ticketCommunication,
        selectedDocuments,
        user,
        ticket.id,
        state.workRole
      );
      // console.log('_ticketPost', _ticketPost);
      postTicketPost.mutate(_ticketPost, {
        onSuccess: () => {
          dispatch({
            type: 'RESET_FORM',
          });
          // setInputValue(null);
          let message = '';
          dispatch({
            type: 'CHANGE_MESSAGE',
            payload: message,
          });
          dispatch({
            type: 'ASSIGN_RECEIVER',
            payload: ticket.contact,
          });
          let workRole: IWorkRoleViewModel = {
            id: user.workRole.id,
            title: user.workRole.title,
            inHouseHourlyRateCost: user.workRole.inHouseHourlyRateCost,
            inHouseHourlyRate: user.workRole.inHouseHourlyRate,
            onSiteHourlyRateCost: user.workRole.onSiteHourlyRateCost,
            onSiteHourlyRate: user.workRole.onSiteHourlyRate,
          };
          let _workRole = setWorkRole(
            ticket.workRole || null,
            ticket.company.workRole || null,
            workRole,
            state.workRole
          );
          dispatch({
            type: 'CHANGE_WORK_ROLE',
            payload: _workRole,
          });

          handleChangeAttachments([]);
        },
      });
    } else {
      if (_ticketCommunication?.id !== null) {
        const _ticketPut = ConvertToTicketPut(
          _ticketCommunication,
          selectedDocuments,
          user,
          ticket.id,
          state.workRole
        );
        putTicketPost.mutate(_ticketPut, {
          onSuccess: () => {
            dispatch({
              type: 'RESET_FORM',
            });
            let message = '';
            dispatch({
              type: 'CHANGE_MESSAGE',
              payload: message,
            });
            dispatch({
              type: 'ASSIGN_RECEIVER',
              payload: ticket.contact,
            });
            let workRole: IWorkRoleViewModel = {
              id: user.workRole.id,
              title: user.workRole.title,
              inHouseHourlyRateCost: user.workRole.inHouseHourlyRateCost,
              inHouseHourlyRate: user.workRole.inHouseHourlyRate,
              onSiteHourlyRateCost: user.workRole.onSiteHourlyRateCost,
              onSiteHourlyRate: user.workRole.onSiteHourlyRate,
            };
            let _workRole = setWorkRole(
              ticket.workRole || null,
              ticket.company.workRole || null,
              workRole,
              state.workRole
            );
            dispatch({
              type: 'CHANGE_WORK_ROLE',
              payload: _workRole,
            });
          },
        });
      }
    }
  };

  // USEEFFECTS

  useEffect(() => {
    console.log('TICKETS STATE', state);
    console.log('TICKET DATA', ticketData);
    if (ticketData) {
      state.ticketId = ticketData.id;
    }
  }, [state, ticketData]);

  useEffect(() => {
    if (ticketData) {
      setTicket(ticketData);
      dispatch({
        type: 'ASSIGN_RECEIVER',
        payload: ticketData.contact,
      });

      if (ticketData.company.workRole) {
        setWorkRole(null, ticketData.workRole, null, state.workRole);
        dispatch({
          type: 'CHANGE_WORK_ROLE',
          payload: ticketData.company.workRole,
        });
        dispatch({
          type: 'CHANGE_WORK_ROLE:ID',
          payload: ticketData.company.workRole.id,
        });
      }
      //   console.log('ticket data', ticketData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData]);

  useEffect(() => {
    if (ratesData && ratesData.rates) {
      let rates = [...ratesData.rates];
      // Example: Let's say you want to increase the rate by 10 for each rate
      rates = rates.map((rate: IRateViewModel) => {
        rate.rateValue = 0;
        return rate;
      });
      dispatch({
        type: 'SET_RATES',
        payload: rates,
      });
    }
  }, [ratesData]);

  useEffect(() => {
    handleChangeAttachments(selectedDocuments);
  }, [selectedDocuments]);

  useEffect(() => {
    if (ticketPostData) {
      setTicketPosts(ticketPostData.ticketPosts);
      //console.log('TICKET POSTS DATA', ticketPosts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketPostData]);

  useEffect(() => {
    if (workRoleData) {
      setWorkRoles(workRoleData.workRoles as IWorkRoleViewModel[]);
    }
  }, [workRoleData]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      // console.log('user', user);
      if (user.workRole?.id != null) {
        let workRole = {
          id: user.workRole.id,
          title: user.workRole.title,
          inHouseHourlyRateCost: user.workRole.inHouseHourlyRateCost,
          inHouseHourlyRate: user.workRole.inHouseHourlyRate,
          onSiteHourlyRateCost: user.workRole.onSiteHourlyRateCost,
          onSiteHourlyRate: user.workRole.onSiteHourlyRate,
        };
        let _workRole = setWorkRole(
          ticket?.workRole,
          ticket?.company?.workRole,
          workRole,
          state.workRole
        );
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: _workRole });
        dispatch({ type: 'CHANGE_WORK_ROLE_ID', payload: _workRole?.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setTotalTime(
      CalculateTotalTime(
        state.startTime,
        state.endTime,
        state.startDeduction,
        state.endDeduction
      )
    );

    if (state.rates && state.rates.length > 0 && state.workRole) {
      let _ticketPostBilling = CalculateRateValue({
        rates: state.rates,
        startTime: state.startTime,
        endTime: state.endTime,
        startDeduction: state.startDeduction,
        endDeduction: state.endDeduction,
        isInHouse: state.isInHouse,
        workRole: state.workRole,
      });
      _ticketPostBilling.ticketPostId = state.id;
      _ticketPostBilling.isBillable = state.billable;
      // console.log('_ticketPostBilling', _ticketPostBilling);
      dispatch({
        type: 'SET_TICKET_POST_BILLING',
        payload: _ticketPostBilling,
      });
      //  console.log('totalRateValue', _ticketPostBilling);
    }
  }, [
    state.id,
    state.rates,
    state.startTime,
    state.endTime,
    state.startDeduction,
    state.endDeduction,
    state.isInHouse,
    state.workRole,
    state.overtime,
    state.billable,
  ]);

  useEffect(() => {
    setTotalDeductionTime(
      CalculateTotalTime(null, null, state.startDeduction, state.endDeduction)
    );
  }, [state.startDeduction, state.endDeduction]);

  const OHjsx: React.FC<OHjsxProps> = ({ onHold: sendEmail, setSendEmail }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSendEmail(e.target.checked);
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >

            {sendEmail ? `${t("Ticket.TicketCommunications.sendMailYes")}` : `${t("Ticket.TicketCommunications.sendMailNo")}`}
          </Typography>

          <Switch
            color={sendEmail ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: sendEmail ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
              },
              '& .MuiSwitch-track': {
                backgroundColor: sendEmail
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)', // This gives a lighter red shade for the track. Adjust as needed.
              },
            }}
            checked={sendEmail}
            onChange={handleToggle}
            name='sendEmail'
          />
        </Stack>
      </Grid>
    );
  };

  return (
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* LEFT SIDE */}
        <Grid item xs={5} sx={{ pr: 0.5, m: 0 }}>
          <SearchFilter
            Icon={TextsmsRoundedIcon}
            Header={t("Ticket.TicketCommunications.header")}
            filterValue={filterType}
            handleFilterChange={handleFilterChange}
            handleSearch={handleSearch}
            selectList={ticketStatus}
          />
          <List
            sx={{
              pl: 1,
              pr: 1,
              height: Math.ceil(height - 248),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {!!ticketPosts &&
              ticketPosts?.map(
                (ticketPost: ITicketPostViewModel, index: number) => (
                  <Grid
                    sx={{
                      backgroundColor: 'transparent',
                      border: 0,
                      boxShadow: 'none',
                    }}
                    key={index}
                    onClick={() => {
                      handleAssignPost(ticketPost);
                    }}
                  >
                    <SingleMessage ticketPost={ticketPost} />
                  </Grid>
                )
              )}
          </List>

          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          ></Paper>
        </Grid>
        {/* RIGHT SIDE */}
        <Grid
          item
          xs={7}
          sx={{
            pl: 0.5,
            m: 0,
            // height: Math.ceil(height - 232),
            flexGrow: 1,
          }}
        >
          <form
            name='ticketCommunicationForm'
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* NEW COMMUNICATION*/}
            <StatusFilter
              Icon={TextsmsRoundedIcon}
              Header={
                !state.id
                  ? `${t("Ticket.TicketCommunications.newCommunication")}
                  ( ${
                      ticketData && ticketData?.ticketNo
                    } )`
                  : `${t("Ticket.TicketCommunications.editCommunication")}`

              }
              name='status'
              control={control}
              label='Status'
              list={ticketCommunicationStatus}
              statusValue={state.status}
              handleChangeStatus={handleChangeStatus}
            />
            <List
              sx={{
                height: Math.ceil(height - 262),
                width: '100%',
                mb: 0,
                p: 2,
                backgroundColor: 'primary.main',
                overflow: 'auto',
              }}
            >
              <Grid container key={'container'} sx={{}}>
                {state.scheduleTime &&
                  (state.status === 1 || state.status === 3) && (
                    <Grid
                      sx={{
                        key: 'scheduleTime',
                        border: 1,
                        borderColor: 'primary.back',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        p: 0,
                        mb: 2,
                      }}
                    >
                      <ScheduleView
                        scheduleTime={state.scheduleTime}
                        handleChange={handleChangeSchedule}
                      />
                    </Grid>
                  )}
                <Stack direction={'column'} key='stack' flex={1} sx={{}}>
                  {/* SWITCHES */}
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      mb: 1,
                    }}
                  >
                    <Grid item xs={8} sx={{ m: 0, p: 0 }}>
                      {ticket?.responsible ? (
                        <>
                          <SingleEmployeeView
                            employee={ticket.responsible}
                            height={60}
                          />
                        </>
                      ) : (
                        <Box
                          sx={{
                            m: 1,
                            height: 60,
                            border: 1,
                            borderColor: 'primary.back',
                            borderRadius: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                          }}
                        >
                          <Button
                            sx={{
                              mx: 'auto',
                              width: '100%',
                              height: '100%',
                              borderRadius: 3,
                            }}
                            variant='contained'
                            onClick={(e) => {
                              handleAssigneMe();
                            }}
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontWeight: 'bold',
                                fontSize: 14,
                                letterSpacing: 2,
                              }}
                            >
                              {t("Ticket.TicketCommunications.assignCustomerEmployee")}
                            </Typography>
                          </Button>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={4} sx={{ m: 0, pl: 1 }}>
                      {state.workRole ? (
                        <Grid
                          sx={{
                            height: 60,
                            border: 1,
                            borderRadius: 2,
                            borderColor: 'primary.back',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Stack
                            direction='row'
                            spacing={0}
                            justifyContent={'space-between'}
                          >
                            <Typography sx={{ pl: 1 }}>
                              {state.workRole.title}
                            </Typography>
                            <ObjectDrawer
                              buttonContext={t("Ticket.TicketCommunications.selectWorkRole")}
                              buttonIcon={<ChangeCircleIcon />}
                              objectList={
                                !!objectDrawerList ? objectDrawerList : []
                              }
                              objectType={[EnumObjectType.WorkRole]}
                              handleChange={handleChange}
                              buttonHeight={30}
                              buttonWidth={30}
                            />
                          </Stack>

                          {state.isInHouse ? (
                            <Typography sx={{ pl: 1 }}>
                              {state.workRole.inHouseHourlyRate}
                            </Typography>
                          ) : (
                            <Typography sx={{ pl: 1 }}>
                              {state.workRole.onSiteHourlyRate}
                            </Typography>
                          )}
                        </Grid>
                      ) : (
                        <ObjectDrawer
                          buttonContext={t("Ticket.TicketCommunications.selectWorkRole")}
                          buttonIcon={null}
                          objectList={
                            !!objectDrawerList ? objectDrawerList : []
                          }
                          objectType={[EnumObjectType.WorkRole]}
                          handleChange={handleChange}
                          buttonHeight={60}
                          buttonWidth={'100%'}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4} sx={{ m: 0, p: 0 }}>
                      <Box
                        sx={{
                          m: 1,
                          ml: 0,
                          border: 1,
                          borderRadius: 2,
                          borderColor: 'primary.back',
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                        }}
                      >
                        <Stack
                          direction='column'
                          spacing={0}
                          alignSelf='center'
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              width: '100%',
                              fontSize: 14,
                              letterSpacing: 1,
                              textAlign: 'center', // Add this line
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                            }}
                          >
                            {t("Ticket.TicketCommunications.Billable")}
                          </Typography>
                          <Box
                            sx={{
                              ml: 2,
                            }}
                          >
                            <FormSwitch
                              name='billable'
                              control={control}
                              onSwitchChange={handleChangeBillable}
                              value={state.billable || false}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ m: 0, p: 0 }}>
                      <Box
                        sx={{
                          m: 1,
                          border: 1,
                          borderRadius: 2,
                          borderColor: 'primary.back',
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                        }}
                      >
                        <Stack
                          direction='column'
                          spacing={0}
                          alignSelf='center'
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              width: '100%',
                              fontSize: 14,
                              letterSpacing: 1,
                              textAlign: 'center', // Add this line
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                            }}
                          >
                            {t("Ticket.TicketCommunications.Overtime")}
                          </Typography>
                          <Box
                            sx={{
                              ml: 2,
                            }}
                          >
                            <FormSwitch
                              name='overtime'
                              control={control}
                              onSwitchChange={handleChangeOvertime}
                              value={state.overtime || false}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ m: 0, p: 0 }}>
                      <Box
                        sx={{
                          m: 1,
                          mr: 0,
                          border: 1,
                          borderRadius: 2,
                          borderColor: 'primary.back',
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                        }}
                      >
                        <Stack
                          direction='column'
                          spacing={0}
                          alignSelf='center'
                        >
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              width: '100%',
                              fontSize: 14,
                              letterSpacing: 1,
                              textAlign: 'center', // Add this line
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                            }}
                          >
                            {t("Ticket.TicketCommunications.InHouse")}
                          </Typography>
                          <Box
                            sx={{
                              ml: 2,
                            }}
                          >
                            <FormSwitch
                              name='isInHouse'
                              control={control}
                              onSwitchChange={handleChangeInHouse}
                              value={state.isInHouse || false}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* TIME */}
                  <Grid sx={{ m: 0, mb: 2 }}>
                    <Paper
                      variant='accordion'
                      sx={{
                        border: 1,
                        borderColor: 'primary.back',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        m: 0,
                        p: 0,
                      }}
                    >
                      <Grid
                        container
                        spacing={0}
                        flexDirection={'row'}
                        sx={{ m: 0, p: 0 }}
                      >
                        <Grid item xs={6} sx={{ m: 0, p: 0 }}>
                          <Grid
                            container
                            sx={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <Grid item xs={1} sx={{ m: 0, p: 0 }}>
                              <ScheduleRoundedIcon
                                sx={{ fontSize: 32, m: 1, pt: 0.5 }}
                              />
                            </Grid>
                            <Grid item xs={11} sx={{ m: 0, pl: 1 }}>
                              <Typography
                                sx={{
                                  mt: 2,
                                  flexGrow: 1,
                                  textTransform: 'uppercase',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  fontWeight: 'bold',
                                  fontSize: 16,
                                  letterSpacing: 3,
                                }}
                              >
                               {t("Ticket.TicketCommunications.dateTime")} {totalTime}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                              <Accordion
                                disableGutters
                                sx={{
                                  backgroundColor: 'primary.main',
                                  border: 1,
                                  borderColor: 'primary.back',
                                  m: 0,
                                  p: 0,
                                }}
                              >
                                <FormAccordionSummary
                                  Icon={HistoryToggleOffIcon}
                                  title={`${t("Ticket.TicketCommunications.deductionTime")} ${totalDeductionTime}`}
                                />

                                <Grid sx={{ ml: 2, mr: 2, mt: 1 }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DesktopDateTimePicker
                                      format='DD/MM/YYYY HH:mm'
                                      value={
                                        dayjs(state.startDeduction)
                                          ? dayjs(
                                              new Date(state.startDeduction)
                                            )
                                          : null
                                      }
                                      onChange={(e) => {
                                        handleChangeStartDeduction(
                                          e as unknown as React.ChangeEvent<HTMLInputElement>
                                        );
                                      }}
                                      ampm={false}
                                      views={[
                                        'year',
                                        'month',
                                        'day',
                                        'hours',
                                        'minutes',
                                      ]}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid sx={{ ml: 2, mr: 2, mt: 1 }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DesktopDateTimePicker
                                      format='DD/MM/YYYY HH:mm'
                                      value={
                                        dayjs(state.endDeduction)
                                          ? dayjs(new Date(state.endDeduction))
                                          : null
                                      }
                                      onChange={(e) => {
                                        handleChangeEndDeduction(
                                          e as unknown as React.ChangeEvent<HTMLInputElement>
                                        );
                                      }}
                                      ampm={false}
                                      views={[
                                        'year',
                                        'month',
                                        'day',
                                        'hours',
                                        'minutes',
                                      ]}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Accordion>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{ m: 0, p: 0 }}>
                          <Grid
                            container
                            sx={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <Grid item xs={12} sx={{ m: 0, pr: 2, pt: 0 }}>
                              <Stack direction={'row'} sx={{}}>
                                <Typography
                                  sx={{
                                    pt: 0.7,
                                    width: 100,
                                    letterSpacing: 1,
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  Start:
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDateTimePicker
                                    format='DD/MM/YYYY HH:mm'
                                    value={
                                      dayjs(state.startTime)
                                        ? dayjs(new Date(state.startTime))
                                        : dayjs(new Date())
                                    }
                                    onChange={(e) => {
                                      handleChangeStartTime(
                                        e as unknown as React.ChangeEvent<HTMLInputElement>
                                      );
                                    }}
                                    onClose={() => {
                                      //console.log('close endtime', e.target.value);
                                      CalculateTotalTime(
                                        state.startTime,
                                        state.endTime,
                                        state.startDeduction,
                                        state.endDeduction
                                      );
                                    }}
                                    ampm={false}
                                    views={[
                                      'year',
                                      'month',
                                      'day',
                                      'hours',
                                      'minutes',
                                    ]}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{ m: 0, p: 0, pl: 2, mt: -1 }}
                            >
                              {resetEndTimeObject.showResetMessage ? (
                                <Typography sx={{ color: 'secondary.main' }}>
                                 {t("Ticket.TicketCommunications.endTimeSuccessfullyReset")}
                                </Typography>
                              ) : (
                                <Typography>{t("Ticket.TicketCommunications.endTimeIncrements")}
                                {}</Typography>
                              )}
                              <Stack
                                direction={'row'}
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                  borderRadius: 3,
                                  overflow: 'hidden',
                                }}
                              >
                                {[15, 30, 45, 60].map(
                                  (interval, index: number) => (
                                    <Button
                                      variant='contained'
                                      key={index}
                                      onClick={() => addTimeToEndTime(interval)}
                                      style={{ borderRadius: 0 }}
                                    >
                                      {interval === 60
                                        ? '+1:00:'
                                        : `+0:${interval}`}
                                    </Button>
                                  )
                                )}
                                {/* Reset Button */}
                                <Grid item xs={4} sx={{ m: 0, p: 0 }}>
                                  {true && (
                                    <Button
                                      variant='contained'
                                      color='secondary' // Makes the button stand out
                                      disabled={
                                        !resetEndTimeObject.isEndTimeChanged
                                      }
                                      onClick={() => {
                                        handleChangeEndTime(
                                          initialState.endTime
                                        );
                                        setResetEndTimeObject({
                                          isEndTimeChanged: false,
                                          showResetMessage: true,
                                        });
                                        setTimeout(() => {
                                          setResetEndTimeObject(
                                            (
                                              prevState: IResetEndTimeObject
                                            ) => ({
                                              ...prevState,
                                              showResetMessage: false,
                                            })
                                          );
                                        }, 3000); // 3000 milliseconds = 3 seconds
                                      }}
                                    >
                                      {t("Ticket.TicketCommunications.reset")}
                                    </Button>
                                  )}
                                </Grid>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{ m: 0, pr: 2, pt: 0.5 }}>
                              <Stack direction={'row'} sx={{}}>
                                <Typography
                                  sx={{
                                    pt: 0.7,
                                    width: 100,
                                    letterSpacing: 1,
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {t("Ticket.TicketCommunications.end")}:

                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDateTimePicker
                                    format='DD/MM/YYYY HH:mm'
                                    value={
                                      dayjs(state.endTime)
                                        ? dayjs(new Date(state.endTime))
                                        : null
                                    }
                                    onChange={(e) => {
                                      handleChangeEndTime(
                                        e as unknown as React.ChangeEvent<HTMLInputElement>
                                      );
                                    }}
                                    ampm={false}
                                    views={[
                                      'year',
                                      'month',
                                      'day',
                                      'hours',
                                      'minutes',
                                    ]}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  {/* ECONOMY INFO */}
                  {economy && (
                    <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                      <Grid
                        container
                        spacing={0}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor: 'primary.back',
                          borderRadius: 2,
                          p: 1,
                        }}
                      >
                        {state.ticketPostBilling &&
                          Object.entries(state.ticketPostBilling).map(
                            ([key, value]) => (
                              <Grid
                                item
                                xs={
                                  key === 'id'
                                    ? 12
                                    : key === 'ticketPostId'
                                    ? 12
                                    : 4
                                }
                                key={key}
                              >
                                <Typography>
                                  {key}: {String(value)}
                                </Typography>
                              </Grid>
                            )
                          )}
                      </Grid>
                    </Grid>
                  )}
                  {/* MESSAGE & AUTOANSVER */}
                  <Grid
                    container
                    spacing={0}
                    flexDirection={'row'}
                    sx={{ m: 0, p: 0 }}
                  >
                    <Grid item xs={7} sx={{ m: 0, pr: 2 }}>
                      <Grid
                        sx={{
                          flexGrow: 1,
                          border: 1,
                          borderColor: 'primary.back',
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: 2,
                        }}
                      >
                        <Stack direction='row' spacing={0} flexGrow={1}>
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                              flexGrow: 1,
                            }}
                          >
                            {t("Ticket.TicketCommunications.internalTicketMessage")}
                          </Typography>
                          <FormSwitch
                            name='internal'
                            control={control}
                            onSwitchChange={handleChangeInternal}
                            value={state.internal || false}
                          />
                        </Stack>
                        <Grid
                          sx={{
                            m: 0,
                            pb: 1,
                            flexGrow: 1,
                            backgroundColor: 'primary.back',
                            borderRadius: 2,
                          }}
                        >
                          <FormTextMultiline
                            control={control}
                            value={state.message != null ? state.message : ' '}
                            minRows={6}
                            multiline={true}
                            name='message'
                            onChange={handleInputChange}
                            onBlur={handleChangeMessage}
                            //state={state}
                          />
                        </Grid>

                        <Typography
                          sx={{
                            m: 0,
                            p: 0,
                            pl: 2,
                            letterSpacing: 1,
                            alignSelf: 'center',
                            alignContent: 'center',
                            // overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            // whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                          }}
                        >
                          {t("Ticket.TicketCommunications.notification")} :
                        </Typography>
                        <Typography
                          sx={{
                            m: 0,
                            p: 1,
                            pl: 2,
                            letterSpacing: 1,
                            alignSelf: 'center',
                            alignContent: 'center',
                            // overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            // whiteSpace: 'nowrap',
                            color: 'primary.text',
                          }}
                        >
                        {t("Ticket.TicketCommunications.notificationMessage")}
                        </Typography>
                        <Stack direction='row' spacing={0} flexGrow={1}>
                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize',
                            }}
                          >
                            {t("Ticket.TicketCommunications.sendEmailTo")}:
                          </Typography>
                          {/*   En ticket i iBluu appen har blitt oppdatert. Logg på iBluu på mobilen din for å se oppdateringen   */}

                          <Typography
                            sx={{
                              m: 0,
                              p: 0,
                              pl: 2,
                              letterSpacing: 1,
                              alignSelf: 'center',
                              alignContent: 'left',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              color: 'secondary.text',
                              flexGrow: 1,
                            }}
                          >
                            {state?.receiver?.email}
                          </Typography>
                          <OHjsx
                            onHold={sendEmail}
                            setSendEmail={setSendEmail}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{
                        m: 0,
                        p: 0,
                        border: 1,
                        borderColor: 'primary.back',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        borderRadius: 2,
                      }}
                    >
                      <Grid
                        sx={{
                          flexGrow: 1,
                          borderRadius: 2,
                        }}
                      >
                        <Stack direction={'row'} sx={{}}>
                          <Typography
                            sx={{
                              flexGrow: 1,
                              letterSpacing: 1,
                              textAlign: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontWeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {t("Ticket.TicketCommunications.autoAnswers")}
                          </Typography>
                        </Stack>
                      </Grid>

                      <List
                        sx={{
                          m: 1,
                          pl: 1,
                          pr: 1,
                          height: 233,
                          backgroundColor: 'primary.main',
                          overflow: 'auto',
                          borderRadius: 2,
                        }}
                      >
                        <ListItem
                          sx={{
                            m: 0,
                            p: 0,
                            mb: 0.5,
                            width: '100%',
                            backgroundColor: 'primary.main',
                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                            ':hover': {
                              cursor: 'pointer',
                              filter: 'brightness(120%)',
                              transition: 'all 0.2s ease-in-out',
                            },
                          }}
                        >
                          <ListItemText
                            primary={t("Ticket.TicketCommunications.imOnVacation")}
                            sx={{
                              m: 0,
                              p: 0,
                              color: 'primary.text',
                              textAlign: 'center',
                            }}
                          />
                        </ListItem>
                      </List>
                      <Accordion
                        disableGutters
                        sx={{
                          backgroundColor: 'transparent',
                          border: 0,
                          m: 1,
                        }}
                      >
                        <FormAccordionSummary
                          Icon={AttachFileIcon}
                          title={t("Ticket.TicketCommunications.Attachments")}
                        />
                        <AccordionDetails>
                          <DocumentUploader
                            setDocuments={(files: any) =>
                              setSelectedDocuments((prevDocs: any) => [
                                ...prevDocs,
                                ...files,
                              ])
                            }
                          />
                          <List>
                            {state.attachments?.map(
                              (document: any, index: number) => {
                                //console.log('document:', document);
                                return (
                                  <ListItem key={index}>
                                    {document.url ? (
                                      <a
                                        rel='noopener noreferrer'
                                        target='_blank'
                                        href={document.url}
                                        style={{ color: 'white' }}
                                      >
                                        {document.fileName}
                                      </a>
                                    ) : (
                                      document.file.name
                                    )}
                                  </ListItem>
                                );
                              }
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </List>
            {/* FOTTER */}
            <Paper
              variant='elevation3'
              key={'footer'}
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              {/* SUBMIT*/}
              <Stack direction={'column'}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  {state.id !== null && (
                    <Button
                      sx={{
                        width: '25%',
                        backgroundColor: 'primary.back',
                      }}
                      onClick={() => {
                        dispatch({
                          type: 'RESET_FORM',
                        });
                        dispatch({
                          type: 'CHANGE_MESSAGE',
                          payload: ' ',
                        });
                        dispatch({
                          type: 'ASSIGN_RECEIVER',
                          payload: ticket.contact,
                        });
                        let workRole: IWorkRoleViewModel = {
                          id: user.workRole.id,
                          title: user.workRole.title,
                          inHouseHourlyRateCost:
                            user.workRole.inHouseHourlyRateCost,
                          inHouseHourlyRate: user.workRole.inHouseHourlyRate,
                          onSiteHourlyRateCost:
                            user.workRole.onSiteHourlyRateCost,
                          onSiteHourlyRate: user.workRole.onSiteHourlyRate,
                        };
                        let _workRole = setWorkRole(
                          ticket.workRole || null,
                          ticket.company.workRole || null,
                          workRole,
                          null
                        );
                        dispatch({
                          type: 'CHANGE_WORK_ROLE',
                          payload: _workRole,
                        });
                        //setInputValue(null);
                      }}
                      variant='contained'
                    >
                      {t("Ticket.TicketCommunications.Cancel")}
                    </Button>
                  )}
                  <Button
                    disabled={state.direction !== 1 ? true : false}
                    sx={{
                      width: '25%',
                      // backgroundColor: 'secondary.main',
                    }}
                    type='submit'
                    id={!state.id ? `${t("Ticket.TicketCommunications.submit")}`: `${t("Ticket.TicketCommunications.confirmEdit")}`}
                    variant='contained'
                  >
                    {!state.id ? `${t("Ticket.TicketCommunications.submit")}`: `${t("Ticket.TicketCommunications.confirmEdit")}`}
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </form>
        </Grid>
      </Grid>
  );
};

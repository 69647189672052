import { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  ListItemText,
  ListItemIcon,
  List,
} from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useAtom } from 'jotai';
import accessTokenAtom from 'data/atoms/accessTokenAtom';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import ApiIcon from '@mui/icons-material/Api';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { Business } from '@mui/icons-material';
import { t } from 'i18next';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';

const moduleOptions = [
  { name: 'Get Customers', description: 'Manage cloudfactory customer data' },
  { name: 'Get Companies', description: 'Manage companies' },
  { name: 'API Documentation', description: 'Access API usage and examples' },
];

export const Modules = () => {
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('Loading...');
  const [selectedModule, setSelectedModule] = useState<string | null>(null);
  const [customers, setCustomers] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);
  const { height } = useWindowsDimension();

  const [refreshToken] = useState(
    'ozAPKTeYhy7KW1JJnZbFh0W90glf1mV1i-L-xjSjtBgg4'
  );

  const fetchAccessToken = async (refreshToken: string) => {
    const url = `https://portal.api.cloudfactory.dk/Authenticate/ExchangeRefreshToken/${refreshToken}`;

    setLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        setAccessToken(data.access_token);
        console.log('Access Token:', data.access_token);
      } else {
        throw new Error('Failed to fetch access token');
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchModuleData = async (pageIndex: number, pageSize: number) => {
    if (!refreshToken) return;
    console.log('Fetching module data');

    const url =
      'https://portal.api.cloudfactory.dk/v2/customers/Customers?PageIndex=1&PageSize=15';
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCustomers(data.results);
        console.log('Module Data:', data);
      } else {
        throw new Error('Failed to fetch module data');
      }
    } catch (error) {
      console.error('Error fetching module data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersForCustomer = async (legacyCustomerId: string) => {
    const url = `https://portal.api.cloudfactory.dk/Microsoft/GetAllUsersOnCustomer?customerId=${legacyCustomerId}`;
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        console.log('Users Data:', data);
      } else {
        throw new Error('Failed to fetch users data');
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleModuleClick = (
    moduleName: string,
    pageIndex: number,
    pageSize: number
  ) => {
    console.log('handleModuleClick', moduleName, pageIndex, pageSize);
    setSelectedModule(moduleName);
    if (moduleName === 'Get Customers') {
      fetchModuleData(pageIndex, pageSize);
    }
  };

  const handleCompanySelect = (legacyCustomerId: string) => {
    console.log('handleCompanySelect', legacyCustomerId);
    fetchUsersForCustomer(legacyCustomerId);
  };

  return (
    <Grid item xs={12}>
      <Paper
        variant='elevation3'
        sx={{
          backgroundColor: 'primary.back',
          // height: Math.ceil(height - 30),
          height: '100%', // Ensures the Paper takes full height of its grid
          overflow: 'auto', //
        }}
      >
        <SearchFilter Header='Cloudfactory' Icon={CloudRoundedIcon} />
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%', borderRadius: 2 }}>
              <Typography variant='h5' gutterBottom>
                API Token Manager
              </Typography>

              <Button
                variant='contained'
                onClick={() => fetchAccessToken(refreshToken)}
                startIcon={
                  loading ? (
                    <CircularProgress size={18} color='inherit' />
                  ) : (
                    <CloudRoundedIcon />
                  )
                }
              >
                Refresh Token
              </Button>
              {accessToken ? (
                <Typography variant='body2' sx={{ mt: 2 }}>
                  Access Token: Set
                </Typography>
              ) : (
                <Typography variant='body2' sx={{ mt: 2 }}>
                  No Access Token Set
                </Typography>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%', borderRadius: 2 }}>
              <Typography variant='h5' gutterBottom>
                System Status
              </Typography>
              <Typography variant='body1'>
                Check the current system status and recent updates.
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle1'>Latest Update:</Typography>
                <Typography variant='body2'>{status}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, height: '100%', borderRadius: 2 }}>
              <Typography variant='h5' gutterBottom>
                Available Modules
              </Typography>
              <Grid container spacing={2}>
                {moduleOptions.map((module, index) => (
                  <Grid item key={index} xs={12} sm={4}>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        cursor: 'pointer',
                        borderRadius: 2,
                        backgroundColor:
                          selectedModule === module.name
                            ? 'primary.light'
                            : 'background.paper',
                      }}
                      onClick={() => handleModuleClick(module.name, 2, 5)}
                    >
                      <ListItemIcon>
                        <ApiIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={module.name}
                        secondary={module.description}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={5} sx={{ pr: 1, m: 0 }}>
            <SearchFilter Icon={Business} Header={t('company.header')} />
            <List
              sx={{
                maxHeight: Math.ceil(height - 500),
                width: '100%',
                mb: 0,
                p: 2,
                backgroundColor: 'primary.dark',
                overflow: 'auto',
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
              }}
            >
              {customers?.map((company: any, index: number) => (
                <div
                  onClick={() => handleCompanySelect(company.legacyId)}
                  key={index}
                >
                  <SingleCompanyView index={index} company={company} />
                </div>
              ))}
            </List>
          </Grid>
          <Grid item xs={5} sx={{ pr: 1, m: 0 }}>
            <SearchFilter Icon={Business} Header={t('employees.header')} />
            <List
              sx={{
                maxHeight: Math.ceil(height - 600),
                width: '100%',
                mb: 0,
                p: 2,
                backgroundColor: 'primary.dark',
                overflow: 'auto',
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
              }}
            >
              {users?.map((employee: any, index: number) => (
                <SingleEmployeeView index={index} employee={employee} />
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

import { useEffect } from 'react';
import { AppRoutes } from 'routes';
import { useIsAuthenticated } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { Sidebar } from 'components/ui';
import BluuTheme from 'assets/BluuTheme.ts';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import useWindowsDimension from 'functions/useWindowsDimension';
import GlobalSpinnerLoader from 'components/ui/GlobalSpinnerLoader';

const drawerWidth = 250;

const PageLayout = () => {
  const { pathname } = useLocation();
  const { height, width } = useWindowsDimension();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Grid
      container
      display={'flex'}
      direction={'row'}
      spacing={0}
      sx={{ m: 0, p: 0, width: width}}
    >
      <ThemeProvider theme={BluuTheme}>
        <CssBaseline />
        <Grid item xs={12} sx={{ height: '10px', m: 0, p: 0 }}>
          <GlobalSpinnerLoader />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            // width:'100%',
            height: height - 28,
            //backgroundColor: 'primary.light',
          }}
        >
          <Sidebar />
        </Grid>
        {!!isAuthenticated && (
          <Grid
            item
            xs={10}
            sx={{
              // width:width - drawerWidth - 20,
              height: height - 28,

              //pr: 1,
            }}
          >
            <AppRoutes />
          </Grid>
        )}
      </ThemeProvider>
    </Grid>
  );
};

export default PageLayout;

import { memo, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  InputBase,
  List,
  Pagination,
  Paper,
  Typography,
} from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { SingleProductView } from 'components/ui/SingleProductView';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { Button, Drawer, Box } from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import Business from '@mui/icons-material/Business';
import { SingleWorkRoleView } from 'components/ui/SingleWorkRoleView';
import { SingleAutoAnswerView } from 'components/ui/SingleAutoAnswerView';
import {
  EnumObjectType,
  enumToStringMapping,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { SingleStorageProductView } from 'components/ui/SingleStorageProductView';
import { SinglePackageView } from 'components/ui/SinglePackageView';
import { SingleCompanyProductView } from 'components/ui/SingleCompanyProductView';
import { SingleBrandView } from 'components/ui/SingleBrandView';
import { SingleResourceTypeView } from 'components/ui/SingleResourceTypeView';

type Props = {
  buttonIcon?: any | null;
  buttonContext: string;
  objectList: any;
  objectType: EnumObjectType[];
  handleChange: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: EnumObjectType
  ) => void;
  buttonHeight: any;
  buttonWidth: any;
};

export const ObjectDrawer = ({
  objectList: objectDrawerList,
  objectType,
  buttonContext,
  buttonIcon,
  handleChange,
  buttonHeight,
  buttonWidth,
}: Props) => {
  // INITIALIZATION
  const { width, height } = useWindowsDimension();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedObjectType, setSelectedObjectType] = useState<EnumObjectType>(
    objectType[0]
  );
  const [selectedObject, setSelectedObject] = useState<objectArray>(
    objectDrawerList[
      objectDrawerList.findIndex(
        (item: objectArray) => item.objectType === objectType[0]
      )
    ]
  );
  const [filteredList, setFilteredList] = useState<any>([]);
  const [originalList, setOriginalList] = useState([]);

  // SEARCH FUNCTIONALITY
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLowerCase());
  };

  //HANDLES
  const handleChangeSelectedObjectType = (e: any, enumType: EnumObjectType) => {
    e.preventDefault();
    setSelectedObjectType(enumType);
  };

  //TOGGLES
  const toggleObjectType = () => {
    return objectType.map((item: EnumObjectType, index: number) => {
      const itemName = enumToStringMapping[item];
      return (
        <Grid
          item
          xs={3}
          key={index}
          sx={{ pl: 1, mt: 1, mb: -1, pb: 2, width: '100%' }}
        >
          <Button
            variant='contained'
            key={index}
            sx={{ height: 40, width: '90%' }}
            onClick={(e: any) => {
              handleChangeSelectedObjectType(e, item);
            }}
          >
            {itemName}
          </Button>
        </Grid>
      );
    });
  };

  // USE EFFECT
  useEffect(() => {
    const filtered = objectDrawerList.find(
      (item: objectArray) => item.objectType === selectedObjectType
    );
    if (!!filtered) {
      if (filtered) {
        // console.log('filtered: ', filtered);
        setSelectedObject(filtered);
        setFilteredList(filtered.objectList);
        setOriginalList(filtered.objectList);
      }
    }
  }, [objectDrawerList, objectType, selectedObjectType]);

  useEffect(() => {
    const filtered =
      searchText === ''
        ? selectedObject.objectList
        : selectedObject.objectList.filter((item: any) => {
            return (
              item.name.toLowerCase().includes(searchText) ||
              (item.phone && item.phone.toLowerCase().includes(searchText)) ||
              (item.email && item.email.toLowerCase().includes(searchText))
            );
          });
    setFilteredList(filtered);
  }, [searchText, selectedObject]);

  // PAGINATION
  const itemsPerPage = 16;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredList.length / itemsPerPage);
  const handlePageChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  // console.log('objectDrawer', currentItems);

  return (
    <>
      {buttonIcon === null ? (
        <Button
          variant='contained'
          sx={{
            //border: 1,
            borderRadius: 2,
            //borderColor: 'primary.dark',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            width: buttonWidth || '100%',
            height: buttonHeight || '100%',
          }}
          onClick={() => setOpen(!open)}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: 2,
              color: 'primary.text',
            }}
          >
            {buttonContext}
          </Typography>
        </Button>
      ) : (
        <Box
          sx={{
            width: buttonWidth || 20,
            height: buttonHeight || 20,
            backgroundColor: 'primary.light',
            display: 'flex', // <-- Set this to flex
            justifyContent: 'center', // <-- This centers children horizontally
            alignItems: 'center', // <-- This centers children vertically
            borderRadius: 2,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            '&:hover': {
              filter: 'brightness(120%)',
            },
          }}
        >
          <IconButton
            sx={{
              p: 0,
              m: 0,
              width: buttonWidth - 5 || 18,
              height: buttonHeight - 5 || 18,
              //color: 'secondary.light',
              /*  '&:hover': {
                color: 'secondary.light'
              } */
            }}
            onClick={() => setOpen(!open)}
          >
            {buttonIcon}
          </IconButton>
        </Box>
      )}

      <Drawer
        anchor={'left'}
        open={open}
        onClose={() => setOpen(!open)}
        sx={{ backgroundColor: 'transparent' }}
      >
        <Box
          sx={{
            width: width / 2,
            backgroundColor: 'primary.back',
            borderRadius: 2,
          }}
        >
          {selectedObject && (
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                backgroundColor: 'transparent',
              }}
            >
              <Grid item xs={12} sx={{ pr: 0, m: 0 }}>
                <SearchFilter
                  Icon={Business}
                  Header={buttonContext}
                  filterValue={''}
                  handleFilterChange={null}
                  handleSearch={handleSearch}
                  hideAll={false}
                  //handleNew={handleNew}
                  selectList={[]}
                />
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    backgroundColor: 'transparent',
                  }}
                >
                  {toggleObjectType()}
                </Grid>
                <List
                  sx={{
                    height: Math.ceil(height - 188),
                    width: '100%',
                    mb: 0,
                    p: 2,
                    backgroundColor: 'primary.back',
                    overflow: 'auto',
                  }}
                >
                  {currentItems?.length === 0 && (
                    <div>No {objectType} found</div>
                  )}
                  {currentItems?.map((item: any, index: number) => (
                    <Paper
                      key={index}
                      onClick={(e) => {
                        handleChange(e, item, selectedObjectType);
                        setOpen(false);
                      }}
                      sx={{
                        ':hover': {
                          cursor: 'pointer',
                          filter: 'brightness(120%)',
                          transition: 'all 0.2s ease-in-out',
                        },
                      }}
                    >
                      {(selectedObject?.objectList?.length ?? 0) >= 1 &&
                        selectedObjectType === EnumObjectType.Vendor && (
                          <SingleCompanyView
                            index={index}
                            company={item}
                            //setCompany={handleChange}
                            //detailNav={setOpen(false)}
                          />
                        )}
                      {selectedObjectType === EnumObjectType.Company && (
                        <SingleCompanyView
                          index={index}
                          company={item}
                          // setCompany={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {selectedObjectType === EnumObjectType.Product && (
                        <SingleProductView
                          index={index}
                          product={item}
                          //setCompany={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {selectedObjectType === EnumObjectType.StorageProduct && (
                        <SingleStorageProductView
                          storageProduct={item}
                          //setCompany={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {selectedObjectType ===
                        EnumObjectType.CompanyEmployees && (
                        <SingleEmployeeView
                          employee={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {selectedObjectType === EnumObjectType.allEmployees && (
                        <SingleEmployeeView
                          employee={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {selectedObjectType ===
                        EnumObjectType.AppOwnerEmployees && (
                        <SingleEmployeeView
                          employee={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {selectedObjectType === EnumObjectType.Package && (
                        <SinglePackageView
                          packageData={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {(selectedObjectType === EnumObjectType.CompanyProduct ||
                        selectedObjectType ===
                          EnumObjectType.unassignedCompanyProduct) && (
                        <SingleCompanyProductView
                          companyProduct={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {(selectedObject?.objectList?.length ?? 0) >= 1 &&
                        selectedObjectType === EnumObjectType.WorkRole && (
                          <SingleWorkRoleView
                            workRole={item}
                            //handleChange={handleChange}
                            //  detailNav={setOpen(false)}
                          />
                        )}
                      {selectedObjectType === EnumObjectType.IssueType && (
                        <SingleAutoAnswerView
                          autoAnswer={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {selectedObjectType ===
                        EnumObjectType.EmployeeProducts && (
                        <SingleCompanyProductView
                          product={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {(selectedObject?.objectList?.length ?? 0) >= 1 &&
                        selectedObjectType ===
                          EnumObjectType.primaryContacts && (
                          <SingleEmployeeView
                            employee={item}
                            //handleChange={handleChange}
                            //  detailNav={setOpen(false)}
                          />
                        )}
                       {(selectedObject?.objectList?.length ?? 0) >= 1 &&
                        selectedObjectType ===
                          EnumObjectType.ResourceTypes && (
                          <SingleResourceTypeView
                            resourceType={item}
                            //handleChange={handleChange}
                            //  detailNav={setOpen(false)}
                          />
                        )}
                    </Paper>
                  ))}
                </List>

                <Paper
                  variant='elevation3'
                  sx={{
                    p: 2,
                    height: 64,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                >
                  {totalPages > 1 && (
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      sx={{ margin: 'auto', padding: '10px' }}
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Drawer>
    </>
  );
};

import { Grid } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { BillingHeaderButtonDetails } from './Components/BillingStyleConfig';
import { PageHeader } from 'components/ui/Global/PageHeader';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { useTranslation } from 'react-i18next';

export const BillingDetail = () => {
  const { t } = useTranslation();
  // INITIALIZATION
  // API
  
  // HANDLES
  
  // EFFECTS

  const BillingHeaderLinks = BillingHeaderButtonDetails(null,null).map(button => ({
    ...button,
    name: t(button.name), // Localize the name here
  }));

  return (
    <Grid container direction='row' spacing={0}>
      <Grid item xs={12} sx={{}}>
        <PageHeader
          Icon={PriceChangeIcon}
          title={t('Billing.header')}
          FormJSX={null}
          HeaderButtons={BillingHeaderLinks}
        /> 
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

import { useParams } from 'react-router';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { ResourceForm } from './ResourceForm';
import { useEffect, useState } from 'react';
import { EnumObjectType, objectArray } from 'Interfaces/IGlobalTypeConfig';
import _ from 'lodash';
import { IResourceTypeViewModel, IResourcesViewModel } from 'Interfaces/IResourcesConfig';
import { useGetObjectList } from 'functions/useGetObjectList';
import { ICompanyViewModel, IDepartmentViewModel } from 'Interfaces/ICompaniesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';

const initialState: IResourcesViewModel = {
  id: 'new',
  name: '',
  resourceTypeId: null,
  resourceType: {} as unknown as IResourceTypeViewModel,
  email: '',
  company: {} as unknown as ICompanyViewModel,
  companyId: 'new',
  contact: {} as unknown as IEmployeeViewModel,
  contactId: 'new',
  departmentId: '0',
  department: {} as unknown as IDepartmentViewModel,
  companyProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[],
  storageProducts: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
};

export const Resource = () => {
  const {companyId, resourceId } = useParams();
  // STATES
  const [resource, setResource] = useState<IResourcesViewModel>(
    null as unknown as IResourcesViewModel
  );
   const [employees, setEmployees] = useState<IEmployeeViewModel[]>(
    null as unknown as IEmployeeViewModel[]
  );

   const [departments, setDepartments] = useState<IDepartmentViewModel[]>(
    null as unknown as IDepartmentViewModel[]);


   // api
   let isEnableCompany = companyId !== undefined && companyId !== 'new' ? true : false;
   const { data:departmentsData } = useGet(rq.departments(companyId || '',isEnableCompany), isEnableCompany);
   const { data: employeeData } = useGet(
    rq.employees(companyId || '', isEnableCompany),
    isEnableCompany
  );
  
  let isEnableResources = resourceId !== undefined && resourceId != 'new' ? true : false;
  const { data: resourceData } = useGet(
    rq.resource(resourceId || '', isEnableResources),
    isEnableResources
  );

  console.log("resourceDataTest", resourceData)
  // api
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let employeeId = 'new';
  let departmentId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
    // update objectList
    if (employeeData !== null) {
      let _companyEmployees = _.cloneDeep(employeeData?.allEmployees || []);
      setEmployees(_companyEmployees);
      objectDrawerList[EnumObjectType.CompanyEmployees].objectList = _companyEmployees;
    }

      // set selected Resource  
    if (resourceData !== null) {
      let tmp = _.cloneDeep(resourceData);  
      setResource(tmp);
    } else {
      setResource(initialState);
    }

      // set departmens
    if (departmentsData !== null) {
      let tmp = _.cloneDeep(departmentsData?.departments);
      setDepartments(tmp);
      objectDrawerList[EnumObjectType.Department].objectList = tmp;
    }

    if (resourceData !== null && employeeData !== null && departmentsData !== null) {
      setIsObjectList(true);
    } else {
      setIsObjectList(false);
    }
  }, [objectDrawerList, employeeData, resourceData, departmentsData]);
  
  if (resource === null && isObjectList === true && employees === null ) {
    return <div>loading...</div>;
  }
  return (
    <ResourceForm
      resource={resourceId !== 'new' ? resource : initialState}
      objectDrawerList={objectDrawerList}
      setIsObjectList={setIsObjectList}
    />
  );
};

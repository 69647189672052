import { useDroppable } from '@dnd-kit/core';
import { SortableSingleTicket } from './tickets/SortabeSingleTicket';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid, List, Paper } from '@mui/material';
import { SearchFilter } from './SearchFilter';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { getFilteredList } from 'functions/misc';
import _ from 'lodash';
import { ticketType } from 'data/enum';
import { useTranslation } from 'react-i18next';

export function DroppableTicketList(props: any) {
const { t } = useTranslation();

  console.log('DroppableTicketList', props.tickets);
  const { setNodeRef } = useDroppable({
    id: props.id,
    data: props.id,
  });

  const {
    id: columnId,
    setActiveTicket,
    tickets,
    handleFilterChange,
    filterType: filterValue,
    handleSearch,
  } = props;
  const list = _.cloneDeep(tickets);

  let navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [filteredTickets, setFilteredTickets] = useState([]);

  const { height } = useWindowsDimension();

  const handleNew = () => {
    navigate('/tickets/new');
  };

  return (
    <>
      <Grid
        ref={setNodeRef}
        item
        xs={12}
        sx={{ pr: 0, m: 0, overflowX: 'hidden' }}
      >
        <SearchFilter
          Icon={SupportAgentRoundedIcon}
          Header={
            props.id === 'newTickets' ? `${t("Ticket.DroppableTicketList.unassignedTickets")}` : `${t("Ticket.DroppableTicketList.yourTickets")}`
          }
          filterValue={props.id === 'newTickets' ? filterValue : null}
          handleFilterChange={
            props.id === 'newTickets' ? handleFilterChange : null
          }
          handleSearch={handleSearch}
          handleNew={handleNew}
          selectList={props.id === 'newTickets' ? ticketType : null}
        />

        <List
          sx={{
            height: Math.ceil(height - 136),
            maxHeight: Math.ceil(height - 136),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {tickets?.length === 0 && <div>No data</div>}
          {tickets?.map((ticket: any, index: number) => {
            return (
              <SortableSingleTicket
                key={ticket.id}
                id={ticket.id}
                ticket={ticket}
                ticketIndex={index}
                setActiveTicket={setActiveTicket}
                columnId={columnId}
              />
            );
          })}
        </List>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </>
  );
}

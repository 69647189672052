import {
  Paper,
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
} from '@mui/material';
import { Header } from 'data/FontStyle';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HeaderButtonInterface } from 'Interfaces/IGlobalTypeConfig';

type Props = {
  Icon: any;
  title: string;
  FormJSX: any;
  GraphJSX: any;
  HeaderButtons: HeaderButtonInterface[];
};

export const ChartHeader = ({
  Icon,
  title,
  FormJSX,
  GraphJSX,
  HeaderButtons,
}: Props) => {
  /* INITIALIZATIONS */
  const navigate = useNavigate();
  /* FUNCTIONS */
  let isHelp = false;
  return (
    <Paper
      elevation={0}
      sx={{
        height: 54,
        backgroundColor: 'primary.main',
        borderRadius: 2,
      }}
    >
      <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
        <Grid item sx={{}}>
          {!!Icon && <Icon sx={Header.icon} />}
        </Grid>
        <Grid item sx={{  }}>
          {GraphJSX && (GraphJSX)}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={Header.text}>{title}</Typography>
        </Grid>
        <Grid item sx={{ mr: 1 }}>
          {isHelp ? (
            <HelpIcon sx={{ fontSize: 38, m: 1, color: 'secondary.text' }} />
          ) : (
            <HelpOutlineIcon
              sx={{ fontSize: 38, m: 1, color: 'secondary.text' }}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

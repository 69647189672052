import {
  ISingleChartBar,
  IItemGroup,
  IMultiChartBar,
} from 'Interfaces/IChartConfig';
import { EnumChartPeriod, EnumChartType } from 'Interfaces/IGlobalTypeConfig';

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const nameList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const generateItemGroup = (
  name: string,
  min: number,
  max: number
): IItemGroup => ({
  name: name,
  budgetValue: getRandomNumber(min, max),
  currentValue: getRandomNumber(min, max),
  currentPercentage: getRandomNumber(0, 100),
  lastPeriodValue: getRandomNumber(min, max),
  lastPeriodPercentage: getRandomNumber(0, 100),
});

export const singleChartBarList: ISingleChartBar[] = nameList.map(
  (name, index) => {
    let itemGroups = [
      generateItemGroup('Investment', 10000, 250000),
      generateItemGroup('Agreement', 250000, 300000),
      generateItemGroup('Support', 105000, 250000),
      generateItemGroup('Other', 1000, 50000),
    ];

    let totalValue = itemGroups.reduce(
      (acc, item) => acc + item.currentValue,
      0
    );
    let budgetValue = itemGroups.reduce(
      (acc, item) => acc + item.budgetValue,
      0
    );
    let lastPeriodValue = itemGroups.reduce(
      (acc, item) => acc + item.lastPeriodValue,
      0
    );

    return {
      name: name,
      totalValue: totalValue,
      percentage: (totalValue / budgetValue) * 100,
      budgetValue: budgetValue,
      lastPeriodValue: lastPeriodValue,
      itemGroups: itemGroups,
    };
  }
);

export const dummyMultiChartBar1: IMultiChartBar = {
  name: '2024',
  chartPeriod: EnumChartPeriod.week,
  chartType: EnumChartType.All,
  totalValue: singleChartBarList.reduce(
    (acc, item) => acc + item.totalValue,
    0
  ),
  lastPeriodValue: singleChartBarList.reduce(
    (acc, item) => acc + item.lastPeriodValue,
    0
  ),
  budgetValue: singleChartBarList.reduce(
    (acc, item) => acc + item.budgetValue,
    0
  ),
  itemGroups: singleChartBarList,
  // Beregn prosentandelen direkte her
  percentage:
    (singleChartBarList.reduce((acc, item) => acc + item.totalValue, 0) /
      singleChartBarList.reduce((acc, item) => acc + item.budgetValue, 0)) *
    100,
};

console.log('dummyMultiChartBar', dummyMultiChartBar1);

console.log('dummyMultiChartBar', dummyMultiChartBar1);

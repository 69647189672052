import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DocumentUploader = (props) => {
  const { t } = useTranslation();

  const { width, height, setDocuments } = props;

  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const handleOndrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleFiles(event);
  };

  const handleFiles = (e) => {
    const files = Array.from(e.dataTransfer.files); // Convert FileList to Array
    Promise.all(files.map(convertToBase64))
      .then((base64Files) => setDocuments(base64Files))
      .catch((err) => console.log(err));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      //console.log('files', file);
      fileReader.onload = () => resolve({ file, base64: fileReader.result });
      fileReader.onerror = (error) => reject(error);
    });
  };

  return (
    <Grid
      item
      sx={{
        width: width,
        height: height,
        backgroundColor: 'primary.light',
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 3,
      }}
      onDragOver={handleOndragOver}
      onDrop={handleOndrop}
    >
      <Typography
        sx={{
          mt: 2,
          p: 2,
          textAlign: 'center',
          alignContent: 'center',
          alignSelf: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'bold',
          fontSize: 14,
          color: 'primary.text',
        }}
      >
        {t("DocumentUploader.dragDropMessage")}

      </Typography>
    </Grid>
  );
};

export default DocumentUploader;

import * as React from "react";
import GetAvatarInitials from "functions/GetAvatarInitials";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { Grid, Box, Paper, Stack, Avatar, IconButton, Typography } from "@mui/material";
import GetColorByName from "functions/GetColorByName";
import { useEffect, useState } from "react";

// XXX Remove this after images is resolved
const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + "/img/",
};

const ShowFeatures = (props) => {
  const features = props.Features;

  return features.map((_features) => {
    if (_features) {
    }
    return (
      <Grid
        item
        key={_features.id}
        xs={6}
        sx={{ backGroundColor: "secondary.main" }}
      >
        <Stack direction={"row"}>
          <Typography
            noWrap
            sx={{
              minWidth: 150,
              fontSize: 14,
              letterSpacing: 1,
              fontWeight: "bold",
              textAlign: "left",
              color: "primary.text",
            }}
          >
            {_features.featureName}
          </Typography>
          <Typography
            noWrap
            sx={{
              width: "100%",
              fontSize: 14,
              letterSpacing: 2,
              fontWeight: "normal",
              textAlign: "left",
              color: "primary.text",
            }}
          >
            {_features.description}
          </Typography>
        </Stack>
      </Grid>
    );
  });
};

export const ProductCard = (props) => {
  const Product = props.product;
  const img = null;
  
  return (
    <Paper
      variant="elevation3"

      sx={{
       m:1,
       borderLeft: 8,
       borderRight: 3,
       borderTop: 0,
       borderBottom: 1,
       borderColor: GetColorByName(Product.productType.name),
       ':hover': {
        cursor: 'pointer',
        filter: 'brightness(120%)',
        transition: 'all 0.2s ease-in-out',
      },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Box
          sx={{
            pl: 2,
            pt: 1,
            minWidth: 100,
            maxWidth: 250,
            textAlign: "left",
            fontSize: 14,
            fontWeight: "bold",
            color: "primary.text",
          }}
        >
          {Product?.brand}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            pl: 2,
            pt: 1,
            textAlign: "left",
            fontSize: 14,
            letterSpacing: 3,
            fontWeight: "bold",
            textTransform: "uppercase",
            color: "primary.text",
          }}
        >
          {Product?.name}
        </Box>

        <Box
          sx={{
            pr: 2,
          }}
        >
          <IconButton aria-label="settings">
            <EditRoundedIcon />
          </IconButton>
          <IconButton aria-label="settings">
            <ShoppingCartRoundedIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Box sx={{ pl: 1, pb: 1 }}>
          {Product && img !== null ? (
            <Avatar
              variant="rounded"
              alt={Product.name}
              src={variables.PHOTO_URL + img + ".png"}
              sx={{ p: 1, width: 96, height: 96 }}
            />
          ) : (
            <Avatar {...GetAvatarInitials(Product.name, 96, 96, 0)} />
          )}
        </Box>
        <Box sx={{ pl: 1, pr: 2, flexGrow: 1 }}>
          <Grid
            container
            spacing={0}
            sx={{
              pl: 1,
              height: 96,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {Product.features && <ShowFeatures Features={Product.features} />}
          </Grid>
        </Box>
        <Box>
          <Box
            sx={{
              pr: 2,
              pt: 2,
              minWidth: 100,
              maxWidth: 250,
              textAlign: "right",
              whiteSpace: "nowrap",
              fontSize: 12,
              letterSpacing: 2,
              fontWeight: "bold",
              color: "secondary.text",
            }}
          >
            {Product?.retailPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

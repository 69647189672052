import { useEffect, useState } from 'react';
import {
  useDeleteModalProductData,
  usePutModalProductData,
} from 'http/useInnovit';

import {
  TextField,
  IconButton,
  Stack,
  Typography,
  Button,
  Box,
  Modal,
} from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

export const ModalProductData = (props) => {
  const {
    open,
    handleClose,
    category,
    setCategory,
    type,
    setType,
    setFeature,
    modalData,
  } = props;

  // states
  const [error, setError] = useState(null);
  const [updateOk, setUpdateOk] = useState(false);
  const [deleteOk, setDeleteOk] = useState(true);
  const [inputValue, setInputValue] = useState(null);

  // api
  const putModal = usePutModalProductData({ modalData }); // xxx replace this with rq
  const delModal = useDeleteModalProductData({ modalData }); // xxx replace this with rq

  /* FUNCTIONS */

  // handles
  const handleSubmitPut = () => {
    let data = {};
    switch (modalData?.title) {
      case 'Category':
        data = {
          id: modalData.data.id,
          name: inputValue,
        };
        break;
      case 'Type':
        data = {
          id: modalData.data.id,
          name: inputValue,
          productCategoryId: category.id,
          description: '',
        };
        break;
      case 'Feature':
        data = {
          id: modalData.data.id,
          name: inputValue,
        };
        break;
      case 'Feature Data':
        data = {
          id: modalData.data.id,
          name: inputValue,
        };
        break;
      default:
        break;
    }

    if (!putModal.isLoading && updateOk && !error) {
      putModal.mutate(data, {
        onSuccess: (res) => {
          switch (modalData?.title) {
            case 'Category':
              setCategory(res);
              break;
            case 'Type':
              setType(res);
              break;
            case 'Feature':
              setFeature(res);
              break;
            default:
              break;
          }
          handleClose();
        },
      });
    }
  };

  const handleSubmitDelete = () => {
    let data = {};
    switch (modalData?.title) {
      case 'Category':
        data = {
          id: modalData.data.id,
        };
        break;
      case 'Type':
        data = {
          id: modalData.data.id,
        };
        break;
      case 'Feature':
        data = {
          typeId: type.id,
          id: modalData.data.id,
        };
        break;
      case 'Feature Data':
        data = {
          id: modalData.data.id,
        };
        break;
      default:
        break;
    }

    if (!putModal.isLoading && deleteOk) {
      delModal.mutate(data, {
        onSuccess: (res) => {
          switch (modalData?.title) {
            case 'Category':
              setCategory(null);
              setType(null);
              setFeature(null);
              break;
            case 'Type':
              setType(null);
              setFeature(null);
              break;
            case 'Feature':
              setFeature(null);
              break;
            default:
              break;
          }
          handleClose();
        },
        onError: (err) => {
          //console.log("delete error", err.message);
        },
      });
    }
  };

  const handleExit = (e) => {
    setInputValue(null);
    setUpdateOk(false);
    setDeleteOk(true);
    setError(null);
    handleClose();
  };

  const handleChange = (e) => {
    e.preventDefault();

    let myRe = /[^a-zA-Z0-9 ]/g;
    if (myRe.test(e.target.value)) {
      setError('Only letters and numbers are allowed');
    } else {
      setError(null);
    }
    // update ok
    if (e.target.value.length > 0 && e.target.value !== modalData.value) {
      setUpdateOk(true);
      setInputValue(e.target.value);
    } else {
      setUpdateOk(false);
    }
    // delete ok
    e.target.value === modalData.value ? setDeleteOk(true) : setDeleteOk(false);
  };

  useEffect(() => {}, [category?.id, type?.id, modalData]);

  return (
    <Modal
      open={open}
      onClose={handleExit}
      aria-labelledby={`modal-${modalData?.title}`}
      aria-describedby={`modal-edit-${modalData?.title}`}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'primary.dark',
          borderRadius: 3,
          boxShadow: 24,
        }}
      >
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-start'
          spacing={0}
          sx={{ mr: 1 }}
        >
          <IconButton
            type='close'
            edge='end'
            aria-label='close'
            onClick={handleClose}
          >
            <ClearRoundedIcon sx={{ fontSize: 22 }} />
          </IconButton>
        </Stack>
        <Typography
          sx={{ p: 2 }}
          id={`modal-${modalData?.title}`}
          variant='h6'
          component='h2'
        >
          Edit {modalData?.title}
        </Typography>

        <TextField
          name='modalInput'
          autoFocus
          onFocus={(event) => {
            event.target.select();
          }}
          fullwidth
          className='subvariant-hovered'
          sx={{ m: 0, p: 2 }}
          helperText={error ? error : null}
          error={!!error}
          onChange={(e) => handleChange(e)}
          // value={modalData?.value}
          defaultValue={modalData?.value}
        />
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-start'
          spacing={2}
          sx={{ p: 2 }}
        >
          <Button
            type='submit'
            variant='contained'
            disabled={putModal.isLoading || !updateOk || !!error}
            onClick={handleSubmitPut}
            name='put'
          >
            Update
          </Button>
          <Button
            type='submit'
            variant='contained'
            disabled={putModal.isLoading || !deleteOk}
            onClick={handleSubmitDelete}
            name='delete'
          >
            Delete
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

import React from 'react';
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FormBox } from 'components/form';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import FormControl from '@mui/material/FormControl';
import {
  EnumScheduleType,
  IScheduleViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { t } from 'i18next';

type Props = {
  scheduleTime: IScheduleViewModel;
  handleChange: (changeType: string, e: any) => void;
};
/* const initialState: IScheduleViewModel = {
  id: '0',
  scheduleType: EnumScheduleType.None,
  deliveryDate: new Date(),
  startDate: new Date(),
  estimatedTime: {
    hours: 0,
    minutes: 0,
  },
};
 */
const hours = Array.from({ length: 24 }, (_, i) => i);
const minutes = Array.from({ length: 12 }, (_, i) => i * 5);

const scheduleTypeOptions = Object.keys(EnumScheduleType)
  .filter((key) => !isNaN(Number(EnumScheduleType[key as any])))
  .map((key) => ({
    value: EnumScheduleType[key as keyof typeof EnumScheduleType],
    label: key,
  }));

export const ScheduleView = ({ scheduleTime, handleChange }: Props) => {
  /*  const [selectedSchedule, setSelectedSchedule] =
  useState<IScheduleViewModel>(scheduleTime);
  
 
  

  const handleDate = (newDate: Date | null, dateType: keyof IScheduleViewModel) => {
    if (!newDate) return; // Exit if newDate is null
  
    const newDateDayjs = dayjs(newDate);
  
    setSelectedSchedule((prevSchedule) => {
      // Clone the current state
      let newState = { ...prevSchedule };
  
      // Update the specified date field
      newState[dateType as keyof Pick<IScheduleViewModel, 'deliveryDate' | 'startDate'>] = newDateDayjs.toDate();
  
     // Additional logic for startDate
    if (dateType === 'deliveryDate') {
      // Check if the deliveryDate is a Monday
      if (newDateDayjs.day() === 1) { // Day.js treats Sunday as 0, Monday as 1, and so on.
        // Set startDate to the previous Friday at 07:00
        const startDateDayjs = newDateDayjs.subtract(3, 'day').set('hour', 7).set('minute', 0);
        newState.startDate = startDateDayjs.toDate();
      } else {
        // If the deliveryDate is not a Monday, set startDate to the day before at 07:00
        const startDateDayjs = newDateDayjs.subtract(1, 'day').set('hour', 7).set('minute', 0);
        newState.startDate = startDateDayjs.toDate();
      }
    }
      return newState;
    });
  };
  const handleScheduleType = (event:any) => {
    const newScheduleType = event.target.value;
    setSelectedSchedule((prevSchedule) => ({
      ...prevSchedule,
      scheduleType: newScheduleType,
    }));
  }
  const handleHourChange = (event:any) => {
    const newHours = event.target.value;
    setSelectedSchedule((prevSchedule) => ({
      ...prevSchedule,
      estimatedTime: {
        ...prevSchedule.estimatedTime,
        hours: newHours,
      },
    }));
  };
  const handleMinuteChange = (event:any) => {
    const newMinutes = event.target.value;
    setSelectedSchedule((prevSchedule) => ({
      ...prevSchedule,
      estimatedTime: {
        ...prevSchedule.estimatedTime,
        minutes: newMinutes,
      },
    }));
  };
  

  useEffect(() => {    
    handleChange(selectedSchedule);
  }, [selectedSchedule]);

  useEffect(() => {
    if (scheduleTime.scheduleType === EnumScheduleType.None && selectedSchedule.scheduleType !== EnumScheduleType.None)
    {
      setSelectedSchedule(scheduleTime);
      return;      
    }
  }, [scheduleTime]);
 */

  return (
    <Grid item xs={12} sx={{ p: 0, m: 0 }}>
      <FormBox Icon={FeedRoundedIcon} title={t("ScheduleView.scheduleTime")} />
      <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
        <Grid item xs={2} sx={{ m: 0, p: 0, pr: 2 }}>
          <Stack direction={'column'} sx={{ pl: 2 }}>
            <Typography
              sx={{
                width: '100%',
                letterSpacing: 1,
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              {t("ScheduleView.type")}
            </Typography>
            <Select
              variant='standard'
              labelId='selected-department-label'
              id='select-schedule-type'
              value={scheduleTime.scheduleType as EnumScheduleType}
              label={t("ScheduleView.selectScheduleType")}
              onChange={(e) =>
                handleChange('TYPE', e.target.value as EnumScheduleType)
              }
            >
              {scheduleTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={3.5} sx={{ m: 0, p: 0, pr: 2 }}>
          <Stack direction={'column'} sx={{}}>
            <Typography
              sx={{
                width: '100%',
                letterSpacing: 1,
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              {t("ScheduleView.deliveryDate")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDateTimePicker
                format='DD/MM/YYYY HH:mm'
                value={
                  dayjs(scheduleTime.deliveryDate)
                    ? dayjs(new Date(scheduleTime.deliveryDate))
                    : dayjs(new Date())
                }
                onChange={(newDate: any) =>
                  handleChange('DELIVERY_DATE', newDate)
                }
                onClose={() => {
                  //console.log('close endtime', e.target.value);
                }}
                ampm={false}
                views={['year', 'month', 'day', 'hours', 'minutes']}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={3.5} sx={{ m: 0, p: 0, pr: 2 }}>
          <Stack direction={'column'} sx={{}}>
            <Typography
              sx={{
                width: '100%',
                letterSpacing: 1,
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              {t("ScheduleView.scheduleDate")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDateTimePicker
                format='DD/MM/YYYY HH:mm'
                value={
                  dayjs(scheduleTime.startDate)
                    ? dayjs(new Date(scheduleTime.startDate))
                    : dayjs(new Date())
                }
                onChange={(newDate: any) =>
                  handleChange('SCHEDULED_DATE', newDate)
                }
                onClose={() => {
                  //console.log('close endtime', e.target.value);
                }}
                ampm={false}
                views={['year', 'month', 'day', 'hours', 'minutes']}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={3} sx={{ m: 0, p: 0, pr: 0 }}>
          <Stack direction={'column'} sx={{}}>
            <Typography
              sx={{
                width: '100%',
                letterSpacing: 1,
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              {t("ScheduleView.etcHoursMinutes")}
            </Typography>
            <Stack direction={'row'} sx={{ width: '100%' }}>
              <FormControl sx={{ width: '50%', pr: 2, pl: 2 }}>
                <Select
                  variant='standard'
                  value={scheduleTime.estimatedTime?.hours}
                  onChange={(e) =>
                    handleChange('HOURS', e.target.value as Number)
                  }
                  label={t("ScheduleView.hour")}
                >
                  {hours.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
  
              <FormControl sx={{ width: '50%', pr: 2, pl: 2 }}>
                <Select
                  sx={{ width: '100%' }}
                  variant='standard'
                  value={scheduleTime.estimatedTime?.minutes}
                  onChange={(e) =>
                    handleChange('MINUTES', e.target.value as Number)
                  }
                  label={t("ScheduleView.minute")}
                >
                  {minutes.map((minute) => (
                    <MenuItem key={minute} value={minute}>
                      {minute}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}
import {Outlet, useNavigate, useParams } from 'react-router-dom';
import useWindowsDimension from 'functions/useWindowsDimension';
import Grid from '@mui/material/Grid';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { PageHeader } from 'components/ui/Global/PageHeader';
import { TicketHeaderButtonDetails } from './Components/TicketStyleConfig';
import { useTranslation } from 'react-i18next';

export const Ticket = () => {
const { t } = useTranslation();

  /* INITIALIZATIONS */
  const { ticketId } = useParams();
  const { width } = useWindowsDimension();
  let navigate = useNavigate();

  // api

  if (ticketId === undefined) {
    return null;
  }
  const TicketHeaderLinks = TicketHeaderButtonDetails(ticketId, null).map(button => ({
    ...button,
    name: t(button.name), // Localize the name here
  }));

  return (
    <Grid container direction='row' spacing={0}>
      <Grid item xs={12} sx={{}}>
        <PageHeader
          Icon={SupportAgentRoundedIcon}
          title='Tickets'
          FormJSX={null}
          HeaderButtons={TicketHeaderLinks}
        />
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

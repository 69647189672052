import { Box, Divider, Grid, Typography } from '@mui/material';
import { IItemGroup, ISingleChartBar } from 'Interfaces/IChartConfig';
import GetSingleMultiBarColor from 'functions/GetSingleMultibarColor';
import { formatNumber } from 'functions/formatNumber';
import { useEffect, useRef, useState } from 'react';

// Funksjoner og grensesnitt importeres her...

interface SingleMultiBarProps {
  data: ISingleChartBar;
  width: number;
  height: number;
}

const getBarColor = (index: number): string => {
  // Erstatt dette med din GetSingleMultiBarColor logikk om nødvendig
  const colors = ['darkgreen', 'lightgreen', 'yellow', 'red'];
  return colors[index] || 'grey';
};

export const SingleMultiBar: React.FC<SingleMultiBarProps> = ({
  data,
  width,
  height,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const maxHeight = height-10; // Maksimal total høyde for søylene
  const budgetValuePlacement = 150; // Plasseringen av budsjettverdilinjen
  const BarBottomStart = 30; // Plasseringen av totalverdilinjen
  const totalValuePlacemant = 0; // Plasseringen av totalverdilinjen
  const namePlacement = -20; // Plasseringen av navnet
 
  const totalValue = data.itemGroups.reduce(
    (sum: number, item: IItemGroup) => sum + item.currentValue,
    0
  );

  // Bestem skalaen basert på totalverdien og budsjettverdien
  let scale = (budgetValuePlacement - BarBottomStart) / data.budgetValue;

  // Hvis totalverdien overskrider budsjettverdien, sjekk om den også overskrider maxHeight
  if (totalValue > data.budgetValue) {
    const projectedHeight = totalValue * scale + BarBottomStart;
    if (projectedHeight > maxHeight) {
      scale = (maxHeight - BarBottomStart) / totalValue;
    }
  }

  // Beregn den akkumulerte høyden for å plassere hver farge i søylen riktig
  let accumulatedHeight = BarBottomStart;

  return (
    <Grid container ref={containerRef} sx={{ width, height, position: 'relative' }}>
      <Box
            sx={{
              position: 'absolute',
              bottom: BarBottomStart,
              width: '80%',
              left: '10%',
              height: maxHeight,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '10px 10px 0 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
      {data.itemGroups.map((item:IItemGroup, index:number) => {
        const relativeHeight = item.currentValue * scale;
        const itemBottom = accumulatedHeight;
        accumulatedHeight += relativeHeight;

        return (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              bottom: `${itemBottom}px`,
              width: '80%',
              left: '10%',
              height: `${relativeHeight}px`,
              backgroundColor: GetSingleMultiBarColor(index),
              borderRadius: index === 0 ? '0 0 10px 10px' : index === data.itemGroups.length - 1 ? '10px 10px 0 0' : '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        );
      })}

      <Box
        sx={{
          width: '90%',
          textAlign: 'center',
          position: 'absolute',
          bottom: `${budgetValuePlacement}px`,
          left: '5%',
        }}
      >
        <Typography variant='caption' sx={{ color: 'white', fontSize:10, }}>
          {formatNumber(data.budgetValue,1)}
          {/* Anta at denne funksjonen eksisterer */}
        </Typography>
        <Divider sx={{ backgroundColor: 'white', height: '2px' }} />
      </Box>

      <Box
        sx={{
          width: '90%',
          textAlign: 'center',
          position: 'absolute',
          bottom: `${totalValuePlacemant}px`,
          left: '5%',
        }}
      >
        <Divider sx={{ backgroundColor: 'white', height: '2px', m: 0, p: 0 }} />
        <Typography variant='caption' sx={{ color: 'white',fontSize:10, }}>
          {formatNumber(data.totalValue,1)}
          {/* Anta at denne funksjonen eksisterer */}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          position: 'absolute',
          bottom: `${namePlacement}px`,
          left: '0',
        }}
      >
        <Typography variant='caption' sx={{ color: 'white',fontSize:12, }}>
          {data.name} {/* Anta at denne funksjonen eksisterer */}
        </Typography>
      </Box>
      {/* Eventuelt andre komponenter og innhold som skal vises i grafen */}
    </Grid>
  );
};

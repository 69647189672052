import * as React from 'react';
import { useNavigate } from 'react-router';
import { Grid, Typography } from '@mui/material';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

export const SingleOrderCompanyView = (props: any) => {
  const company = props.company;
  return (
    <Grid container flexGrow={1} direction={'row'} sx={{ height: 88 }}>
      <Grid
        item
        xs={3}
        sx={{
          //  width: 128,
          borderRadius: 2,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: 'primary.light',
          display: 'flex',
        }}
      >
        <img
          src={company?.url || MissingPhoto.COMPANY_URL}
          style={{
            padding: 0,
            width: '100%',
            height: '100%',
            objectFit: 'scale-down',
          }}
          alt={'logo'}
        />
      </Grid>
      <Grid item xs={9}>
        <Grid container flexGrow={1} direction={'row'} sx={{ m: 0, p: 0 }}>
          <Grid item xs={12} sx={{ m: 0, p: 0 }}>
            <Typography
              sx={{
                m: 0,
                p: 0,
                pl: 1,
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 16,
              }}
            >
              {company?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ m: 0, p: 0 }}>
            <Typography
              sx={{
                m: 0,
                p: 0,
                pl: 1,
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 14,
                color: 'secondary.text',
              }}
            >
              {company?.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ m: 0, p: 0 }}>
            <Typography
              sx={{
                m: 0,
                p: 0,
                pl: 1,
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {company?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ m: 0, p: 0 }}>
            <Typography
              sx={{
                m: 0,
                p: 0,
                pl: 1,
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {company?.webPage || 'No webPage'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import React from 'react'; // Import React if not globally imported
import { Avatar, Grid, Paper, Stack, Typography } from '@mui/material';
import { IUserViewModel } from 'data/atoms/atomConfig';
import getInitials from 'functions/GetInitials';

interface ProfileViewProps {
  user: any;
}

interface IImg {
  PRODUCT: string;
  VENDOR: string;
  EMPLOYEE: string;
}
const missingImage: IImg = {
  PRODUCT: process.env.PUBLIC_URL + '/img/noImage.png',
  VENDOR: process.env.PUBLIC_URL + '/img/noCompany.png',
  EMPLOYEE: process.env.PUBLIC_URL + '/img/noEmployee.png',
};

export const ProfileView: React.FC<ProfileViewProps> = (props) => {
  const { user } = props;  return (
    <Paper variant='elevation3' sx={{ p: 2, mb: 2, width: '100%' }}>
      <Grid container direction={'row'}>
      <img
        
        src={user?.url || missingImage.EMPLOYEE}
        alt='Url'
        style={{
          width: 256,
          height: 256,
          borderRadius: 2,
          objectFit: 'scale-down',
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
        }}
      />
        <Stack direction='column' spacing={0} sx={{ p: 2 }}>
          <Typography variant='h5'>{user.name}</Typography>
          <Typography variant='body1'>{user.email}</Typography>
          <Typography variant='body1'>{user.phone}</Typography>
          <Typography variant='body1'>{user.title}</Typography>
        </Stack>
      </Grid>
    </Paper>
  );
};

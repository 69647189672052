import { useState, useEffect } from 'react';
import {
  EnumObjectType,
  allObjectList,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import _ from 'lodash';

export function useGetObjectList(
  companyId: string,
  employeeId: string,
  departmentId: string,
  resourceId: string,
  isReloadNeeded: boolean
) {
  const [objectDrawerList, setObjectDrawerList] =
    useState<objectArray[]>(allObjectList);

  // "useGet" kan nå kalles her siden "useGetObjectList" er en egendefinert hook
  const { data: objectListData } = useGet(
    rq.objectList(
      companyId ?? 'new',
      employeeId ?? 'new',
      departmentId ?? 'new',
      resourceId ?? 'new',
      isReloadNeeded
    ),
    isReloadNeeded
  );

  useEffect(() => {
    if (objectListData) {
      let newObjectDrawerList = _.cloneDeep(allObjectList);
     // console.log('ORGINAL objectListData', JSON.stringify(objectListData));
      /* Komplett liste */

      newObjectDrawerList[Number(EnumObjectType.Product)].objectList =
        objectListData.productCatalogList || [];
      newObjectDrawerList[Number(EnumObjectType.StorageProduct)].objectList =
        objectListData.storageProductList || [];
      newObjectDrawerList[
        Number(EnumObjectType.unassignedCompanyProduct)
      ].objectList = objectListData.unassignedCompanyProductList || [];
      newObjectDrawerList[Number(EnumObjectType.Package)].objectList =
        objectListData.productPackageList || [];
      newObjectDrawerList[Number(EnumObjectType.WorkRole)].objectList =
        objectListData.workRoleList || [];
      newObjectDrawerList[Number(EnumObjectType.CompanyEmployees)].objectList =
        objectListData.companyEmployeeList || [];
      newObjectDrawerList[Number(EnumObjectType.AppOwnerEmployees)].objectList =
        objectListData.appOwnerEmployeeList || [];
      newObjectDrawerList[Number(EnumObjectType.primaryContacts)].objectList =
        objectListData.primaryContactList || [];
      newObjectDrawerList[Number(EnumObjectType.EmployeeProducts)].objectList =
        objectListData.employeesProductList || [];
      newObjectDrawerList[Number(EnumObjectType.IssueType)].objectList =
        objectListData.issueTypeList || [];
      newObjectDrawerList[Number(EnumObjectType.Vendor)].objectList =
        objectListData.vendorList || [];
      newObjectDrawerList[Number(EnumObjectType.allEmployees)].objectList =
        objectListData.allEmployeeList || [];
      newObjectDrawerList[Number(EnumObjectType.Department)].objectList =
        objectListData.departmentList || [];
      newObjectDrawerList[Number(EnumObjectType.Resource)].objectList =
        objectListData.resourceList || [];
      newObjectDrawerList[Number(EnumObjectType.ProductCategories)].objectList =
        objectListData.productCategorieList || [];
      newObjectDrawerList[Number(EnumObjectType.Brands)].objectList =
        objectListData.brandList || [];
      newObjectDrawerList[Number(EnumObjectType.sharedProducts)].objectList =
        objectListData.sharedProductList || [];
      newObjectDrawerList[Number(EnumObjectType.ResourceTypes)].objectList =
        objectListData.resourceTypeList || [];

      // company
      if (objectListData.selectedCompany) {
        newObjectDrawerList[Number(EnumObjectType.SelectedCompany)].objectList =
          [objectListData.selectedCompany];
      } else {
        newObjectDrawerList[Number(EnumObjectType.SelectedCompany)].objectList =
          [];
      }

      // department
      if (objectListData.selectedDepartment) {
        newObjectDrawerList[
          Number(EnumObjectType.SelectedDepartment)
        ].objectList = [objectListData.selectedDepartment];
      } else {
        newObjectDrawerList[
          Number(EnumObjectType.SelectedDepartment)
        ].objectList = [];
      }
      // resource
      if (objectListData.selectedResource) {
        newObjectDrawerList[
          Number(EnumObjectType.SelectedResource)
        ].objectList = [objectListData.selectedResource];
      } else {
        newObjectDrawerList[
          Number(EnumObjectType.SelectedResource)
        ].objectList = [];
      }

      setObjectDrawerList(newObjectDrawerList);
      console.log('ORGINAL ObjectDrawerList', newObjectDrawerList);
    }
  }, [objectListData]);

  return objectDrawerList;
}

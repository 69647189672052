export default function GetChartColor(props:number) {
 // console.log("GetPriorityColor", props);

  let color = "primary";

  switch (props) {  
    case 0:
      color = 'success';
      break;
    case 1:
      color = 'secondary';
      break;
    case 2:
      color = 'warning';
      break;
    case 3:
      color = 'error';
      break;
    case 4:
      color = 'info';
      break;
    case 5:
      color = 'third';
      break;
    default:
      color = 'primary';
      break;
  }
 // console.log("GetPriorityColor color - " , color);

  return color;
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import GetAvatarInitials from 'functions/GetAvatarInitials';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import {
  Grid,
  Paper,
  Avatar,
  Stack,
  AvatarGroup,
  IconButton,
  Badge,
} from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import GetPriorityColor from 'functions/GetPriorityColor';
import { useNavigate } from 'react-router';

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};

export const SingleSaleView = (props) => {
  let navigate = useNavigate();
  const id = `${props.ticket.id}`;
  const index = props.index;
  const ticket = props.ticket;

  //console.log("SingleSaleView", ticket);
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Paper
          variant='elevation3'
          sx={{
            backgroundColor: `${
              snapshot.isDragging ? 'primary.light' : 'primary.main'
            }`,
            p: 1,
            mb: 1,
            borderLeft: 8,
            borderColor: GetPriorityColor(ticket.priority),
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Stack direction={'row'}>
            <Grid container direction={'row'}>
              <Grid item sx={{ mr: 1, width: 82 }}>
                <Stack direction={'column'}>
                  <Typography
                    sx={{
                      m: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {ticket.id}
                  </Typography>
                  <Typography
                    sx={{
                      m: 0,
                      letterSpacing: 1,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {ticket.timeStamp}
                  </Typography>
                  <Typography
                    sx={{
                      m: 0,
                      p: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                  >
                    Age {ticket.hoursTotal}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item sx={{ mr: 1, width: 100 }}>
                <Paper
                  sx={{
                    mx: 'auto',
                    display: 'flex',
                    alignSelf: 'flex-center',
                    elevation: 0,
                    backgroundColor: 'primary.text',
                    mt: 0.2,
                    p: 1,
                  }}
                >
                  {!!ticket.company.logo ? (
                    <Avatar
                      variant='rounded'
                      alt={ticket.company.name}
                      src={variables.PHOTO_URL + ticket.company.logo + '.png'}
                      sx={{
                        mx: 'auto',
                        borderRadius: 2,
                        width: 88,
                        height: 44,
                      }}
                    />
                  ) : (
                    <Avatar
                      {...GetAvatarInitials(ticket.company.name, 88, 44, 3)}
                    />
                  )}
                </Paper>
                {/*  <Box
            sx={{
              display: "flex",
              width: 100,
              height: 5,
              mt: 1,
              backgroundColor: GetPriorityColor(ticket.priority),
            }}
          />  */}
              </Grid>
              <Grid item>
                {/* <Paper
                  sx={{
                    mx: "auto",
                    display: "flex",
                    alignSelf: "flex-center",
                    elevation: 0,
                    backgroundColor: "transparent",

                    borderRadius: 2,
                    borderLeft: 3,
                    borderRight: 0,
                    borderBottom: 0,
                    borderColor: GetColorByName(ticket.product[0].name),
                    mt: 0.2,
                    p: 1,
                  }}
                > */}

                {!!ticket.product[0].url ? (
                  <>
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      badgeContent={
                        ticket.product.length > 1 ? ticket.product.length : ''
                      }
                      color={
                        ticket.product.length > 1 ? 'secondary' : 'primary'
                      }
                    >
                      <Avatar
                        variant='rounded'
                        alt={ticket.product[0].name}
                        src={
                          variables.PHOTO_URL + ticket.product[0].url + '.png'
                        }
                        sx={{
                          mx: 'auto',
                          borderRadius: 2,
                          width: 120,
                          height: 60,
                        }}
                      />
                    </Badge>
                  </>
                ) : (
                  <Avatar
                    {...GetAvatarInitials(ticket.product[0].name, 88, 44, 3)}
                  />
                )}
                {/*   </Paper> */}
              </Grid>

              <Grid item sx={{ ml: 1, mr: 1, flexGrow: 1 }}>
                <Stack direction={'column'}>
                  <Typography
                    sx={{
                      m: 0,
                      p: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                  >
                    {ticket.product[0].name}
                  </Typography>

                  <Typography
                    sx={{
                      m: 0,
                      p: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {ticket.product[0].brand}
                  </Typography>
                  <Typography
                    sx={{
                      m: 0,
                      p: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: 'secondary.text',
                    }}
                  >
                    {ticket.product[0]?.retailPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={2} sx={{}}>
                <AvatarGroup max={2}>
                  {ticket.contacts.map((employee) =>
                    !!employee.logo ? (
                      <Avatar
                        key={employee.id}
                        alt={employee.name}
                        src={variables.PHOTO_URL + employee.logo + '.png'}
                        sx={{ borderRadius: 10, width: 48, height: 48 }}
                      />
                    ) : (
                      <Avatar
                        key={employee.id}
                        {...GetAvatarInitials(employee.name, 48, 48, 10)}
                      />
                    )
                  )}
                </AvatarGroup>
              </Grid>
            </Grid>
            <Grid sx={{}}>
              <IconButton
                sx={{ mr: -1, mt: -1 }}
                onClick={() => {
                  navigate(`/ticket/${ticket.id}`);
                }}
              >
                <LaunchRoundedIcon sx={{ fontSize: 26 }} />
              </IconButton>
            </Grid>
          </Stack>
        </Paper>
      )}
    </Draggable>
  );
};

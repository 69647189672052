import React, {
  useEffect,
  useReducer,
} from 'react';
import {
  Grid,
  Box,
  Paper,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ProfileView } from 'components/ui/Account/ProfileView';
import { ResponsibleList } from 'components/ui/Account/ResponsibleList';
import SourceRoundedIcon from '@mui/icons-material/SourceRounded';
import { usePut } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import userAtom from 'data/atoms/userAtom';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { useTranslation } from 'react-i18next';


interface State {
  workRole: IWorkRoleViewModel;
  workRoles: IWorkRoleViewModel[];
}

// Action type definition
interface Action {
  type: string;
  workRole: IWorkRoleViewModel;
}

// Initial state


const reducer = (state: any, action: any) => {
  //console.log("reducer", state, action);
  switch (action.type) {
    case 'CHANGE_WORK_ROLE':
      return {
        ...state,
        workRole: action.workRole,
      };
    default:
      return null;
  }
};

interface HeaderProps {
  icon: React.ElementType;
  action?: React.ElementType;
  headerText: string;
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  icon: IH,
  action: AH,
  headerText: HT,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        p: 1,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <IH sx={{ fontSize: 40 }} />
      </Box>
      <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
        <Typography
          noWrap
          sx={{
            textTransform: 'capitalize',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: 4,
          }}
        >
          {HT} {children}
        </Typography>
      </Box>
      <Box sx={{}}>{AH && <AH sx={{ fontSize: 40 }} />}</Box>
    </Box>
  );
};

export const AccountPage: React.FC = () => {
  const { t } = useTranslation();
  const [user, setUser] = useAtom(userAtom);
  const [owner, setOwner] = useAtom(ownerAtom);

  const initialState: State = {
    workRole: {} as IWorkRoleViewModel,
    workRoles: [],
  };

  // STATE
  const [state, dispatch] = useReducer(reducer, initialState);
  let isEnabled = owner && user ? true : false;
  const putUser = usePut(
    rqPost.employee(owner.id, user.id, isEnabled),
    isEnabled
  );

  // EFFECT
  useEffect(() => {
    // Update state when user is available
    if (user) {
      dispatch({ type: 'CHANGE_WORK_ROLE', workRole: user.workRole || {} });
    }
  }, [user]);

  // HANDLERS
  const handleBoardSubmit = (e: SelectChangeEvent<string>) => {
    e.preventDefault();
    //console.log('handleBoardSubmit', e.target.value);
    let workRole = e.target.value as string; // Assuming workRole id is a string
    const foundWorkRole = owner?.workRoles.find(
      (role: IWorkRoleViewModel) => role.id === workRole
    );

    if (foundWorkRole) {
      dispatch({ type: 'CHANGE_WORK_ROLE', workRole: foundWorkRole });
      user.workRole = foundWorkRole;
      user.workRoleId = foundWorkRole.id;
      putUser.mutate(user);
    } else {
      // Handle error case when work role is not found
      console.error('Work role not found');
    }
  };
  return (
    <Grid container spacing={2} flexGrow={1} direction={'row'}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Grid container flexGrow={1} direction={'row'}>
          <ProfileView user={user} />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <form
              /* onSubmit={handleSubmit(onSubmit)}  */
              name='boardForm'
            >
              <Paper
                variant='elevation3'
                sx={{
                  mb: 2,
                  p: 1,
                  backgroundColor: 'primary.main',
                }}
              >
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs={8}>
                    <Header
                      icon={SourceRoundedIcon}
                      headerText={t('myPage.workRole')}
                    ></Header>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <FormControl variant='standard' fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        WorkRole
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={state.workRole.id || ''}
                        label='Work role'
                        onChange={(e) => handleBoardSubmit(e)}
                        disabled={!owner || !owner.workRoles || owner.workRoles.length === 0}
                      >
                        {owner && owner.workRoles && owner.workRoles.length > 0 && (
                          owner.workRoles.map((workRole: IWorkRoleViewModel) => (
                            <MenuItem key={workRole.id} value={workRole.id}>
                              {workRole.title}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'success.main',
                color: 'secondary.Text',
              }}
            >
              HOLIDAY REQUEST
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'warning.main',
                color: 'warning.Text',
              }}
            >
              SICK STATUS
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'info.main',
                color: 'info.Text',
              }}
            >
              SCHEDULED TIME REMINDERS
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'error.main',
                color: 'primary.dark',
              }}
            >
              PRODUCTS
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
              }}
            >
              ACTION LIST
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <ResponsibleList />
      </Grid>
    </Grid>
  );
};

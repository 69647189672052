import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, Avatar, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import getInitials from 'functions/GetInitials';
import { EnumPlacement, MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

export const SingleCompanyProductView = (props: any) => {
  const product = props.product;

  // console.log('SingleCompanyProductView ', props);
  return (
    <Grid
      container
      flexGrow={1}
      direction={'row'}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(
          product?.productType?.name || product?.product?.productType?.name
        ),
        backgroundColor:
          product?.owner || product?.placement === (3 as EnumPlacement)
            ? 'primary.main'
            : 'primary.main',

        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
        height: 84,
      }}
    >
      <Grid
        item
        sx={{
          backgroundColor: 'primary.light',
          width: 84,
          height: 84,
        }}
      >
        <img
          src={
            product?.product?.url
              ? product?.product?.url
              : MissingPhoto.PRODUCT_URL
          }
          style={{
            padding: 5,
            borderRadius: 3,
            objectFit: 'scale-down',
            width: 80,
            height: 80,
          }}
          alt={'previewUrl'}
        />
      </Grid>
      <Grid item flex={1} sx={{}}>
        <Grid container display={'flex'} direction={'row'}>
          <Grid
            item
            xs={10}
            sx={{
              pl: 1,
              height: 80,
            }}
          >
            <Stack direction={'column'}>
              <Stack direction={'row'}>
                <Typography
                  sx={{
                    letterSpacing: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}
                >
                  {product?.product.name}
                </Typography>

                <Typography
                  sx={{
                    pl: 2,
                    pt: 0.5,
                    letterSpacing: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: 12,
                  }}
                >
                  {product?.serviceTag || ''}
                </Typography>
              </Stack>
              {(product?.owner ||
                product?.placement === (3 as EnumPlacement)) && (
                <Grid
                  container
                  sx={{
                    height: 56,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: 56,
                      height: 56,
                      backgroundColor: 'primary.light',
                    }}
                  >
                    <img
                      src={product?.owner?.url || MissingPhoto.EMPLOYEE_URL}
                      style={{
                        width: 56,
                        height: 56,
                        borderRadius: 3,
                        objectFit: 'contain',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                      }}
                      alt={'previewUrl'}
                    />
                  </Grid>
                  <Grid item sx={{}}>
                    <Stack direction={'column'}>
                      <Typography
                        noWrap
                        sx={{
                          pl: 1,
                          fontSize: 12,
                          letterSpacing: 1,
                          fontWeight: 'bold',
                          textAlign: 'left',
                          color: 'primary.text',
                        }}
                      >
                        {product?.owner?.name || 'Common Company Product'}
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          pl: 1,
                          fontSize: 12,
                          letterSpacing: 1,
                          fontWeight: 'bold',
                          textAlign: 'left',
                          color: 'primary.text',
                        }}
                      >
                        {product?.owner?.email || ''}
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          pl: 1,
                          fontSize: 12,
                          letterSpacing: 1,
                          fontWeight: 'bold',
                          textAlign: 'left',
                          color: 'primary.text',
                        }}
                      >
                        {product?.owner?.phone || ''}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Grid>

          <Grid item xs={2} sx={{}}>
            <Stack direction={'column'}>
              <Typography
                noWrap
                sx={{
                  pr: 1,
                  letterSpacing: 0,
                  textAlign: 'right',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  fontSize: 14,
                }}
              >
                ( {product?.product?.brand?.brandName || 'No brand'} )
              </Typography>
              <Typography
                sx={{
                  height: 64,
                  pr: 1,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  alignContent: 'stretch',
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textTransform: 'capitalize',
                  color: 'secondary.text',
                }}
              >
                {product?.retailPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

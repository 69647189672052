import React, { useRef, useLayoutEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { MultiBarView } from 'components/ui/Charts/MultiBarView';
import useWindowsDimension from 'functions/useWindowsDimension';
import { dummyMultiChartBar1 } from 'components/ui/Charts/Components/YearChartDummyData';

export const Test = () => {
  const { width, height } = useWindowsDimension();

  const parentRef = useRef<HTMLDivElement>(null); // Spesifiserer HTMLDivElement som type for ref
  const [parentWidth, setParentWidth] = useState(0);

  useLayoutEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    updateParentWidth(); // Oppdater ved mount
    window.addEventListener('resize', updateParentWidth); // Oppdater ved vindu endres

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  return (
    <Grid container display={'flex'} direction={'row'} sx={{ m: 0, p: 0, height: height - 28, backgroundColor: 'primary.back' }}>
      <Grid ref={parentRef} xs={6} sx={{ pr: .5 }}>
        <MultiBarView
          data={dummyMultiChartBar1}
          width={parentWidth}
          height={350}
        />
      </Grid>
      <Grid xs={6} sx={{ pl: .5 }}>
        <MultiBarView
          data={dummyMultiChartBar1}
          width={parentWidth }
          height={350}
        />
      </Grid>
    </Grid>
  );
};

export const Header = {
  icon: {
    fontSize: 38,
    color: 'primary.text',
    m: 1,
  },
  text: {
    p: 1.5,
    flexGrow: 1,
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    color: 'primary.text',
    fontSize: 20,
    letterSpacing: 4,
    display: 'flex', // <-- Set this to flex
    justifyContent: 'center', // <-- This centers children horizontally
    alignItems: 'center', // <-- This centers children vertically
  },
};
export const SearchFilterStyle = {
  icon: {
    fontSize: 35,
    color: 'primary.text',
    m: 1,
  },
  buttonBox: {
    m: 0.5,
    width: 30,
    height: 30,
    backgroundColor: 'primary.light',
    display: 'flex', // <-- Set this to flex
    justifyContent: 'center', // <-- This centers children horizontally
    alignItems: 'center', // <-- This centers children vertically
    borderRadius: 2,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
    '&:hover': {
      filter: 'brightness(120%)',
    },
  },
  text: {
    ml: 0,
    mt: 2.2,
    flexGrow: 1,
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    color: 'primary.text',
    fontSize: 14,
    letterSpacing: 2,
  },
};
export const ListStyle = {
  list: {
    width: '100%',
    mb: 0,
    p: 1,
    overflow: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  text: {
    ml: 0,
    mt: 2.2,
    flexGrow: 1,
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 2,
  },
};
export const SingleItemStyle = {
  container: {
    mb: 1,
    borderLeft: 8,
    borderRight: 3,
    borderRadius: 3,
    boxShadow: 3,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    ':hover': {
      cursor: 'pointer',
      filter: 'brightness(120%)',
      backgroundColor: 'primary.main',
      transition: 'all 0.2s ease-in-out',
    },
  },
  imageHolder: {
    p: 0,
    m: 0,
    width: 88,
    height: 88,
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    backgroundColor: 'primary.light',
    display: 'flex',
  },
  header: {
    letterSpacing: 1,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: 'primary.text',
    fontSize: 14,
  },
  featuresName: {
    width: 110,
    fontSize: 10,
    letterSpacing: 1,
    fontWeight: 'bold',
    textAlign: 'left',
    color: 'primary.text',
  },
  value: {
    height: 64,
    pr: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'stretch',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: 'secondary.text',
    fontSize: 15,
  },
  brand: {
    pr: 1,
    letterSpacing: 0,
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    fontSize: 12,
  },
  chip: {
    height: 56,
    width: 56,
    borderRadius: 20,
    fontSize: 16,
    p: 0,
    m: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    color: 'primary.text',
  },
};
export const FooterStyle = {
  text: {
    pr: 1,
    letterSpacing: 0,
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    fontSize: 14,
  },
  value: {
    pr: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'stretch',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: 'secondary.text',
    fontSize: 15,
  },
};
export const SectionHeader = {
  icon: {
    fontSize: 32,
    color: 'primary.text',
  },
  title: {
    ml: 1,
    mt: 0.7,
    flexGrow: 1,
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: 14,
    color: 'primary.text',
    letterSpacing: 2,
  },
};
export const iconButtonAddIconStyle = {
  mt: 3,
  mr: 0.5,
  width: 20,
  height: 20,
  backgroundColor: 'primary.light',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 2,
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
  '&:hover': {
    filter: 'brightness(120%)',
    color: 'secondary.text',
  },
};
export const TextFieldStyle = {
  '& .Mui-focused': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  backgroundColor: 'rgba(0, 0, 0, 0.05)'
};
export const SelectStyle = {
  width: '100%',
  '& .Mui-focused': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  backgroundColor: 'rgba(0, 0, 0, 0.05)'
};
export const TextFieldLabelStyle = {
  width: '100%',
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  letterSpacing: 1,
  fontSize: 12,
  color: 'primary.text',
};
export const SelectHeaderStyle = {
  width: '100%',
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  letterSpacing: 1,
  fontSize: 12,
  color: 'primary.text',
};

export const ProductFormStyle = {
  addIcon: iconButtonAddIconStyle,
  select: SelectStyle,
  textField: TextFieldStyle,
  textFieldLabel: TextFieldLabelStyle,
  SelectHeader: SelectHeaderStyle,
};
export const StorageFormStyle = {
  icon: {
    fontSize: 38,
    color: 'primary.text',
    m: 1,
  },
  productName: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 2,
    color: 'primary.text',
  },
  textFieldHeader: {
    fontSize: 14,
    width: '100%',
    letterSpacing: 1,
    pl: 1,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: 'primary.text',
  },
  productBrand: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    letterSpacing: 1,
    textAlign: 'right',
    color: 'primary.text',
  },
  featuresName: {
    width: 110,
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: 'bold',
    textAlign: 'left',
    color: 'primary.text',
  },
  value: {
    pr: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'stretch',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: 'secondary.text',
    fontSize: 15,
  },
  brand: {
    pr: 1,
    letterSpacing: 0,
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    fontSize: 12,
  },
};

import {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilteredListWithMultiple } from 'functions/misc';
import { SingleProductView } from 'components/ui/SingleProductView';
import useWindowsDimension from 'functions/useWindowsDimension';
import _ from 'lodash';

import { Grid, List, Paper } from '@mui/material';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';
import { ICategoriesViewModel } from 'Interfaces/IGlobalTypeConfig';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import { useTranslation } from 'react-i18next';

type Props = {
  categories: ICategoriesViewModel[];
  products: IProductViewModel[];
  handleClick: (
    Event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: IProductViewModel
  ) => void;
  handleNew: any;
};

export const ProductList = ({
  categories,
  products,
  handleClick,
  handleNew,
}: Props) => {
const { t } = useTranslation();

  /* INITIALIZATION */
  const { height } = useWindowsDimension();
  let navigate = useNavigate();

  // states
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [productTypes, setProductTypes] = useState([]);

  /* FUNCTIONS */

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    let filterValue = filterType === 'all' ? '' : filterType; // This is your filter value based on the filter type
    // Now call getFilteredList once with both search and filter values
    let searchRows = getFilteredListWithMultiple(
      products,
      e.target.value,
      filterValue
    );
    setFilteredProducts(searchRows);
  };

  const handleFilterChange = (e: any) => {
    setFilterType(e);
    let filterValue = e.toLowerCase() === 'all' ? '' : e;
    // Now ensure we're considering the current search value as well
    let filteredRows = getFilteredListWithMultiple(
      products,
      search,
      filterValue
    );
    setFilteredProducts(filteredRows);
  };

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
  }, [filteredProducts]);

  useEffect(() => {
    let tmp: any = [];
    categories.forEach((category: ICategoriesViewModel) => {
      category.productTypes.forEach((type) => {
        tmp.push(type);
      });
    });
    setProductTypes(tmp.sort((a: any, b: any) => a.name.localeCompare(b.name)));
  }, [categories]);
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        backgroundColor: 'primary.main',
        borderRadius: 3,
      }}
    >
      <Grid item xs={12} sx={{ m: 0 }}>
        <SearchFilter
          Icon={ImportantDevicesRoundedIcon}
          Header={t("Products.ProductList.products")}
          filterValue={filterType}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          handleNew={handleNew}
          selectList={productTypes}
          hasSelectAll={true}
        />
  
        <List
          sx={{
            height: Math.ceil(height - 248),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            overflow: 'auto',
          }}
        >
          {filteredProducts?.length === 0 && <div>{t("Products.ProductList.noProductsFound")}</div>}
          {filteredProducts?.map((product, index) => (
            <Paper
              variant='elevation3'
              key={index}
              onClick={(e) => {
                handleClick(e, product);
              }}
            >
              {product.productType !== null && (
                <SingleProductView
                  key={index}
                  index={index}
                  product={product}
                />
              )}
            </Paper>
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
  
};

import {  
  useGet,  
} from 'http/useInnovit';
import {  
  rq,  
} from 'http/apiRoutes';
import * as yup from 'yup';
import {
  Grid,
  Paper,
} from '@mui/material';
import CompanyView from 'components/ui/CompanyView';

let _ = require('lodash');

const schema = yup
  .object()
  .shape({
    organizationNumber: yup.string().min(9).max(9).required(),
    name: yup.string().min(3).max(50).required(),
    mainAddress: yup.object().shape({
      street: yup.string().required(),
      postalCode: yup.string().min(4).max(4).required(),
      city: yup.string().min(2).max(50).required(),
    }),
    phone: yup.string().min(8).max(16).required(),
    email: yup.string().email().required(),
  })
  .required();

export const MainCompany = () => {
  /* INITIALIZATIONS */
  //const [account, setAccount] = useAtom(userAccount);
  // console.log("MainCompany account ", account);
  let isEmployee = true;
  const { data: company } = useGet(rq.appOwner(isEmployee), isEmployee);
  //console.log('MainCompany company ', company);
  return (
    <>
      <Grid item xs={12}>
        <Paper variant='elevation3' sx={{ p: 2 }}>
          {company && <CompanyView company={company} />}
        </Paper>
      </Grid>
    </>
  );
};

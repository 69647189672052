import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
// @ts-ignore
import { SinglePackageView } from 'components/ui/SinglePackageView';

import { Grid, Paper, List } from '@mui/material';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { SearchFilter } from 'components/ui/SearchFilter';
import { IPackageViewModel } from '../../Interfaces/IPackageConfig';
import { getFilteredList } from 'functions/misc';
import { useTranslation } from 'react-i18next';

export const PackagesList = () => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const { companyId, packageId } = useParams();
  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const [packages, setPackages] = useState([] as IPackageViewModel[]);
  // api
  let isEnabled = companyId ? true : false;
  const { data: packageData } = useGet(rq.packages(companyId || '', isEnabled));
  const [filterType, setFilterType] = useState([
    { id: 1, name: 'All' },
    { id: 2, name: 'Active' },
  ]);
  const [search, setSearch] = useState('');
  const ref = useRef(2);
  // handles
  const handleNew = (e: any) => {
    e.preventDefault();
    navigate(`/companies/${companyId}/packages/new`);
  };

  const handleFilterChange = (e: any) => {
    setFilterType(e.target.value);
    ref.current = e.target.value;
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("search", str);
    // setSearch(str);
    // const searchRegex = new RegExp(escapeRegExp(str), "i");
    // const filteredRows = data.filter((row) => {
    //   return Object.keys(row).some((field) => {

    //     return searchRegex.test(row[field].toString());
    //   });
    // });
    setPackages(getFilteredList(packageData?.productPackages, e.target.value));
    return;
  };

  useEffect(() => {
    if (packageData) {
     // console.log('packageData', packageData);
      setPackages(
        (packageData.productPackages as IPackageViewModel[]) ||
          ([] as IPackageViewModel[])
      );
    }
  }, [packageData]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={12} sx={{ p: 0, m: 0 }}>
        <SearchFilter
          Icon={PeopleAltTwoToneIcon}
          Header={t('company.packages.listHeader')}
          filterValue={filterType}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          handleNew={handleNew}
          // selectList={[]}
        />

        <List
          sx={{
            height: Math.ceil(height - 244),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {packages?.map((_package: IPackageViewModel, index: number) => (
            <Paper
              variant='elevation3'
              sx={{ mb: 1 }}
              key={index}
              onClick={() => {
                navigate(
                  '/companies/' + companyId + '/packages/' + _package.id
                );
              }}
            >
              {<SinglePackageView packageData={_package} />}
            </Paper>
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
};

import { FormControlLabel, Switch } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';

export const FormSwitch = (props: any) => {
  const { t } = useTranslation();

  const { name, control } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Switch
                sx={{}}
                onChange={(e) => {
                   onChange(e); 
                  props.onSwitchChange &&
                    props.onSwitchChange(
                      props.value !== undefined ? !props.value : !value
                    );
                }}
                color="secondary"
                checked={props.value !== undefined ? props.value : value}
              />
            }
            label={
              props.value !== undefined
                ? props.value
                  ? `${t("FormSwitch.yes")}`
                  : `${t("FormSwitch.no")}`
                : value
                ? `${t("FormSwitch.yes")}`
                : `${t("FormSwitch.no")}`
            }
          />
        )}
      />
    </>
  );
};

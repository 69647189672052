import React, { useEffect, useState } from 'react';
import { SingleTicketView } from './SingleTicketView';
import { useDraggable } from '@dnd-kit/core';

export function SortableSingleTicket(props: any) {
  const { id, ticket, ticketIndex, columnId, setActiveTicket } = props;
  // console.log('SortableSingleTicket', props);
  const [isDisabled, setIsDisabled] = useState(false);
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: props.id,
      disabled: isDisabled,
      data: { type: 'ticket', ticket },
    });

  const style: React.CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
      }
    : {
        cursor: !isDisabled ? 'grab' : 'default',
      };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <SingleTicketView
        key={ticketIndex}
        index={id}
        ticket={ticket}
        setIsDragDisabled={setIsDisabled}
        //setCompany={setCompany}
      />
    </div>
  );
}

import {
  Grid,
  List,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import React, { useEffect, useReducer, useState } from 'react';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  IOrderPostViewModel,
  IOrderViewModel,
} from 'Interfaces/IOrderConfig';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import _ from 'lodash';
import { useGet} from 'http/useInnovit';
import { rq} from 'http/apiRoutes';
import { EnumOrderStatus } from 'Interfaces/IGlobalTypeConfig';
import { SingleOrderView } from './OrderPost/SingleOrderView';
import { SingleOrderCompanyView } from 'components/ui/SingleOrderCompanyView';
import dayjs from 'dayjs';
import { SingleReceivedOrderPostView } from './OrderPost/SingleReceivedOrderPostView';
import { useTranslation } from 'react-i18next';

const initialState: any = {
  orderList: [] as IOrderViewModel[], // left side object
  selectedOrder: {
    id: null,
    orderNo: null,
    orderStatus: EnumOrderStatus.Ordered,
    orderDate: dayjs(new Date()).toDate(),
    trackingNo: null,
    orderUnitCost: 0,
    orderRetailValue: 0,
    deliveryDate: null,
    estimatedDeliveryDate: null,
    vendorInvoiceNumber: null,
    vendorId: '',
    vendor: null,
    vendorContactId: '',
    vendorContact: null,
    shippingCost: 0,
    deliveryStreet: '',
    deliveryPostalCode: '',
    deliveryArea: '',
    deliveryCity: '',
    deliveryCountry: 'string',
    attention: null,
    orderPosts: null,
    responsibleId: '',
  } as IOrderViewModel, //right side object
};
type Action =
  | { type: 'RESET_FORM' }
  | { type: 'SET_ORDERS'; payload: IOrderViewModel[] }
  | { type: 'SET_SELECTED_ORDER'; payload: IOrderViewModel }
  | { type: 'CHANGE_ORDER_POST'; payload: IOrderPostViewModel };
function stateReducer(state: any, action: Action): any {
  let _orderList = _.cloneDeep(state.orderList);
  switch (action.type) {
    case 'RESET_FORM':
      return initialState;
    case 'SET_ORDERS':
      return { ...state, orderList: action.payload };
    case 'SET_SELECTED_ORDER':
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case 'CHANGE_ORDER_POST':
      let _selectedOrder = _.cloneDeep(state.selectedOrder);
      let _orderPosts = _.cloneDeep(_selectedOrder.orderPosts);
      let _orderPostIndex = _orderPosts.findIndex(
        (obj: IOrderPostViewModel) => obj.id === action.payload.id
      );
      _orderPosts[_orderPostIndex] = action.payload;
      _selectedOrder.orderPosts = _orderPosts;
      return {
        ...state,
        selectedOrder: _selectedOrder,
      };
    default:
      return state;
  }
}

const OrderStatus = (orderPosts: IOrderPostViewModel[]) => {
  let status = EnumOrderStatus.InTransit;
  if (orderPosts) {
    orderPosts.forEach((orderPost: IOrderPostViewModel) => {
      if (
        orderPost.deliveredQuantity > 0 &&
        orderPost.deliveredQuantity < orderPost.quantity
      ) {
        status = EnumOrderStatus.PartialDelivered;
      //  console.log('PartialDelivered');
      }
    });
  }
  if (status === EnumOrderStatus.InTransit) {
    status = EnumOrderStatus.Delivered;
    orderPosts.forEach((orderPost: IOrderPostViewModel) => {
      if (
        orderPost.deliveredQuantity > 0 &&
        orderPost.deliveredQuantity < orderPost.quantity
      ) {
        status = EnumOrderStatus.PartialDelivered;
     //   console.log('Delivered');
      }
    });
  }
  if (status === EnumOrderStatus.Delivered) {
    orderPosts.forEach((orderPost: IOrderPostViewModel) => {
      if (
        orderPost.deliveredQuantity === 0 &&
        orderPost.deliveredQuantity < orderPost.quantity
      ) {
        status = EnumOrderStatus.InTransit;
       // console.log('Delivered');
      }
    });
  }
  return status;
};

export const OrderHistory = () => {
const { t } = useTranslation();

  // INITIALIZATION
  const { height } = useWindowsDimension();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [isReloadOrdersData, setIsReloadOrdersData] = useState<boolean>(true);

  // API
  const { data: ordersData } = useGet(
    rq.orders(EnumOrderStatus.Delivered, isReloadOrdersData),
    isReloadOrdersData
  );

  // HANDLES
  const handleSelectedOrder = (e: any, obj: IOrderViewModel) => {
    e.preventDefault();

    dispatch({ type: 'SET_SELECTED_ORDER', payload: obj });
  };

  // BLURS
  const handleBlur = (obj: IOrderPostViewModel) => {
   // console.log('handleBlur', obj);
    dispatch({ type: 'CHANGE_ORDER_POST', payload: obj });
  };

  useEffect(() => {
    if (ordersData) {
      let _orderList = _.cloneDeep(ordersData.orders);
      //console.log('_orderList useEffect: ', _orderList);
      _orderList.forEach((order: IOrderViewModel) => {
        order.orderStatus = OrderStatus(order.orderPosts || []);
        order.orderPosts &&
          order.orderPosts.forEach((orderPost: IOrderPostViewModel) => {
            orderPost.serialNumbers = null;
          });
      });

    //  console.log('useEffect', _orderList);
      dispatch({
        type: 'SET_ORDERS',
        payload: _orderList,
      });
      setIsReloadOrdersData(false);
    }
  }, [ordersData]);

  return (
    <Grid container spacing={0} sx={{ display: 'flex', flexDirection: 'row' }}>
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: .5, m: 0 }}>
        <SearchFilter 
          Icon={CreditCardIcon} 
          Header={t("Orders.OrderHistory.orderHistory")} 
        />
        <List
          sx={{
            p: 2,
            height: Math.ceil(height - 242),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {!!state.orderList &&
            state.orderList.map((item: IOrderViewModel, index: number) => (
              <Grid
                sx={{
                  backgroundColor: 'transparent',
                  border: 0,
                  boxShadow: 'none',
                }}
                key={index}
                onClick={(e) => {
                  handleSelectedOrder(e, item);
                }}
              >
                <SingleOrderView
                  order={item}
                  isDisabled={true}
                  handleBlur={null}
                />
              </Grid>
            ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
  
      {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ pl: .5, m: 0 }}>
        <SearchFilter 
          Icon={CreditCardIcon} 
          Header={t("Orders.OrderHistory.selectedOrderHistory")} 
        />
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            p: 2,
            backgroundColor: 'primary.main',
          }}
        >
          <Grid item xs={6} sx={{}}>
            {!state.selectedOrder.Vendor && (
              <SingleOrderCompanyView company={state.selectedOrder?.vendor} />
            )}
          </Grid>
          <Grid item xs={2} sx={{}}></Grid>
          <Grid item xs={4} sx={{ m: 0, p: 0, pr: 1 }}>
            <Stack direction={'column'} sx={{}}>
              <Stack direction={'row'} sx={{}}>
                <Typography
                  sx={{ m: 1, pt: 0.5, fontWeight: 'bold', width: '60%' }}
                >
                  {t("Orders.OrderHistory.totalCost")}
                </Typography>
                <Typography
                  sx={{
                    m: 1,
                    pt: 0.5,
                    width: '40%',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  {state.selectedOrder?.orderUnitCost || '0.00'}
                </Typography>
              </Stack>
              <Stack direction={'row'} sx={{}}>
                <Typography
                  sx={{ m: 1, pt: 0.5, fontWeight: 'bold', width: '60%' }}
                >
                  {t("Orders.OrderHistory.shippingCost")}
                </Typography>
                <Typography
                  sx={{
                    m: 1,
                    pt: 0.5,
                    width: '40%',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  {state.selectedOrder?.shippingCost || '0.00'}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <List
          sx={{
            p: 2,
            height: Math.ceil(height - 374),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {state.selectedOrder.orderPosts &&
            state.selectedOrder.orderPosts?.map(
              (orderPost: IOrderPostViewModel, index: number) => (
                <Grid
                  sx={{
                    backgroundColor: 'transparent',
                    border: 0,
                    boxShadow: 'none',
                  }}
                  key={index}
                >
                  <SingleReceivedOrderPostView
                    orderPost={orderPost}
                    isDisabled={false}
                    handleBlur={handleBlur}
                  />
                </Grid>
              )
          )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              p: 0,
              m: 0,
              mb: 2,
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          ></Grid>
        </Paper>
      </Grid>
    </Grid>
  );
  
};

import { Box, Grid, IconButton, List, Paper, Typography } from "@mui/material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { Link } from "react-router-dom";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";

const Marketing = () => {
  return (
    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
      <Paper
        elevation={10}
        sx={{
          mb: 2,
          p: 2,
          width: "100%",
          borderRadius: 3,
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box sx={{ pt: 1 }}>
            <EventNoteRoundedIcon sx={{ fontSize: 40 }} />
          </Box>
          <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
            <Typography
              noWrap
              variant= "subHeader"
            >
              Marketing
            </Typography>
          </Box>
          <Box sx={{}}>
            <Link to={"Company"}>
              <Box sx={{ justifyContent: "flex-end" }}>
                <IconButton
                  onClick={() => {
                    console.info("I'm a button.");
                  }}
                >
                  <AddBoxRoundedIcon
                    sx={{ fontSize: 40, color: "success.main" }}
                  />
                </IconButton>
              </Box>
            </Link>
          </Box>
        </Box>
      </Paper>

      <Grid xs={12}>
        <Paper
          sx={{
            p: 2,
            borderRadius: 3,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          <List
            sx={{
              m: 0,
            }}
          ></List>
        </Paper>
      </Grid>
      <Grid xs={12}>
        <Paper
          sx={{
            width: "100%",
            mt: 2,
            p: 2,
            borderRadius: 3,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
};

export default Marketing;

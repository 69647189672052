export const getTimeDifference = (start, end) => {
  let endTime = new Date(end);
  let startTime = new Date(start);
  let diffMilliseconds = endTime - startTime;

  // Calculate the number of hours and minutes
  let diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
  let diffMinutes = Math.floor(
    (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );

  // Format the hours and minutes as strings
  let diffHoursStr = diffHours.toString().padStart(2, '0');
  let diffMinutesStr = diffMinutes.toString().padStart(2, '0');

  // Combine the hours and minutes into a HH:mm format string
  let diffTimeStr = `${diffHoursStr}:${diffMinutesStr}`;

  return diffTimeStr;
};

export interface IBrandViewModel {
    id: string;
    brandName: string;
    isActive: boolean;
  }

  export enum IEnumCycleAlignment {
    None = 0,
    Product = 1,
    ProductType = 2,
    Category = 3,
  }

  export enum IEnumBrandState {
    All = 0,
    Active = 1,
    Inactive = 2,
  }


import { useEffect, useState } from 'react';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
import { EmployeeList } from 'components/ui/EmployeeList';

import { Button, Drawer, Box } from '@mui/material';

export const EmployeeDrawer = (props) => {
  const {
    companyId,
    buttonIcon,
    handleEmployeeSubmit,
    handleRemoveEmployeeSubmit,
  } = props;
  const { width } = useWindowsDimension();
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState([]);

  //console.log('handleemployee submit', handleEmployeeSubmit)

  // api
  const { data } = useGet(rq.employees(companyId));

  useEffect(() => {
    if (data) {
      //console.log('data', data);
      setEmployees(data.allEmployees);
    }
  }, [data]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%',
          height: '100%',
        }}
      >
        <Button
          sx={{ p: 0, m: 0 }}
          size='small'
          type='open'
          variant='contained'
          onClick={() => setOpen(!open)}
        >
          {buttonIcon}
        </Button>
      </Box>
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(!open)}>
        <Box sx={{ width: width / 2, backgroundColor: 'primary.main' }}>
          {employees.length > 0 && (
            <EmployeeList
              employees={employees}
              setOpen={setOpen}
              handleEmployeeSubmit={handleEmployeeSubmit}
              handleRemoveEmployeeSubmit={handleRemoveEmployeeSubmit}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

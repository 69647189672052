export default function GetSingleMultiBarColor(props: number) {
  // console.log("GetPriorityColor", props);

  let color = 'primary';

  switch (props) {
    case 0:
      color = 'success.light';
      break;
    case 1:
      color = 'info.light';
      break;
    case 2:
      color = 'third.light';
      break;
    case 3:
      color = 'warning.light';
      break;
    default:
      color = 'primary.light';
      break;
  }
  // console.log("GetPriorityColor color - " , color);

  return `${color}`;
}

import React, { memo } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormSelect = memo((props: any) => {
  const { name, control, label, list, handleFilterChange } = props;
  const generateSingleOptions = () => {
    return list.map((option: any) => {
      return (
        <MenuItem key={option.id} value={option.id}>
          {option.name
            ? option.name
            : option.description
            ? option.description
            : option.title
            ? option.title
            : option.brandName}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <FormControl fullWidth variant='standard'>
        <InputLabel>{label}</InputLabel>
        <Controller
          render={({ field: { onChange, value, onBlur } }) => (
            <Select
              value={
                props.statusValue !== undefined ? props.statusValue : value
              }
              /* onChange={(e) => {
                onChange(e.target.value);
              }} */
              onChange={(e) => {
                onChange(e);
                if (handleFilterChange) {
                  handleFilterChange(e.target.value);
                }
              }}
              onBlur={onBlur}
            >
              {generateSingleOptions()}
            </Select>
          )}
          control={control}
          name={name}
          defaultValue={''} // default value due to products feature data
        />
      </FormControl>
    </>
  );
});

/*  return (
    <>
      <FormControl fullWidth variant="standard">
        <InputLabel>{label}</InputLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Select
               
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value}
                error={!!error}
              >
                {generateSingleOptions()}
              </Select>
              {!!error && <FormHelperText>{error.message}</FormHelperText>}
            </>
          )}
          control={control}
          name={header}
        />
      </FormControl>
    </>
  );
});
 */

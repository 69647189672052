import { Grid } from "@mui/material"
import { Outlet } from "react-router"

export const Tickets = () => {

  return (
    <>
      <Grid container rowSpacing={0} sx={{m:0,p:0}}>        
        <Outlet />
      </Grid>
    </>
  )
}
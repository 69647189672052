import { Typography, Grid } from '@mui/material';
import { SectionHeader } from 'data/FontStyle';

export const FormBox = (props: any) => {
  const { Icon, title, FormJSX, FormOBJ, FormBTN, info } = props;
  // console.log('FormBox', props);
  return (
    <Grid item xs={12} display={'flex'} flexDirection={'row'} sx={{ m: 0, p: 0 }}>
      {!!Icon && <Icon sx={{ ...SectionHeader.icon }} />}

      <Typography
        sx={{
         ...SectionHeader.title
        }}
      >
        {title}
      </Typography>

      {!!info && info}

      {!!FormOBJ && <Grid sx={{ width: 130, height: 30 }}>{FormOBJ}</Grid>}
      {!!FormJSX && (
        <Grid sx={{ width: 130, height: 30 }}>
          <FormJSX />
        </Grid>
      )}
      {!!FormBTN && <Grid sx={{pr:0,}}>{FormBTN}</Grid>}
    </Grid>
  );
};

import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { HeaderButtonInterface } from 'Interfaces/IGlobalTypeConfig';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import JoinFullRoundedIcon from '@mui/icons-material/JoinFullRounded';

export const TicketHeaderButtonDetails = (
  linkId1: string | null,
  linkId2: string | null
): HeaderButtonInterface[] => [
  {
    name: "Ticket.TicketStyleConfig.details",
    component: { Link },
    to: `/tickets/${linkId1}`,
    icon: DashboardIcon,
  },

  {
    name: "Ticket.TicketStyleConfig.Communication",
    component: { Link },
    to: `/tickets/${linkId1}/communications`,
    icon: ChatRoundedIcon,
  },

  {
    name: "Ticket.TicketStyleConfig.economy",
    component: { Link },
    to: `/tickets/${linkId1}/economy`,
    icon: AttachMoneyRoundedIcon,
  },
  {
    name: "Ticket.TicketStyleConfig.products",
    component: { Link },
    to: `/tickets/${linkId1}/products`,
    icon: DevicesRoundedIcon,
  },

  {
    name: "Ticket.TicketStyleConfig.productHistory",
    component: { Link },
    to: `/tickets/${linkId1}/productshistory`,
    icon: DevicesOtherRoundedIcon,
  },

  {
    name: "Ticket.TicketStyleConfig.resolution",
    component: { Link },
    to: `/tickets/${linkId1}/resolution`,
    icon: ReceiptRoundedIcon,
  },

  {
    name: "Ticket.TicketStyleConfig.attachments",
    component: { Link },
    to: `/tickets/${linkId1}/attachments`,
    icon: AttachFileRoundedIcon,
  },
  {
    name: "Ticket.TicketStyleConfig.merge",
    component: { Link },
    to: `/tickets/${linkId1}/merge`,
    icon: JoinFullRoundedIcon,
  },
];

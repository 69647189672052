import { Box, ListItem, ListItemText } from '@mui/material';

import { ChartDataColor } from '../../data/variables';

export const ChartDataList = (props: any) => {
  /*  console.log("ChartDataList", props); */
  return (
    <ListItem
      alignItems={'center'}
      sx={{
        m: 0.5,
        p: 0,
        borderRadius: 2,
        border: 1,
        borderColor: 'primary.dark',
        backgroundColor: ChartDataColor[props.id],
      }}
      disableGutters
    >
      <ListItemText
        sx={{
          m: 0,
          p: 0.25,
        }}
        primaryTypographyProps={{
          m: 0,
          p: 0,
          fontSize: '14px',
          letterSpacing: 2,
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: 'primary.dark',
        }}
        secondaryTypographyProps={{
          m: 0,
          p: 0,
          fontSize: '18px',
          letterSpacing: 3,
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: 'primary.dark',
        }}
        primary={
          <Box
            component='span'
            sx={{
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {props.name}
          </Box>
        }
        secondary={props.value}
      ></ListItemText>
    </ListItem>
  );
};

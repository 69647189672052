import { atom } from 'jotai';

const loadAccessToken = () => {
  const token = sessionStorage.getItem('accessToken');
  return token ? token : '';
};

// Utility function to save the token to local storage
const saveAccessToken = (token: any) => {
  sessionStorage.setItem('accessToken', token);
};

const accessTokenAtom = atom(
  loadAccessToken(), // Initial state from local storage
  (get, set, update) => {
    saveAccessToken(update);
    set(accessTokenAtom, update);
  }
);
accessTokenAtom.debugLabel = 'accessTokenAtom';
export default accessTokenAtom;

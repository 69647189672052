import { Grid, Paper } from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import React from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useTranslation } from 'react-i18next';

type Props = {};

export const OrderFinance = (props: Props) => {
const { t } = useTranslation();

return (
  <Grid
    container
    sx={{
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    }}
  >
    <Grid item xs={12} sx={{ p: 0, m: 0 }}>
      <SearchFilter
        Icon={AttachMoneyIcon}
        Header={t("Orders.OrderFinance.finance")}
      />
      <Paper
        variant='elevation3'
        sx={{
          p: 2,
          height: 64,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      ></Paper>
    </Grid>
  </Grid>
);

};

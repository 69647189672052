import { SingleTicketHistory } from "components/ui/SingleTicketHistory";
import useWindowsDimension from "functions/useWindowsDimension";
import { SubHeading } from "components/ui";
import {
  Box,
  Grid,
  InputAdornment,
  List,
  Paper,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { ProductCard } from "components/ui/ProductCard";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import CreditScoreRoundedIcon from "@mui/icons-material/CreditScoreRounded";
import DevicesOtherRoundedIcon from "@mui/icons-material/DevicesOtherRounded";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import PermIdentityRoundedIcon from "@mui/icons-material/PermIdentityRounded";
import PermIdentityTwoToneIcon from "@mui/icons-material/PermIdentityTwoTone";

export const SaleEconomy = () => {
  let ticket = [];
  return (
    <div>
      <Paper variant="elevation3" sx={{ ml: 2, mb: 2, p: 2, height: 358 }}>
        <Box
          sx={{
            display: "flex",

            p: 1,
            mb: 2,
          }}
        >
          <Box sx={{ pt: 1 }}>
            <CreditScoreRoundedIcon sx={{ fontSize: 40 }} />
          </Box>
          <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
            <Typography noWrap variant="subHeader">
              economics
            </Typography>
          </Box>
          <Box sx={{}}></Box>
        </Box>

        <TextField
          sx={{ width: "50%", p: 2 }}
          id="input-with-icon-textfield"
          label="Fixed Time"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WatchLaterRoundedIcon />{" "}
                {/* WatchLaterRoundedIcon QueryBuilderRoundedIcon */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          defaultValue={ticket.fixedTime}
        />
        <TextField
          sx={{ width: "50%", p: 2 }}
          id="input-with-icon-textfield"
          label="Max Time"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <QueryBuilderRoundedIcon />{" "}
                {/* WatchLaterRoundedIcon QueryBuilderRoundedIcon */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          defaultValue={ticket.maxTime}
        />
        <TextField
          sx={{ width: "50%", p: 2 }}
          id="input-with-icon-textfield"
          label="Fixed Price"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaymentsRoundedIcon />{" "}
                {/* PaymentsRoundedIcon  CreditCardRoundedIcon */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          defaultValue={ticket.fixedPrice?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
        <TextField
          sx={{ width: "50%", p: 2 }}
          id="input-with-icon-textfield"
          label="Max Price"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CreditCardRoundedIcon />{" "}
                {/* PaymentsRoundedIcon  CreditCardRoundedIcon */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          defaultValue={ticket.maxPrice?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
        <TextField
          sx={{ width: "50%", p: 2 }}
          id="input-with-icon-textfield"
          label="Fixed Rate Senior "
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityTwoToneIcon />
                {/* PermIdentityTwoToneIcon PermIdentityRoundedIcon */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          defaultValue={ticket.fixedSeniorRate?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
        <TextField
          sx={{ width: "50%", p: 2 }}
          id="input-with-icon-textfield"
          label="Fixed Rate Junior"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityRoundedIcon />
                {/* PaymentsRoundedIcon  CreditCardRoundedIcon */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          defaultValue={ticket.fixedJuniorRate?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
      </Paper>
    </div>
  );
};

import {
  Box,
  Button,
  Grid,
  List,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useEffect, useReducer, useState } from 'react';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { SearchFilter } from 'components/ui/SearchFilter';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { SingleCompanyProductView } from 'components/ui/SingleCompanyProductView';
import { FormBox } from 'components/form';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { ObjectDrawer } from './ObjectDrawer';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import EventIcon from '@mui/icons-material/Event';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
// @ts-ignore
import { getFilteredList } from 'functions/misc';

import {
  ICompanyProductUpdateViewModel,
  ICompanyProductViewModel,
} from '../../Interfaces/ICompanyProductConfig';
import {
  EnumObjectType,
  EnumPlacement,
  ObjectCompanyProductFilterList,
  allObjectList,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import GenerateGuid from 'functions/GenerateGuid';
import { IProductUpdateViewModel } from 'Interfaces/ICompaniesConfig';
import { IResourcesViewModel } from 'Interfaces/IResourcesConfig';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
let _ = require('lodash');

const initialState: ICompanyProductViewModel = {
  id: '',
  serviceTag: '',
  productId: '',
  guaranteeEndDate: null,
  product: {} as IProductUpdateViewModel,
  companyId: GenerateGuid(),
  purchaserId: GenerateGuid(),
  ownerId: null,
  owner: {} as IEmployeeViewModel,
  comment: '',
  lastBilled: null,
  quantity: 1,
  unitPrice: 0,
  retailPrice: 0,
  discount: 0,
  lifetime: 0,
  security: true,
  status: 0,
  productWarning: '',
  placement: 0 as EnumPlacement,
  isActive: false,
  isBillable: true,
  serialNumber: '',
  resourceId: null,
  toggleEdit: false,
  isDateEditable: false,
  resource: {} as IResourcesViewModel,
};
type Action =
  | { type: 'TOGGLE_IS_ACTIVE'; payload: boolean }
  | { type: 'TOGGLE_EDIT'; payload: boolean }
  | { type: 'SET_DATE_EDITABLE'; payload: boolean }
  | { type: 'SET_PRODUCT'; payload: ICompanyProductViewModel }
  | {
      type: 'UPDATE_FIELD';
      field: keyof ICompanyProductViewModel;
      payload: any;
    };
const reducer = (
  state: ICompanyProductViewModel,
  action: Action
): ICompanyProductViewModel => {
  switch (action.type) {
    case 'SET_PRODUCT':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.payload,
      };
    case 'SET_DATE_EDITABLE':
      return {
        ...state,
        isDateEditable: action.payload,
      };
    case 'TOGGLE_EDIT':
      return {
        ...state,
        toggleEdit: action.payload,
      };
    default:
      return state;
  }
};

export const CompanyProducts = () => {
  /* INITIALIZATION */
  const { width, height } = useWindowsDimension();
  const { companyId } = useParams() as any;
  const navigate = useNavigate();
  const [companyProducts, setCompanyProducts] = useState<
    ICompanyProductViewModel[]
  >([]);
  const [filteredCompanyProductList, setFilteredCompanyProductList] = useState<
    ICompanyProductViewModel[]
  >([]);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const [employees, setEmployees] = useState([] as IEmployeeViewModel[]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [filterType, setFilterType] = useState('All');
  const [search, setSearch] = useState('');
  // API
  let isEnabled = companyId ? true : false;
  const { data: companyProductData } = useGet(
    rq.companyProducts(companyId, isEnabled),
    isEnabled
  );
  const { data: employeeData } = useGet(rq.employees(companyId, isEnabled));
  let employeeId = '';
  const postEmployeeDeactivate = usePost(
    rqPost.employeeDeactivate(companyId, employeeId, true)
  );
  const putEmployee = usePut(
    rqPost.employee(companyId, employeeId, true),
    true
  );
  let productId = state.id ? state.id : '';
  const updateCompanyProducts = usePut(
    rqPost.updateCompanyProduct(productId, true)
  );

  // FUNCTIONS
  interface OHjsxProps {
    onOff: boolean;
    dispatch: React.Dispatch<Action>;
  }
  const IsActiveSwitch: React.FC<OHjsxProps> = ({ onOff, dispatch }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'TOGGLE_IS_ACTIVE',
        payload: e.target.checked,
      });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onOff ? 'YES' : 'NO'}
          </Typography>

          <Switch
            color={onOff ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onOff ? 'secondary.light' : 'primary.text',
              },
              '& .MuiSwitch-track': {
                backgroundColor: onOff
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)',
              },
            }}
            checked={onOff}
            onChange={handleToggle}
            name='Is company product active'
          />
        </Stack>
      </Grid>
    );
  };
  interface OHjsxProps {
    onOff: boolean;
    dispatch: React.Dispatch<Action>;
  }

  // HANDLES
  const handleClick = (e: any, product: ICompanyProductViewModel) => {
    //console.log('handleClick ', e, product);
    let _product = product;
    _product.productId = product.product.id;
    dispatch({
      type: 'SET_PRODUCT',
      payload: _product,
    });
  };
  const handleDeleteConfirm = (event: any) => {
    // console.log('delete ', state);
    setDeleteConfirm(false);
  };
  const handleChangeEmployee = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.CompanyEmployees:
        let object = selectedObject as IEmployeeViewModel;
        //console.log('handleChangeEmployee ', object);
        dispatch({
          type: 'UPDATE_FIELD',
          field: 'ownerId',
          payload: object.id,
        });
        dispatch({
          type: 'UPDATE_FIELD',
          field: 'owner',
          payload: object,
        });
        break;
      default:
        break;
    }
  };

  const handleFilter = (e: any) => {
    setFilterType(e);
    // console.log('handleFilter ', e);
    let filterValue = 0;
    if (e === 'All') {
      filterValue = 99;
    }
    let filteredByPlacement = companyProducts.filter(
      (item: any) => item.placement <= filterValue
    );
    setFilteredCompanyProductList(filteredByPlacement);
    setFilterType(e);
  };

  const handleSearch = (e: any) => {
    // console.log('handleSearch ', companyProductData?.companyProducts, e);
    setFilteredCompanyProductList(
      getFilteredList(companyProductData?.companyProducts, e.target.value)
    );
  };

  // USE EFFECTS
  useEffect(() => {
    console.log('companyProductData ', companyProductData);
    if (companyProductData?.companyProducts?.length > 0) {
      allObjectList[5].objectList = companyProductData.companyProducts;
      setCompanyProducts(companyProductData.companyProducts);
      setFilteredCompanyProductList(companyProductData.companyProducts);

      if (companyProductData.companyProducts[0].guaranteeEndDate) {
        dispatch({
          type: 'UPDATE_FIELD',
          field: 'guaranteeEndDate',
          payload: companyProductData.companyProducts[0].guaranteeEndDate,
        });
      }
    }
  }, [companyProductData]);

  useEffect(() => {
    if (employeeData && employeeData?.allEmployees) {
      allObjectList[3].objectList = employeeData.allEmployees;
      setEmployees(employeeData.allEmployees);
    }
  }, [employeeData]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    let companyProduct: ICompanyProductUpdateViewModel = {
      id: state.id ? state.id : '',
      serviceTag: state.serviceTag,
      productId: state.product.id,
      product: state.product,
      quantity: +state.quantity,
      companyId: state.companyId,
      comment: state.comment,
      unitPrice: state.unitPrice,
      retailPrice: state.retailPrice,
      discount: state.discount,
      lifetime: state.lifetime,
      security: state.security,
      status: state.status,
      productWarning: state.productWarning,
      guaranteeEndDate: state.guaranteeEndDate,
      purchaserId: state.purchaserId,
      ownerId: state.ownerId,
      placement: state.placement,
      isActive: state.isActive,
      serialNumber: state.serialNumber,
      resourceId: state.resourceId,
    };
    //console.log('companyProductList ', companyProduct);
    updateCompanyProducts.mutate(companyProduct, {
      onSuccess: (res: any) => {
        console.log('updateCompanyProducts');
      },
    });
  };

  console.log('PRODUCTS STATE', state);

  return (
    <Grid container>
      {/* LEFT SIDE */}
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',

          borderRadius: 3,
          pr: 0.5,
        }}
      >
        <Grid item xs={12} sx={{ p: 0, m: 0 }}>
          <SearchFilter
            Icon={SupportAgentRoundedIcon}
            Header='Company Products'
            filterValue={filterType}
            handleFilterChange={handleFilter}
            selectList={ObjectCompanyProductFilterList}
            handleSearch={handleSearch}
            hasSelectAll={true}
          />

          <List
            sx={{
              height: Math.ceil(height - 242),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {filteredCompanyProductList?.length === 0 && <div>No data</div>}
            {filteredCompanyProductList?.map((product: any, index: any) => (
              <div
                key={index}
                onClick={(e: any) => {
                  handleClick(e, product);
                }}
              >
                <SingleCompanyProductView
                  key={index}
                  index={index}
                  product={product}
                />
              </div>
            ))}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          ></Paper>
        </Grid>
      </Grid>
      {/* LEFT SIDE */}
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: 3,
          pl: 0.5,
        }}
      >
        <Grid item xs={12} sx={{ p: 0, m: 0 }}>
          <SearchFilter
            Icon={SupportAgentRoundedIcon}
            Header='Company Products'
            //filterValue={filterType}
            //handleFilterChange={handleFilterChange}
            //handleSearch={handleSearch}
            //handleNew={handleNew}
            //selectList={companyTypes}
          />

          <List
            sx={{
              height: Math.ceil(height - 242),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {state.product.name?.length > 0 && (
              <Grid item xs={12} sx={{}}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: Math.ceil(height - 650),
                      backgroundColor: 'primary.main',
                      borderRadius: 2,
                      boxShadow:
                        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)',
                    }}
                  >
                    <FormBox
                      Icon={PersonRoundedIcon}
                      title='owner'
                      FormJSX={() => (
                        <Grid
                          // item
                          sx={{
                            p: 1,
                            // outline: '1px solid red',
                            position: 'absolute',
                            right: 30,
                            justifyContent: 'flex-end',
                            width: 26,
                          }}
                        >
                          <ObjectDrawer
                            buttonContext={'Select employee'}
                            buttonIcon={
                              <ChangeCircleIcon sx={{ fontSize: 26 }} />
                            }
                            objectList={allObjectList}
                            objectType={[EnumObjectType.CompanyEmployees]}
                            handleChange={handleChangeEmployee}
                            buttonWidth={26}
                            buttonHeight={26}
                          />
                        </Grid>
                      )}
                    />

                    {state.product.name?.length > 0 && (
                      <Grid item xs={12} sx={{ p: 1 }}>
                        <SingleCompanyProductView product={state} />
                      </Grid>
                    )}
                  </Paper>
                </Grid>

                {/* quantity */}
                <Paper
                  elevation={1}
                  sx={{
                    mb: 2,
                    backgroundColor: 'primary.main',
                    borderRadius: 2,
                    boxShadow:
                      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid item sx={{ pl: 2, pt: 1.5 }}>
                      <TimesOneMobiledataIcon sx={{ fontSize: 32 }} />
                    </Grid>
                    <Grid item sx={{ pl: 1, pt: 2.2, flexGrow: 1 }}>
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                          fontSize: 14,
                          letterSpacing: 2,
                        }}
                      >
                        Quantity
                      </Typography>
                    </Grid>
                    <TextField
                      sx={{
                        width: 350,
                        pr: 2,
                        pt: 1.5,
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      InputProps={{
                        inputProps: {
                          style: { textAlign: 'right', paddingRight: 10 },
                        },
                      }}
                      value={state.quantity || ''}
                      onChange={(e: any) =>
                        dispatch({
                          type: 'UPDATE_FIELD',
                          field: 'quantity',
                          payload: e.target.value,
                        })
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Grid>
                </Paper>
                <Paper
                  elevation={1}
                  sx={{
                    mb: 2,
                    backgroundColor: 'primary.main',
                    borderRadius: 2,
                    boxShadow:
                      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid item sx={{ pl: 2, pt: 1.5 }}>
                      <EditNoteIcon sx={{ fontSize: 32 }} />
                    </Grid>
                    <Grid item sx={{ pl: 1, pt: 2.2, flexGrow: 1 }}>
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                          fontSize: 14,
                          letterSpacing: 2,
                        }}
                      >
                        Service Tag
                      </Typography>
                    </Grid>
                    <TextField
                      sx={{
                        width: 350,
                        pr: 2,
                        pt: 1.5,
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      value={state.serviceTag || ''}
                      onChange={(e: any) =>
                        dispatch({
                          type: 'UPDATE_FIELD',
                          field: 'serviceTag',
                          payload: e.target.value,
                        })
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Grid>
                </Paper>
                <Paper
                  elevation={1}
                  sx={{
                    mb: 2,
                    backgroundColor: 'primary.main',
                    borderRadius: 2,
                    boxShadow:
                      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid item sx={{ pl: 2, pt: 1.5 }}>
                      <EventIcon sx={{ fontSize: 32 }} />
                    </Grid>
                    <Grid item sx={{ pl: 1, pt: 2.2, flexGrow: 1 }}>
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                          fontSize: 14,
                          letterSpacing: 2,
                        }}
                      >
                        Warranty End Date
                      </Typography>
                    </Grid>
                    {state?.guaranteeEndDate || state.isDateEditable ? (
                      <Grid sx={{ pr: 2, pt: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            value={
                              state.guaranteeEndDate
                                ? dayjs(new Date(state.guaranteeEndDate))
                                : null
                            }
                            onChange={(newValue) => {
                              dispatch({
                                type: 'UPDATE_FIELD',
                                field: 'guaranteeEndDate',
                                payload: newValue,
                              });
                              dispatch({
                                type: 'SET_DATE_EDITABLE',
                                payload: false,
                              }); // make field non-editable after selection
                            }}
                            views={['year', 'month', 'day']}
                          />
                        </LocalizationProvider>
                      </Grid>
                    ) : (
                      <Button
                        variant='contained'
                        sx={{ mr: 2 }}
                        onClick={() =>
                          dispatch({ type: 'SET_DATE_EDITABLE', payload: true })
                        }
                      >
                        Add
                      </Button>
                    )}
                  </Grid>
                </Paper>

                <Paper
                  elevation={1}
                  sx={{
                    mb: 2,
                    backgroundColor: 'primary.main',
                    borderRadius: 2,
                    boxShadow:
                      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid item sx={{ pl: 2, pt: 1.5 }}>
                      <PriceChangeRoundedIcon sx={{ fontSize: 32 }} />
                    </Grid>
                    <Grid item sx={{ pl: 1, pt: 2.2, flexGrow: 1 }}>
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                          fontSize: 14,
                          letterSpacing: 2,
                        }}
                      >
                        retail price
                      </Typography>
                    </Grid>
                    <Button
                      variant='contained'
                      sx={{ mr: 2 }}
                      onClick={() =>
                        dispatch({
                          type: 'TOGGLE_EDIT',
                          // field: 'toggleEdit',
                          payload: !state.toggleEdit, // Toggle the editability state
                        })
                      }
                    >
                      Edit Value
                    </Button>
                    <TextField
                      sx={{
                        width: 350,
                        pr: 2,
                        pt: 1.5,
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      value={state.retailPrice || ''}
                      InputProps={{
                        inputProps: {
                          style: { textAlign: 'right', paddingRight: 10 },
                        },
                      }}
                      disabled={!state.toggleEdit}
                      onChange={(e: any) =>
                        dispatch({
                          type: 'UPDATE_FIELD',
                          field: 'retailPrice',
                          payload: e.target.value,
                        })
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Grid>
                </Paper>

                <Paper
                  elevation={1}
                  sx={{
                    mb: 2,
                    backgroundColor: 'primary.main',
                    borderRadius: 2,
                    boxShadow:
                      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid item sx={{ pl: 2, pt: 1.5 }}>
                      <ReportProblemRoundedIcon sx={{ fontSize: 32 }} />
                    </Grid>
                    <Grid item sx={{ pl: 1, pt: 2.2, flexGrow: 1 }}>
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                          fontSize: 14,
                          letterSpacing: 2,
                        }}
                      >
                        product Warning
                      </Typography>
                    </Grid>
                    <TextField
                      sx={{
                        width: 350,
                        pr: 2,
                        pt: 1.5,
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      value={state.productWarning || ''}
                      onChange={(e: any) =>
                        dispatch({
                          type: 'UPDATE_FIELD',
                          field: 'productWarning',
                          payload: e.target.value,
                        })
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Grid>
                </Paper>

                <Paper
                  elevation={1}
                  sx={{
                    mb: 2,
                    backgroundColor: 'primary.main',
                    borderRadius: 2,
                    boxShadow:
                      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Grid item sx={{ pl: 2, pt: 1.5 }}>
                      <AdjustRoundedIcon sx={{ fontSize: 32 }} />
                    </Grid>
                    <Grid item sx={{ pl: 1, pt: 2.2, flexGrow: 1 }}>
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                          fontSize: 14,
                          letterSpacing: 2,
                        }}
                      >
                        active
                      </Typography>
                    </Grid>
                    <IsActiveSwitch
                      onOff={state.isActive}
                      dispatch={dispatch}
                    />
                  </Grid>
                </Paper>
              </Grid>
            )}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {/*   Submit Button */}
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              {state.isActive === false && (
                <Button
                  variant='contained'
                  onClick={() =>
                    setDeleteConfirm(!deleteConfirm ? true : false)
                  }
                >
                  {postEmployeeDeactivate.isLoading
                    ? 'Deleting Company Product...'
                    : 'Delete Company Product'}
                </Button>
              )}
              {!!deleteConfirm === true && (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleDeleteConfirm}
                >
                  Confirm Delete
                </Button>
              )}
              <Grid sx={{ flexGrow: 1 }}></Grid>
              <Button
                onClick={(e) => {
                  onSubmit(e);
                }}
                variant='contained'
              >
                {putEmployee.isLoading
                  ? 'Updating Company Product...'
                  : 'Update Company Product'}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

import {
  Badge,
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import GetColorByName from 'functions/GetColorByName';
import { EnumOrderPostStatus, EnumOrderStatus, MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import GetPriorityColor from 'functions/GetPriorityColor';
import { IOrderPostViewModel } from 'Interfaces/IOrderConfig';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import GetChartColor from 'functions/GetChartColor';
import { useTranslation } from 'react-i18next';

type Props = {
  orderPost: IOrderPostViewModel;
  isDisabled: boolean;
  handleBlur: {
    (
      event: React.FocusEvent<HTMLInputElement>,
      obj: IOrderPostViewModel,
      name: string,
      value: number
    ): void;
  } | null;
};

type Action =
  | { type: 'RESET_FORM' }
  | { type: 'UPDATE_RETAIL_PRICE'; value: number }
  | { type: 'UPDATE_UNIT_PRICE'; value: number }
  | { type: 'SET_FORM'; payload: IOrderPostViewModel }
  | { type: 'SET_URL'; payload: string }
  | { type: 'UPDATE_UNIT_QUANTITY'; value: number };

const initialState: IOrderPostViewModel = {
  id: '',
  status: EnumOrderPostStatus.New,
  deliveryDate: null,
  companyId: '',
  company: null,
  companyContactId: '',
  companyContact: null,
  ticketId: '',
  ticket: null,
  productId: '',
  product: null,
  productVersion: 0,
  ticketProductId: null,
  ticketProduct: null,
  unitPrice: 0,
  retailPrice: 0,
  quantity: 0,
  deliveredQuantity: 0,
  serialNumbers: null,
  isSerialNumber: false,
  originalQuantity: 0,
  originalUnitPrice: 0,
  originalRetailPrice: 0,
  url: null,
  responsibleId: '',
  prevOrderPostId: null,
};

function stateReducer(
  state: IOrderPostViewModel,
  action: Action
): IOrderPostViewModel {
  switch (action.type) {
    case 'RESET_FORM':
      return initialState;
    case 'UPDATE_RETAIL_PRICE':
      return {
        ...state,
        retailPrice: action.value,
      };
    case 'UPDATE_UNIT_PRICE':
      return {
        ...state,
        unitPrice: action.value,
      };
    case 'SET_FORM':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_URL':
      return {
        ...state,
        url: action.payload,
      };
    case 'UPDATE_UNIT_QUANTITY':
      return {
        ...state,
        quantity: action.value,
      };
    default:
      return state;
  }
}

export const SingleOrderPostProductView = ({
  orderPost,
  isDisabled,
  handleBlur,
}: Props) => {
const { t } = useTranslation();

 /*  console.log('X', orderPost); */
  const [state, dispatch] = useReducer(stateReducer, orderPost);
  const [product, setProduct] = useState<IBillingProductViewModel | null>(
    {} as IBillingProductViewModel
  );
  // INITIALIZATION

  // API

  // HANDLES
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  // EFFECTS
  useEffect(() => {
    dispatch({ type: 'SET_FORM', payload: orderPost });
    let product = orderPost.ticketProduct?.product
      ? orderPost.ticketProduct.product
      : orderPost.ticketProduct?.companyProduct?.product
      ? orderPost.ticketProduct.companyProduct.product
      : orderPost?.product
      ? orderPost.product
      : null;
    setProduct(product);

    let url = orderPost.ticketProduct?.product?.url
      ? orderPost.ticketProduct?.product.url
      : orderPost.ticketProduct?.companyProduct?.product?.url
      ? orderPost.ticketProduct?.companyProduct.product.url
      : orderPost?.product?.url
      ? orderPost.product.url
      : null;
    //console.log('url', url);
    if (url) {
      dispatch({ type: 'SET_URL', payload: url });
    }
  }, [orderPost]);


  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        height: 114,
        mb: 2,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        zIndex: 1,
        backgroundColor: 'primary.main',
        borderColor: `${GetChartColor(Number(state.status))}.lighter`,
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid
        item
        xs={1.5}
        sx={{
          p: 0.3,
          width: 112,
          height: 112,
          borderRadius: 2,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: 'primary.light',
        }}
      >
        {state?.url != null ? (
          <img
            src={state.url}
            style={{
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              objectFit: 'scale-down',
            }}
            alt={'logo'}
          />
        ) : (
          <img
            src={MissingPhoto.PRODUCT_URL}
            style={{
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              objectFit: 'scale-down',
            }}
            alt={'logo'}
          />
        )}
      </Grid>

      <Grid
        item
        xs={10.5}
        sx={{
          pl: 1,
        }}
      >
        <Stack direction={'column'}>
          <Typography
            sx={{
              letterSpacing: 1,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {product?.name}
          </Typography>

          <Grid
            container
            direction={'row'}
            sx={{
              height: 40,
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            {product?.features &&
              product?.features.map((_features: any, index: number) => {
                return (
                  <Grid item key={index} xs={3}>
                    <Grid container direction={'row'}>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          sx={{
                            fontSize: 10,
                            letterSpacing: 1,
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: 'primary.text',
                          }}
                        >
                          {_features.featureName || 'feature'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          sx={{
                            fontSize: 10,
                            letterSpacing: 1,
                            fontWeight: 'normal',
                            textAlign: 'left',
                            color: 'primary.text',
                          }}
                        >
                          {_features.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Stack>
        <Grid item xs={12} sx={{}}>
          <Stack direction={'row'}>
            <Grid item xs={12} sx={{ m: 0, p: 0, pr: 1 }}>
              <Stack direction={'column'} sx={{}}>
                <Typography
                  sx={{
                    width: '100%',
                    letterSpacing: 1,
                    pl: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                {t("Orders.SingleOrderPostProductView.unitPrice")}
                </Typography>
                <TextField
                  sx={{
                    mt: -0.5,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    '& .MuiInputAdornment-root': {
                      '& p': {
                        fontSize: '14px',
                        textAlign: 'left',
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontSize: 14,
                      paddingRight: 0,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <p>.00</p>
                      </InputAdornment>
                    ),
                  }}
                  value={state.unitPrice ? state.unitPrice : 0}
                  onFocus={handleFocus}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_UNIT_PRICE',
                      value: +e.target.value,
                    })
                  }
                  onBlur={(e: any) => {
                    !!handleBlur &&
                      handleBlur(e, state, 'unitPrice', +e.target.value);
                  }}
                  disabled={isDisabled}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ m: 0, p: 0, pr: 1 }}>
              <Stack direction={'column'} sx={{}}>
                <Typography
                  sx={{
                    width: '100%',
                    letterSpacing: 1,
                    pl: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                 {t("Orders.SingleOrderPostProductView.retailPrice")}

                </Typography>
                <TextField
                  sx={{
                    mt: -0.5,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    '& .MuiInputAdornment-root': {
                      '& p': {
                        fontSize: '14px',
                        textAlign: 'left',
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontSize: 14,
                      paddingRight: 0,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <p>.00</p>
                      </InputAdornment>
                    ),
                  }}
                  value={state.retailPrice ? state.retailPrice : 0}
                  onFocus={handleFocus}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_RETAIL_PRICE',
                      value: +e.target.value,
                    })
                  }
                  onBlur={(e: any) => {
                    !!handleBlur &&
                      handleBlur(e, state, 'retailPrice', +e.target.value);
                  }}
                  disabled={true}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ m: 0, p: 0, pr: 1 }}>
              <Stack direction={'column'} sx={{}}>
                <Typography
                  sx={{
                    width: '100%',
                    letterSpacing: 1,
                    pl: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                 {t("Orders.SingleOrderPostProductView.quantity")}
                </Typography>
                <TextField
                  sx={{
                    mt: -0.5,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    '& .MuiInputAdornment-root': {
                      '& p': {
                        fontSize: '14px',
                        textAlign: 'left',
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontSize: 14,
                      paddingRight: 0,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <p></p>
                      </InputAdornment>
                    ),
                  }}
                  value={state.quantity ? state.quantity : 0}
                  onFocus={handleFocus}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_UNIT_QUANTITY',
                      value: +e.target.value,
                    })
                  }
                  onBlur={(e: any) => {
                    !!handleBlur &&
                      handleBlur(e, state, 'quantity', +e.target.value);
                  }}
                  disabled={true}
                />
              </Stack>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';

export const FormText = (props: any) => {
  // console.dir('FormText', props);
  return (
    <Controller
      {...props}
      render={({
        field: { onChange, ref, name, value },
        fieldState: { error },
        formState,
      }) => (
        // console.log('value:', name, value),
        <TextField
          color='secondary'
          fullWidth
          // className="subvariant-hovered"
          sx={props.sx ? props.sx : { m: 0, p: 0 }}
          inputRef={ref}
          helperText={error ? error.message : null}
          error={!!error}
          onChange={(e) => {
            onChange(e);
          }}
          value={value || ''}
          // defaultValue={props.defaultValue}
          // placeholder={props.placeholder}
          label={props.label}
          type={props.type}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                {props.startAdornment}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                {props.endAdornment}
              </InputAdornment>
            ),
          }}
          variant={props.variant ? props.variant : 'standard'}
        />
      )}
    />
  );
};

import { useMemo, useReducer, useRef } from 'react';

import { Avatar, Grid, List, Paper } from '@mui/material';

import useWindowsDimension from 'functions/useWindowsDimension';
import { getFilteredList } from 'functions/misc';
import { useGet, usePut, usePost } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { useState, useEffect } from 'react';
import { SingleTicketView } from 'components/ui/tickets/SingleTicketView';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCorners,
} from '@dnd-kit/core';
import { DroppableTicketList } from '../../components/ui/DroppableTicketList';
import { createPortal } from 'react-dom';

/* FUNCTIONS */

export const TicketsList = () => {
  // INITIALIZATION

  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const [filterType, setFilterType] = useState(1);
  const [user] = useAtom(userAtom);

  const [ticket, setTicket] = useState(null);
  const [activeTicket, setActiveTicket] = useState(null);
  const [activeTicketId, setActiveTicketId] = useState(null);
  const [sortedTickets, setSortedTickets] = useState([]);
  const [sortedUserTickets, setSortedUserTickets] = useState([]);
  const columns = ['newTickets', 'userTickets'];
  const boardRef = useRef(1);

  // API
  let isEnabled = user && Object.keys(user).length > 0 ? true : false;
  console.log('Object.keys(user)', Object.keys(user));

  const { data: userTicketsData } = useGet(
    rq.userTickets(1, user.id, isEnabled),
    isEnabled
  );
  const { data: newTicketsData } = useGet(
    rq.tickets(boardRef.current, 0, isEnabled),
    isEnabled
  );

  // console.log('all tickes', newTicketsData);

  let isPutEnabled = ticket?.id !== undefined ? true : false;
  const { mutate: putTicket } = usePut(
    rqPost.ticketResponsible(activeTicketId, isPutEnabled),
    isPutEnabled
  );
  const { mutate: postTicket } = usePost(
    rqPost.ticketResponsible(activeTicketId, isPutEnabled),
    isPutEnabled
  );

  // const convertToUpdateTicket = (ticket) => {
  //   return {
  //     id: ticket.id,
  //     ticketNo: ticket.ticketNo,
  //     board: ticket.board,
  //     priority: ticket.priority,
  //     status: ticket.status,
  //     companyId: ticket.company.id,
  //     contactId: ticket.contact.id,
  //     creatorId: ticket.creator.id,
  //     responsibleId: ticket.responsible.id,
  //     shippingCost: ticket.shippingCost,

  //     deliveryStreet: ticket.deliveryStreet ?? '',
  //     deliveryPostalCode: ticket.deliveryPostalCode ?? '',
  //     deliveryArea: ticket.deliveryArea ?? '',
  //     deliveryCity: ticket.deliveryCity ?? '',
  //     deliveryCountry: ticket.deliveryCountry ?? '',
  //     deliveryPostBox: ticket.deliveryPostBox ?? '',
  //     attention: ticket.attention,
  //     deliveryDate: ticket.deliveryDate,
  //     schedule: ticket.schedule ?? null,
  //     etcHours: ticket.etcHours,
  //     etcMinutes: ticket.etcMinutes,
  //     jobType: ticket.jobType,
  //     supporTypeId: ticket.supportType.id,
  //     companyProductWithErrorId: ticket.companyProductWithError?.id ?? null,
  //     errorDescriptionId: ticket.errorDescription?.id ?? null,
  //     comment: ticket.comment,
  //     fixedHourlyRate: ticket.fixedHourlyRate,
  //     fixedTime: ticket.fixedTime,
  //     fixedPrice: ticket.fixedPrice,
  //     maxTime: ticket.maxTime,
  //     maxPrice: ticket.maxPrice,

  //     productBilling: ticket.productBilling,
  //     billing: ticket.billing,
  //     title: ticket.title,

  //     companyProducts: [],
  //     productCatalog: [],
  //     storageProduct: [],
  //     unassignedCompanyProducts: [],
  //     techniciansId: [],
  //   };
  // };

  // USEFFECTS
  useEffect(() => {
    if (newTicketsData) {
      // console.log('newTicketsData', newTicketsData);
      let sortedTickets = [...newTicketsData.tickets].sort(
        (a, b) => b.priority - a.priority
      );
      setSortedTickets(sortedTickets);
    }
  }, [newTicketsData]);

  useEffect(() => {
    if (userTicketsData) {
      //console.log('userTicketsData', userTicketsData);
      let sortedTickets = [...userTicketsData.tickets].sort(
        (a, b) => b.priority - a.priority
      );

      setSortedUserTickets(sortedTickets);
    }
  }, [userTicketsData]);

  useEffect(() => {
    console.log('useEffect - sortedTickets', sortedTickets, sortedUserTickets);
  }, [sortedTickets, sortedUserTickets]);

  useEffect(() => {
    //  console.log('useEffect - Filter Type', filterType);
  }, [filterType]);

  // HANDLES
  const handleFilterChange = (e) => {
    // console.log('handleFilterChange', e);
    setFilterType(e);

    boardRef.current = e;
  };

  const handleSearch = (e) => {
    setSortedTickets(getFilteredList(newTicketsData?.tickets, e.target.value));
  };

  const handleAssigneMe = (ticket) => {
    let ticketResponsible = {
      ticketId: ticket.id,
      responsibleId: user.id,
    };
    console.log('ticketResponsible', ticketResponsible);

    putTicket(ticketResponsible, {});

    // console.log('handleAssigneMe', ticket);
    // ticket.responsibleId = user.id;
    // if (ticket.technicians === null) {
    //   ticket.technicians = [];
    // }
    // // let updateTicket = convertToUpdateTicket(ticket);
    //putTicket(ticket.id, user.id);
  };

  const handleRemoveAssign = (ticket) => {
    // console.log('handleRemoveAssigneMe', ticket);
    // if (ticket.technicians === null) {
    //   ticket.technicians = [];
    // }
    // let updateTicket = convertToUpdateTicket(ticket);
    // ticket.responsible = null;
    // ticket.responsibleId = null;
    let ticketResponsible = {
      ticketId: ticket.id,
      responsibleId: null,
    };
    console.log('ticketResponsible', ticketResponsible);
    putTicket(ticketResponsible, {});
  };

  const handleDragStart = (e) => {
    setActiveTicketId(e.active.id);
    //  console.log('handleDragStart', e.active);
    setActiveTicket(e.active.data.current?.ticket);
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    setActiveTicketId(null);
    setActiveTicket(null);

    const getColumnNameForId = (id) => {
      if (sortedTickets.find((ticket) => ticket.id === id)) {
        return 'newTickets';
      } else if (sortedUserTickets.find((ticket) => ticket.id === id)) {
        return 'userTickets';
      }
      return null;
    };

    const activeColumn = getColumnNameForId(active.id);
    //  console.log('active column: ' + activeColumn);
    const overColumn = over ? over.id : null;

    if (overColumn && activeColumn !== overColumn) {
      //  console.log('Item was moved to a different column!', active.id);
      if (activeColumn === 'newTickets') {
        handleAssigneMe(
          sortedTickets.find((ticket) => ticket.id === active.id)
        );
      } else if (activeColumn === 'userTickets') {
        handleRemoveAssign(
          sortedUserTickets.find((ticket) => ticket.id === active.id)
        );
      }
    } else {
      console.log(
        'Item stayed in the same column or was not placed over any.',
        active.id
      );
      return;
    }

    if (activeColumn === 'newTickets' && overColumn === 'userTickets') {
      const ticketToMove = sortedTickets.find(
        (ticket) => ticket.id === active.id
      );
      if (ticketToMove) {
        setSortedTickets((prevState) =>
          prevState.filter((ticket) => ticket.id !== active.id)
        );
        setSortedUserTickets((prevState) => [ticketToMove, ...prevState]);
      }
    } else if (activeColumn === 'userTickets' && overColumn === 'newTickets') {
      const ticketToMove = sortedUserTickets.find(
        (ticket) => ticket.id === active.id
      );

      if (ticketToMove) {
        setSortedUserTickets((prevState) =>
          prevState.filter((ticket) => ticket.id !== active.id)
        );
        setSortedTickets((prevState) => [ticketToMove, ...prevState]);
      }
    }
  };

  // dnd sortable

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          // overflowX: 'hidden',
        }}
      >
        {columns.map((column) => {
          switch (column) {
            case 'newTickets':
              return (
                <Grid item xs={6} sx={{ pr: 0.5 }}>
                  <DroppableTicketList
                    key={column}
                    id={column}
                    tickets={sortedTickets}
                    setActiveTicket={setActiveTicket}
                    handleFilterChange={handleFilterChange}
                    filterType={filterType}
                    handleSearch={handleSearch}
                  />
                </Grid>
              );
            case 'userTickets':
              return (
                <Grid item xs={6} sx={{ pl: 0.5 }}>
                  <DroppableTicketList
                    key={column}
                    id={column}
                    tickets={sortedUserTickets}
                    setActiveTicket={setActiveTicket}
                  />
                </Grid>
              );
            default:
              return null;
          }
        })}
      </Grid>
      {createPortal(
        <DragOverlay>
          {activeTicket && <SingleTicketView ticket={activeTicket} />}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

import { Route, Routes } from 'react-router-dom';
import Dashboard from 'pages/Dashboard';

import {
  Companies,
  CompaniesList,
  Company,
  CompanyDetails,
  Employees,
  Department,
  Departments,
  Resources,
  Resource,
  Package,
  Packages,
} from 'pages/Companies';

import {
  Tickets,
  Ticket,
  TicketDetails,
  TicketCommunications,
  TicketEconomy,
  TicketProducts,
  TicketProductsHistory,
  TicketResolution,
  TicketAttachments,
  TicketsList,
  TicketMerge,
  SelectedTicketMerge,
  NewTicket,
} from 'pages/Tickets';

import Marketing from 'pages/Marketing';
import Finance from 'pages/Finance';

import {
  AppValues,
  MainCompany,
  Setup,
  ProductData,
  MainEmployees,
  WorkRoles,
} from 'pages/Setup';

import {
  OrderDetail,
  OrderPosts,
  OrderReceived,
  OrderFinance,
  OrderHistory,
} from 'pages/Orders';

import { Products, ProductForm } from 'pages/Products';
import { StorageView, Storage } from 'pages/Storage';
import { SaleAttachments } from 'pages/Sales/SaleAttachments';
import { SaleResolution } from 'pages/Sales/SaleResolution';
import { SaleEconomy } from 'pages/Sales/SaleEconomy';
import { SaleProductsHistory } from 'pages/Sales/SaleProductsHistory';
import { SaleProducts } from 'pages/Sales/SaleProducts';
import { SaleCommunications } from 'pages/Sales/SaleCommunications';
import { SaleDetails } from 'pages/Sales/SaleDetails';
import { Sale } from 'pages/Sales/Sale';
import { SalesTable } from 'pages/Sales/SalesTable';
import { Sales } from 'pages/Sales/Sales';

import { Test } from 'pages/Test';
import { CompanyProducts } from 'pages/Companies/CompanyProducts';
import { CompanyTickets } from 'pages/Companies/CompanyTickets';
import { CompanyFinance } from 'pages/Companies/CompanyFinance';
import { Agreements } from 'pages/Companies/Agreements';
import { AccountPage } from 'pages/Account/AccountPage';
import { Feedbacks } from 'pages/Feedback/Feedbacks';
import { BillingDetail } from './pages/Billing/BillingDetail';
import { BillingView } from './pages/Billing/BillingView';
import { Billed } from './pages/Billing/Billed';
import { AutoAnswers } from './pages/Setup';
import { Brand } from './pages/Setup/Brand';
import { Employee } from './pages/Companies';
import { DefaultSettings } from './pages/Setup/DefaultSettings';
import { Modules } from './pages/Setup/Modules';

export const AppRoutes = (props) => {
  return (
    <Routes>
      {/* <Route path="/" element={<App pca={pca} />}> */}
      <Route path='accountPage' element={<AccountPage />} />

      <Route path='BillingView' element={<BillingDetail />}>
        <Route index element={<BillingView />} />
      </Route>
      <Route path='/billing/ordersList' element={<BillingDetail />}>
        <Route index element={<Billed />} />
      </Route>

      <Route path='dashboard' element={<Dashboard />} />

      <Route path='companies' element={<Companies />}>
        <Route index element={<CompaniesList />} />
        <Route path=':companyId' element={<Company />}>
          <Route index element={<CompanyDetails />} />
          <Route path='departments' element={<Departments />}>
            {/* <Route path="new" element={<Department />} /> */}
            <Route path=':departmentId' element={<Department />} />
          </Route>
          <Route path='resources' element={<Resources />}>
            <Route path=':resourceId' element={<Resource />} />
          </Route>
          <Route path='employees' element={<Employees />}>
            <Route path=':employeeId' element={<Employee />} />
          </Route>
          <Route path='packages' element={<Packages />}>
            <Route path=':packageId' element={<Package />} />
          </Route>
          <Route path='products' element={<CompanyProducts />}></Route>
          <Route path='tickets' element={<CompanyTickets />}></Route>
          <Route path='agreements' element={<Agreements />}></Route>
          <Route path='finance' element={<CompanyFinance />}></Route>
        </Route>
      </Route>

      <Route path='tickets' element={<Tickets />}>
        <Route index element={<TicketsList />} />
        <Route path='new' element={<NewTicket />} />
        <Route path=':ticketId' element={<Ticket />}>
          <Route index element={<TicketDetails />} />
          <Route path='communications' element={<TicketCommunications />} />
          <Route path='products' element={<TicketProducts />} />
          <Route path='productsHistory' element={<TicketProductsHistory />} />
          <Route path='economy' element={<TicketEconomy />} />
          <Route path='resolution' element={<TicketResolution />} />
          <Route path='attachments' element={<TicketAttachments />} />
          <Route path='merge' element={<TicketMerge />}>
            <Route path=':selectedTicketId' element={<SelectedTicketMerge />} />
          </Route>
        </Route>
      </Route>

      <Route path='sales' element={<Sales />}>
        <Route index element={<SalesTable />} />
        <Route path=':saleId' element={<Sale />}>
          <Route index element={<SaleDetails />} />
          <Route path='communications' element={<SaleCommunications />} />
          <Route path='products' element={<SaleProducts />} />
          <Route path='productsHistory' element={<SaleProductsHistory />} />
          <Route path='economy' element={<SaleEconomy />} />
          <Route path='resolution' element={<SaleResolution />} />
          <Route path='attachments' element={<SaleAttachments />} />
        </Route>
      </Route>

      <Route path='orders' element={<OrderDetail />}>
        <Route index element={<OrderPosts />} />
        <Route path='orderReceived' element={<OrderReceived />} />
        <Route path='orderHistory' element={<OrderHistory />} />
        {/*<Route path='products' element={<OrderProducts />} />*/}
        <Route path='finance' element={<OrderFinance />} />
      </Route>

      <Route path='Storage' element={<Storage />}>
        <Route index element={<StorageView />} />
        <Route path=':productId' element={<ProductForm />} />
      </Route>

      <Route path='products' element={<Products />}>
        <Route path=':productId' element={<Products />} />
      </Route>

      <Route path='marketing' element={<Marketing />} />
      <Route path='finance' element={<Finance />} />

      <Route path='setup' element={<Setup />}>
        <Route index element={<MainCompany />} />
        <Route path='mainCompany' element={<MainCompany />}></Route>
        <Route path='employees' element={<MainEmployees />}></Route>
        <Route path='autoAnswers' element={<AutoAnswers />}></Route>
        <Route path='appValues' element={<AppValues />}></Route>
        <Route path='productData' element={<ProductData />}></Route>
        <Route path='workRoles' element={<WorkRoles />}></Route>
        <Route path='brand' element={<Brand />}></Route>
        <Route path='defaultSettings' element={<DefaultSettings />}></Route>
        <Route path='modules' element={<Modules />}></Route>
      </Route>

      <Route path='feedback' element={<Feedbacks />} />
      <Route path='test' element={<Test />} />

      {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
              routes for. */}
      {/* <Route path="*" element={<App pca={pca}/>} />
       </Route>
       <Route path="*" element={<App pca={pca} />} />  */}
    </Routes>
  );
};

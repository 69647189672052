export default function GetPriorityColor(value: string | number) {
 // console.log("GetPriorityColor", props);

  let color = "success.light";

  switch (value) {
    case "NONE":
    case 0:
      color = 'priorityNone.light';
      break;
    
    case "LOW":
    case 1:
      color = 'priorityLow.light';
      break;

    case "NORMAL":
    case 2:
      color = 'priorityNormal.light';
      break;

    case "HIGH":
    case 3:
      color = 'priorityHigh.light';
      break;

      case "CRITICAL":
    case 4:
    color = 'priorityCritical.light';
      break;
          
    default:
      color = 'primary.light';
      break;
  }
 // console.log("GetPriorityColor color - " , color);

  return color;
}

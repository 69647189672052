import { useIsFetching } from "react-query";
import LinearProgress from "@mui/material/LinearProgress";

export default function GlobalSpinnerLoader() {
  const isFetching = useIsFetching();

  return isFetching ? (
    <>
      <LinearProgress color="warning" sx={{height: '2px'}} />
    </>
  ) : null;
}

function GetColorByName(name: string) {
  let hash = 0;
  let i;

  if (name) {
    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  if (name === '' || !name) {
    color = 'primary.text';
  }
  return color;
}
export default GetColorByName;
